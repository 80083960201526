import React from "react"
import style from "./Modal.module.css"
import { useDispatch } from "react-redux"
import { hideModals } from "../../../redux/actions/ModalActions"
import { animated, useSpring } from "react-spring"
import c from "classnames"

/**
 * This element is a wrapper which supplies an animated modal
 *
 * @param children the content that is displayed as a modal
 * @param className the classname of the wrapper
 * @param dismiss the function that is called when the outside of the modal is clicked
 * @returns {JSX.Element} the modal wrapper
 * @constructor
 */
export const Modal = ({ children, className, dismiss }) => {
    const dispatch = useDispatch()

    const panelProps = useSpring({
        transform: "translate(0,0)",
        from: { transform: "translate(100%,0)" },
    })

    const wrapperProps = useSpring({
        opacity: "1",
        from: { opacity: "0" },
    })

    return (
        <animated.div
            className={style.modalWrapper}
            onClick={() => (dismiss ? dismiss(false) : dispatch(hideModals()))}
            style={wrapperProps}
        >
            <animated.div
                className={c(style.modal, className)}
                onClick={(evt) => evt.stopPropagation()}
                style={panelProps}
            >
                {children}
            </animated.div>
        </animated.div>
    )
}
