import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
} from "@fortawesome/free-solid-svg-icons"
import tableStyle from "../../../style/table.module.css"
import cn from "classnames"
import ContentLoader from "react-content-loader"

/**
 *
 * @param pagination the pagination object that is rendered
 * @param loading if the pagination is still loading
 * @param onPageChange a function to set the page
 * @returns {JSX.Element|null} the pagination element if a pagination is present, null otherwise
 * @constructor
 */
export const Pagination = ({ pagination, loading, onPageChange }) => {
    if (loading || !pagination) {
        return (
            <div className={tableStyle.paginationBox}>
                <FontAwesomeIcon
                    className={tableStyle.paginationControls}
                    icon={faAngleDoubleLeft}
                />
                <FontAwesomeIcon
                    className={tableStyle.paginationControls}
                    icon={faAngleLeft}
                />

                <ContentLoader
                    className={tableStyle.paginationLoader}
                    viewBox="0 0 130 10"
                >
                    {[...new Array(11)].map((_, i) => (
                        <rect key={i} x={i * 12} y="1" width="9" height="9" />
                    ))}
                </ContentLoader>
                <FontAwesomeIcon
                    className={tableStyle.paginationControls}
                    icon={faAngleRight}
                />
                <FontAwesomeIcon
                    className={tableStyle.paginationControls}
                    icon={faAngleDoubleRight}
                />
            </div>
        )
    }

    if ((pagination?.pages || 0) < 2) {
        return null
    }

    if (!onPageChange) {
        onPageChange = () => {
            console.warn("No page change function")
        }
        console.warn("No page change function")
    }

    const start = Math.max(1, pagination.currentPage - 5)
    const end = Math.min(pagination.pages, pagination.currentPage + 5)

    const pages = Array(10)
        .fill(0)
        .map((_, index) => index + start)
        .filter((v) => v <= end)

    return (
        <div className={tableStyle.paginationBox}>
            <FontAwesomeIcon
                className={tableStyle.paginationControls}
                icon={faAngleDoubleLeft}
                onClick={() => onPageChange(1)}
            />
            <FontAwesomeIcon
                className={tableStyle.paginationControls}
                icon={faAngleLeft}
                onClick={() =>
                    onPageChange(Math.max(1, pagination.currentPage - 1))
                }
            />
            {pages.map((i) => (
                <span
                    key={i}
                    className={cn(
                        tableStyle.pagination,
                        pagination.currentPage === i &&
                            tableStyle.activePagination,
                    )}
                    onClick={() => onPageChange(i)}
                >
                    {i}
                </span>
            ))}
            <FontAwesomeIcon
                className={tableStyle.paginationControls}
                icon={faAngleRight}
                onClick={() =>
                    onPageChange(
                        Math.min(pagination.pages, pagination.currentPage + 5),
                    )
                }
            />
            <FontAwesomeIcon
                className={tableStyle.paginationControls}
                icon={faAngleDoubleRight}
                onClick={() => onPageChange(pagination.pages)}
            />
        </div>
    )
}
