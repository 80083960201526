/**
 * This element shows a placeholder until the ready flag is set
 *
 * @param ready if the placeholder should be shown
 * @param children the content that is shown when ready
 * @param placeholder the placeholder that is shown when not ready
 * @returns the placeholder element
 * @constructor
 */
export const Placeholder = ({ ready, children, placeholder }) => {
    if (ready) {
        return children
    } else {
        return placeholder
    }
}
