import { useHistory, useParams } from "react-router-dom"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { faGrip } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core"
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

import style from "./StrategyRanking.module.css"
import cn from "classnames"
import { useEffect, useState } from "react"
import buttonStyle from "../../../style/button.module.css"
import {
    restrictToVerticalAxis,
    restrictToWindowEdges,
} from "@dnd-kit/modifiers"
import c from "capitalize"
import { Placeholder } from "../../util/placeholder/Placeholder"
import ContentLoader from "react-content-loader"
import { Banner } from "../../util/Banner"
import { showError } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"
import { EnumLabel } from "../../util/table/EnumLabel"

const GET_PRICING_LIST = gql`
    query ($id: UUID!) {
        pricingList(id: $id) {
            id
            name
            displayName
            rankedModelConfigurations {
                name
                id
                rank
                pricingListGroup {
                    id
                    name
                    action
                }
                strategy {
                    id
                    name
                    model {
                        id
                        name
                    }
                }
            }
        }
    }
`

const SET_STRATEGY_ORDER = gql`
    mutation ($id: UUID!, $modelConfigurations: [UUID]) {
        setModelConfigurationOrder(
            id: $id
            modelConfigurationIds: $modelConfigurations
        ) {
            id
            name
            rankedModelConfigurations {
                name
                id
                rank
                pricingListGroup {
                    id
                    name
                    action
                }
                strategy {
                    id
                    name
                    model {
                        id
                        name
                    }
                }
            }
        }
    }
`

const RankItem = ({ item, id, pos }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id })

    return (
        <div
            ref={setNodeRef}
            className={style.listItem}
            style={{
                transform: CSS.Transform.toString(transform),
                transition,
            }}
            {...attributes}
            {...listeners}
        >
            <FontAwesomeIcon icon={faGrip} className={style.dragHandle} />
            <p className={style.rank}>{pos + 1}</p>
            <p>{item?.name}</p>
            <p>{item?.pricingListGroup?.name}</p>
            <EnumLabel label={item?.pricingListGroup?.action} />
            <p>{item?.strategy?.name}</p>
            <p>{item?.strategy?.model?.name}</p>
        </div>
    )
}

const StrategyRankingList = ({
    modelConfigurationRanks,
    pricingListId,
    pricingListName,
    showLoading,
}) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    )

    const { t } = useTranslation(["common", "table"])

    const [rankList, setRankList] = useState([])

    const history = useHistory()
    const dispatch = useDispatch()

    const [setStrategies, { loading }] = useMutation(SET_STRATEGY_ORDER, {
        refetchQueries: [GET_PRICING_LIST],
    })

    useEffect(() => {
        setRankList(modelConfigurationRanks)
    }, [modelConfigurationRanks])

    return (
        <>
            <div className={style.buttonBar}>
                <h1>{pricingListName}</h1>
                <button
                    disabled={!rankList?.length}
                    onClick={() => {
                        setStrategies({
                            variables: {
                                id: pricingListId,
                                modelConfigurations: rankList.map(
                                    (item) => item.id,
                                ),
                            },
                        })
                            .then((r) =>
                                history.push(
                                    r?.data?.setModelConfigurationOrder?.id
                                        ? `/pricing_list/${r?.data?.setModelConfigurationOrder?.id}`
                                        : "/pricing_lists",
                                ),
                            )
                            .catch((e) => dispatch(showError(e.message)))
                    }}
                    className={buttonStyle.button}
                >
                    {c(t("table:save"))}
                </button>
            </div>
            <div className={style.sortList}>
                <div className={cn(style.listItem, style.listHeader)}>
                    <p />
                    <p>{t("table:rank")}</p>
                    <p>{t("table:name")}</p>
                    <p>{t("table:pricing_list_group")}</p>
                    <p>{t("table:action")}</p>
                    <p>{t("table:strategy")}</p>
                    <p>{t("table:model")}</p>
                </div>

                <Placeholder
                    ready={!loading && !showLoading}
                    placeholder={[...Array(3)].map((_, i) => (
                        <ContentLoader
                            viewBox="0 0 100 10"
                            key={i}
                            className={style.contentLoader}
                            preserveAspectRatio={"none"}
                        >
                            <rect x="5" y="5" width="5" height="10" />
                            <rect x="15" y="5" width="5" height="10" />
                            <rect x="25" y="5" width="30" height="10" />
                            <rect x="60" y="5" width="30" height="10" />
                        </ContentLoader>
                    ))}
                >
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        modifiers={[restrictToVerticalAxis]}
                        onDragEnd={(evt) => {
                            const { active, over } = evt
                            if (active.id !== over.id) {
                                setRankList((items) => {
                                    const oldIndex = items.findIndex(
                                        (i) => i.id === active.id,
                                    )
                                    const newIndex = items.findIndex(
                                        (i) => i.id === over.id,
                                    )
                                    return arrayMove(items, oldIndex, newIndex)
                                })
                            }
                        }}
                    >
                        <SortableContext
                            modifiers={[restrictToWindowEdges]}
                            items={rankList || []}
                            strategy={verticalListSortingStrategy}
                        >
                            {rankList?.map((item, i) => (
                                <RankItem
                                    key={item?.id}
                                    item={item}
                                    id={item?.id}
                                    pos={i}
                                />
                            ))}
                        </SortableContext>
                    </DndContext>
                </Placeholder>
            </div>
        </>
    )
}

export const StrategyRanking = () => {
    const { id } = useParams()

    const { data, loading } = useQuery(GET_PRICING_LIST, { variables: { id } })

    const { t } = useTranslation("table")

    return (
        <div>
            <Banner text={t("table:rank_model_configurations")} />
            <div className={style.wrapper}>
                <StrategyRankingList
                    modelConfigurationRanks={
                        data?.pricingList?.rankedModelConfigurations
                    }
                    pricingListId={id}
                    showLoading={loading}
                    pricingListName={data?.pricingList?.displayName}
                />
            </div>
        </div>
    )
}
