/**
 * Converts a base64 string to an Uint8Array
 * @param base64String the base64 encoded string
 * @returns {Uint8Array} the output Uint8Array
 */
const urlBase64ToUint8Array = (base64String) => {
    let padding = "=".repeat((4 - (base64String.length % 4)) % 4)
    let base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/")

    try {
        let rawData = window.atob(base64)
        let outputArray = new Uint8Array(rawData.length)

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i)
        }
        return outputArray
    } catch (e) {
        console.error(base64String)
        console.error(e)
    }
}

/**
 * adds the service worker registration to load
 * @constructor
 */
export default function LocalServiceWorkerRegister() {
    const swPath = `${process.env.PUBLIC_URL}/service-worker.js`
    if ("serviceWorker" in navigator) {
        window.addEventListener("load", () => {
            navigator.serviceWorker.register(swPath).then(() => {
                console.info("Service worker registered")
            })
        })
    }
}

/**
 * Checks if the app is running locally
 * @type {boolean} true if the app is running locally
 */
const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        window.location.hostname === "[::1]" ||
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
        ),
)

/**
 * Registers the service worker after checking if its valid
 * @param config the service worker config
 */
export const register = (config) => {
    if ("serviceWorker" in navigator) {
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
        if (publicUrl.origin !== window.location.origin) {
            return
        }

        window.addEventListener("load", () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

            if (isLocalhost) {
                checkValidServiceWorker(swUrl, config)

                navigator.serviceWorker.ready.then(() => {
                    console.log(
                        "This web app is being served cache-first by a service " +
                            "worker. To learn more, visit https://cra.link/PWA",
                    )
                })
            } else {
                registerValidSW(swUrl, config)

                navigator.serviceWorker.ready
                    .then((registration) => {
                        if (registration.pushManager) {
                            return registration.pushManager
                                .getSubscription()
                                .then(async function (subscription) {
                                    if (subscription) {
                                        return subscription
                                    }

                                    const response = await fetch(
                                        `${process.env.PUBLIC_URL}/auth/push/vapidPublicKey`,
                                    )
                                    const vapidPublicKey = await response.text()

                                    const convertedVapidKey =
                                        urlBase64ToUint8Array(vapidPublicKey)

                                    if (!convertedVapidKey) {
                                        console.error(
                                            "Invalid VAPID public key",
                                        )
                                        return
                                    }

                                    return registration.pushManager
                                        .subscribe({
                                            userVisibleOnly: true,
                                            applicationServerKey:
                                                convertedVapidKey,
                                        })
                                        .then(function (pushSubscription) {
                                            return pushSubscription
                                        })
                                })
                                .then((pushSubscription) => {
                                    fetch("/auth/push/register", {
                                        method: "post",
                                        headers: {
                                            "Content-type": "application/json",
                                        },
                                        body: JSON.stringify(pushSubscription),
                                    })
                                })
                        } else {
                            console.warn(
                                "pushManager not available via web standard",
                            )
                        }
                    })
                    .catch((error) => {
                        console.error(
                            "Error during service worker registration:",
                            error,
                        )
                    })
            }
        })
    }
}

/**
 * Registers the service worker
 * @param swUrl the service worker URL
 * @param config the servive worker config
 */
const registerValidSW = (swUrl, config) => {
    navigator.serviceWorker
        .register(swUrl, { scope: "/" })
        .then((registration) => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing
                if (installingWorker == null) {
                    return
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === "installed") {
                        if (navigator.serviceWorker.controller) {
                            console.info(
                                "New content is available and will be used when all " +
                                    "tabs for this page are closed. See https://cra.link/PWA.",
                            )

                            if (config && config.onUpdate) {
                                config.onUpdate(registration)
                            }
                        } else {
                            console.info("Content is cached for offline use.")

                            if (config && config.onSuccess) {
                                config.onSuccess(registration)
                            }
                        }
                    }
                }
            }
        })
        .catch((error) => {
            console.error("Error during service worker registration:", error)
        })
}

/**
 * Checks if the service worker is valid
 * @param swUrl the URL of the service worker
 * @param config the config of the service worker
 */
const checkValidServiceWorker = (swUrl, config) => {
    fetch(swUrl, {
        headers: { "Service-Worker": "script" },
    })
        .then((response) => {
            const contentType = response.headers.get("content-type")
            if (
                response.status === 404 ||
                (contentType != null &&
                    contentType.indexOf("javascript") === -1)
            ) {
                navigator.serviceWorker.ready
                    .then((registration) => {
                        registration.unregister().then(() => {
                            window.location.reload()
                        })
                    })
                    .catch((error) =>
                        console.warn("ServiceWorker Warning: ", error),
                    )
            } else {
                registerValidSW(swUrl, config)
            }
        })
        .catch(() => {
            console.warn(
                "No internet connection found. App is running in offline mode.",
            )
        })
}

/**
 * Unregisters the service worker
 */
export const unregister = () => {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister()
            })
            .catch((error) => {
                console.error(error.message)
            })
    }
}
