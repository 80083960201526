import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import { Link, useParams } from "react-router-dom"
import { EnumLabel } from "../../util/table/EnumLabel"
import cn from "classnames"

const SINGLE_MARKETING_CALCULATIONS_QUERY = gql`
    query getPricingList($id: UUID!, $pageNum: Int) {
        list: marketingList(id: $id) {
            id
            name
            calculations: marketingListCalculations(
                limit: 20
                pageNum: $pageNum
            ) {
                currentPage
                hasNext
                hasPrevious
                pages
                page {
                    id
                    name
                    calculationStart
                    calculationEnd
                    calculationStatus
                    list: marketingList {
                        name
                        id
                    }
                }
            }
        }
    }
`

const ALL_MARKETING_CALCULATIONS_QUERY = gql`
    query getPricingList($pageNum: Int) {
        list: marketingCalculations(limit: 20, pageNum: $pageNum) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                name
                calculationStart
                calculationEnd
                calculationStatus
                list: marketingList {
                    name
                    id
                }
            }
        }
    }
`

const SINGLE_ALLOCATION_CALCULATIONS_QUERY = gql`
    query getPricingList($id: UUID!, $pageNum: Int) {
        list: allocationList(id: $id) {
            id
            name
            calculations: allocationListCalculations(
                limit: 20
                pageNum: $pageNum
            ) {
                currentPage
                hasNext
                hasPrevious
                pages
                page {
                    id
                    name
                    calculationStart
                    calculationEnd
                    calculationStatus
                    list: allocationList {
                        name
                        id
                    }
                }
            }
        }
    }
`

const ALL_ALLOCATION_CALCULATIONS_QUERY = gql`
    query getPricingList($pageNum: Int) {
        list: allocationCalculations(limit: 20, pageNum: $pageNum) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                name
                calculationStart
                calculationEnd
                calculationStatus
                list: allocationList {
                    name
                    id
                }
            }
        }
    }
`

export const MarketingCalculationList = ({ isAllocation }) => {
    const [pageNum, setPage] = useState(1)

    const { t, i18n } = useTranslation(["common", "table"])
    const { id } = useParams()

    const { data, loading } = useQuery(
        isAllocation
            ? id
                ? SINGLE_ALLOCATION_CALCULATIONS_QUERY
                : ALL_ALLOCATION_CALCULATIONS_QUERY
            : id
            ? SINGLE_MARKETING_CALCULATIONS_QUERY
            : ALL_MARKETING_CALCULATIONS_QUERY,
        {
            variables: {
                pageNum,
                id,
            },
        },
    )

    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    return (
        <div>
            <Banner
                text={
                    data?.list?.name
                        ? `${t("table:calculations")}: ${data?.list?.name}`
                        : t("table:calculations")
                }
            />

            {!loading &&
            !(id ? data?.list : data)?.calculations?.page?.length ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}
            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: `repeat(${id ? 4 : 5}, 1fr)`,
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:name")}
                />
                {id ? null : (
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:marketing_list")}
                    />
                )}
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:status")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:start_datetime")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:end_datetime")}
                />
                {loading && !data?.calculationSettings && (
                    <TableContentLoader columns={id ? 5 : 6} />
                )}
                {(id ? data?.list : data)?.calculations?.page?.map((c, i) => (
                    <Fragment key={i}>
                        {c.calculationStatus === "DONE" ? (
                            <Link
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.linkCell,
                                )}
                                to={
                                    isAllocation
                                        ? `/allocation/${id}/calculations/${c?.id}/`
                                        : `/marketing/${id}/calculations/${c?.id}/`
                                }
                            >
                                {c?.name}
                            </Link>
                        ) : (
                            <p className={tableStyle.cell}>{c?.name}</p>
                        )}
                        {id ? null : (
                            <Link
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.linkCell,
                                )}
                                to={
                                    isAllocation
                                        ? `/allocation/${c?.marketingList?.id}`
                                        : `/marketing/${c?.marketingList?.id}`
                                }
                            >
                                {c?.marketingList?.name}
                            </Link>
                        )}
                        <EnumLabel
                            className={tableStyle.cell}
                            label={c.calculationStatus}
                        />
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(c.calculationStart))}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {dateFormat.format(new Date(c.calculationEnd))}
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={(id ? data?.list : data)?.calculations}
                loading={loading && !(id ? data?.list : data)?.calculations}
            />
        </div>
    )
}
