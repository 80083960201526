import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import { Link } from "react-router-dom"
import { EnumLabel } from "../../util/table/EnumLabel"
import cn from "classnames"
import buttonStyle from "../../../style/button.module.css"
import { ListCell } from "../../util/table/ListCell"

const CALCULATIONS_QUERY = gql`
    query getPlausi($pageNum: Int) {
        me {
            name
        }
        plausibilityChecks(limit: 20, pageNum: $pageNum) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                name
                plausibilityStatus
                plausibilityCheckStart
                plausibilityCheckEnd
                calculations(limit: 20) {
                    currentPage
                    page {
                        id
                        pricingList {
                            id
                            name: displayName
                        }
                    }
                }
            }
        }
    }
`

export const PlausiList = () => {
    const [pageNum, setPage] = useState(1)

    const { t, i18n } = useTranslation(["common", "table"])

    const { data, loading } = useQuery(CALCULATIONS_QUERY, {
        variables: {
            pageNum,
        },
    })

    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    return (
        <div>
            <Banner text={t("table:plausibility_checks")} />

            <div className={tableStyle.stackedFilterBar}>
                <div className={tableStyle.buttonSection}>
                    <Link
                        className={buttonStyle.orangeButton}
                        to={"/ifa_changes"}
                    >
                        {t("table:run_plausibility_check")}
                    </Link>
                </div>
            </div>

            {!loading && !data?.plausibilityChecks?.page?.length ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}
            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "1fr 1fr 2fr 1fr 1fr",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:name")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:status")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pricing_lists")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:start_datetime")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:end_datetime")}
                />
                {loading && !data?.calculationSettings && (
                    <TableContentLoader columns={4} />
                )}
                {data?.plausibilityChecks?.page?.map((c, i) => (
                    <Fragment key={i}>
                        {c.plausibilityStatus === "DONE" ||
                        c.plausibilityStatus === "CLOSED" ? (
                            <Link
                                className={cn(
                                    tableStyle.linkCell,
                                    tableStyle.cell,
                                )}
                                to={`/plausibility/${c.id}`}
                            >
                                {c?.name}
                            </Link>
                        ) : (
                            <p className={tableStyle.cell}>{c?.name}</p>
                        )}
                        <EnumLabel
                            className={tableStyle.cell}
                            label={c.plausibilityStatus}
                        />
                        <ListCell
                            className={tableStyle.cell}
                            labels={c?.calculations?.page.map(
                                (c) => c?.pricingList,
                            )}
                            destination={"pricing_list"}
                        />
                        <p className={tableStyle.cell}>
                            {dateFormat.format(
                                new Date(c?.plausibilityCheckStart),
                            )}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(
                                new Date(c?.plausibilityCheckEnd),
                            )}
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.plausibilityChecks}
                loading={loading && !data?.plausibilityChecks}
            />
        </div>
    )
}
