import { useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation } from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import inputStyle from "../../../style/input.module.css"
import { uniqueId } from "lodash"
import buttonStyle from "../../../style/button.module.css"
import formStyle from "../../../style/form.module.css"
import { showError } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"
import { Loader } from "../../util/Loader"
import { useHistory } from "react-router-dom"

const CREATE_PRODUCT = gql`
    mutation ($name: String!, $pzn: String!) {
        createProduct(name: $name, pzn: $pzn) {
            id
            name
            pzn
        }
    }
`

export const ProductCreator = () => {
    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [pzn_input_id] = useState(() => uniqueId("pzn_input_"))

    const [name, setName] = useState("")
    const [pzn, setPZN] = useState("")

    const { t } = useTranslation(["common", "table"])

    const [create, { loading: createProductLoading }] =
        useMutation(CREATE_PRODUCT)

    const dispatch = useDispatch()
    const history = useHistory()

    if (createProductLoading) {
        return (
            <div>
                <Banner text={t("table:product")} />
                <Loader />
            </div>
        )
    }

    return (
        <div>
            <Banner text={t("table:product")} />
            <div className={tableStyle.stretchFilterBar}>
                <div className={tableStyle.filterBar} />

                <div>
                    <button
                        className={buttonStyle.button}
                        disabled={!name || !pzn}
                        onClick={() =>
                            create({
                                variables: {
                                    name,
                                    pzn,
                                },
                            })
                                .then(() => history.push(`/products`))
                                .catch((e) => dispatch(showError(e.message)))
                        }
                    >
                        {t("table:save")}
                    </button>
                </div>
            </div>
            <div className={formStyle.rowWrapper}>
                <div className={formStyle.formGrid}>
                    <label htmlFor={pzn_input_id}>{t("table:pzn")}</label>
                    <input
                        id={pzn_input_id}
                        className={inputStyle.text}
                        type="text"
                        maxLength={8}
                        value={pzn}
                        onChange={(evt) => setPZN(+evt.target.value || "")}
                    />
                    <label htmlFor={name_input_id}>{t("table:name")}</label>
                    <input
                        id={name_input_id}
                        className={inputStyle.text}
                        type="text"
                        value={name}
                        onChange={(evt) => setName(evt.target.value)}
                    />
                </div>
            </div>
        </div>
    )
}
