import cn from "classnames"
import style from "./ListCell.module.css"
import { Link } from "react-router-dom"

/**
 * This element is a cell which contains multiple clickable elements
 *
 * @param labels the elements that should be displayed as a list
 * @param className the classname of the cell
 * @param destination the link destination for the labels, the ID from the lables will be appended
 * @returns {JSX.Element} the list cell element
 * @constructor
 */
export const ListCell = ({ labels, className, destination }) => {
    if (destination) {
        return (
            <p className={cn(className, style.label)}>
                {labels?.map((l) => ((
                    l.name.includes("Hageda") ||
                    l.name.includes("HAGEDA") ||
                    l.name.includes("230") || 
                    l.name.includes("231") || 
                    l.name.includes("232") || 
                    l.name.includes("233") || 
                    l.name.includes("234")) ? (
                        <Link
                            className={style.badge_hageda}
                            key={l.id}
                            to={`/${destination}/${l.id}`}
                        >
                            {l.name}
                        </Link>
                    ) : (
                        <Link
                            className={style.badge}
                            key={l.id}
                            to={`/${destination}/${l.id}`}
                        >
                            {l.name}
                        </Link>
                    )
                ))}
            </p>
        )
    } else {
        return (
            <p className={cn(className, style.label)}>
                {labels?.map((l) => (
                    <span className={style.badge} key={l.id}>
                        {l.name}
                    </span>
                ))}
            </p>
        )
    }
}
