import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { useParams } from "react-router-dom"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import cn from "classnames"
import buttonStyle from "../../../style/button.module.css"
import inputStyle from "../../../style/input.module.css"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import { useDispatch } from "react-redux"
import { EnumLabel } from "../../util/table/EnumLabel"
import c from "capitalize"
import { showError, showInfo } from "../../../redux/actions/NotificationActions"
import { PricingListResultExpander } from "../../util/PricingListResultExpander"
import { SmartFilter } from "../../util/input/SmartFilter"
import {
    getCurrencyFormatter,
    getDayMonthYearFormatter,
    getIntegerFormatter,
    getNumberFormatter,
    getPercentSafeFormatter,
    getSafeFormatter,
} from "../../../i18n"

const GET_MARKETING_LIST_RESULTS = gql`
    query (
        $id: UUID!
        $listId: UUID!
        $query: String
        $pageNum: Int
        $order: [MarketingListResultByProductSearchOrder]
        $filter: MarketingListResultProductSearchFilter
    ) {
        list: marketingList(id: $listId) {
            name
            id
            calculation: marketingCalculation(id: $id) {
                name
                id
                results: sortableMarketingResults(
                    limit: 20
                    pageNum: $pageNum
                    orderBy: $order
                    filterParams: $filter
                    query: $query
                ) {
                    currentPage
                    hasNext
                    hasPrevious
                    pages
                    suggestedQuery
                    page {
                        id
                        marketingType
                        type
                        validFrom
                        validUntil
                        bulkAmount1
                        calculatedPrice
                        bulkAmount2
                        calculatedPriceAmount2
                        bulkAmount3
                        calculatedPriceAmount3
                        agrosso1
                        agrosso2
                        AEP
                        GEP
                        product {
                            id
                            name
                            pzn
                            unit
                            supplierName
                        }
                        manualAdjustment {
                            id
                            bulkAmount1
                            bulkAmount2
                            bulkAmount3
                            priceAmount1
                            priceAmount2
                            priceAmount3
                        }
                    }
                }
            }
        }
    }
`

const FILTER_MARKETING_QUERY = gql`
    query getFilter($id: UUID!, $listId: UUID!) {
        list: marketingList(id: $listId) {
            name
            id
            calculation: marketingCalculation(id: $id) {
                name
                id
                results: sortableMarketingResults(limit: 0) {
                    filterFields {
                        name
                        dataType
                        displayName
                        options
                    }
                }
            }
        }
    }
`

const SET_MARKETING_PRICE = gql`
    mutation ($changes: [ManualMarketingPriceInput]) {
        setPrice: setMarketingPrices(marketingAdjustments: $changes) {
            id
            manualAdjustment {
                id
                bulkAmount1
                bulkAmount2
                bulkAmount3
                priceAmount1
                priceAmount2
                priceAmount3
            }
        }
    }
`

const EXPORT_MARKETING_RESULSTS = gql`
    mutation send($id: UUID!) {
        send: sendMarketingAsEmail(id: $id)
    }
`

const GET_ALLOCATION_LIST_RESULTS = gql`
    query (
        $id: UUID!
        $listId: UUID!
        $query: String
        $pageNum: Int
        $order: [AllocationListResultByProductSearchOrder]
        $filter: AllocationListResultProductSearchFilter
    ) {
        list: allocationList(id: $listId) {
            name
            id
            calculation: allocationCalculation(id: $id) {
                name
                id
                results: sortableAllocationResults(
                    limit: 20
                    pageNum: $pageNum
                    orderBy: $order
                    filterParams: $filter
                    query: $query
                ) {
                    currentPage
                    hasNext
                    hasPrevious
                    pages
                    suggestedQuery
                    page {
                        id
                        type
                        validFrom
                        validUntil
                        bulkAmount1
                        calculatedPrice
                        bulkAmount2
                        calculatedPriceAmount2
                        bulkAmount3
                        calculatedPriceAmount3
                        agrosso1
                        agrosso2
                        AEP
                        GEP
                        product {
                            id
                            name
                            pzn
                            unit
                            supplierName
                        }
                        manualAdjustment {
                            id
                            bulkAmount1
                            bulkAmount2
                            bulkAmount3
                            priceAmount1
                            priceAmount2
                            priceAmount3
                        }
                    }
                }
            }
        }
    }
`

const FILTER_ALLOCATION_QUERY = gql`
    query getFilter($id: UUID!, $listId: UUID!) {
        list: allocationList(id: $listId) {
            name
            id
            calculation: allocationCalculation(id: $id) {
                name
                id
                results: sortableAllocationResults(limit: 0) {
                    filterFields {
                        name
                        dataType
                        displayName
                        options
                    }
                }
            }
        }
    }
`

const SET_ALLOCATION_PRICE = gql`
    mutation ($changes: [ManualAllocationPriceInput]) {
        setPrice: setAllocationPrices(allocationAdjustments: $changes) {
            id
            manualAdjustment {
                id
                bulkAmount1
                bulkAmount2
                bulkAmount3
                priceAmount1
                priceAmount2
                priceAmount3
            }
        }
    }
`

const EXPORT_ALLOCATION_RESULSTS = gql`
    mutation send($id: UUID!) {
        send: sendAllocationAsEmail(id: $id)
    }
`

export const MarketingListResults = ({ isAllocation }) => {
    const { listId, id } = useParams()
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [changes, setChanges] = useState({})
    const [query, setQuery] = useState("")
    const [filter, setFilter] = useState({})
    const [editRow, setEditRow] = useState(null)
    const [expanderProduct, setExpanderProduct] = useState(null)

    const { t, i18n } = useTranslation(["common", "table", "messages"])

    const { data, loading: queryLoading } = useQuery(
        isAllocation ? GET_ALLOCATION_LIST_RESULTS : GET_MARKETING_LIST_RESULTS,
        {
            variables: {
                pageNum,
                order,
                filter,
                query,
                id,
                listId,
            },
        },
    )

    const clampOrEmpty = (input, minValue, maxValue) =>
        input.trim()
            ? Math.max(minValue, Math.min(maxValue, +input.trim()))
            : ""

    // const { data: resultsMetricData } = useQuery(RESULTS_METRICS, {
    //     variables: { id },
    //     pollInterval: 30000,
    //     skip: queryLoading || !data,
    // })

    const [setManualPrices, { loading: updateLoading }] = useMutation(
        isAllocation ? SET_ALLOCATION_PRICE : SET_MARKETING_PRICE,
    )
    const [exportResults] = useMutation(
        isAllocation ? EXPORT_ALLOCATION_RESULSTS : EXPORT_MARKETING_RESULSTS,
    )

    const loading = updateLoading || queryLoading

    const currencyFormat = getSafeFormatter(getCurrencyFormatter(i18n.language))
    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )
    const percentFormatter = getPercentSafeFormatter(
        getNumberFormatter(i18n.language),
    )

    const dateFormatter = getSafeFormatter(
        getDayMonthYearFormatter(i18n.language),
    )

    const dispatch = useDispatch()

    return (
        <div>
            <Banner
                text={
                    loading
                        ? t(
                              isAllocation
                                  ? "table:allocation_list_results"
                                  : "table:marketing_list_results",
                          )
                        : `${t(
                              isAllocation
                                  ? "table:allocation_list_results"
                                  : "table:marketing_list_results",
                          )}:
                     ${data?.list?.name || ""}
                     - ${data?.list?.calculation?.name || ""}`
                }
            />
            {!loading && !data?.list ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}

            <div className={dropDownStyle.container}>
                <SmartFilter
                    filterFieldQuery={
                        isAllocation
                            ? FILTER_ALLOCATION_QUERY
                            : FILTER_MARKETING_QUERY
                    }
                    filterQueryOptions={{
                        variables: { id, listId },
                    }}
                    filterPath={(q) =>
                        q?.list?.calculation?.results?.filterFields
                    }
                    setQuery={setQuery}
                    setFilter={setFilter}
                    suggestedQuery={
                        data?.list?.calculation?.results?.suggestedQuery
                    }
                />

                <div className={tableStyle.buttonBar}>
                    <button
                        className={buttonStyle.button}
                        onClick={() =>
                            exportResults({
                                variables: {
                                    id,
                                },
                            })
                                .then(() =>
                                    dispatch(
                                        showInfo("common:export_triggered"),
                                    ),
                                )
                                .catch((e) => dispatch(showError(e.message)))
                        }
                    >
                        {t("table:all_metrics_download_csv")}
                    </button>
                </div>
            </div>
            <div className={dropDownStyle.container}>
                <div className={tableStyle.buttonBar}>
                    {Object.entries(changes).length > 0 && (
                        <>
                            <h3>
                                {t("table:number_of_unsaved_changes")}:{" "}
                                {Object.entries(changes).length}
                            </h3>

                            <button
                                className={buttonStyle.button}
                                onClick={() => {
                                    setManualPrices({
                                        variables: {
                                            changes: Object.entries(
                                                changes,
                                            ).map((c) => ({
                                                id: c[0],
                                                manualPriceBulk1:
                                                    Number.parseFloat(
                                                        c[1]?.manualPrice,
                                                    ) ?? null,
                                                manualPriceBulk2:
                                                    Number.parseFloat(
                                                        c[1]
                                                            ?.manualPriceAmount2,
                                                    ) ?? null,
                                                manualPriceBulk3:
                                                    Number.parseFloat(
                                                        c[1]
                                                            ?.manualPriceAmount3,
                                                    ) ?? null,
                                                manualAmountBulk1:
                                                    Number.parseInt(
                                                        c[1]?.bulkAmount1,
                                                    ) ?? null,
                                                manualAmountBulk2:
                                                    Number.parseInt(
                                                        c[1]?.bulkAmount2,
                                                    ) ?? null,
                                                manualAmountBulk3:
                                                    Number.parseInt(
                                                        c[1]?.bulkAmount3,
                                                    ) ?? null,
                                            })),
                                        },
                                    })
                                        .then(() => setChanges({}))
                                        .catch((e) =>
                                            dispatch(showError(e.message)),
                                        )
                                }}
                            >
                                {updateLoading ? "..." : c(t("common:save"))}
                            </button>

                            <button
                                className={cn(
                                    buttonStyle.button,
                                    buttonStyle.dangerButton,
                                )}
                                onClick={() => setChanges({})}
                            >
                                {c(t("common:reset"))}
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div className={tableStyle.wrapper}>
                <div
                    className={tableStyle.table}
                    style={{
                        gridTemplateColumns: isAllocation
                            ? "1fr 2fr repeat(4, 1fr) 0.5fr 1fr repeat(6, 5em) 1fr repeat(4, 1fr)"
                            : "1fr 2fr repeat(5, 1fr) 0.5fr 1fr repeat(6, 5em) 1fr repeat(4, 1fr)",
                    }}
                >
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:pzn")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductPznAsc"}
                        desc={"ProductPznDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:product")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductNameAsc"}
                        desc={"ProductNameDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:supplier")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductSupplierNameAsc"}
                        desc={"ProductSupplierNameDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:unit")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductUnitAsc"}
                        desc={"ProductUnitDesc"}
                    />
                    {isAllocation ? null : (
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:type")}
                            order={order}
                            setOrder={setOrder}
                            asc={"MarketingTypeAsc"}
                            desc={"MarketingTypeDesc"}
                        />
                    )}
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:valid_from")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ValidFromAsc"}
                        desc={"ValidFromDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:valid_until")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ValidUntilAsc"}
                        desc={"ValidUntilDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:sales_volume")}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:market_share")}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={`${t("table:bulk_amount")} 1`}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={`${t("table:price")} 1`}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={`${t("table:bulk_amount")} 2`}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={`${t("table:price")} 2`}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={`${t("table:bulk_amount")} 3`}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={`${t("table:price")} 3`}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:price_type")}
                        order={order}
                        setOrder={setOrder}
                        asc={"UsedStrategyParamsAsc"}
                        desc={"UsedStrategyParamsDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:agrosso") + " 1"}
                        order={order}
                        setOrder={setOrder}
                        asc={"Agrosso1Asc"}
                        desc={"Agrosso1Desc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:agrosso") + " 2"}
                        order={order}
                        setOrder={setOrder}
                        asc={"Agrosso2Asc"}
                        desc={"Agrosso2Desc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:aep")}
                        order={order}
                        setOrder={setOrder}
                        asc={"AEPAsc"}
                        desc={"AEPDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:gep")}
                        order={order}
                        setOrder={setOrder}
                        asc={"GEPAsc"}
                        desc={"GEPDesc"}
                    />

                    {loading && !data?.list && (
                        <TableContentLoader columns={21} rows={20} />
                    )}
                    {data?.list?.calculation?.results?.page.map((s, i) => (
                        <Fragment key={i}>
                            <p
                                className={tableStyle.fullWidthCell}
                                onClick={() => setExpanderProduct(s?.id)}
                            >
                                {s?.product?.pzn}
                            </p>
                            <p
                                className={tableStyle.cell}
                                onClick={() => setExpanderProduct(s?.id)}
                            >
                                {s?.product?.name}
                            </p>
                            <p className={tableStyle.cell}>
                                {s?.product?.supplierName}A
                            </p>
                            <p className={tableStyle.cell}>
                                {s?.product?.unit}
                            </p>
                            {isAllocation ? null : (
                                <EnumLabel
                                    className={tableStyle.cell}
                                    label={s?.marketingType}
                                />
                            )}
                            <p className={tableStyle.cell}>
                                {dateFormatter.format(new Date(s?.validFrom))}
                            </p>
                            <p className={tableStyle.cell}>
                                {dateFormatter.format(new Date(s?.validUntil))}
                            </p>
                            <p className={tableStyle.cell}>
                                {integerFormatter.format(
                                    s?.calculatedSalesVolume,
                                )}
                            </p>
                            <p className={tableStyle.cell}>
                                {percentFormatter.format(
                                    s?.calculatedMarketShare,
                                )}
                            </p>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.editCell,
                                )}
                                onClick={() =>
                                    setEditRow({
                                        id: s?.id,
                                        bulk: 1,
                                        isPrice: false,
                                    })
                                }
                            >
                                {s?.id === editRow?.id &&
                                editRow?.bulk === 1 &&
                                !editRow?.isPrice ? (
                                    <input
                                        type="number"
                                        autoFocus={true}
                                        value={
                                            changes[s?.id]?.bulkAmount1 ??
                                            s?.manualAdjustment?.bulkAmount1 ??
                                            s?.bulkAmount1 ??
                                            ""
                                        }
                                        className={inputStyle.text}
                                        placeholder={s?.bulkAmount1}
                                        onBlur={() => setEditRow(null)}
                                        onChange={(evt) =>
                                            setChanges({
                                                ...changes,
                                                [s?.id]: {
                                                    manualPrice:
                                                        changes[s?.id]
                                                            ?.manualPrice ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount1,
                                                    manualPriceAmount2:
                                                        changes[s?.id]
                                                            ?.manualPriceAmount2 ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount2,
                                                    manualPriceAmount3:
                                                        changes[s?.id]
                                                            ?.manualPriceAmount3 ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount3,
                                                    bulkAmount1: clampOrEmpty(
                                                        evt.target.value.trim(),
                                                        0,
                                                        999,
                                                    ),
                                                    bulkAmount2:
                                                        changes[s?.id]
                                                            ?.bulkAmount2 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount2,
                                                    bulkAmount3:
                                                        changes[s?.id]
                                                            ?.bulkAmount3 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount3,
                                                    ignorePlausiCheck:
                                                        changes[s?.id]
                                                            ?.ignorePlausiCheck ??
                                                        s?.manualAdjustment
                                                            ?.ignorePlausiCheck,
                                                },
                                            })
                                        }
                                    />
                                ) : (
                                    changes[s?.id]?.bulkAmount1 ??
                                    s?.manualAdjustment?.bulkAmount1 ??
                                    s?.bulkAmount1
                                )}
                            </p>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.editCell,
                                )}
                                onClick={() =>
                                    setEditRow({
                                        id: s?.id,
                                        bulk: 1,
                                        isPrice: true,
                                    })
                                }
                            >
                                {s?.id === editRow?.id &&
                                editRow?.bulk === 1 &&
                                editRow?.isPrice ? (
                                    <input
                                        type="number"
                                        autoFocus={true}
                                        value={
                                            changes[s?.id]?.manualPrice ??
                                            s?.manualAdjustment?.priceAmount1 ??
                                            s?.calculatedPrice ??
                                            ""
                                        }
                                        className={inputStyle.text}
                                        placeholder={
                                            s?.manualAdjustment?.priceAmount1 ??
                                            s?.calculatedPrice
                                        }
                                        onBlur={() => setEditRow(null)}
                                        onChange={(evt) =>
                                            setChanges({
                                                ...changes,
                                                [s?.id]: {
                                                    manualPrice: clampOrEmpty(
                                                        evt.target.value.trim(),
                                                        0,
                                                        99999.99,
                                                    ),
                                                    manualPriceAmount2:
                                                        changes[s?.id]
                                                            ?.manualPriceAmount2 ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount2,
                                                    manualPriceAmount3:
                                                        changes[s?.id]
                                                            ?.manualPriceAmount3 ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount3,
                                                    bulkAmount1:
                                                        changes[s?.id]
                                                            ?.bulkAmount1 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount1,
                                                    bulkAmount2:
                                                        changes[s?.id]
                                                            ?.bulkAmount2 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount2,
                                                    bulkAmount3:
                                                        changes[s?.id]
                                                            ?.bulkAmount3 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount3,
                                                    ignorePlausiCheck:
                                                        changes[s?.id]
                                                            ?.ignorePlausiCheck ??
                                                        s?.manualAdjustment
                                                            ?.ignorePlausiCheck,
                                                },
                                            })
                                        }
                                    />
                                ) : (
                                    currencyFormat.format(
                                        changes[s?.id]?.manualPrice ??
                                            s?.manualAdjustment?.priceAmount1 ??
                                            s?.calculatedPrice,
                                    )
                                )}
                            </p>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.editCell,
                                )}
                                onClick={() =>
                                    setEditRow({
                                        id: s?.id,
                                        bulk: 2,
                                        isPrice: false,
                                    })
                                }
                            >
                                {s?.id === editRow?.id &&
                                editRow?.bulk === 2 &&
                                !editRow?.isPrice ? (
                                    <input
                                        type="number"
                                        autoFocus={true}
                                        value={
                                            changes[s?.id]?.bulkAmount2 ??
                                            s?.manualAdjustment?.bulkAmount2 ??
                                            s?.bulkAmount2 ??
                                            ""
                                        }
                                        className={inputStyle.text}
                                        placeholder={s?.bulkAmount2}
                                        onBlur={() => setEditRow(null)}
                                        onChange={(evt) =>
                                            setChanges({
                                                ...changes,
                                                [s?.id]: {
                                                    manualPrice:
                                                        changes[s?.id]
                                                            ?.manualPrice ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount1,
                                                    manualPriceAmount2:
                                                        changes[s?.id]
                                                            ?.manualPriceAmount2 ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount2,
                                                    manualPriceAmount3:
                                                        changes[s?.id]
                                                            ?.manualPriceAmount3 ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount3,
                                                    bulkAmount1:
                                                        changes[s?.id]
                                                            ?.bulkAmount1 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount1,
                                                    bulkAmount2: clampOrEmpty(
                                                        evt.target.value.trim(),
                                                        0,
                                                        999,
                                                    ),
                                                    bulkAmount3:
                                                        changes[s?.id]
                                                            ?.bulkAmount3 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount3,
                                                    ignorePlausiCheck:
                                                        changes[s?.id]
                                                            ?.ignorePlausiCheck ??
                                                        s?.manualAdjustment
                                                            ?.ignorePlausiCheck,
                                                },
                                            })
                                        }
                                    />
                                ) : (
                                    changes[s?.id]?.bulkAmount2 ??
                                    s?.manualAdjustment?.bulkAmount2 ??
                                    s?.bulkAmount2
                                )}
                            </p>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.editCell,
                                )}
                                onClick={() =>
                                    setEditRow({
                                        id: s?.id,
                                        bulk: 2,
                                        isPrice: true,
                                    })
                                }
                            >
                                {s?.id === editRow?.id &&
                                editRow?.bulk === 2 &&
                                editRow?.isPrice ? (
                                    <input
                                        type="number"
                                        autoFocus={true}
                                        value={
                                            changes[s?.id]
                                                ?.manualPriceAmount2 ??
                                            s?.manualAdjustment?.priceAmount2 ??
                                            s?.calculatedPriceAmount2 ??
                                            ""
                                        }
                                        className={inputStyle.text}
                                        placeholder={
                                            s?.manualAdjustment?.priceAmount2 ??
                                            s?.calculatedPriceAmount2
                                        }
                                        onBlur={() => setEditRow(null)}
                                        onChange={(evt) =>
                                            setChanges({
                                                ...changes,
                                                [s?.id]: {
                                                    manualPrice:
                                                        changes[s?.id]
                                                            ?.manualPrice ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount1,
                                                    manualPriceAmount2:
                                                        clampOrEmpty(
                                                            evt.target.value.trim(),
                                                            0,
                                                            99999.99,
                                                        ),
                                                    manualPriceAmount3:
                                                        changes[s?.id]
                                                            ?.manualPriceAmount3 ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount3,
                                                    bulkAmount1:
                                                        changes[s?.id]
                                                            ?.bulkAmount1 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount1,
                                                    bulkAmount2:
                                                        changes[s?.id]
                                                            ?.bulkAmount2 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount2,
                                                    bulkAmount3:
                                                        changes[s?.id]
                                                            ?.bulkAmount3 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount3,
                                                    ignorePlausiCheck:
                                                        changes[s?.id]
                                                            ?.ignorePlausiCheck ??
                                                        s?.manualAdjustment
                                                            ?.ignorePlausiCheck,
                                                },
                                            })
                                        }
                                    />
                                ) : (
                                    currencyFormat.format(
                                        changes[s?.id]?.manualPriceAmount2 ??
                                            s?.manualAdjustment?.priceAmount2 ??
                                            s?.calculatedPriceAmount2,
                                    )
                                )}
                            </p>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.editCell,
                                )}
                                onClick={() =>
                                    setEditRow({
                                        id: s?.id,
                                        bulk: 3,
                                        isPrice: false,
                                    })
                                }
                            >
                                {s?.id === editRow?.id &&
                                editRow?.bulk === 3 &&
                                !editRow?.isPrice ? (
                                    <input
                                        type="number"
                                        autoFocus={true}
                                        value={
                                            changes[s?.id]?.bulkAmount3 ??
                                            s?.manualAdjustment?.bulkAmount3 ??
                                            s?.bulkAmount3 ??
                                            ""
                                        }
                                        className={inputStyle.text}
                                        placeholder={s?.bulkAmount3}
                                        onBlur={() => setEditRow(null)}
                                        onChange={(evt) =>
                                            setChanges({
                                                ...changes,
                                                [s?.id]: {
                                                    manualPrice:
                                                        changes[s?.id]
                                                            ?.manualPrice ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount1,
                                                    manualPriceAmount2:
                                                        changes[s?.id]
                                                            ?.manualPriceAmount2 ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount2,
                                                    manualPriceAmount3:
                                                        changes[s?.id]
                                                            ?.manualPriceAmount3 ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount3,
                                                    bulkAmount1:
                                                        changes[s?.id]
                                                            ?.bulkAmount1 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount1,
                                                    bulkAmount2:
                                                        changes[s?.id]
                                                            ?.bulkAmount2 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount2,
                                                    bulkAmount3: clampOrEmpty(
                                                        evt.target.value.trim(),
                                                        0,
                                                        999,
                                                    ),
                                                    ignorePlausiCheck:
                                                        changes[s?.id]
                                                            ?.ignorePlausiCheck ??
                                                        s?.manualAdjustment
                                                            ?.ignorePlausiCheck,
                                                },
                                            })
                                        }
                                    />
                                ) : (
                                    changes[s?.id]?.bulkAmount3 ??
                                    s?.manualAdjustment?.bulkAmount3 ??
                                    s?.bulkAmount3
                                )}
                            </p>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.editCell,
                                )}
                                onClick={() =>
                                    setEditRow({
                                        id: s?.id,
                                        bulk: 3,
                                        isPrice: true,
                                    })
                                }
                            >
                                {s?.id === editRow?.id &&
                                editRow?.bulk === 3 &&
                                editRow?.isPrice ? (
                                    <input
                                        type="number"
                                        autoFocus={true}
                                        value={
                                            changes[s?.id]
                                                ?.manualPriceAmount3 ??
                                            s?.manualAdjustment?.priceAmount3 ??
                                            s?.calculatedPriceAmount3 ??
                                            ""
                                        }
                                        className={inputStyle.text}
                                        placeholder={
                                            s?.manualAdjustment?.priceAmount3 ??
                                            s?.calculatedPriceAmount3
                                        }
                                        onBlur={() => setEditRow(null)}
                                        onChange={(evt) =>
                                            setChanges({
                                                ...changes,
                                                [s?.id]: {
                                                    manualPrice:
                                                        changes[s?.id]
                                                            ?.manualPrice ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount1,
                                                    manualPriceAmount2:
                                                        changes[s?.id]
                                                            ?.manualPriceAmount2 ??
                                                        s?.manualAdjustment
                                                            ?.priceAmount2,
                                                    manualPriceAmount3:
                                                        clampOrEmpty(
                                                            evt.target.value.trim(),
                                                            0,
                                                            99999.99,
                                                        ),
                                                    bulkAmount1:
                                                        changes[s?.id]
                                                            ?.bulkAmount1 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount1,
                                                    bulkAmount2:
                                                        changes[s?.id]
                                                            ?.bulkAmount2 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount2,
                                                    bulkAmount3:
                                                        changes[s?.id]
                                                            ?.bulkAmount3 ??
                                                        s?.manualAdjustment
                                                            ?.bulkAmount3,
                                                    ignorePlausiCheck:
                                                        changes[s?.id]
                                                            ?.ignorePlausiCheck ??
                                                        s?.manualAdjustment
                                                            ?.ignorePlausiCheck,
                                                },
                                            })
                                        }
                                    />
                                ) : (
                                    currencyFormat.format(
                                        changes[s?.id]?.manualPriceAmount3 ??
                                            s?.manualAdjustment?.priceAmount3 ??
                                            s?.calculatedPriceAmount3,
                                    )
                                )}
                            </p>
                            <EnumLabel
                                className={cn(tableStyle.cell)}
                                label={s?.type}
                            />
                            <p className={tableStyle.fullWidthCell}>
                                {currencyFormat.format(s?.agrosso1)}
                            </p>
                            <p className={tableStyle.fullWidthCell}>
                                {currencyFormat.format(s?.agrosso2)}
                            </p>
                            <p className={tableStyle.fullWidthCell}>
                                {currencyFormat.format(s?.exportAep ?? s?.AEP)}
                            </p>
                            <p
                                className={cn(
                                    tableStyle.fullWidthCell,
                                    tableStyle.lastCell,
                                )}
                            >
                                {currencyFormat.format(s?.GEP)}
                            </p>
                            <PricingListResultExpander
                                isShown={
                                    expanderProduct && expanderProduct === s?.id
                                }
                                close={() => setExpanderProduct(null)}
                                pricingListResult={s}
                            />
                        </Fragment>
                    ))}
                </div>
                <Pagination
                    onPageChange={(page) => setPage(page)}
                    pagination={data?.list?.calculation?.results}
                    loading={loading && !data?.list}
                />
            </div>
        </div>
    )
}
