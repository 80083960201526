import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { Flash, WARNING } from "../../util/Flash"
import "../../../style/index.css"
import tableStyle from "../../../style/table.module.css"
import buttonStyle from "../../../style/button.module.css"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import cn from "classnames"
import { Banner } from "../../util/Banner"
import {
    getDayMonthYearFormatter,
    getSafeFormatter,
    getTimeFormatter,
} from "../../../i18n"
import { ListCell } from "../../util/table/ListCell"
import { CalculationSettingsEditorSidebar } from "../../util/modals/CalculationSettingEditorSidebar"
import { DeletionWithAffectedModal } from "../../util/modals/DeletionWithAffectedModal"
import c from "capitalize"

export const CALCULATION_SETTINGS_QUERY = gql`
    query getCalculationSettings(
        $pageNum: Int
        $order: [CalculationSettingAllOrder]
    ) {
        calculationSettings(limit: 20, pageNum: $pageNum, orderBy: $order) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                tableName
                date
                assortments {
                    id
                    name
                }
                createdAt
                updatedAt
            }
        }
    }
`

const DELETE_CALCULATION_SETTING = gql`
    mutation ($id: UUID!, $ignoreAffected: Boolean) {
        delete: deleteCalculationSetting(
            id: $id
            ignoreAffected: $ignoreAffected
        ) {
            success
            affected {
                model
                name
                id
            }
            blockers {
                model
                name
                id
            }
        }
    }
`

export const CalculationSettingList = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState(["TableNameAsc"])

    const { t, i18n } = useTranslation(["common", "table", "enums"])

    const { data, loading, refetch } = useQuery(CALCULATION_SETTINGS_QUERY, {
        variables: {
            pageNum,
            order,
        },
    })

    const [currentElement, setCurrentElement] = useState(null)

    const [calculationSettingsBarShown, setCalculationSettingsBarShown] =
        useState(false)

    const [calculationSettingVariables, setCalculationSettingVariables] =
        useState(null)

    const showCalculationSettingSideBar = (variables) => {
        setCalculationSettingVariables(variables)
        setCalculationSettingsBarShown(true)
    }

    const showEmptyCalculationSettingSideBar = () => {
        setCalculationSettingVariables(null)
        setCalculationSettingsBarShown(true)
    }

    const dayMonthYearFormat = getSafeFormatter(
        getDayMonthYearFormatter(i18n.language),
    )
    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    const dismiss = () => {
        setCalculationSettingsBarShown(false)
    }

    return (
        <div>
            <Banner text={t("table:calculation_settings")} />
            <div className={cn(tableStyle.buttonBar)}>
                <button
                    className={buttonStyle.button}
                    onClick={() => showEmptyCalculationSettingSideBar(true)}
                >
                    {t("table:add_new_calculation_setting")}
                </button>
            </div>
            {currentElement && (
                <DeletionWithAffectedModal
                    id={currentElement}
                    name={"name"}
                    delete_mutation={DELETE_CALCULATION_SETTING}
                    onDismiss={() => setCurrentElement(null)}
                    refetchQueries={[CALCULATION_SETTINGS_QUERY]}
                    onCancel={() => setCurrentElement(null)}
                />
            )}
            {calculationSettingsBarShown === true ? (
                <CalculationSettingsEditorSidebar
                    calculationSettingVariables={calculationSettingVariables}
                    dismiss={dismiss}
                    refetch={refetch}
                />
            ) : null}

            {!loading && !data?.calculationSettings.page?.length ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}
            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 3em",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:name")}
                    asc="TableNameAsc"
                    desc="TableNameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:date")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:assortment")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                    asc="UpdatedAtAsc"
                    desc="UpdatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                    asc="CreatedAtAsc"
                    desc="CreatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <p className={tableStyle.header} />
                {loading && !data?.calculationSettings && (
                    <TableContentLoader columns={8} />
                )}
                {data?.calculationSettings.page.map((s, i) => (
                    <Fragment key={i}>
                        <p
                            className={cn(tableStyle.cell, tableStyle.linkCell)}
                            onClick={() =>
                                showCalculationSettingSideBar({
                                    id: s.id,
                                    tableName: s.tableName,
                                    date: s.date,
                                    assortments: s.assortments,
                                    validUntil: s.validUntil,
                                    refetch,
                                })
                            }
                        >
                            {c(t(`enums:${s?.tableName?.toLowerCase()}`))}
                        </p>
                        <p className={tableStyle.cell}>
                            {dayMonthYearFormat.format(new Date(s.date))}
                        </p>
                        <ListCell
                            className={tableStyle.cell}
                            labels={s?.assortments}
                            destination={"assortment"}
                        />
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s.updatedAt))}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s.createdAt))}
                        </p>
                        <p className={tableStyle.actionCell}>
                            <button
                                className={cn(
                                    tableStyle.actionButton,
                                    tableStyle.negativeActionButton,
                                )}
                                onClick={() => {
                                    setCurrentElement(s.id)
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.calculationSettings}
                loading={loading && !data?.calculationSettings}
            />
        </div>
    )
}
