import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import inputStyle from "../../../style/input.module.css"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import cn from "classnames"
import { SmartFilter } from "../../util/input/SmartFilter"
import { getDayMonthYearFormatter, getSafeFormatter } from "../../../i18n"
import { EnumLabel } from "../../util/table/EnumLabel"
import { Link } from "react-router-dom"
import buttonStyle from "../../../style/button.module.css"
import { showError } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"
import styles from "../../util/modals/Notification.module.css"

const GET_PRICING_LIST_GROUP = gql`
    query (
        $pageNum: Int!
        $order: [MetricDatesOverrideAllOrder]
        $filter: MetricDatesOverrideAllFilter
    ) {
        metricDatesOverrides(
            pageNum: $pageNum
            orderBy: $order
            filterParams: $filter
            limit: 20
        ) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                tableName
                date
                product {
                    name
                    id
                    pzn
                }
                assortment {
                    id
                    name
                }
            }
        }
    }
`

const FILTER_QUERY = gql`
    query getFilter {
        metricDatesOverrides(limit: 0) {
            filterFields {
                name
                dataType
                displayName
                options
            }
        }
    }
`

const DELETE = gql`
    mutation ($filter: MetricDatesOverrideDeletionAllFilter) {
        deleteMetricDatesWithFilter(filterParams: $filter)
    }
`

export const MetricDateOverrides = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [filter, setFilter] = useState(null)

    const [validationCode, setValidationCode] = useState(
        (Math.random() + 1).toString(36).substring(3, 7),
    )
    const [validationName, setValidationNane] = useState("")
    const [deletionEnabled, setDeletionEnabled] = useState(false)

    const { t, i18n } = useTranslation(["common", "table"])

    const dateFormatter = getSafeFormatter(
        getDayMonthYearFormatter(i18n.language),
    )

    const [deleteDates] = useMutation(DELETE)

    const dispatch = useDispatch()

    const { data, loading } = useQuery(GET_PRICING_LIST_GROUP, {
        variables: {
            pageNum,
            order,
            filter,
        },
    })

    return (
        <div>
            <Banner text={t("table:targeted_data_updates")} />
            <div className={tableStyle.stackedFilterBar}>
                <SmartFilter
                    filterFieldQuery={FILTER_QUERY}
                    filterPath={(q) => q?.metricDatesOverrides?.filterFields}
                    setFilter={setFilter}
                    hasSearch={false}
                />
                <div className={tableStyle.buttonSection}>
                    <Link
                        className={buttonStyle.orangeButton}
                        to={"/targeted_data_updates/csv"}
                    >
                        {t("table:upload_calculation_setting_dates")}
                    </Link>
                    {deletionEnabled && Object.keys(filter || {})?.length ? (
                        <div>
                            <div className={tableStyle.confirmBox}>
                                <button
                                    className={cn(
                                        buttonStyle.button,
                                        buttonStyle.dangerButton,
                                    )}
                                    onClick={() =>
                                        deleteDates({
                                            variables: {
                                                filter,
                                            },
                                        })
                                            .then(() => {
                                                setDeletionEnabled(false)
                                                setValidationCode(
                                                    (Math.random() + 1)
                                                        .toString(36)
                                                        .substring(3, 7),
                                                )
                                                setValidationNane("")
                                                window.location.reload()
                                            })
                                            .catch((e) =>
                                                dispatch(showError(e.message)),
                                            )
                                    }
                                    disabled={validationName !== validationCode}
                                >
                                    {t("table:delete_selected")}
                                </button>
                                <input
                                    className={inputStyle.text}
                                    value={validationName}
                                    onChange={(e) =>
                                        setValidationNane(e.target.value)
                                    }
                                    onPaste={(evt) => evt.preventDefault()}
                                />
                            </div>
                            <p>
                                {t("common:enter_deletion_code")}:{" "}
                                <span className={styles.bold}>
                                    {validationCode}
                                </span>
                            </p>
                        </div>
                    ) : (
                        <button
                            className={cn(
                                buttonStyle.button,
                                buttonStyle.dangerButton,
                            )}
                            title={t("table:filter_to_delete")}
                            onClick={() => setDeletionEnabled(true)}
                            disabled={!Object.keys(filter || {})?.length}
                        >
                            {t("table:delete_selected")}
                        </button>
                    )}
                </div>
            </div>
            {!loading && !data?.metricDatesOverrides ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}

            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "repeat(5, 1fr)",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pzn")}
                    asc="ProductPznAsc"
                    desc="ProductPznDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:product_name")}
                    asc="ProductNameAsc"
                    desc="ProductNameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:reference_date")}
                    asc="DateAsc"
                    desc="DateDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:table_name")}
                    asc="TableNameAsc"
                    desc="TableNameDesc"
                    order={order}
                    setOrder={setOrder}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:assortment")}
                    asc="AssortmentNameAsc"
                    desc="AssortmentNameDesc"
                    order={order}
                    setOrder={setOrder}
                />

                {loading && !data?.pricingListGroup && (
                    <TableContentLoader columns={18} />
                )}
                {data?.metricDatesOverrides?.page.map((s, i) => (
                    <Fragment key={i}>
                        <p className={tableStyle.cell}>{s?.product?.pzn}</p>
                        <p className={tableStyle.cell}>{s?.product?.name}</p>
                        <p className={tableStyle.cell}>
                            {dateFormatter.format(new Date(s?.date))}
                        </p>

                        <EnumLabel
                            label={s?.tableName}
                            className={tableStyle.cell}
                        />

                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {s?.assortment?.name}
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.metricDatesOverrides}
                loading={loading && !data?.metricDatesOverrides}
            />
        </div>
    )
}
