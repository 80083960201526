const initialState = {
    sideBarShown: false,
    product: null,
    scrollLocked: false,
}

export const HIDE_ALL_MODALS = "HIDE_ALL_MODALS"
export const SHOW_SIDEBAR_MODAL = "SHOW_SIDEBAR_MODAL"
export const SHOW_PRODUCT = "SHOW_PRODUCT"

/**
 * Reducer for the sidebar.
 *
 * @param state the old state
 * @param type the type of the action that should be performed
 * @param product the product that should be shown
 * @returns {{product, sideBarShown: boolean, scrollLocked: boolean}|{product: null, sideBarShown: boolean, scrollLocked: boolean}} the new state
 * @constructor
 */
export const ModalReducer = (state = initialState, { type, product }) => {
    switch (type) {
        case SHOW_PRODUCT:
            return {
                ...state,
                product,
                scrollLocked: true,
            }
        case SHOW_SIDEBAR_MODAL:
            return {
                ...state,
                sideBarShown: true,
                scrollLocked: true,
            }
        case HIDE_ALL_MODALS:
            return initialState

        default:
            return state
    }
}
