import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { useHistory, useParams } from "react-router-dom"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import inputStyle from "../../../style/input.module.css"
import { uniqueId } from "lodash"
import buttonStyle from "../../../style/button.module.css"
import formStyle from "../../../style/form.module.css"
import { showError } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"
import cn from "classnames"
import { Loader } from "../../util/Loader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import Select from "react-select"
import makeAnimated from "react-select/animated"

const GET_DISTRIBUTOR = gql`
    query ($id: UUID!) {
        distributor(id: $id) {
            id
            name
            title
            message
            pricingListTypes(
                limit: -1
                filterParams: {
                    hideInCreateEquals: false
                    disabledEquals: false
                }
            ) {
                page {
                    id
                    displayName
                    pricingListTypeNumber
                }
            }
            emails(limit: -1) {
                page {
                    id
                    email
                }
            }
        }
    }
`

const GET_PRICING_LIST_TYPES = gql`
    query {
        pricingListTypes(
            limit: -1
            filterParams: { hideInCreateEquals: false, disabledEquals: false }
        ) {
            page {
                id
                displayName
                pricingListTypeNumber
            }
        }
    }
`

const UPDATE_DISTRIBUTOR = gql`
    mutation (
        $id: UUID!
        $name: String
        $title: String!
        $message: String!
        $emails: [EmailInputType]
        $pricingListTypes: [UUID]
    ) {
        updateDistributor(
            id: $id
            name: $name
            title: $title
            message: $message
            emails: $emails
            pricingListTypes: $pricingListTypes
        ) {
            id
            title
            message
            name
            pricingListTypes {
                page {
                    id
                    displayName
                    pricingListTypeNumber
                }
            }
            emails(limit: -1) {
                page {
                    id
                    email
                }
            }
        }
    }
`

const CREATE_DISTRIBUTOR = gql`
    mutation (
        $name: String!
        $title: String!
        $message: String!
        $emails: [EmailInputType]
        $pricingListTypes: [UUID]
    ) {
        createDistributor(
            name: $name
            title: $title
            message: $message
            emails: $emails
            pricingListTypes: $pricingListTypes
        ) {
            id
            name
            title
            message
            pricingListTypes {
                page {
                    id
                    displayName
                    pricingListTypeNumber
                }
            }
            emails(limit: -1) {
                page {
                    id
                    email
                }
            }
        }
    }
`

export const DistributorEditor = () => {
    const { id } = useParams()
    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [pricing_list_types_id] = useState(() =>
        uniqueId("pricing_list_types_"),
    )
    const [order, setOrder] = useState([])

    const [name, setName] = useState("")
    const [message, setMessage] = useState("")
    const [title, setTitle] = useState("")
    const [email, setEmail] = useState("")
    const [emails, setEmails] = useState([])
    const [pricingListTypes, setPricingListTypes] = useState([])

    const { t } = useTranslation(["common", "table"])

    const { data, loading } = useQuery(GET_DISTRIBUTOR, {
        variables: {
            id,
        },
        skip: !id,
    })

    const { data: pricingListTypeData, loading: pricingListLoadingData } =
        useQuery(GET_PRICING_LIST_TYPES)

    const history = useHistory()

    const [update, { loading: updateDistributorLoading }] =
        useMutation(UPDATE_DISTRIBUTOR)
    const [create, { loading: createDistributorLoading }] =
        useMutation(CREATE_DISTRIBUTOR)

    const dispatch = useDispatch()

    useEffect(() => {
        setName(data?.distributor?.name || "")
        setTitle(data?.distributor?.title || "")
        setMessage(data?.distributor?.message || "")
        setEmails(
            data?.distributor?.emails?.page?.map((e) => ({
                email: e.email,
                id: e.id,
            })) || [],
        )
        setPricingListTypes(
            data?.distributor?.pricingListTypes?.page?.map((e) => e.id || []),
        )
    }, [data?.distributor])

    if (
        loading ||
        createDistributorLoading ||
        updateDistributorLoading ||
        pricingListLoadingData
    ) {
        return (
            <div>
                <Banner text={t("table:distributor")} />
                <Loader />
            </div>
        )
    }

    return (
        <div>
            <Banner
                text={`${t("table:distributor")}: ${
                    name || data?.distributor?.name || ""
                }`}
            />
            <div className={tableStyle.stretchFilterBar}>
                <div className={tableStyle.filterBar} />

                <div>
                    <button
                        className={buttonStyle.button}
                        disabled={!name || !title || !message}
                        onClick={() =>
                            id
                                ? update({
                                      variables: {
                                          id,
                                          name,
                                          title,
                                          message,
                                          emails,
                                          pricingListTypes,
                                      },
                                  })
                                      .then(() => history.push(`/distributors`))
                                      .catch((e) =>
                                          dispatch(showError(e.message)),
                                      )
                                : create({
                                      variables: {
                                          name,
                                          emails,
                                          title,
                                          message,
                                          pricingListTypes,
                                      },
                                  })
                                      .then(() => history.push(`/distributors`))
                                      .catch((e) =>
                                          dispatch(showError(e.message)),
                                      )
                        }
                    >
                        {t("table:save")}
                    </button>
                </div>
            </div>
            <div className={formStyle.rowWrapper}>
                <div className={formStyle.legend}>
                    <h1>{t("table:legend")}</h1>
                    <p>{t("table:pricing_list_name")}</p>
                    <p>{"{name}"}</p>
                    <p>{t("table:pricing_list_type")}</p>
                    <p>{"{type}"}</p>
                    <p>{t("table:pricing_list_type_number")}</p>
                    <p>{"{ek}"}</p>
                    <p>{t("table:sender_name")}</p>
                    <p>{"{sender}"}</p>
                    <p>{t("table:valid_from")}</p>
                    <p>{"{valid_from}"}</p>
                    <p>{t("table:valid_until")}</p>
                    <p>{"{valid_until}"}</p>
                </div>
                <div className={formStyle.formGrid}>
                    <label htmlFor={name_input_id}>{t("table:name")}</label>
                    <input
                        id={name_input_id}
                        className={inputStyle.text}
                        type="text"
                        value={name}
                        onChange={(evt) => setName(evt.target.value)}
                    />
                    <label>{t("table:title")}</label>
                    <input
                        className={inputStyle.text}
                        type="text"
                        value={title}
                        onChange={(evt) => setTitle(evt.target.value)}
                    />
                    <label>{t("table:message")}</label>
                    <textarea
                        className={inputStyle.textArea}
                        value={message}
                        rows={5}
                        onChange={(evt) => setMessage(evt.target.value)}
                    />
                    <label htmlFor={pricing_list_types_id}>
                        {t("table:pricing_list_types")}
                    </label>
                    <Select
                        isMulti={true}
                        value={pricingListTypes?.map((a) => ({
                            value: a,
                            label: pricingListTypeData?.pricingListTypes?.page
                                .map((a) => ({
                                    ...a,
                                    fullName: `${a.displayName} (${a.pricingListTypeNumber})`,
                                }))
                                .find((as) => as.id === a)?.fullName,
                        }))}
                        onChange={(as) =>
                            setPricingListTypes(as.map((a) => a.value))
                        }
                        loadingMessage={() => t("common:loading")}
                        isLoading={loading}
                        components={makeAnimated()}
                        options={pricingListTypeData?.pricingListTypes?.page?.map(
                            (a) => ({
                                value: a.id,
                                label: `${a.displayName} (${a.pricingListTypeNumber})`,
                            }),
                        )}
                        id={pricing_list_types_id}
                    />
                </div>
            </div>
            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "1fr 3em",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:email")}
                    asc="NameAsc"
                    desc="NameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <p className={tableStyle.header} />
                {loading && !data?.pricingLists && (
                    <TableContentLoader columns={2} />
                )}
                {emails.map((s, i) => (
                    <Fragment key={i}>
                        <p className={tableStyle.cell}>{s?.email}</p>
                        <p className={tableStyle.actionCell}>
                            <button
                                className={cn(
                                    tableStyle.actionButton,
                                    tableStyle.lastCell,
                                    tableStyle.negativeActionButton,
                                )}
                                onClick={() =>
                                    setEmails(
                                        emails.filter(
                                            (e) =>
                                                !(
                                                    e.email === s?.email &&
                                                    e.id === s?.id
                                                ),
                                        ),
                                    )
                                }
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </p>
                    </Fragment>
                ))}
            </div>
            <div className={formStyle.inputRow}>
                <input
                    className={inputStyle.text}
                    type="email"
                    value={email}
                    onChange={(evt) => setEmail(evt.target.value)}
                />
                <button
                    disabled={!email}
                    className={buttonStyle.button}
                    onClick={() => {
                        setEmails([{ email }, ...emails])
                        setEmail("")
                    }}
                >
                    {t("table:add_email")}
                </button>
            </div>
        </div>
    )
}
