/**
 * Registration function that asks the user to enable notifications
 */
export const askNotificationPermission = () => {
    /**
     * Stores the Notification permission
     *
     * @param permission the permission the user granted
     */
    const handlePermission = (permission) => {
        if (!("permission" in Notification)) {
            Notification.permission = permission
        }
    }

    if (!("Notification" in window)) {
        console.warn("This browser does not support notifications.")
    } else {
        if (checkNotificationPromise()) {
            Notification.requestPermission().then((permission) => {
                handlePermission(permission)
            })
        } else {
            Notification.requestPermission((permission) => {
                handlePermission(permission)
            })
        }
    }
}

/**
 * checks if the permission API of the browser is promise based
 * @returns {boolean} true if the permission API of the browser is promise based.
 */
export const checkNotificationPromise = () => {
    try {
        Notification.requestPermission().then()
    } catch (e) {
        return false
    }

    return true
}
