import tableStyle from "../../../style/table.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons"

/**
 * This element is a header above a table column with elements to indicate ordering
 *
 * @param label the text the label should display
 * @param asc the attribute that needs to be set to sort the results ascending by this column
 * @param desc the attribute that needs to be set to sort the results descending by this column
 * @param order the current ordering as a list of strings
 * @param setOrder the function used to set the order list
 * @param style the style applied to the header, default is merged
 * @returns {JSX.Element} the header element
 * @constructor
 */
export const TableHeader = ({ label, asc, desc, order, setOrder, style }) => {
    if (!order) {
        return (
            <p style={style} className={tableStyle.header}>
                {label}
            </p>
        )
    } else {
        let index = order.indexOf(asc)

        if (index < 0) {
            index = order.indexOf(desc)
        }
        return (
            <p
                className={tableStyle.header}
                style={{ cursor: "pointer", ...(style || {}) }}
                onClick={() => {
                    let newOrder = order
                        .slice(0)
                        .filter((o) => ![asc, desc].includes(o))
                    if (order.includes(asc)) {
                        newOrder.unshift(desc)
                    } else if (!order.includes(desc)) {
                        newOrder.unshift(asc)
                    }
                    setOrder(newOrder)
                }}
            >
                {label}{" "}
                <FontAwesomeIcon
                    icon={
                        order.includes(asc)
                            ? faSortUp
                            : order.includes(desc)
                            ? faSortDown
                            : faSort
                    }
                />{" "}
                <sup className={tableStyle.orderNumber}>
                    {index >= 0 ? index + 1 : ""}
                </sup>
            </p>
        )
    }
}
