import React from "react"
import ReactDOM from "react-dom"
import "typeface-roboto"
import "./style/index.css"
import { App } from "./App"
import { ApolloProvider } from "@apollo/client"
import { client } from "./client"
import { BrowserRouter } from "react-router-dom"
import { Provider as ReduxProvider } from "react-redux"
import { applyMiddleware, createStore } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import rootReducer from "./redux/reducers/RootReducer"
import "./i18n"
import { Notification } from "./components/util/modals/Notification"
import { LoginScreen } from "./components/util/modals/LoginScreen"
import { ModalManager } from "./components/util/modals/ModalManager"
import * as serviceWorkerRegistration from "./notifications/register"
import * as pushPermissionsApi from "./notifications/push_permissions"
import { ErrorBoundary } from "./components/util/ErrorBoundary"

/**
 * Redux store with thunk middleware and dev tools enabled.
 * @type {Store<EmptyObject & S & {}, AnyAction> & Store<S & {}, A> & {dispatch: ThunkDispatch<any, undefined, AnyAction>}}
 */
export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk)),
)

ReactDOM.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <ApolloProvider client={client}>
                <BrowserRouter>
                    <ErrorBoundary>
                        <App />
                        <LoginScreen />
                        <ModalManager />
                        <Notification />
                    </ErrorBoundary>
                </BrowserRouter>
            </ApolloProvider>
        </ReduxProvider>
    </React.StrictMode>,
    document.getElementById("root"),
)

serviceWorkerRegistration.register()
pushPermissionsApi.askNotificationPermission()
