import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import {gql, useMutation, useQuery} from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import cn from "classnames"
import {
    getCurrencyFormatter,
    getIntegerFormatter,
    getSafeFormatter,
} from "../../../i18n"
import { ProductDetailExpander } from "../../util/ProductDetailExpander"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import {showError, showInfo} from "../../../redux/actions/NotificationActions";
import {useDispatch} from "react-redux";
import {Pagination} from "../../util/table/Pagination";

const GET_PRODUCTS = gql`
    query($pageNum: Int!){
        missingMinPriceProductList(
            pageNum: $pageNum
            limit: 20
        ){
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                name
                pzn
                unit
                atc
                quantity
                prescriptionDrug
                pharmacyOnly
                supplierName
                AEP
                GEP
                agrosso1
                agrosso2
                agrosso1Midas
                agrosso2Midas
                agrosso1Mvda
                agrosso2Mvda
                agrosso1Generic
                agrosso2Generic
                revenueGrossoTotal
                revenueGrossoWholesale
                revenueGrossoPhoenix
                revenueGrossoDirect
            }
        }
    }
`

const DOWNLOAD_PRODUCTS = gql`
    mutation download{
        downloadMissingMinPriceProductList
    }
`

export const MissingMinPriceProductList = () => {
    const [expanderProduct, setExpanderProduct] = useState(null)

    const [loadProducts, setLoadProducts] = useState(false)
    const [pageNum, setPage] = useState(1)

    const dispatch = useDispatch()

    const { t, i18n } = useTranslation(["common", "table"])

    const currencyFormat = getSafeFormatter(getCurrencyFormatter(i18n.language))
    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    const { data, loading } = useQuery(GET_PRODUCTS, {
        skip: !loadProducts,
        variables: {
            pageNum,
        }
    })

    const [download] = useMutation(DOWNLOAD_PRODUCTS)

    return (
        <div>
            <Banner text={t("table:missing_min_price_products")}/>
            {loadProducts && <div className={dropDownStyle.container}>
                <span/>
                <div className={tableStyle.buttonBar}>
                    <button
                        className={buttonStyle.button}
                        onClick={() => download().then(() =>
                                    dispatch(
                                        showInfo("common:export_triggered"),
                                    ),
                                )
                                .catch((e) => dispatch(showError(e.message)))}
                    >
                        {c(t("table:export_products"))}
                    </button>
                </div>
            </div>}
            {!loading && !data?.missingMinPriceProductList && loadProducts ? (
                <Flash text={t("table:no_results")} type={WARNING}/>
            ) : null}

            {! loadProducts && <div className={cn(tableStyle.buttonBar, tableStyle.centerButtonBar)}>
                <button
                    className={cn(buttonStyle.button, buttonStyle.bigButton)}
                    onClick={() => {
                        setLoadProducts(true)
                    }}
                >
                    {c(t("table:load_products"))}
                </button>
            </div>}

            {loadProducts && <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "1fr 2fr 2fr repeat(15, 1fr)",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pzn")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:product_name")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:supplier_name")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:atc")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:unit")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:quantity")}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:aep")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:gep")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:agrosso") + " 1  Generic"}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:agrosso") + " 2  Generic"}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:agrosso") + " 1  MVDA"}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:agrosso") + " 2  MVDA"}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:agrosso") + " 1  MIDAS"}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:agrosso") + " 2  MIDAS"}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:revenue_grosso_total")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:revenue_grosso_wholesale")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:revenue_grosso_phoenix")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:revenue_grosso_direct")}
                />

                {loading && !data?.pricingListGroup && (
                    <TableContentLoader columns={18} rows={20}/>
                )}
                {data?.missingMinPriceProductList?.page.map((s, i) => (
                    <Fragment key={i}>
                        <p
                            className={tableStyle.cell}
                            onClick={() => setExpanderProduct(s?.id)}
                        >
                            {s?.pzn}
                        </p>
                        <p
                            className={tableStyle.cell}
                            onClick={() => setExpanderProduct(s?.id)}
                        >
                            {s?.name}
                        </p>
                        <p className={tableStyle.cell}>{s?.supplierName}</p>
                        <p className={tableStyle.cell}>{s?.atc}</p>
                        <p className={tableStyle.cell}>{s?.unit}</p>
                        <p className={tableStyle.cell}>
                            {integerFormatter.format(s?.quantity)}
                        </p>

                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.AEP)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.GEP)}
                        </p>

                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.agrosso1Generic)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.agrosso2Generic)}
                        </p>

                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.agrosso1Mvda)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.agrosso2Mvda)}
                        </p>

                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.agrosso1Midas)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.agrosso2Midas)}
                        </p>

                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.revenueGrossoTotal)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.revenueGrossoWholesale)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.revenueGrossoPhoenix)}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {currencyFormat.format(s?.revenueGrossoDirect)}
                        </p>
                        <ProductDetailExpander
                            isShown={
                                expanderProduct && expanderProduct === s?.id
                            }
                            close={() => setExpanderProduct(null)}
                            product={s}
                        />
                    </Fragment>
                ))}
            </div>}
            {loadProducts &&<Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.missingMinPriceProductList}
                loading={loading && !data?.missingMinPriceProductList}
            />}
        </div>
    )
}
