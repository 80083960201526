import { HIDE_AUTH, SHOW_AUTH } from "../reducers/AuthReducer"

/**
 * Builds the action that shows the auth screen
 * @returns {{type: string}} the action that shows the auth screen
 */
export const showAuth = () => {
    return {
        type: SHOW_AUTH,
    }
}

/**
 * Builds the action that hides the auth screen
 * @returns {{type: string}} the action that hides the auth screen
 */
export const hideAuth = () => {
    return {
        type: HIDE_AUTH,
    }
}
