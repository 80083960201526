import style from "./SmartFilter.module.css"
import c from "capitalize"
import inputStyles from "../../../style/input.module.css"
import buttonStyles from "../../../style/button.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons"
import { animated, useSpring } from "react-spring"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import useMeasure from "react-use-measure"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import cn from "classnames"
import tableStyle from "../../../style/table.module.css"
import { cloneDeep, snakeCase } from "lodash"
import { useQuery } from "@apollo/client"
import { Toggle } from "./Toggle"

/**
 * This element is a single row in the filter
 *
 * @param filterFields the list of fields that represent the options
 * @param filter the current filter value of this row
 * @param setFilter function that gets called with the modified filter for this row
 * @param remove function that gets called to remove the row
 * @param triggerSearch function that gets called when the user wants to run the search
 * @returns {JSX.Element} the filter row element
 * @constructor
 */
const FilterRow = ({
    filterFields,
    filter,
    setFilter,
    remove,
    triggerSearch,
}) => {
    const { t } = useTranslation(["common", "filter", "enums"])

    return (
        <>
            <Select
                placeholder={""}
                value={
                    (filterFields
                        ?.filter((v) => v.value === filter?.field)
                        .map((f) => ({
                            value: f.name,
                            label: f.displayName
                                ?.map((d) => t(`filter:${d.toLowerCase()}`))
                                .join(" "),
                            type: f.dataType,
                            selectOptions: f.options,
                        })) || [])[0]
                }
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
                menuPosition={"fixed"}
                onChange={(s) => {
                    switch (s.type) {
                        // case "USER":
                        //     setFilterTypeOptions(["equals", "beginsWith", "endsWith", "contains"])
                        //     break
                        case "STRING":
                            if (s.selectOptions?.length) {
                                setFilter({
                                    field: s.value,
                                    type: "Equals",
                                    value: "",
                                    valueOptions: s.selectOptions,
                                    typeOptions: ["Equals"],
                                    valueType: "choice",
                                })
                            } else {
                                setFilter({
                                    field: s.value,
                                    type: null,
                                    value: "",
                                    valueOptions: [],
                                    typeOptions: [
                                        "Equals",
                                        "BeginsWith",
                                        "EndsWith",
                                        "Contains",
                                    ],
                                    valueType: "text",
                                })
                            }
                            break
                        case "BOOLEAN":
                            setFilter({
                                field: s.value,
                                type: "Equals",
                                value: "",
                                valueOptions: s.selectOptions,
                                typeOptions: ["Equals"],
                                valueType: "toggle",
                            })
                            break
                        case "NUMBER":
                            setFilter({
                                field: s.value,
                                type: null,
                                value: "",
                                typeOptions: [
                                    "Equals",
                                    "Larger",
                                    "Smaller",
                                    "EqualOrLarger",
                                    "EqualOrSmaller",
                                ],
                                valueOptions: [],
                                valueType: "number",
                            })
                            break
                        case "DATETIME":
                            setFilter({
                                field: s.value,
                                type: null,
                                value: "",
                                typeOptions: [
                                    "Equals",
                                    "Larger",
                                    "Smaller",
                                    "EqualOrLarger",
                                    "EqualOrSmaller",
                                ],
                                valueOptions: [],
                                valueType: "date",
                            })
                            break
                        // case "UUID":
                        //     setFilterTypeOptions(["equals"])
                        //     break
                    }
                }}
                loadingMessage={() => t("common:loading")}
                isLoading={!filterFields}
                components={makeAnimated()}
                options={
                    filterFields
                        ?.filter(
                            (f) =>
                                f.dataType !== "USER" && f.dataType !== "UUID",
                        )
                        ?.map((f) => ({
                            value: f.name,
                            label: f.displayName
                                ?.map((d) => t(`filter:${d.toLowerCase()}`))
                                .join(" "),
                            type: f.dataType,
                            selectOptions: f.options,
                        })) || []
                }
            />
            <Select
                placeholder={""}
                value={
                    filter?.type
                        ? {
                              value: filter?.type,
                              label: t(`filter:${snakeCase(filter?.type)}`),
                          }
                        : ""
                }
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
                menuPosition={"fixed"}
                onChange={(s) =>
                    setFilter({
                        ...filter,
                        type: s.value,
                        value: "",
                    })
                }
                loadingMessage={() => t("common:loading")}
                isLoading={!filterFields}
                isDisabled={!filter?.typeOptions}
                components={makeAnimated()}
                options={
                    filter?.typeOptions?.map((f) => ({
                        value: f,
                        label: t(`filter:${snakeCase(f)}`),
                    })) || []
                }
            />
            {filter?.valueType === "choice" ? (
                <Select
                    placeholder={""}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                    }}
                    menuPosition={"fixed"}
                    value={
                        filter?.value
                            ? {
                                  value: filter?.value,
                                  label: t(
                                      `enums:${filter?.value?.toLowerCase()}`,
                                  ),
                              }
                            : ""
                    }
                    isDisabled={!filter?.type}
                    onChange={(s) => setFilter({ ...filter, value: s.value })}
                    loadingMessage={() => t("common:loading")}
                    isLoading={!filterFields}
                    components={makeAnimated()}
                    options={
                        filter?.valueOptions?.map((f) => ({
                            value: f,
                            label: t(`enums:${f?.toLowerCase()}`),
                        })) || []
                    }
                />
            ) : null}

            {filter?.valueType === "toggle" ? (
                <Toggle
                    disabled={!filter?.type}
                    isChecked={!!filter?.value}
                    setIsChecked={(v) => setFilter({ ...filter, value: v })}
                />
            ) : null}

            {filter?.valueType === "text" ||
            filter?.valueType === "number" ||
            filter?.valueType === "date" ? (
                <input
                    className={inputStyles.text}
                    disabled={!filter?.type}
                    value={
                        filter?.value instanceof Date
                            ? filter?.value.toISOString().substring(0, 10)
                            : filter?.value || ""
                    }
                    type={filter?.valueType || "text"}
                    onKeyDown={(evt) => {
                        if (evt.key === "Enter") {
                            triggerSearch()
                        }
                    }}
                    onChange={(evt) =>
                        setFilter({
                            ...filter,
                            value:
                                filter?.valueType === "date"
                                    ? new Date(evt.target.value)
                                    : evt.target.value,
                        })
                    }
                />
            ) : null}
            <button
                className={cn(
                    tableStyle.actionButton,
                    tableStyle.negativeActionButton,
                )}
                onClick={() => remove()}
            >
                <FontAwesomeIcon icon={faTimes} />
            </button>
        </>
    )
}

/**
 * This element is a self constructing filter bar with search functionality
 *
 * @param parentSetQuery function that gets called with a string for string based searches
 * @param parentSetFilter function that gets called with the filter params after they changed
 * @param filterQueryOptions optional options for the filter query
 * @param suggestedQuery the text that should be shows as suggested under the searchbox
 * @param filterFieldQuery the GraphQL query to get the filter information
 * @param filterPath the path in the GraphQL query to get to the filter information
 * @param hasSearch true if the searchbar should be displayed
 * @returns {JSX.Element} the smartfilter element
 * @constructor
 */
export const SmartFilter = ({
    setQuery: parentSetQuery = () => {},
    setFilter: parentSetFilter,
    filterQueryOptions = {},
    suggestedQuery,
    filterFieldQuery,
    filterPath,
    hasSearch = true,
}) => {
    const [query, setQuery] = useState("")
    const [queryTooShort, setQueryTooShort] = useState(false)
    const [filter, setFilter] = useState([])
    const [filterOpen, setFilterOpen] = useState(false)

    const [bind, { height }] = useMeasure()
    const { t } = useTranslation(["common", "filter"])

    const { data, loading } = useQuery(filterFieldQuery, filterQueryOptions)

    const animatedStyle = useSpring({
        height: filterOpen ? `${height}px` : "0",
    })

    const filterFields = filterPath(data)

    const search = (newFilter) => {
        if (query?.length >= 3) {
            parentSetQuery(query)
            setQueryTooShort(false)
        } else {
            if (query?.length && query?.length > 0) {
                setQueryTooShort(true)
            } else {
                setQueryTooShort(false)
            }

            parentSetQuery("")
        }
        parentSetFilter(
            (newFilter || filter)
                .filter(
                    (v) =>
                        (v.value || (!v.value && v.value === 0)) &&
                        v.type &&
                        v.field,
                )
                .reduce(
                    (acc, n) => ({
                        ...acc,
                        [n.field + n.type]: n.value,
                    }),
                    {},
                ),
        )
    }

    return (
        <div className={style.searchWrapper}>
            <div className={style.searchArea}>
                {hasSearch ? (
                    <div className={style.searchBox}>
                        <input
                            type="search"
                            value={query}
                            onChange={(evt) => {
                                setQuery(evt.target.value)
                                setQueryTooShort(false)
                            }}
                            placeholder={c(t("filter:search"))}
                            className={inputStyles.text}
                            onKeyDown={(evt) => {
                                if (evt.key === "Enter") {
                                    search()
                                }
                            }}
                        />
                        {queryTooShort ? (
                            <p>{t("filter:query_too_short")}</p>
                        ) : null}

                        {suggestedQuery ? (
                            <p
                                className={style.suggestion}
                                onClick={() => {
                                    setQuery(suggestedQuery)
                                }}
                            >
                                {c(t("filter:did_you_mean"))} {suggestedQuery}?
                            </p>
                        ) : null}
                    </div>
                ) : null}
                {hasSearch || filterOpen ? (
                    <button
                        className={cn(buttonStyles.button)}
                        onClick={() => search()}
                    >
                        {c(t("filter:search"))}
                    </button>
                ) : null}
                {filterFields && !loading ? (
                    <span className={style.filterButton}>
                        <FontAwesomeIcon
                            icon={faFilter}
                            onClick={() => {
                                if (!filterOpen && !filter?.length) {
                                    setFilter([
                                        {
                                            field: null,
                                            type: null,
                                            typeOptions: [],
                                            value: null,
                                            valueOptions: [],
                                            valueType: "text",
                                        },
                                    ])
                                }
                                setFilterOpen(!filterOpen)
                            }}
                        />
                        +
                    </span>
                ) : null}
            </div>
            <div>
                <animated.div style={animatedStyle} className={style.filterBox}>
                    <animated.div style={animatedStyle}>
                        <div ref={bind} className={style.filterRowBox}>
                            {filter.map((f, i) => (
                                <FilterRow
                                    key={i}
                                    triggerSearch={search}
                                    filterFields={filterFields}
                                    filter={f}
                                    setFilter={(f) => {
                                        const copy = cloneDeep(filter)
                                        copy[i] = f
                                        setFilter(copy)
                                    }}
                                    remove={() => {
                                        const copy = cloneDeep(filter)
                                        copy.splice(i, 1)
                                        if (copy.length === 0) {
                                            search(copy)
                                            setFilterOpen(false)
                                        }
                                        setFilter(copy)
                                    }}
                                />
                            ))}
                            <button
                                onClick={() =>
                                    setFilter([
                                        ...filter,
                                        {
                                            field: null,
                                            type: null,
                                            typeOptions: [],
                                            value: null,
                                            valueOptions: [],
                                            valueType: "text",
                                        },
                                    ])
                                }
                                className={cn(
                                    buttonStyles.button,
                                    style.addButton,
                                )}
                            >
                                +
                            </button>
                        </div>
                    </animated.div>
                </animated.div>
            </div>
        </div>
    )
}
