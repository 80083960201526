import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import {
    getDayMonthYearFormatter,
    getIntegerFormatter,
    getSafeFormatter,
    getTimeFormatter,
} from "../../../i18n"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Link, useHistory, useParams } from "react-router-dom"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import inputStyle from "../../../style/input.module.css"
import { uniqueId } from "lodash"
import style from "../home/Home.module.css"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import { useDispatch } from "react-redux"
import { showError } from "../../../redux/actions/NotificationActions"
import { EnumLabel } from "../../util/table/EnumLabel"
import { Loader } from "../../util/Loader"
import { EnterNameModal } from "../../util/modals/EnterNameModal"
import { AssortmentProductViewer } from "../assortments/AssortmentProductViewer"
import cn from "classnames"
import { DeletionWithAffectedModal } from "../../util/modals/DeletionWithAffectedModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { ModelConfigurationPopup } from "../modelConfiguration/ModelConfigurationPopup"
import inputStyles from "../../../style/input.module.css"
import { AssortmentGroupNameDisplay } from "../assortmentGroup/AssortmentGroupNameDisplay"

const GET_OPTIONS = gql`
    query options {
        pricingListTypes(
            limit: -1
            filterParams: { hideInCreateEquals: false, disabledEquals: false }
        ) {
            page {
                id
                name
                displayName
                pricingListTypeNumber
                cycle
                assortment {
                    id
                    name
                }
            }
        }
    }
`

const GET_PRICING_LIST = gql`
    query getPricingList($id: UUID!) {
        me {
            id
            name
        }
        pricingList(id: $id) {
            id
            name
            displayName
            status
            createdAt
            updatedAt
            submittedAt
            validFrom
            validUntil
            assortment {
                id
                name
            }
            pricingListCalculations(limit: -1) {
                page {
                    id
                    name
                    calculationStatus
                }
            }
            chosenCalculation {
                id
            }
            modelConfigurations(limit: -1) {
                page {
                    strategy {
                        id
                        name
                        isMl
                    }
                    id
                    name
                    pricingListGroup {
                        id
                        name
                        action
                        productAmount
                        createdAt
                        updatedAt
                    }
                }
            }
            pricingListType {
                id
                dependsOn {
                    id
                    displayName
                    pricingLists(limit: -1) {
                        page {
                            name
                            displayName
                            chosenCalculation {
                                id
                            }
                        }
                    }
                }
            }
            assortment {
                id
                finalAmount
                name
                enlistGroups(limit: -1) {
                    page {
                        id
                        name
                        action
                        priority
                        isSystemGroup
                        productAmount
                        createdAt
                        updatedAt
                    }
                }
                delistGroups(limit: -1) {
                    page {
                        id
                        name
                        action
                        isSystemGroup
                        priority
                        productAmount
                        createdAt
                        updatedAt
                    }
                }
            }
        }
    }
`

const UPDATE_PRICING_LIST = gql`
    mutation (
        $id: UUID!
        $pricingListType: UUID
        $assortment: UUID
        $chosenCalculation: UUID
        $name: String
        $validFrom: DateTime
        $validUntil: DateTime
    ) {
        updatePricingList(
            id: $id
            name: $name
            pricingListType: $pricingListType
            assortment: $assortment
            validFrom: $validFrom
            validUntil: $validUntil
            chosenCalculation: $chosenCalculation
        ) {
            id
            name
            status
            createdAt
            updatedAt
            submittedAt
            validFrom
            validUntil
            chosenCalculation {
                id
            }
        }
    }
`

const CREATE_PRICING_LIST = gql`
    mutation (
        $name: String!
        $validFrom: DateTime!
        $validUntil: DateTime!
        $assortment: UUID!
        $pricingListType: UUID!
    ) {
        createPricingList(
            name: $name
            validFrom: $validFrom
            validUntil: $validUntil
            pricingListType: $pricingListType
            assortment: $assortment
        ) {
            id
            name
            status
            createdAt
            updatedAt
            submittedAt
            validFrom
            validUntil
        }
    }
`

const SET_LOCKED = gql`
    mutation ($id: UUID!) {
        setPricingListClosed(id: $id) {
            id
            status
        }
    }
`

const SET_CREATED = gql`
    mutation ($id: UUID!) {
        setPricingListCreated(id: $id) {
            id
            status
        }
    }
`

const TRIGGER_ML = gql`
    mutation ($id: UUID!, $name: String!, $dependentRuns: [UUID]) {
        triggerModelRun(
            name: $name
            pricingList: $id
            dependentRuns: $dependentRuns
        ) {
            id
        }
    }
`

const DELETE_MODEL_CONFIGURATION = gql`
    mutation ($id: UUID!, $ignoreAffected: Boolean) {
        delete: deleteModelConfiguration(
            id: $id
            ignoreAffected: $ignoreAffected
        ) {
            success
            affected {
                model
                name
                id
            }
            blockers {
                model
                name
                id
            }
        }
    }
`

export const PricingListEditor = () => {
    const { id } = useParams()

    const [name, setName] = useState("")
    const [dependentIds, setDependentIds] = useState({})
    const [validFrom, setValidFrom] = useState("")
    const [validUntil, setValidUntil] = useState("")
    const [assortment, setAssortment] = useState("")
    const [pricingListType, setPricingListType] = useState("")
    const [chosenCalculation, setChosenCalculation] = useState("")

    const [namePickerShown, setNamePickerShown] = useState(false)
    const [modelConfigurationEditorShown, setModelConfigurationEditorShown] =
        useState(false)

    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [valid_from_id] = useState(() => uniqueId("valid_from_"))
    const [valid_until_id] = useState(() => uniqueId("valid_until_"))
    const [dependent_chooser_id] = useState(() =>
        uniqueId("dependent_chooser_"),
    )
    const [pricingListType_id] = useState(() => uniqueId("pricingListType_"))
    const [chosenCalculation_id] = useState(() =>
        uniqueId("chosenCalculation_"),
    )

    const [currentDeletionPendingConfig, setCurrentDeletionPendingConfig] =
        useState(null)

    const { t, i18n } = useTranslation(["common", "table"])
    const dayMonthYearFormat = getSafeFormatter(
        getDayMonthYearFormatter(i18n.language),
    )
    const integerFormat = getSafeFormatter(getIntegerFormatter(i18n.language))
    const timeFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    const { data, loading } = useQuery(GET_PRICING_LIST, {
        variables: {
            id,
        },
        skip: !id,
    })

    const { data: selectData, loading: selectLoading } = useQuery(GET_OPTIONS)

    const [updatePricingList, { loading: pricingListUpdateLoading }] =
        useMutation(UPDATE_PRICING_LIST, {
            refetchQueries: [GET_PRICING_LIST],
        })

    const [createPricingList, { loading: pricingListCreateLoading }] =
        useMutation(CREATE_PRICING_LIST, {
            refetchQueries: [GET_PRICING_LIST],
        })

    const [triggerML, { loading: triggerMlLoading }] = useMutation(TRIGGER_ML)
    const [setLocked, { loading: setLockedLoading }] = useMutation(SET_LOCKED)
    const [setCreated, { loading: setCreatedLoading }] =
        useMutation(SET_CREATED)

    useEffect(() => {
        setValidFrom(data?.pricingList?.validFrom.substring(0, 10) || "")
        setValidUntil(data?.pricingList?.validUntil.substring(0, 10) || "")
        setName(data?.pricingList?.name || "")
        setAssortment(data?.pricingList?.assortment)
        setPricingListType(data?.pricingList?.pricingListType?.id || "")
        setChosenCalculation(data?.pricingList?.chosenCalculation?.id || "")
    }, [data?.pricingList])

    const dispatch = useDispatch()
    const history = useHistory()

    const pricingListLoading =
        pricingListUpdateLoading || pricingListCreateLoading

    return (
        <div>
            {modelConfigurationEditorShown && (
                <ModelConfigurationPopup
                    dismiss={() => setModelConfigurationEditorShown(false)}
                    pricingListId={id}
                    refetchQueries={GET_PRICING_LIST}
                />
            )}
            {currentDeletionPendingConfig && (
                <DeletionWithAffectedModal
                    id={currentDeletionPendingConfig?.id}
                    delete_mutation={DELETE_MODEL_CONFIGURATION}
                    onDismiss={() => setCurrentDeletionPendingConfig(null)}
                    refetchQueries={[GET_PRICING_LIST]}
                    onCancel={() => setCurrentDeletionPendingConfig(null)}
                    name={currentDeletionPendingConfig?.name}
                />
            )}
            {namePickerShown ? (
                <EnterNameModal
                    text={t("table:name_pricing_list_calculation")}
                    defaultText={`${c(
                        t("table:calculation"),
                    )} ${timeFormat.format(new Date())} [${
                        data?.me?.name || ""
                    }]`}
                    onCancel={() => setNamePickerShown(false)}
                    loading={triggerMlLoading}
                    disabled={
                        data?.pricingList?.pricingListType?.dependsOn
                            ?.length !== Object.values(dependentIds)?.length
                    }
                    onNameSet={(name) => {
                        triggerML({
                            variables: {
                                id,
                                name,
                                dependentRuns:
                                    Object.values(dependentIds) || null,
                            },
                        })
                            .then(() =>
                                history.push(
                                    `/pricing_list/${id}/calculations`,
                                ),
                            )
                            .catch((e) => {
                                setNamePickerShown(false)
                                dispatch(showError(e.message))
                            })
                    }}
                >
                    {data?.pricingList?.pricingListType?.dependsOn?.map(
                        (dependsOn) => (
                            <div
                                key={dependsOn.id}
                                className={style.dependentBox}
                            >
                                <label htmlFor={dependent_chooser_id}>
                                    {t("table:is_dependent_on")}{" "}
                                    {dependsOn?.displayName}
                                </label>
                                <select
                                    className={inputStyles.select}
                                    value={dependentIds[dependsOn.id]}
                                    onChange={(evt) => {
                                        const localDependants =
                                            structuredClone(dependentIds)
                                        localDependants[dependsOn.id] =
                                            evt.target.value
                                        setDependentIds(localDependants)
                                    }}
                                >
                                    <option value="" />
                                    {dependsOn?.pricingLists?.page
                                        .filter((d) => d?.chosenCalculation?.id)
                                        .map((d, i) => (
                                            <option
                                                key={i}
                                                value={d?.chosenCalculation?.id}
                                            >
                                                {d.displayName}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        ),
                    )}
                </EnterNameModal>
            ) : null}
            <Banner
                text={
                    id
                        ? `${t("table:pricing_list")}: ${
                              data?.pricingList?.displayName || ""
                          }`
                        : t("table:create_pricing_list")
                }
            />
            <AssortmentProductViewer
                assortmentId={data?.pricingList?.assortment?.id}
                disabled={!id}
                pricingListType={pricingListType}
            >
                <div className={tableStyle.stackedFilterBar}>
                    {loading ||
                    selectLoading ||
                    setLockedLoading ||
                    setCreatedLoading ||
                    pricingListLoading ? (
                        <Loader />
                    ) : (
                        <div className={tableStyle.filterBar}>
                            <div className={tableStyle.filterInnerSection}>
                                <label
                                    htmlFor={name_input_id}
                                    className={tableStyle.label}
                                >
                                    {t("table:pricing_list_name")}
                                </label>
                                <input
                                    id={name_input_id}
                                    className={inputStyle.text}
                                    type="text"
                                    value={name}
                                    onChange={(evt) =>
                                        setName(evt.target.value)
                                    }
                                />
                            </div>
                            <div className={tableStyle.filterInnerSection}>
                                <label
                                    htmlFor={valid_from_id}
                                    className={tableStyle.label}
                                >
                                    {t("table:valid_from")}
                                </label>
                                <input
                                    id={valid_from_id}
                                    className={inputStyle.text}
                                    type="date"
                                    value={validFrom}
                                    onChange={(evt) =>
                                        setValidFrom(evt.target.value)
                                    }
                                />
                            </div>
                            <div className={tableStyle.filterInnerSection}>
                                <label
                                    htmlFor={valid_until_id}
                                    className={tableStyle.label}
                                >
                                    {t("table:valid_until")}
                                </label>
                                <input
                                    id={valid_until_id}
                                    className={inputStyle.text}
                                    type="date"
                                    value={validUntil}
                                    onChange={(evt) =>
                                        setValidUntil(evt.target.value)
                                    }
                                />
                            </div>
                            <div className={tableStyle.filterInnerSection}>
                                <label className={tableStyle.label}>
                                    {t("table:assortment")}
                                </label>
                                <p>{assortment?.name}</p>
                            </div>
                            <div className={tableStyle.filterInnerSection}>
                                <p className={tableStyle.label}>
                                    {t("table:product_amount")}
                                </p>
                                <p>
                                    {integerFormat.format(
                                        assortment?.finalAmount,
                                    )}
                                </p>
                            </div>
                            <div className={tableStyle.filterInnerSection}>
                                <label
                                    htmlFor={pricingListType_id}
                                    className={tableStyle.label}
                                >
                                    {t("table:pricing_list_type")}
                                </label>
                                <select
                                    id={pricingListType_id}
                                    className={inputStyle.select}
                                    onChange={(evt) => {
                                        const plt =
                                            selectData?.pricingListTypes?.page.find(
                                                (t) =>
                                                    t.id === evt.target.value,
                                            )
                                        if (plt?.assortment) {
                                            setAssortment(plt?.assortment)
                                        }
                                        if (plt?.cycle && !id) {
                                            const today = new Date()
                                            const startOfMonth = new Date(
                                                Date.UTC(
                                                    today.getFullYear(),
                                                    today.getMonth(),
                                                    1,
                                                ),
                                            )
                                                .toISOString()
                                                .substring(0, 10)
                                            const midOfMonth = new Date(
                                                Date.UTC(
                                                    today.getFullYear(),
                                                    today.getMonth(),
                                                    15,
                                                ),
                                            )
                                                .toISOString()
                                                .substring(0, 10)
                                            const endOfMonth = new Date(
                                                Date.UTC(
                                                    today.getFullYear(),
                                                    today.getMonth() + 1,
                                                    0,
                                                ),
                                            )
                                                .toISOString()
                                                .substring(0, 10)
                                            switch (plt?.cycle) {
                                                case "MONTHLY":
                                                    setValidFrom(startOfMonth)
                                                    setValidUntil(endOfMonth)
                                                    break
                                                case "BIMONTHLY":
                                                    if (today.getDay() >= 15) {
                                                        setValidFrom(midOfMonth)
                                                        setValidUntil(
                                                            endOfMonth,
                                                        )
                                                    } else {
                                                        setValidFrom(
                                                            startOfMonth,
                                                        )
                                                        setValidUntil(
                                                            midOfMonth,
                                                        )
                                                    }
                                            }
                                        }
                                        setPricingListType(evt.target.value)
                                    }}
                                    value={pricingListType}
                                >
                                    <option value="" />
                                    {selectData?.pricingListTypes?.page?.map(
                                        (m, i) => (
                                            <option key={i} value={m.id}>
                                                {m.displayName} (
                                                {m.pricingListTypeNumber})
                                            </option>
                                        ),
                                    )}
                                </select>
                            </div>
                            {data?.pricingList?.id ? (
                                <div className={tableStyle.filterInnerSection}>
                                    <label
                                        htmlFor={chosenCalculation_id}
                                        className={tableStyle.label}
                                    >
                                        {t("table:chosen_calculation")}
                                    </label>
                                    <select
                                        id={chosenCalculation_id}
                                        className={inputStyle.select}
                                        disabled={
                                            data?.pricingList?.status ===
                                            "CLOSED"
                                        }
                                        onChange={(evt) =>
                                            setChosenCalculation(
                                                evt.target.value,
                                            )
                                        }
                                        value={chosenCalculation}
                                    >
                                        <option value="" />
                                        {data?.pricingList?.pricingListCalculations?.page
                                            ?.filter(
                                                (calc) =>
                                                    calc.calculationStatus ===
                                                    "DONE",
                                            )
                                            .map((calc, i) => (
                                                <option key={i} value={calc.id}>
                                                    {calc.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            ) : null}
                        </div>
                    )}
                    <div className={tableStyle.buttonSection}>
                        {!loading && id && (
                            <button
                                onClick={() =>
                                    setModelConfigurationEditorShown(true)
                                }
                                className={buttonStyle.orangeButton}
                            >
                                {t("table:create_configuration")}
                            </button>
                        )}
                        {chosenCalculation &&
                            data?.pricingList?.status &&
                            data?.pricingList?.status !== "WIP" && (
                                <button
                                    className={cn(
                                        buttonStyle.button,
                                        buttonStyle.dangerButton,
                                    )}
                                    onClick={() =>
                                        updatePricingList({
                                            variables: {
                                                id,
                                                chosenCalculation,
                                            },
                                        })
                                            .then(() => {
                                                if (
                                                    data?.pricingList
                                                        ?.status === "CREATED"
                                                ) {
                                                    setLocked({
                                                        variables: {
                                                            id,
                                                        },
                                                    })
                                                        .then((r) =>
                                                            history.push(
                                                                `/pricing_list/${r?.data?.setPricingListClosed?.id}`,
                                                            ),
                                                        )
                                                        .catch((e) =>
                                                            dispatch(
                                                                showError(
                                                                    e.message,
                                                                ),
                                                            ),
                                                        )
                                                } else {
                                                    setCreated({
                                                        variables: {
                                                            id,
                                                        },
                                                    })
                                                        .then((r) =>
                                                            history.push(
                                                                `/pricing_list/${r?.data?.setPricingListCreated?.id}`,
                                                            ),
                                                        )
                                                        .catch((e) =>
                                                            dispatch(
                                                                showError(
                                                                    e.message,
                                                                ),
                                                            ),
                                                        )
                                                }
                                            })
                                            .catch((e) =>
                                                dispatch(showError(e.message)),
                                            )
                                    }
                                >
                                    {data?.pricingList?.status === "CREATED"
                                        ? c(t("table:close_pricing_list"))
                                        : c(t("table:reopen_pricing_list"))}
                                </button>
                            )}
                        {data?.pricingList?.id ? (
                            <button
                                className={buttonStyle.orangeButton}
                                disabled={loading}
                                onClick={() => setNamePickerShown(true)}
                            >
                                {t("table:run_calculation")}
                            </button>
                        ) : null}
                        {data?.pricingList?.id ? (
                            <Link
                                className={buttonStyle.lightBlueButton}
                                to={`/pricing_list/${data?.pricingList?.id}/model_configuration_ranking`}
                            >
                                {c(t("table:rank_model_configurations"))}
                            </Link>
                        ) : null}
                        {data?.pricingList?.pricingListCalculations?.page
                            ?.length ? (
                            <Link
                                className={buttonStyle.lightBlueButton}
                                to={`/pricing_list/${data?.pricingList?.id}/calculations`}
                            >
                                {c(t("table:see_calculations"))}
                            </Link>
                        ) : null}

                        <button
                            className={buttonStyle.button}
                            disabled={
                                !name ||
                                !validFrom ||
                                !validUntil ||
                                !assortment?.id ||
                                !pricingListType
                            }
                            onClick={() =>
                                id
                                    ? updatePricingList({
                                          variables: {
                                              id,
                                              validFrom: new Date(validFrom),
                                              validUntil: new Date(validUntil),
                                              name,
                                              assortment: assortment?.id,
                                              pricingListType,
                                              chosenCalculation:
                                                  chosenCalculation || null,
                                          },
                                      })
                                          .then((r) =>
                                              history.push(
                                                  `/pricing_list/${r?.data?.updatePricingList?.id}`,
                                              ),
                                          )
                                          .catch((e) =>
                                              dispatch(showError(e.message)),
                                          )
                                    : createPricingList({
                                          variables: {
                                              validFrom: new Date(validFrom),
                                              validUntil: new Date(validUntil),
                                              name,
                                              assortment: assortment?.id,
                                              pricingListType,
                                              chosenCalculation:
                                                  chosenCalculation || null,
                                          },
                                      })
                                          .then((r) =>
                                              history.push(
                                                  `/pricing_list/${r?.data?.createPricingList?.id}`,
                                              ),
                                          )
                                          .catch((e) =>
                                              dispatch(showError(e.message)),
                                          )
                            }
                        >
                            {t("table:save")}
                        </button>
                    </div>
                </div>
                {id ? (
                    <>
                        <h2 className={style.sectionHeading}>
                            {t("table:price_setting")}
                        </h2>
                        <div
                            className={tableStyle.table}
                            style={{
                                gridTemplateColumns: "repeat(7, 1fr) 3em",
                            }}
                        >
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:pricing_list_group")}
                                asc="NameAsc"
                                desc="NameDesc"
                            />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:action")}
                                asc="ActionAsc"
                                desc="ActionDesc"
                            />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:model_configuration")}
                                asc="ActionAsc"
                                desc="ActionDesc"
                            />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:strategy")}
                                asc="ActionAsc"
                                desc="ActionDesc"
                            />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:products")}
                                asc="ProductAmountAsc"
                                desc="ProductAmountDesc"
                            />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:created_at")}
                                asc="CreatedAtAsc"
                                desc="CreatedAtDesc"
                            />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:updated_at")}
                                asc="UpdatedAtAsc"
                                desc="UpdatedAtDesc"
                            />
                            <p className={tableStyle.header} />
                            {(pricingListLoading || loading) &&
                                !data?.pricingList && (
                                    <TableContentLoader columns={8} />
                                )}
                            {data?.pricingList?.modelConfigurations?.page.map(
                                (s, i) => (
                                    <Fragment key={i}>
                                        <Link
                                            className={tableStyle.cell}
                                            to={`/pricing_list_group/${s?.pricingListGroup?.id}`}
                                        >
                                            {s?.pricingListGroup?.name}
                                        </Link>
                                        <EnumLabel
                                            className={tableStyle.cell}
                                            label={s?.pricingListGroup?.action}
                                        />
                                        <Link
                                            className={tableStyle.cell}
                                            to={`/model_configuration/${s?.id}`}
                                        >
                                            {s?.name}
                                        </Link>
                                        <Link
                                            className={tableStyle.cell}
                                            to={
                                                s?.strategy?.isMl
                                                    ? `/ml_strategy/${s?.strategy?.id}`
                                                    : `/rule_strategy/${s?.strategy?.id}`
                                            }
                                        >
                                            {s?.strategy?.name}
                                        </Link>

                                        <p className={tableStyle.cell}>
                                            {integerFormatter.format(
                                                s?.pricingListGroup
                                                    ?.productAmount,
                                            )}
                                        </p>
                                        <p className={tableStyle.cell}>
                                            {dayMonthYearFormat.format(
                                                new Date(
                                                    s?.pricingListGroup?.createdAt,
                                                ),
                                            )}
                                        </p>
                                        <p className={tableStyle.cell}>
                                            {dayMonthYearFormat.format(
                                                new Date(
                                                    s?.pricingListGroup?.updatedAt,
                                                ),
                                            )}
                                        </p>
                                        <p
                                            className={cn(
                                                tableStyle.actionCell,
                                                tableStyle.lastCell,
                                            )}
                                        >
                                            <button
                                                className={cn(
                                                    tableStyle.actionButton,
                                                    tableStyle.negativeActionButton,
                                                )}
                                                onClick={() => {
                                                    setCurrentDeletionPendingConfig(
                                                        {
                                                            id: s?.id,
                                                            name: s?.name,
                                                        },
                                                    )
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                />
                                            </button>
                                        </p>
                                    </Fragment>
                                ),
                            )}
                        </div>
                        <h2 className={style.sectionHeading}>
                            {t("table:enlistings")}
                        </h2>
                        <div
                            className={tableStyle.table}
                            style={{ gridTemplateColumns: "repeat(4, 1fr)" }}
                        >
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:assortment_group")}
                                asc="NameAsc"
                                desc="NameDesc"
                            />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:products")}
                                asc="AssortmentNameAsc"
                                desc="AssortmentNameDesc"
                            />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:created_at")}
                                asc="CreatedAtAsc"
                                desc="CreatedAtDesc"
                            />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:updated_at")}
                                asc="UpdatedAtAsc"
                                desc="UpdatedAtDesc"
                            />
                            {(pricingListLoading || loading) &&
                                !data?.pricingList && (
                                    <TableContentLoader columns={6} />
                                )}
                            {data?.pricingList?.assortment?.enlistGroups?.page.map(
                                (s, i) => (
                                    <Fragment key={i}>
                                        <Link
                                            className={tableStyle.cell}
                                            to={`/assortment_group/${s.id}`}
                                        >
                                            <AssortmentGroupNameDisplay
                                                row={s}
                                            />
                                        </Link>
                                        <p className={tableStyle.cell}>
                                            {integerFormatter.format(
                                                s?.productAmount,
                                            )}
                                        </p>
                                        <p className={tableStyle.cell}>
                                            {dayMonthYearFormat.format(
                                                new Date(s?.createdAt),
                                            )}
                                        </p>
                                        <p
                                            className={cn(
                                                tableStyle.cell,
                                                tableStyle.lastCell,
                                            )}
                                        >
                                            {dayMonthYearFormat.format(
                                                new Date(s?.updatedAt),
                                            )}
                                        </p>
                                    </Fragment>
                                ),
                            )}
                        </div>
                        <h2 className={style.sectionHeading}>
                            {t("table:delistings")}
                        </h2>
                        <div
                            className={tableStyle.table}
                            style={{ gridTemplateColumns: "repeat(4, 1fr)" }}
                        >
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:assortment_group")}
                                asc="NameAsc"
                                desc="NameDesc"
                            />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:products")}
                                asc="AssortmentNameAsc"
                                desc="AssortmentNameDesc"
                            />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:created_at")}
                                asc="CreatedAtAsc"
                                desc="CreatedAtDesc"
                            />
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:updated_at")}
                                asc="UpdatedAtAsc"
                                desc="UpdatedAtDesc"
                            />
                            {(pricingListLoading || loading) &&
                                !data?.pricingList && (
                                    <TableContentLoader columns={6} />
                                )}
                            {data?.pricingList?.assortment?.delistGroups?.page.map(
                                (s, i) => (
                                    <Fragment key={i}>
                                        <Link
                                            className={tableStyle.cell}
                                            to={`/assortment_group/${s.id}`}
                                        >
                                            <AssortmentGroupNameDisplay
                                                row={s}
                                            />
                                        </Link>
                                        <p className={tableStyle.cell}>
                                            {integerFormatter.format(
                                                s?.productAmount,
                                            )}
                                        </p>
                                        <p className={tableStyle.cell}>
                                            {dayMonthYearFormat.format(
                                                new Date(s?.createdAt),
                                            )}
                                        </p>
                                        <p
                                            className={cn(
                                                tableStyle.cell,
                                                tableStyle.lastCell,
                                            )}
                                        >
                                            {dayMonthYearFormat.format(
                                                new Date(s?.updatedAt),
                                            )}
                                        </p>
                                    </Fragment>
                                ),
                            )}
                        </div>
                    </>
                ) : null}
            </AssortmentProductViewer>
        </div>
    )
}
