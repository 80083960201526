import { Banner } from "../../util/Banner"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import { useCallback, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useHistory } from "react-router-dom"
import { Loader } from "../../util/Loader"
import style from "../groups/GroupUpload.module.css"
import cn from "classnames"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import tableStyle from "../../../style/table.module.css"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import { showError } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { uniqueId } from "lodash"

const UPLOAD_FILE = gql`
    mutation ($file: Upload!, $pricingListTypeId: UUID!) {
        seedPricingListFromCsv(
            file: $file
            pricingListTypeId: $pricingListTypeId
        )
    }
`

const GET_PRICING_LIST_TYPES = gql`
    query {
        pricingListTypes(
            limit: -1
            filterParams: { hideInCreateEquals: false, disabledEquals: false }
        ) {
            page {
                name
                id
            }
        }
    }
`

export const PricingListImport = () => {
    const { t } = useTranslation(["enums", "table", "common"])
    const history = useHistory()

    const [file, setFile] = useState("")
    const [pricingListType, setPricingListType] = useState(null)

    const [type_pricing_list_type_id] = useState(() =>
        uniqueId("type_pricing_list_type_"),
    )

    const { data, loading: pricingListTypesLoading } = useQuery(
        GET_PRICING_LIST_TYPES,
    )

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles[0]) {
            setFile(acceptedFiles[0])
        }
    }, [])

    const [upload, { loading }] = useMutation(UPLOAD_FILE)

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    const dispatch = useDispatch()

    if (loading) {
        return (
            <>
                <Banner text={t("table:upload_pricing_list")} />
                <Loader />
            </>
        )
    }

    return (
        <>
            <Banner text={t("table:upload_pricing_list")} />
            <div className={tableStyle.stackedFilterBar}>
                <div className={tableStyle.filterInnerSection}>
                    <label
                        htmlFor={type_pricing_list_type_id}
                        className={tableStyle.label}
                    >
                        {t("table:pricing_list_group_type")}
                    </label>
                    <Select
                        isMulti={false}
                        className={style.selectMany}
                        placeholder={""}
                        value={(() => {
                            const type = data?.pricingListTypes?.page?.find(
                                (t) => t.id === pricingListType,
                            )
                            if (type) {
                                return {
                                    value: type.id,
                                    label: type.name,
                                }
                            }
                        })()}
                        onChange={(a) => setPricingListType(a.value)}
                        loadingMessage={() => t("common:loading")}
                        isLoading={pricingListTypesLoading}
                        components={makeAnimated()}
                        options={data?.pricingListTypes?.page?.map((a) => ({
                            value: a.id,
                            label: a.name,
                        }))}
                        id={type_pricing_list_type_id}
                    />
                </div>
                <div className={tableStyle.buttonSection}>
                    <button
                        className={buttonStyle.button}
                        disabled={!pricingListType || !file}
                        onClick={() =>
                            upload({
                                variables: {
                                    file,
                                    pricingListTypeId: pricingListType,
                                },
                            })
                                .then(() => {
                                    history.push(`/`)
                                })
                                .catch((e) => dispatch(showError(e.message)))
                        }
                    >
                        {c(t("table:upload"))}
                    </button>
                </div>
            </div>
            {!file && (
                <div className={style.dropWrapper}>
                    <div
                        className={cn(
                            style.dropZone,
                            isDragActive && style.highlight,
                        )}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <h2>{t("table:drop_to_upload")}</h2>
                        ) : (
                            <h2>{t("table:drag_to_upload")}</h2>
                        )}
                    </div>
                </div>
            )}
            {file && (
                <div className={style.uploadedList}>
                    <div className={style.fileBox}>
                        <p>{file?.name || t("table:file")}</p>
                        <button
                            className={cn(
                                tableStyle.actionButton,
                                tableStyle.negativeActionButton,
                            )}
                            onClick={() => setFile(null)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
