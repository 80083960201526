import { gql, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import ContentLoader from "react-content-loader"
import { Placeholder } from "./placeholder/Placeholder"
import logo from "../../res/logo.png"
import style from "./Nav.module.css"
import { Link, Route, useParams } from "react-router-dom"
import { useState } from "react"
import { animated, useSpring } from "react-spring"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faLanguage,
    faLayerGroup,
    faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons"
import { cycleLanguage } from "../../i18n"
import buttonStyle from "../../style/button.module.css"
import useMeasure from "react-use-measure"

/**
 * Query to get the currently logged-in user
 *
 * @type {DocumentNode}
 */
const ME_QUERY = gql`
    query {
        me {
            name
            image
            id
        }
    }
`

/**
 * Placeholder for before the profile has loaded
 *
 * @returns {JSX.Element} the placeholder element
 * @constructor
 */
const ProfilePlaceholder = () => (
    <ContentLoader
        viewBox="0 0 400 100"
        color={"black"}
        className={style.userBox}
    >
        <circle cx="335" cy="50" r="36" />
        <rect x="65" y="53" rx="4" ry="4" width="222" height="10" />
    </ContentLoader>
)

/**
 * Gets the current page name and translates it.
 *
 * @returns the display ready page name
 * @constructor
 */
const CurrentPage = () => {
    let { path } = useParams()
    const { t } = useTranslation("page_names")

    return t(path)
}

/**
 * This element is a top navigation bar containing links
 * @returns {JSX.Element}
 * @constructor
 */
export const Nav = () => {
    const { data, loading } = useQuery(ME_QUERY)
    const { t } = useTranslation(["common", "page_names"])
    const [isDrawerShown, setDrawerShown] = useState(false)
    const [bind, { height }] = useMeasure()

    const animatedStyle = useSpring({
        height: isDrawerShown ? `${height}px` : "0",
    })

    return (
        <div>
            <div className={style.nav}>
                <div className={style.navBox}>
                    <a href="/">
                        <img className={style.logo} alt="Logo" src={logo} />
                    </a>
                    <p
                        onClick={() => setDrawerShown(!isDrawerShown)}
                        className={style.appName}
                    >
                        {t("common:app_name")}
                    </p>
                    <p
                        onClick={() => setDrawerShown(!isDrawerShown)}
                        className={style.pageName}
                    >
                        <Route path="/:path">
                            <FontAwesomeIcon icon={faLayerGroup} />
                            <CurrentPage />
                        </Route>
                        <Route exact={true} path="/">
                            <FontAwesomeIcon icon={faLayerGroup} />
                            {t("page_names:home")}
                        </Route>
                    </p>
                </div>
                <Placeholder
                    ready={!loading && data?.me}
                    placeholder={<ProfilePlaceholder />}
                >
                    <div className={style.userBox}>
                        <div>
                            <p>{data?.me?.name}</p>
                            <div className={style.buttonBox}>
                                <FontAwesomeIcon
                                    className={style.navButton}
                                    icon={faLanguage}
                                    onClick={() => cycleLanguage()}
                                />
                                <a href="/auth/logout">
                                    <FontAwesomeIcon
                                        className={style.navButton}
                                        icon={faRightFromBracket}
                                    />
                                </a>
                            </div>
                        </div>
                        <img alt={data?.me?.name} src={data?.me?.image} />
                    </div>
                </Placeholder>
            </div>
            <div className={style.linkBox}>
                <animated.div
                    className={style.dropdown}
                    style={animatedStyle}
                    onClick={() => setDrawerShown(false)}
                >
                    <ul ref={bind}>
                        <li>
                            <h1>{t("page_names:section_overview")}</h1>
                        </li>
                        <li>
                            <Link to={"/"}>{t("page_names:home")}</Link>
                        </li>
                        <li>
                            <Link to={"/assortment_groups"}>
                                {t("page_names:assortment_groups")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/assortments"}>
                                {t("page_names:assortments")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/pricing_list_groups"}>
                                {t("page_names:pricing_list_groups")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/strategies"}>
                                {t("page_names:strategies")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/pricing_lists"}>
                                {t("page_names:pricing_lists")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/products"}>
                                {t("page_names:products")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/distributors"}>
                                {t("page_names:distributors")}
                            </Link>
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <h1>1. {t("page_names:calculation_start")}</h1>
                        </li>
                        <li>
                            <Link to={"/pricing_list"}>
                                {t("page_names:create_new_pricing_list")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/enlist_and_delist/csv"}>
                                {t("page_names:manual_listing")} (CSV)
                            </Link>
                        </li>
                        <li>
                            {/*"/new_pricing_cycle"*/}
                            <Link className={buttonStyle.disabledLink} to={"#"}>
                                {t("page_names:new_pricing_cycle")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/tasks"}>{t("page_names:tasks")}</Link>
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <h1>2. {t("page_names:calculation_basis")}</h1>
                        </li>
                        <li>
                            <Link to={"/calculation_settings"}>
                                {t("page_names:set_due_dates")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/calculation_settings/csv"}>
                                {t("page_names:targeted_data_updates")} (CSV)
                            </Link>
                        </li>
                        <li>
                            <Link className={buttonStyle.disabledLink} to={"#"}>
                                {t("page_names:data_science_model_status")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/targeted_data_updates"}>
                                {t("page_names:targeted_data_updates")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/add_pzn"}>
                                {t("page_names:add_pzn")}
                            </Link>
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <h1>
                                3. {t("page_names:configure_pricing_lists")}
                            </h1>
                        </li>
                        {/*
                        <li>
                            <Link to={"/price/csv"}>
                                {t("page_names:manual_prices")} (CSV)
                            </Link>
                        </li>
                        */}
                        <li>
                            <Link to={"/price/bulk/csv"}>
                                {t("page_names:manual_bulk_prices")} (CSV)
                            </Link>
                        </li>
                        <li>
                            <Link to={"/article_group/csv"}>
                                {t("page_names:article_group_for_alt_strategy")}{" "}
                                (CSV)
                            </Link>
                        </li>
                        <li>
                            <Link to={"/rule_strategy"}>
                                {t("page_names:add_new_rule_strategy")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/ml_strategy"}>
                                {t("page_names:add_new_ml_strategy")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/model_configuration_mass_assign"}>
                                {t(
                                    "page_names:article_group_strategy_allocation",
                                )}
                            </Link>
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <h1>
                                4. {t("page_names:pricing_list_calculation")}
                            </h1>
                        </li>
                        <li>
                            <Link to={"/calculations"}>
                                {t("page_names:calculations")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/run_info"}>
                                {t("page_names:run_info")}
                            </Link>
                        </li>
                    </ul>

                    <ul>
                        <li>
                            <h1>5. {t("page_names:general_check")}</h1>
                        </li>
                        <li>
                            <Link to={"/plausibility_checks"}>
                                {t("page_names:plausibility")}
                            </Link>
                        </li>
                        <li>
                            <Link to={"/ifa_changes"}>
                                {t("page_names:ifa_changes")}
                            </Link>
                        </li>
                        {/*
                        <li>
                            <Link to={"/new_plausibility_check"}>
                                {t("page_names:new_plausibility_check")}
                            </Link>
                        </li>
                        */}
                        <li>
                            <Link to={"/export"}>{t("page_names:export")}</Link>
                        </li>
                    </ul>
                </animated.div>
            </div>
        </div>
    )
}
