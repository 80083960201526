import {
    ApolloClient,
    ApolloLink,
    concat,
    InMemoryCache,
    split,
} from "@apollo/client"
import { WebSocketLink } from "@apollo/client/link/ws"
import { getMainDefinition } from "@apollo/client/utilities"
import { onError } from "@apollo/client/link/error"
import { store } from "./index"
import { showAuth } from "./redux/actions/AuthActions"
import { createUploadLink } from "apollo-upload-client"
import { showError } from "./redux/actions/NotificationActions"
import { RetryLink } from "@apollo/client/link/retry"

/**
 *  The retry link defines that if a request fails, the app tries again with a delay
 * @type {RetryLink}
 */
const retryLink = new RetryLink({
    attempts: {
        max: Infinity,
        retryIf: (error, _operation) => {
            if (error?.statusCode > 500) {
                return error
            }
        },
    },
    delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
    },
})

/**
 * The websocket link connects the app to the websocket. Not used currently
 * @type {WebSocketLink}
 */
const wsLink = new WebSocketLink({
    uri: `wss://${window.location.hostname}/v1/graphql/`,
    options: {
        reconnect: true,
        lazy: true,
    },
})

/**
 * The http link connects the app to the main GraphQL API
 * @type {ApolloLink}
 */
const httpLink = createUploadLink({
    uri: `https://${window.location.hostname}/v1/graphql/`,
})

/**
 * The error link shows nicer errors for certain error codes and detects log ins
 * @type {ApolloLink}
 */
const errorLink = onError(({ response, networkError }) => {
    if (networkError) {
        console.error(`Network error: `, networkError)
        if (networkError?.statusCode === 403) {
            store.dispatch(showAuth())
        } else if (networkError?.statusCode === 500) {
            console.error("Server Error")
            store.dispatch(showError("server_error"))
            return
        } else if (networkError?.statusCode === 502) {
            console.error("No Server Response")
            store.dispatch(showError("no_server_connection"))
            return
        } else if (networkError?.statusCode === 504) {
            console.error("Timeout")
            store.dispatch(showError("timeout"))
            return
        }
    }

    if (response?.errors) {
        for (const error of response.errors) {
            if (error.message === "no_auth") {
                store.dispatch(showAuth())
            }
        }
    }
})

/**
 * The split links splits the traffic between the websocket and the http API
 * @type {ApolloLink}
 */
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        )
    },
    wsLink,
    concat(retryLink, httpLink),
)

/**
 * The links object combines the split link and the error link
 * @type {ApolloLink}
 */
const links = ApolloLink.from([errorLink, splitLink])

const authMiddleware = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem("token")

    operation.setContext({
        headers: {
            authorization: `JWT ${token}` || null,
        },
    })

    return forward(operation)
})

/**
 * The client initialized apollo with the links and auth middleware
 * @type {ApolloClient<NormalizedCacheObject>}
 */
export const client = new ApolloClient({
    link: concat(authMiddleware, links),
    cache: new InMemoryCache(),
})
