import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import { Link, useParams } from "react-router-dom"
import { EnumLabel } from "../../util/table/EnumLabel"
import cn from "classnames"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SINGLE_CALCULATIONS_QUERY = gql`
    query getPricingList($id: UUID!, $pageNum: Int) {
        pricingList(id: $id) {
            id
            displayName
            name
            chosenCalculation {
                id
            }
            pricingListCalculations(limit: 20, pageNum: $pageNum) {
                currentPage
                hasNext
                hasPrevious
                pages
                page {
                    id
                    name
                    calculationStart
                    calculationEnd
                    calculationStatus
                    pricingList {
                        name
                        displayName
                        id
                    }
                }
            }
        }
    }
`

const ALL_CALCULATIONS_QUERY = gql`
    query getPricingList($pageNum: Int) {
        pricingListCalculations(limit: 20, pageNum: $pageNum) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                name
                calculationStart
                calculationEnd
                calculationStatus
                pricingList {
                    name
                    displayName
                    id
                }
            }
        }
    }
`

export const CalculationList = () => {
    const [pageNum, setPage] = useState(1)

    const { t, i18n } = useTranslation(["common", "table"])
    const { id } = useParams()

    const { data, loading } = useQuery(
        id ? SINGLE_CALCULATIONS_QUERY : ALL_CALCULATIONS_QUERY,
        {
            variables: {
                pageNum,
                id,
            },
        },
    )

    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    return (
        <div>
            <Banner
                text={
                    data?.pricingList?.displayName
                        ? `${t("table:calculations")}: ${
                              data?.pricingList?.displayName
                          }`
                        : t("table:calculations")
                }
            />

            {!loading &&
            !(id ? data?.pricingList : data)?.pricingListCalculations?.page
                ?.length ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}
            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: `3em repeat(${id ? 4 : 5}, 1fr)`,
                }}
            >
                <p className={tableStyle.header} />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:name")}
                />
                {id ? null : (
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:pricing_list")}
                    />
                )}
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:status")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:start_datetime")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:end_datetime")}
                />
                {loading && !data?.calculationSettings && (
                    <TableContentLoader columns={id ? 5 : 6} />
                )}
                {(id
                    ? data?.pricingList
                    : data
                )?.pricingListCalculations?.page?.map((c, i) => (
                    <Fragment key={i}>
                        <p className={tableStyle.checkCell}>
                            {c.id ===
                            data?.pricingList?.chosenCalculation?.id ? (
                                <FontAwesomeIcon icon={faCheck} />
                            ) : null}
                        </p>
                        {c.calculationStatus === "DONE" ? (
                            <Link
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.linkCell,
                                )}
                                to={`/pricing_list/${c?.pricingList?.id}/calculations/${c.id}`}
                            >
                                {c?.name}
                            </Link>
                        ) : (
                            <p className={tableStyle.cell}>{c?.name}</p>
                        )}
                        {id ? null : (
                            <Link
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.linkCell,
                                )}
                                to={`/pricing_list/${c?.pricingList?.id}`}
                            >
                                {c?.pricingList?.displayName}
                            </Link>
                        )}
                        <EnumLabel
                            className={tableStyle.cell}
                            label={c.calculationStatus}
                        />
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(c.calculationStart))}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {dateFormat.format(new Date(c.calculationEnd))}
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={
                    (id ? data?.pricingList : data)?.pricingListCalculations
                }
                loading={
                    loading &&
                    !(id ? data?.pricingList : data)?.pricingListCalculations
                }
            />
        </div>
    )
}
