import { Banner } from "../../util/Banner"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import { useCallback, useEffect, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Redirect, useHistory, useParams } from "react-router-dom"
import { Loader } from "../../util/Loader"
import { uniqueId } from "lodash"
import inputStyle from "../../../style/input.module.css"
import style from "./GroupUpload.module.css"
import cn from "classnames"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import tableStyle from "../../../style/table.module.css"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import { showError } from "../../../redux/actions/NotificationActions"
import downloadCsv from "js-file-download"
import { useDispatch } from "react-redux"
import { Toggle } from "../../util/input/Toggle"
import makeAnimated from "react-select/animated"
import Select from "react-select"

const UPLOAD_FILE = gql`
    mutation (
        $file: Upload!
        $name: String!
        $defaultType: String!
        $force: Boolean
        $id: UUID
        $ignoreWrongTypes: Boolean
    ) {
        createPriceSettingPricingListGroupFromCsv(
            file: $file
            name: $name
            defaultType: $defaultType
            force: $force
            ignoreWrongTypes: $ignoreWrongTypes
            id: $id
        ) {
            id
        }
    }
`

const UPLOAD_BULK_FILE = gql`
    mutation (
        $file: Upload!
        $defaultType: String!
        $force: Boolean
        $ignoreWrongTypes: Boolean
        $skipMissingLists: Boolean
    ) {
        createBulkPriceSettingPricingListGroupFromCsv(
            file: $file
            defaultType: $defaultType
            force: $force
            ignoreWrongTypes: $ignoreWrongTypes
            skipMissingLists: $skipMissingLists
        )
    }
`

const GET_PRICING_LIST_GROUP = gql`
    query ($id: UUID!) {
        pricingListGroup(id: $id) {
            id
            name
            isSystemGroup
        }
    }
`

export const PriceUpload = ({ mass = false }) => {
    const { t } = useTranslation(["enums", "table", "common"])
    const history = useHistory()

    const { id } = useParams()

    const [upload, { loading: singleUploadLoading }] = useMutation(UPLOAD_FILE)
    const [bulkUpload, { loading: bulkUploadLoading }] =
        useMutation(UPLOAD_BULK_FILE)

    const loading = singleUploadLoading || bulkUploadLoading

    const [name, setName] = useState("")
    const [file, setFile] = useState("")
    const [defaultType, setDefaultType] = useState("ABSOLUTE")
    const [force, setForce] = useState(false)
    const [skipMissingLists, setSkipMissingLists] = useState(false)
    const [ignoreWrongTypes, setIgnoreWrongTypes] = useState(false)

    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [default_type_id] = useState(() => uniqueId("default_type_"))
    const [ignore_missing_product_id] = useState(() =>
        uniqueId("ignore_missing_product_"),
    )
    const [skip_missing_lists_id] = useState(() =>
        uniqueId("skip_missing_lists_"),
    )
    const [ignore_wrong_types_id] = useState(() =>
        uniqueId("ignore_wrong_types_"),
    )

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles[0]) {
            setFile(acceptedFiles[0])
            if (!name) {
                setName(acceptedFiles[0].name || "")
            }
        }
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    const { data, loading: pricingListGroupLoading } = useQuery(
        GET_PRICING_LIST_GROUP,
        {
            variables: {
                id,
            },
            skip: !id,
        },
    )

    useEffect(() => {
        setName(data?.pricingListGroup?.name || "")
    }, [data])

    const dispatch = useDispatch()

    if (loading) {
        return (
            <>
                <Banner text={t("table:upload_price_group")} />
                <Loader />
            </>
        )
    }

    if (data?.pricingListGroup?.isSystemGroup) {
        return <Redirect to={"/price/csv"} />
    }

    return (
        <>
            <Banner text={t("table:upload_price_group")} />
            <div className={tableStyle.stackedFilterBar}>
                {loading || (id && pricingListGroupLoading) ? (
                    <Loader />
                ) : (
                    <div className={tableStyle.filterBar}>
                        {mass ? null : (
                            <div className={tableStyle.filterInnerSection}>
                                <label
                                    htmlFor={name_input_id}
                                    className={tableStyle.label}
                                >
                                    {t("table:name")}
                                </label>
                                <input
                                    id={name_input_id}
                                    className={inputStyle.text}
                                    type="text"
                                    value={name}
                                    onChange={(evt) =>
                                        setName(evt.target.value)
                                    }
                                />
                            </div>
                        )}
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={default_type_id}
                                className={tableStyle.label}
                            >
                                {t("table:default_type")}
                            </label>
                            <Select
                                id={default_type_id}
                                components={makeAnimated()}
                                value={{
                                    label: t(
                                        `enums:${defaultType.toLowerCase()}`,
                                    ),
                                    value: defaultType,
                                }}
                                onChange={(a) => setDefaultType(a.value)}
                                loadingMessage={() => t("common:loading")}
                                isLoading={loading}
                                options={[
                                    {
                                        label: t("enums:absolute"),
                                        value: "ABSOLUTE",
                                    },
                                    {
                                        label: t("enums:relative"),
                                        value: "RELATIVE",
                                    },
                                    {
                                        label: t("enums:relative_percentage"),
                                        value: "RELATIVE_PERCENTAGE",
                                    },
                                ]}
                            />
                        </div>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={ignore_missing_product_id}
                                className={tableStyle.label}
                            >
                                {t("table:ignore_missing_product")}
                            </label>
                            <Toggle
                                id={ignore_missing_product_id}
                                isChecked={force}
                                setIsChecked={setForce}
                            />
                        </div>
                        {mass ? (
                            <div className={tableStyle.filterInnerSection}>
                                <label
                                    htmlFor={skip_missing_lists_id}
                                    className={tableStyle.label}
                                >
                                    {t("table:skip_missing_lists")}
                                </label>
                                <Toggle
                                    id={skip_missing_lists_id}
                                    isChecked={skipMissingLists}
                                    setIsChecked={setSkipMissingLists}
                                />
                            </div>
                        ) : null}
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={ignore_wrong_types_id}
                                className={tableStyle.label}
                            >
                                {t("table:ignore_wrong_types")}
                            </label>
                            <Toggle
                                id={ignore_wrong_types_id}
                                isChecked={ignoreWrongTypes}
                                setIsChecked={setIgnoreWrongTypes}
                            />
                        </div>
                    </div>
                )}
                <div className={tableStyle.buttonSection}>
                    {mass ? (
                        <button
                            className={buttonStyle.button}
                            disabled={!file}
                            onClick={() =>
                                bulkUpload({
                                    variables: {
                                        file,
                                        defaultType,
                                        force,
                                        skipMissingLists,
                                        ignoreWrongTypes,
                                    },
                                })
                                    .then(() => {
                                        history.push(`/`)
                                    })
                                    .catch((e) =>
                                        dispatch(showError(e.message)),
                                    )
                            }
                        >
                            {c(t("table:upload"))}
                        </button>
                    ) : (
                        <button
                            className={buttonStyle.button}
                            disabled={!name || !file}
                            onClick={() =>
                                upload({
                                    variables: {
                                        file,
                                        name,
                                        defaultType,
                                        force,
                                        id,
                                        ignoreWrongTypes,
                                    },
                                })
                                    .then((r) => {
                                        history.push(
                                            `/pricing_list_group/${r.data.createPriceSettingPricingListGroupFromCsv?.id}`,
                                        )
                                    })
                                    .catch((e) =>
                                        dispatch(showError(e.message)),
                                    )
                            }
                        >
                            {c(t("table:upload"))}
                        </button>
                    )}
                    <button
                        onClick={() =>
                            downloadCsv(
                                mass
                                    ? "pzn;type;bulk_amount_1;fix_price_bulk_1;bulk_amount_2;fix_price_bulk_2;bulk_amount_3;fix_price_bulk_3;pricing_list_number;comment;comment_2;date_1;date_2\n"
                                    : "pzn;type;min_price;max_price;bulk_amount_1;fix_price_bulk_1;bulk_amount_2;fix_price_bulk_2;bulk_amount_3;fix_price_bulk_3\n",
                                mass
                                    ? "bulk_price_upload.csv"
                                    : "price_upload.csv",
                                "application/csv",
                            )
                        }
                        className={buttonStyle.button}
                    >
                        {t("table:download_template_csv")}
                    </button>
                </div>
            </div>
            {!file && (
                <div className={style.dropWrapper}>
                    <div
                        className={cn(
                            style.dropZone,
                            isDragActive && style.highlight,
                        )}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <h2>{t("table:drop_to_upload")}</h2>
                        ) : (
                            <h2>{t("table:drag_to_upload")}</h2>
                        )}
                    </div>
                </div>
            )}
            {file && (
                <div className={style.uploadedList}>
                    <div className={style.fileBox}>
                        <p>{file?.name || t("table:file")}</p>
                        <button
                            className={cn(
                                tableStyle.actionButton,
                                tableStyle.negativeActionButton,
                            )}
                            onClick={() => setFile(null)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
