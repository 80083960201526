import style from "./PricingListResult.module.css"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { getIntegerFormatter, getSafeFormatter } from "../../../i18n"

export const ResultsMetricDisplay = ({ resultsMetrics }) => {
    const { t, i18n } = useTranslation("table")

    const [totalResults, setTotalResults] = useState(null)
    const [plausiIssues, setPlausiIssues] = useState(null)
    const [totalPlausiIssues, setTotalPlausiIssues] = useState(null)
    const [totalIgnoredIssues, setTotalIgnoredIssues] = useState(null)

    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    useEffect(() => {
        if (!isNaN(resultsMetrics?.quickMetrics?.totalCount)) {
            setTotalResults(resultsMetrics?.quickMetrics?.totalCount)
        }

        if (!isNaN(resultsMetrics?.quickMetrics?.smallAttention)) {
            setPlausiIssues(resultsMetrics?.quickMetrics?.smallAttention)
        }

        if (!isNaN(resultsMetrics?.quickMetrics?.smallSolved)) {
            setTotalPlausiIssues(resultsMetrics?.quickMetrics?.smallSolved)
        }

        if (!isNaN(resultsMetrics?.quickMetrics?.bigAttention)) {
            setPlausiIssues(resultsMetrics?.quickMetrics?.bigAttention)
        }

        if (!isNaN(resultsMetrics?.quickMetrics?.bigSolved)) {
            setTotalPlausiIssues(resultsMetrics?.quickMetrics?.bigSolved)
        }

        if (!isNaN(resultsMetrics?.quickMetrics?.ignored)) {
            setTotalIgnoredIssues(resultsMetrics?.quickMetrics?.ignored)
        }
    }, [resultsMetrics])

    return (
        <>
            <div className={style.metricBox}>
                <p>{t("table:total_results")}:</p>
                <p>{integerFormatter.format(totalResults)}</p>
                <p>{t("table:total_unresolved")}:</p>
                <p>{integerFormatter.format(plausiIssues)}</p>
            </div>
            <div className={style.metricBox}>
                <p>{t("table:total_resolved")}:</p>
                <p>{integerFormatter.format(totalPlausiIssues)}</p>
                <p>{t("table:total_ignored")}:</p>
                <p>{integerFormatter.format(totalIgnoredIssues)}</p>
            </div>
        </>
    )
}
