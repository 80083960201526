import style from "./Loader.module.css"

/**
 * This element is a progress spinner
 *
 * @returns {JSX.Element} a progress spinner
 * @constructor
 */
export const Loader = () => (
    <div className={style.wrapper}>
        <div className={style.ldsRoller}>
            {[...Array(8)].map((_, i) => (
                <div key={i} />
            ))}
        </div>
    </div>
)
