import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import style from "./Banner.module.css"
import { animated, useSpring } from "react-spring"
import { useEffect, useState } from "react"
import cn from "classnames"

/**
 * Query that gets the name of the logged-in user
 *
 * @type {DocumentNode}
 */
const ME_QUERY = gql`
    query {
        me {
            firstName
            id
        }
    }
`

/**
 * This element is a banner that indicates the deployment environment via color
 * and displays a text. The text is either welcoming the user or the text provided to the banner
 *
 * @param text the text that should be displayed instead of the welcome message
 * @returns {JSX.Element} the banner element
 * @constructor
 */
export const Banner = ({ text }) => {
    const { t } = useTranslation("common")
    const { data } = useQuery(ME_QUERY, { skip: !!text })

    const animatedStyle = useSpring({ margin: data?.me ? "0%" : "-200%" })

    const [environment, setEnvironment] = useState(null)

    useEffect(() => {
        if (window.location.host.toLowerCase().includes("local")) {
            setEnvironment(style.local)
        } else if (window.location.host.toLowerCase().includes("dev")) {
            setEnvironment(style.dev)
        } else if (
            window.location.host.toLowerCase().includes("test") ||
            window.location.host.toLowerCase().includes("stage")
        ) {
            setEnvironment(style.test)
        } else {
            setEnvironment(null)
        }
    }, [])

    return (
        <div className={cn(style.banner, environment)}>
            <div>
                {text ? (
                    <h1>{text}</h1>
                ) : (
                    <h1>
                        <animated.span style={animatedStyle}>
                            <span>{t("hello")} </span>
                            {data?.me?.firstName}
                        </animated.span>
                    </h1>
                )}
            </div>
            <h3>{false && "TODO: Add X days till submission"}</h3>
        </div>
    )
}
