import { Component } from "react"
import { Nav } from "./Nav"
import { Banner } from "./Banner"
import style from "./ErrorBoundary.module.css"
import buttonStyle from "../../style/button.module.css"
import cn from "classnames"
import { withTranslation } from "react-i18next"

/**
 * Error boundary class that renders a clean looking error page if
 * a child component crashes
 */
class ErrorBoundaryComponent extends Component {
    constructor(props) {
        super(props)
        this.state = { error: null, errorInfo: null }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        })
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <Nav />
                    <Banner text={this.props.t("something_went_wrong")} />
                    <div className={style.errorWrapper}>
                        <h1>
                            {this.state.error && this.state.error.toString()}
                        </h1>
                        <br />
                        <details>
                            <summary>{this.props.t("error_details")}</summary>
                            {this.state.errorInfo.componentStack}
                        </details>
                        <button
                            className={cn(
                                buttonStyle.button,
                                buttonStyle.bigButton,
                            )}
                            onClick={() => window.location.reload()}
                        >
                            {this.props.t("reload")}
                        </button>
                    </div>
                </div>
            )
        }
        return this.props.children
    }
}

export const ErrorBoundary = withTranslation("common")(ErrorBoundaryComponent)
