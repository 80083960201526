// import { Nav } from "./components/util/Nav"
import { Route, Switch } from "react-router-dom"
import { Home } from "./components/view/home/Home"
import { Banner } from "./components/util/Banner"
import { useTranslation } from "react-i18next"
import { StrategyList } from "./components/view/strategy/StrategyList"
import { ModelConfigurationEditor } from "./components/view/modelConfiguration/ModelConfigurationEditor"
import { ModelConfigurationList } from "./components/view/modelConfiguration/ModelConfigurationList"
import { PricingListEditor } from "./components/view/pricingList/PricingListEditor"
import { PricingListList } from "./components/view/pricingList/PricingListList"
import { StrategyRanking } from "./components/view/pricingList/StrategyRanking"
import { EnDelistUpload } from "./components/view/groups/EnDelistGroupUpload"
import { StrategyUpload } from "./components/view/groups/StrategyGroupUpload"
import { PriceUpload } from "./components/view/groups/PriceGroupUpload"
import { AssortmentGroupList } from "./components/view/assortmentGroup/AssortmentGroupList"
import { CalculationSettingList } from "./components/view/calculationSetting/CalculationSettingsList"
import { AssortmentGroupEditor } from "./components/view/assortmentGroup/AssortmentGroupEditor"
import { PricingListResult } from "./components/view/pricingListResult/PricingListResult"
import { AssortmentList } from "./components/view/assortments/AssortmentList"
import { AssortmentEditor } from "./components/view/assortments/AssortmentEditor"
import { PricingListGroupList } from "./components/view/pricingListGroup/PricingListGroupList"
import { PricingListGroupEditor } from "./components/view/pricingListGroup/PricingListGroupEditor"
import { CalculationList } from "./components/view/pricingList/CalculationList"
import { useSelector } from "react-redux"
import { CalculationSettingUpload } from "./components/view/calculationSetting/CalculationSettingUpload"
import { ExportEditor } from "./components/view/export/ExportEditor"
import { ProductList } from "./components/view/products/ProductList"
import { PlausibilityResult } from "./components/view/bigPlausability/PlausabilityResults"
import { PlausiList } from "./components/view/bigPlausability/PlausiList"
import { ProductDetail } from "./components/view/products/ProductDetail"
import { PricingListImport } from "./components/view/import/PriceListImport"
import { StrategyEditor } from "./components/view/strategy/StrategyEditor"
import { CreatePricingCycle } from "./components/view/newPricingCycle/CreatePricingCycle"
import { PlausiCreatorFullscreen } from "./components/view/bigPlausability/PlausiCreator"
import { TaskList } from "./components/view/home/TaskList"
import { DistributorList } from "./components/view/distributor/DistributorList"
import { DistributorEditor } from "./components/view/distributor/DistributerEditor"
import { RunInfoList } from "./components/view/home/RunInfoList"
import { SideNav } from "./components/util/SideNav"
import { MetricDateOverrides } from "./components/view/calculationSetting/MetricDatesOverride"
import { ProductCreator } from "./components/view/products/ProductCreator"
import { MarketingUpload } from "./components/view/groups/MarketingGroupUpload"
import { MarketingListEditor } from "./components/view/marketing/MarketingListEditor"
import { MarketingListList } from "./components/view/marketing/MarketingListList"
import { MarketingCalculationList } from "./components/view/marketing/MarketingCalculationList"
import { MarketingListResults } from "./components/view/marketing/MarketingListResult"
import {MissingMinPriceProductList} from "./components/view/products/MissingMinPriceProductList";

/**
 * The router of the app.
 * Switches views depending on the URL while keeping the SideNav the same
 * @returns {JSX.Element}
 * @constructor
 */
export const App = () => {
    const { t } = useTranslation("common")

    const scrollLocked = useSelector((s) => s.modal.scrollLocked)

    return (
        <SideNav>
            <div
                style={{
                    overflow: scrollLocked ? "hidden" : "initial",
                    backgroundColor: "var(--white)",
                    flexGrow: 1,
                }}
            >
                {/*This disables the top nav bar*/}
                {/*<Nav />*/}
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    {/*Uploads*/}
                    <Route path="/enlist_and_delist/csv/:id?">
                        <EnDelistUpload />
                    </Route>
                    <Route path="/article_group/csv/:id?">
                        <StrategyUpload />
                    </Route>
                    <Route path="/targeted_data_updates/csv">
                        <CalculationSettingUpload />
                    </Route>
                    {/*
                    <Route path="/price/csv/:id?">
                        <PriceUpload />
                    </Route>
                    */}
                    <Route path="/price/bulk/csv">
                        <PriceUpload mass={true} />
                    </Route>
                    {/*Models*/}
                    <Route path="/pricing_lists">
                        <PricingListList />
                    </Route>
                    <Route path="/pricing_list/:id?/model_configuration_ranking">
                        <StrategyRanking />
                    </Route>
                    <Route path="/pricing_list/:pricingListId/calculations/:id">
                        <PricingListResult />
                    </Route>
                    <Route path="/pricing_list/:id/calculations">
                        <CalculationList />
                    </Route>
                    <Route path="/calculations">
                        <CalculationList />
                    </Route>
                    <Route path="/pricing_list/:id?">
                        <PricingListEditor />
                    </Route>
                    <Route path="/pricing_list_groups">
                        <PricingListGroupList />
                    </Route>
                    <Route path="/pricing_list_group/:id">
                        <PricingListGroupEditor />
                    </Route>
                    <Route path="/strategies">
                        <StrategyList />
                    </Route>
                    <Route path="/rule_strategy/:id?">
                        <StrategyEditor isMl={false} />
                    </Route>
                    <Route path="/ml_strategy/:id?">
                        <StrategyEditor isMl={true} />
                    </Route>
                    <Route path="/products">
                        <ProductList />
                    </Route>
                    <Route path="/missing_min_price_products">
                        <MissingMinPriceProductList />
                    </Route>
                    <Route path="/tasks">
                        <TaskList />
                    </Route>
                    <Route path="/run_info">
                        <RunInfoList />
                    </Route>
                    <Route path="/product/:id">
                        <ProductDetail />
                    </Route>
                    <Route path="/add_pzn">
                        <ProductCreator />
                    </Route>
                    <Route path="/model_configurations">
                        <ModelConfigurationList />
                    </Route>
                    <Route path="/model_configuration/:id?">
                        <ModelConfigurationEditor />
                    </Route>
                    <Route path="/model_configuration_mass_assign">
                        <ModelConfigurationEditor mass={true} />
                    </Route>
                    <Route path="/assortments">
                        <AssortmentList />
                    </Route>
                    <Route path="/assortment/:id">
                        <AssortmentEditor />
                    </Route>
                    <Route path="/assortment_groups">
                        <AssortmentGroupList />
                    </Route>
                    <Route path="/assortment_group/:id">
                        <AssortmentGroupEditor />
                    </Route>
                    <Route path="/calculation_settings">
                        <CalculationSettingList />
                    </Route>
                    <Route path="/targeted_data_updates">
                        <MetricDateOverrides />
                    </Route>
                    <Route path="/export">
                        <ExportEditor />
                    </Route>
                    <Route path="/plausibility/:id">
                        <PlausibilityResult />
                    </Route>
                    <Route path="/plausibility_checks">
                        <PlausiList />
                    </Route>
                    <Route path="/distributor/:id?">
                        <DistributorEditor />
                    </Route>
                    <Route path="/distributors">
                        <DistributorList />
                    </Route>
                    <Route path="/pricing_list_import">
                        <PricingListImport />
                    </Route>
                    <Route path="/new_pricing_cycle">
                        <CreatePricingCycle />
                    </Route>
                    <Route path="/ifa_changes">
                        <PlausiCreatorFullscreen updateIfa={true} />
                    </Route>
                    {/*
                    <Route path="/new_plausibility_check">
                        <PlausiCreatorFullscreen updateIfa={false} />
                    </Route>
                    */}
                    <Route path="/new_correction_list">
                        <PlausiCreatorFullscreen
                            updateIfa={false}
                            runPlausi={false}
                        />
                    </Route>

                    <Route path="/marketing/csv/:id?">
                        <MarketingUpload />
                    </Route>
                    <Route path="/marketing/:listId/calculations/:id">
                        <MarketingListResults />
                    </Route>
                    <Route path="/marketing/:id/calculations">
                        <MarketingCalculationList />
                    </Route>
                    <Route path="/marketing/:id">
                        <MarketingListEditor />
                    </Route>
                    <Route path="/marketing/">
                        <MarketingListList />
                    </Route>
                    <Route path="/marketing/calculations">
                        <MarketingCalculationList />
                    </Route>

                    <Route path="/allocation/csv/:id?">
                        <MarketingUpload isAllocation={true} />
                    </Route>

                    <Route path="/allocation/:listId/calculations/:id">
                        <MarketingListResults isAllocation={true} />
                    </Route>
                    <Route path="/allocation/:id/calculations">
                        <MarketingCalculationList isAllocation={true} />
                    </Route>
                    <Route path="/allocation/:id">
                        <MarketingListEditor isAllocation={true} />
                    </Route>
                    <Route path="/allocation/">
                        <MarketingListList isAllocation={true} />
                    </Route>

                    <Route path="/allocation/calculations">
                        <MarketingCalculationList isAllocation={true} />
                    </Route>

                    {/*dataSynchronisation*/}
                    {/*<Route path="/product_listing">*/}
                    {/*    <ProductListing />*/}
                    {/*</Route>*/}
                    {/*Util*/}
                    <Route path="/">
                        <Banner text={"404"} />
                        <h1>{t("common:not_found")}</h1>
                    </Route>
                </Switch>
            </div>
        </SideNav>
    )
}
