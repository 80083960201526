import { useState } from "react"

/**
 * SVG filled with randomly placed and colored shapes
 *
 * @param className the classname the SVG will be given
 * @param preserveAspectRatio true if the aspect ratio of the SVG should be preserved
 * @returns {JSX.Element} the random SVG
 * @constructor
 */
export const ShapeSVG = ({ className, preserveAspectRatio }) => {
    const [bg] = useState(() => {
        const randomColor = () => {
            return [
                "var(--orange)",
                "var(--blue)",
                "var(--red)",
                "var(--purple)",
            ][Math.floor(Math.random() * 4)]
        }

        /**
         * Generates a list of random shapes
         *
         * @returns {JSX.Element} a list of random shapes
         */
        const randomShape = () => {
            return [
                <polygon
                    key={1}
                    transform={`rotate(${Math.random() * 180})`}
                    points={`0,0 10,5 0,10`}
                    fill={randomColor()}
                />,
                <polygon
                    key={2}
                    transform={`rotate(${Math.random() * 180})`}
                    points={`0,0 0,2.5 2.5,2.5 2.5,7.5 7.5,7.5 7.5,5 5,5 5,0`}
                    fill="var(--lightGray)"
                />,
                <circle key={3} r={2} fill={randomColor()} />,
                <circle key={4} r={2.5} fill={randomColor()} />,
            ][Math.floor(Math.random() * 4)]
        }

        let list = []
        for (let i = 0; i < Math.random() * 100 + 100; i++) {
            list.push(
                <g
                    key={i}
                    transform={`translate(${Math.random() * 400}, ${
                        Math.random() * 100
                    })`}
                >
                    {randomShape()}
                </g>,
            )
        }
        return list
    })

    return (
        <svg
            preserveAspectRatio={preserveAspectRatio}
            viewBox="0 0 400 100"
            className={className}
        >
            {bg}
        </svg>
    )
}
