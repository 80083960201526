import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import cn from "classnames"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import { useDispatch } from "react-redux"
import { EnumLabel } from "../../util/table/EnumLabel"
import { Link, useHistory, useParams } from "react-router-dom"
import inputStyle from "../../../style/input.module.css"
import buttonStyle from "../../../style/button.module.css"
import { showError, showInfo } from "../../../redux/actions/NotificationActions"
import c from "capitalize"
import { EnterNameModal } from "../../util/modals/EnterNameModal"
import {
    getCurrencyFormatter,
    getSafeFormatter,
    getTimeFormatter,
} from "../../../i18n"
import { PricingListResultExpander } from "../../util/PricingListResultExpander"
import { SmartFilter } from "../../util/input/SmartFilter"
import style from "../pricingListResult/PricingListResult.module.css"
import { Toggle } from "../../util/input/Toggle"
import { ResultsMetricDisplay } from "../pricingListResult/ResultsMetricDisplay"
import { Impact } from "./Impact"
import { QuickFilter } from "./QuickFilter"

const GET_PRICING_LIST_RESULTS = gql`
    query (
        $query: String
        $pageNum: Int
        $order: [PlausibilityCheckResultByProductSearchOrder]
        $filter: PlausibilityCheckResultProductSearchFilter
        $id: UUID!
    ) {
        me {
            name
        }
        plausibilityCheck(id: $id) {
            id
            name
            plausibilityStatus
            calculations(limit: -1) {
                page {
                    id
                }
            }
            sortablePlausibilityResults(
                limit: 20
                pageNum: $pageNum
                orderBy: $order
                filterParams: $filter
                query: $query
            ) {
                currentPage
                hasNext
                hasPrevious
                pages
                suggestedQuery
                page {
                    id
                    pricingListCalculation {
                        id
                        pricingList {
                            id
                            name
                            displayName
                        }
                    }
                    isAutomatic
                    bigPlausibilityPrice
                    bigPlausibilityPriceAmount2
                    bigPlausibilityPriceAmount3
                    calculatedSalesVolume
                    bigPlausibilityStatus
                    bigPlausibilityMessage
                    smallPlausibilityPrice
                    calculatedMarketShare
                    plausibilitySalesVolume
                    plausibilityMarketShare
                    calculatedRevenue
                    plausibilityRevenue
                    smallPlausibilityStatus
                    previousPrice
                    usedStrategyParams
                    agrosso1
                    agrosso2
                    AEP
                    exportAep
                    GEP
                    revenue
                    salesQuantity
                    previousPrice
                    revenueGrossoTotal
                    revenueGrossoWholesale
                    revenueGrossoPhoenix
                    revenueGrossoDirect
                    bulkAmount1
                    bulkAmount2
                    bulkAmount3
                    product {
                        id
                        name
                        pzn
                        unit
                        supplierName
                        type
                    }
                    manualAdjustment {
                        id
                        bulkAmount1
                        bulkAmount2
                        bulkAmount3
                        priceAmount1
                        priceAmount2
                        priceAmount3
                        ignorePlausiCheck
                    }
                }
            }
        }
    }
`

const FILTER_QUERY = gql`
    query getFilter($id: UUID!) {
        plausibilityCheck(id: $id) {
            id
            sortablePlausibilityResults(limit: 0) {
                filterFields {
                    name
                    dataType
                    displayName
                    options
                }
            }
        }
    }
`

const SET_PRICE = gql`
    mutation ($changes: [ManualPriceInput]) {
        setManualPrices(pricingListResults: $changes) {
            id
            ignorePlausiCheck
            manualAdjustment {
                id
                bulkAmount1
                bulkAmount2
                bulkAmount3
                priceAmount1
                priceAmount2
                priceAmount3
                ignorePlausiCheck
            }
        }
    }
`

const TRIGGER_PLAUSI = gql`
    mutation ($ids: [UUID], $name: String!) {
        triggerPlausiRun(name: $name, calculationRuns: $ids) {
            id
        }
    }
`

const TRIGGER_PLAUSI_EMAIL = gql`
    mutation ($id: UUID!) {
        sendPlausiMetricsAsMail(plausibilityId: $id)
    }
`

const RESULTS_METRICS = gql`
    query ($id: UUID!) {
        plausibilityCheck(id: $id) {
            id
            quickMetrics {
                totalCount
                bigAttention
                bigSolved
                ignored
            }
        }
    }
`

const SET_LOCKED = gql`
    mutation ($id: UUID!) {
        setPlausiCheckClosed(id: $id) {
            id
            plausibilityStatus
        }
    }
`

export const PlausibilityResult = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([
        "BigPlausibilityStatusAsc",
        "ProductPznAsc",
    ])
    const [query, setQuery] = useState("")
    const [filter, setFilter] = useState({
        bigPlausibilityStatusEquals: "ATTENTION",
    })
    const [changes, setChanges] = useState({})
    const [editRow, setEditRow] = useState(null)
    const [expanderProduct, setExpanderProduct] = useState(null)

    const { t, i18n } = useTranslation(["common", "table", "messages"])

    const { id } = useParams()

    const [setManualPrices, { loading: updateLoading }] = useMutation(SET_PRICE)
    const [setLocked, { loading: setLockedLoading }] = useMutation(SET_LOCKED)
    const [namePickerShown, setNamePickerShown] = useState(false)

    const history = useHistory()

    const clampOrEmpty = (input, minValue, maxValue) =>
        input.trim()
            ? Math.max(minValue, Math.min(maxValue, +input.trim()))
            : ""

    const [triggerPlausi, { loading: triggerPlausiLoading }] =
        useMutation(TRIGGER_PLAUSI)

    const [triggerMetricExport, { loading: triggerEmailLoading }] =
        useMutation(TRIGGER_PLAUSI_EMAIL)

    const { data, loading } = useQuery(GET_PRICING_LIST_RESULTS, {
        variables: {
            pageNum,
            order,
            filter,
            query,
            id,
        },
    })

    const { data: resultsMetricData } = useQuery(RESULTS_METRICS, {
        variables: { id },
        skip: loading || !data,
    })

    const dispatch = useDispatch()
    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))
    const currencyFormat = getSafeFormatter(getCurrencyFormatter(i18n.language))

    return (
        <div>
            {namePickerShown ? (
                <EnterNameModal
                    text={t("table:create_plausibility_check")}
                    defaultText={`${c(
                        t("table:plausibility_check"),
                    )} ${dateFormat.format(new Date())} [${
                        data?.me?.name || ""
                    }]`}
                    onCancel={() => setNamePickerShown(false)}
                    loading={triggerPlausiLoading}
                    onNameSet={(name) => {
                        triggerPlausi({
                            variables: {
                                name,
                                ids: data?.plausibilityCheck?.calculations?.page.map(
                                    (c) => c.id,
                                ),
                            },
                        })
                            .then(() => {
                                history.push("plausibility_checks")
                            })
                            .catch((e) => dispatch(showError(e.message)))
                    }}
                />
            ) : null}
            <Banner
                text={
                    loading
                        ? t("table:plausibility_check")
                        : `${t("table:plausibility_check")}:
                        ${data?.plausibilityCheck?.name || ""}`
                }
            />
            <Impact plausibilityRunId={id}>
                {!loading && !data?.plausibilityCheck ? (
                    <Flash text={t("table:no_results")} type={WARNING} />
                ) : null}

                <div className={dropDownStyle.container}>
                    <SmartFilter
                        filterFieldQuery={FILTER_QUERY}
                        filterQueryOptions={{ variables: { id } }}
                        filterPath={(q) =>
                            q?.plausibilityCheck?.sortablePlausibilityResults
                                ?.filterFields
                        }
                        setQuery={setQuery}
                        setFilter={setFilter}
                        suggestedQuery={
                            data?.plausibilityCheck?.sortablePricingListResults
                                ?.suggestedQuery
                        }
                    />

                    <div className={tableStyle.buttonBar}>
                        <button
                            className={buttonStyle.button}
                            disabled={triggerEmailLoading}
                            onClick={() =>
                                triggerMetricExport({
                                    variables: {
                                        id,
                                    },
                                })
                                    .then(() =>
                                        dispatch(
                                            showInfo("common:export_triggered"),
                                        ),
                                    )
                                    .catch((e) =>
                                        dispatch(showError(e.message)),
                                    )
                            }
                        >
                            {t("table:all_metrics_download_csv")}
                        </button>
                        <button
                            className={cn(
                                buttonStyle.button,
                                buttonStyle.orangeButton,
                            )}
                            disabled={loading}
                            onClick={() => setNamePickerShown(true)}
                        >
                            {t("table:rerun_plausibility_check")}
                        </button>
                        {data?.plausibilityCheck?.plausibilityStatus !==
                        "CLOSED" ? (
                            <button
                                className={cn(
                                    buttonStyle.button,
                                    buttonStyle.dangerButton,
                                )}
                                disabled={loading || setLockedLoading}
                                onClick={() =>
                                    setLocked({
                                        variables: {
                                            id,
                                        },
                                    })
                                        .then((r) =>
                                            history.push(
                                                `/plausibility/${r?.data?.setPlausiCheckClosed?.id}`,
                                            ),
                                        )
                                        .catch((e) =>
                                            dispatch(showError(e.message)),
                                        )
                                }
                            >
                                {t("table:close_plausi_check")}
                            </button>
                        ) : null}
                    </div>
                </div>
                <QuickFilter
                    filter={filter}
                    setFilter={(f) => {
                        const new_filter = {}

                        for (const [key, value] of Object.entries(f)) {
                            if (
                                typeof value !== "undefined" &&
                                value !== null
                            ) {
                                new_filter[key] = value
                            }
                        }

                        if (Object.keys(new_filter).length) {
                            setFilter(new_filter)
                        } else {
                            setFilter(null)
                        }
                    }}
                    loading={loading}
                    isBigPlausi={true}
                    calculationRunIds={
                        data?.plausibilityCheck?.calculations?.page.map(
                            (c) => c.id,
                        ) || []
                    }
                />
                <div className={dropDownStyle.container}>
                    <div className={dropDownStyle.container}>
                        <ResultsMetricDisplay
                            resultsMetrics={
                                resultsMetricData?.plausibilityCheck
                            }
                        />
                    </div>

                    <div className={tableStyle.buttonBar}>
                        {Object.entries(changes).length > 0 && (
                            <>
                                <h3>
                                    {t("table:number_of_unsaved_changes")}:{" "}
                                    {Object.entries(changes).length}
                                </h3>

                                <button
                                    className={buttonStyle.button}
                                    onClick={() => {
                                        setManualPrices({
                                            variables: {
                                                changes: Object.entries(
                                                    changes,
                                                ).map((c) => ({
                                                    id: c[0],
                                                    manualPriceBulk1:
                                                        Number.parseFloat(
                                                            c[1]?.manualPrice,
                                                        ) ?? null,
                                                    manualPriceBulk2:
                                                        Number.parseFloat(
                                                            c[1]
                                                                ?.manualPriceAmount2,
                                                        ) ?? null,
                                                    manualPriceBulk3:
                                                        Number.parseFloat(
                                                            c[1]
                                                                ?.manualPriceAmount3,
                                                        ) ?? null,
                                                    manualAmountBulk1:
                                                        Number.parseInt(
                                                            c[1]?.bulkAmount1,
                                                        ) ?? null,
                                                    manualAmountBulk2:
                                                        Number.parseInt(
                                                            c[1]?.bulkAmount2,
                                                        ) ?? null,
                                                    manualAmountBulk3:
                                                        Number.parseInt(
                                                            c[1]?.bulkAmount3,
                                                        ) ?? null,
                                                    ignorePlausiCheck:
                                                        c[1]
                                                            ?.ignorePlausiCheck ||
                                                        null,
                                                })),
                                            },
                                        })
                                            .then(() => setChanges({}))
                                            .catch((e) =>
                                                dispatch(showError(e.message)),
                                            )
                                    }}
                                >
                                    {updateLoading
                                        ? "..."
                                        : c(t("common:save"))}
                                </button>

                                <button
                                    className={cn(
                                        buttonStyle.button,
                                        buttonStyle.dangerButton,
                                    )}
                                    onClick={() => setChanges({})}
                                >
                                    {c(t("common:reset"))}
                                </button>
                            </>
                        )}
                    </div>
                </div>
                <div className={tableStyle.wrapper}>
                    <div
                        className={tableStyle.table}
                        style={{
                            gridTemplateColumns:
                                "1fr 1fr 2fr repeat(5, 1fr) 0.5fr 1fr repeat(6, auto) 1fr repeat(4, 1fr)",
                        }}
                    >
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:pricing_list")}
                            order={order}
                            setOrder={setOrder}
                            asc={"PricingListCalculationPricingListNameAsc"}
                            desc={"PricingListCalculationPricingListNameDesc"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:pzn")}
                            order={order}
                            setOrder={setOrder}
                            asc={"ProductPznAsc"}
                            desc={"ProductPznDesc"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:product")}
                            order={order}
                            setOrder={setOrder}
                            asc={"ProductNameAsc"}
                            desc={"ProductNameDesc"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:supplier")}
                            order={order}
                            setOrder={setOrder}
                            asc={"ProductSupplierNameAsc"}
                            desc={"ProductSupplierNameDesc"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:unit")}
                            order={order}
                            setOrder={setOrder}
                            asc={"ProductUnitAsc"}
                            desc={"ProductUnitDesc"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:product_type")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:previous_revenue")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:plausibility_status")}
                            order={order}
                            setOrder={setOrder}
                            asc={"BigPlausibilityStatusAsc"}
                            desc={"BigPlausibilityStatusDesc"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:ignore_plausi_check")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:plausibility_message")}
                            order={order}
                            setOrder={setOrder}
                            asc={"BigPlausibilityMessageAsc"}
                            desc={"BigPlausibilityMessageDesc"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={`${t("table:bulk_amount")} 1`}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={`${t("table:price")} 1`}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={`${t("table:bulk_amount")} 2`}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={`${t("table:price")} 2`}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={`${t("table:bulk_amount")} 3`}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={`${t("table:price")} 3`}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:price_type")}
                            order={order}
                            setOrder={setOrder}
                            asc={"UsedStrategyParamsAsc"}
                            desc={"UsedStrategyParamsDesc"}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:agrosso") + " 1"}
                            order={order}
                            setOrder={setOrder}
                            asc={"Agrosso1_Asc"}
                            desc={"Agrosso1_Desc"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:agrosso") + " 2"}
                            order={order}
                            setOrder={setOrder}
                            asc={"Agrosso2_Asc"}
                            desc={"Agrosso2_Desc"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:aep")}
                            order={order}
                            setOrder={setOrder}
                            asc={"AEPAsc"}
                            desc={"AEPDesc"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:gep")}
                            order={order}
                            setOrder={setOrder}
                            asc={"GEPAsc"}
                            desc={"GEPDesc"}
                        />

                        {loading &&
                            !data?.plausibilityCheck
                                ?.sortablePlausibilityResults && (
                                <TableContentLoader columns={32} rows={20} />
                            )}
                        {data?.plausibilityCheck?.sortablePlausibilityResults?.page.map(
                            (s, i) => (
                                <Fragment key={i}>
                                    <Link
                                        to={`/pricing_list/${s?.pricingListCalculation?.pricingList?.id}`}
                                        className={cn(
                                            tableStyle.fullWidthCell,
                                            tableStyle.linkCell,
                                        )}
                                    >
                                        {
                                            s?.pricingListCalculation
                                                ?.pricingList?.displayName
                                        }
                                    </Link>
                                    <p
                                        className={tableStyle.fullWidthCell}
                                        onClick={() =>
                                            setExpanderProduct(s?.id)
                                        }
                                    >
                                        {s?.product?.pzn}
                                    </p>
                                    <p
                                        className={tableStyle.cell}
                                        onClick={() =>
                                            setExpanderProduct(s?.id)
                                        }
                                    >
                                        {s?.product?.name}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {s?.product?.supplierName}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {s?.product?.unit}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {s?.product?.type}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(s?.revenue)}
                                    </p>

                                    <EnumLabel
                                        className={cn(
                                            tableStyle.cell,
                                            s?.ignorePlausiCheck
                                                ? style.strike
                                                : null,
                                        )}
                                        label={s?.bigPlausibilityStatus}
                                    />
                                    <div className={tableStyle.cell}>
                                        <Toggle
                                            isChecked={
                                                changes[s?.id]
                                                    ?.ignorePlausiCheck ??
                                                s?.manualAdjustment
                                                    ?.ignorePlausiCheck
                                            }
                                            disabled={updateLoading}
                                            setIsChecked={(c) =>
                                                setChanges({
                                                    ...changes,
                                                    [s?.id]: {
                                                        manualPrice:
                                                            changes[s?.id]
                                                                ?.manualPrice ??
                                                            s?.manualAdjustment
                                                                ?.priceAmount1,
                                                        manualPriceAmount2:
                                                            changes[s?.id]
                                                                ?.manualPriceAmount2 ??
                                                            s?.manualAdjustment
                                                                ?.priceAmount2,
                                                        manualPriceAmount3:
                                                            changes[s?.id]
                                                                ?.manualPriceAmount3 ??
                                                            s?.manualAdjustment
                                                                ?.priceAmount3,
                                                        bulkAmount1:
                                                            changes[s?.id]
                                                                ?.bulkAmount1 ??
                                                            s?.manualAdjustment
                                                                ?.bulkAmount1,
                                                        bulkAmount2:
                                                            changes[s?.id]
                                                                ?.bulkAmount2 ??
                                                            s?.manualAdjustment
                                                                ?.bulkAmount2,
                                                        bulkAmount3:
                                                            changes[s?.id]
                                                                ?.bulkAmount3 ??
                                                            s?.manualAdjustment
                                                                ?.bulkAmount3,
                                                        ignorePlausiCheck: c,
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                    <p className={tableStyle.fullWidthCell}>
                                        {s?.bigPlausibilityMessage
                                            ? t(
                                                  `messages:${s?.bigPlausibilityMessage}`,
                                              )
                                            : null}
                                    </p>

                                    <p
                                        className={cn(
                                            tableStyle.cell,
                                            tableStyle.editCell,
                                        )}
                                        onClick={() =>
                                            setEditRow({
                                                id: s?.id,
                                                bulk: 1,
                                                isPrice: false,
                                            })
                                        }
                                    >
                                        {s?.id === editRow?.id &&
                                        editRow?.bulk === 1 &&
                                        !editRow?.isPrice ? (
                                            <input
                                                type="number"
                                                autoFocus={true}
                                                value={
                                                    changes[s?.id]
                                                        ?.bulkAmount1 ??
                                                    s?.manualAdjustment
                                                        ?.bulkAmount1 ??
                                                    s?.bulkAmount1 ??
                                                    ""
                                                }
                                                className={inputStyle.text}
                                                placeholder={s?.bulkAmount1}
                                                onBlur={() => setEditRow(null)}
                                                onChange={(evt) =>
                                                    setChanges({
                                                        ...changes,
                                                        [s?.id]: {
                                                            manualPrice:
                                                                changes[s?.id]
                                                                    ?.manualPrice ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount1,
                                                            manualPriceAmount2:
                                                                changes[s?.id]
                                                                    ?.manualPriceAmount2 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount2,
                                                            manualPriceAmount3:
                                                                changes[s?.id]
                                                                    ?.manualPriceAmount3 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount3,
                                                            bulkAmount1:
                                                                clampOrEmpty(
                                                                    evt.target.value.trim(),
                                                                    0,
                                                                    999,
                                                                ),
                                                            bulkAmount2:
                                                                changes[s?.id]
                                                                    ?.bulkAmount2 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount2,
                                                            bulkAmount3:
                                                                changes[s?.id]
                                                                    ?.bulkAmount3 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount3,
                                                            ignorePlausiCheck:
                                                                changes[s?.id]
                                                                    ?.ignorePlausiCheck ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.ignorePlausiCheck,
                                                        },
                                                    })
                                                }
                                            />
                                        ) : (
                                            changes[s?.id]?.bulkAmount1 ??
                                            s?.manualAdjustment?.bulkAmount1 ??
                                            s?.bulkAmount1
                                        )}
                                    </p>
                                    <p
                                        className={cn(
                                            tableStyle.cell,
                                            tableStyle.editCell,
                                        )}
                                        onClick={() =>
                                            setEditRow({
                                                id: s?.id,
                                                bulk: 1,
                                                isPrice: true,
                                            })
                                        }
                                    >
                                        {s?.id === editRow?.id &&
                                        editRow?.bulk === 1 &&
                                        editRow?.isPrice ? (
                                            <input
                                                type="number"
                                                autoFocus={true}
                                                value={
                                                    changes[s?.id]
                                                        ?.manualPrice ??
                                                    s?.manualAdjustment
                                                        ?.priceAmount1 ??
                                                    s?.bigPlausibilityPrice ??
                                                    ""
                                                }
                                                className={inputStyle.text}
                                                placeholder={
                                                    s?.manualAdjustment
                                                        ?.priceAmount1 ??
                                                    s?.bigPlausibilityPrice ??
                                                    s?.smallPlausibilityPrice ??
                                                    s?.calculatedPrice
                                                }
                                                onBlur={() => setEditRow(null)}
                                                onChange={(evt) =>
                                                    setChanges({
                                                        ...changes,
                                                        [s?.id]: {
                                                            manualPrice:
                                                                clampOrEmpty(
                                                                    evt.target.value.trim(),
                                                                    0,
                                                                    99999.99,
                                                                ),
                                                            manualPriceAmount2:
                                                                changes[s?.id]
                                                                    ?.manualPriceAmount2 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount2,
                                                            manualPriceAmount3:
                                                                changes[s?.id]
                                                                    ?.manualPriceAmount3 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount3,
                                                            bulkAmount1:
                                                                changes[s?.id]
                                                                    ?.bulkAmount1 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount1,
                                                            bulkAmount2:
                                                                changes[s?.id]
                                                                    ?.bulkAmount2 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount2,
                                                            bulkAmount3:
                                                                changes[s?.id]
                                                                    ?.bulkAmount3 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount3,
                                                            ignorePlausiCheck:
                                                                changes[s?.id]
                                                                    ?.ignorePlausiCheck ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.ignorePlausiCheck,
                                                        },
                                                    })
                                                }
                                            />
                                        ) : (
                                            currencyFormat.format(
                                                changes[s?.id]?.manualPrice ??
                                                    s?.manualAdjustment
                                                        ?.priceAmount1 ??
                                                    s?.bigPlausibilityPrice ??
                                                    "",
                                            )
                                        )}
                                    </p>
                                    <p
                                        className={cn(
                                            tableStyle.cell,
                                            tableStyle.editCell,
                                        )}
                                        onClick={() =>
                                            setEditRow({
                                                id: s?.id,
                                                bulk: 2,
                                                isPrice: false,
                                            })
                                        }
                                    >
                                        {s?.id === editRow?.id &&
                                        editRow?.bulk === 2 &&
                                        !editRow?.isPrice ? (
                                            <input
                                                type="number"
                                                autoFocus={true}
                                                value={
                                                    changes[s?.id]
                                                        ?.bulkAmount2 ??
                                                    s?.manualAdjustment
                                                        ?.bulkAmount2 ??
                                                    s?.bulkAmount2 ??
                                                    ""
                                                }
                                                className={inputStyle.text}
                                                placeholder={s?.bulkAmount2}
                                                onBlur={() => setEditRow(null)}
                                                onChange={(evt) =>
                                                    setChanges({
                                                        ...changes,
                                                        [s?.id]: {
                                                            manualPrice:
                                                                changes[s?.id]
                                                                    ?.manualPrice ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount1,
                                                            manualPriceAmount2:
                                                                changes[s?.id]
                                                                    ?.manualPriceAmount2 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount2,
                                                            manualPriceAmount3:
                                                                changes[s?.id]
                                                                    ?.manualPriceAmount3 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount3,
                                                            bulkAmount1:
                                                                changes[s?.id]
                                                                    ?.bulkAmount1 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount1,
                                                            bulkAmount2:
                                                                clampOrEmpty(
                                                                    evt.target.value.trim(),
                                                                    0,
                                                                    999,
                                                                ),
                                                            bulkAmount3:
                                                                changes[s?.id]
                                                                    ?.bulkAmount3 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount3,
                                                            ignorePlausiCheck:
                                                                changes[s?.id]
                                                                    ?.ignorePlausiCheck ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.ignorePlausiCheck,
                                                        },
                                                    })
                                                }
                                            />
                                        ) : (
                                            changes[s?.id]?.bulkAmount2 ??
                                            s?.manualAdjustment?.bulkAmount2 ??
                                            s?.bulkAmount2
                                        )}
                                    </p>
                                    <p
                                        className={cn(
                                            tableStyle.cell,
                                            tableStyle.editCell,
                                        )}
                                        onClick={() =>
                                            setEditRow({
                                                id: s?.id,
                                                bulk: 2,
                                                isPrice: true,
                                            })
                                        }
                                    >
                                        {s?.id === editRow?.id &&
                                        editRow?.bulk === 2 &&
                                        editRow?.isPrice ? (
                                            <input
                                                type="number"
                                                autoFocus={true}
                                                value={
                                                    changes[s?.id]
                                                        ?.manualPriceAmount2 ??
                                                    s?.manualAdjustment
                                                        ?.priceAmount2 ??
                                                    s?.bigPlausibilityPriceAmount2 ??
                                                    ""
                                                }
                                                className={inputStyle.text}
                                                placeholder={
                                                    s?.manualAdjustment
                                                        ?.priceAmount2 ??
                                                    s?.bigPlausibilityPriceAmount2 ??
                                                    s?.smallPlausibilityPriceAmount2 ??
                                                    s?.calculatedPriceAmount2
                                                }
                                                onBlur={() => setEditRow(null)}
                                                onChange={(evt) =>
                                                    setChanges({
                                                        ...changes,
                                                        [s?.id]: {
                                                            manualPrice:
                                                                changes[s?.id]
                                                                    ?.manualPrice ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount1,
                                                            manualPriceAmount2:
                                                                clampOrEmpty(
                                                                    evt.target.value.trim(),
                                                                    0,
                                                                    99999.99,
                                                                ),
                                                            manualPriceAmount3:
                                                                changes[s?.id]
                                                                    ?.manualPriceAmount3 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount3,
                                                            bulkAmount1:
                                                                changes[s?.id]
                                                                    ?.bulkAmount1 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount1,
                                                            bulkAmount2:
                                                                changes[s?.id]
                                                                    ?.bulkAmount2 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount2,
                                                            bulkAmount3:
                                                                changes[s?.id]
                                                                    ?.bulkAmount3 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount3,
                                                            ignorePlausiCheck:
                                                                changes[s?.id]
                                                                    ?.ignorePlausiCheck ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.ignorePlausiCheck,
                                                        },
                                                    })
                                                }
                                            />
                                        ) : (
                                            currencyFormat.format(
                                                changes[s?.id]
                                                    ?.manualPriceAmount2 ??
                                                    s?.manualAdjustment
                                                        ?.priceAmount2 ??
                                                    s?.bigPlausibilityPriceAmount2 ??
                                                    s?.smallPlausibilityPriceAmount2 ??
                                                    s?.calculatedPriceAmount2,
                                            )
                                        )}
                                    </p>
                                    <p
                                        className={cn(
                                            tableStyle.cell,
                                            tableStyle.editCell,
                                        )}
                                        onClick={() =>
                                            setEditRow({
                                                id: s?.id,
                                                bulk: 3,
                                                isPrice: false,
                                            })
                                        }
                                    >
                                        {s?.id === editRow?.id &&
                                        editRow?.bulk === 3 &&
                                        !editRow?.isPrice ? (
                                            <input
                                                type="number"
                                                autoFocus={true}
                                                value={
                                                    changes[s?.id]
                                                        ?.bulkAmount3 ??
                                                    s?.manualAdjustment
                                                        ?.bulkAmount3 ??
                                                    s?.bulkAmount3 ??
                                                    ""
                                                }
                                                className={inputStyle.text}
                                                placeholder={s?.bulkAmount3}
                                                onBlur={() => setEditRow(null)}
                                                onChange={(evt) =>
                                                    setChanges({
                                                        ...changes,
                                                        [s?.id]: {
                                                            manualPrice:
                                                                changes[s?.id]
                                                                    ?.manualPrice ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount1,
                                                            manualPriceAmount2:
                                                                changes[s?.id]
                                                                    ?.manualPriceAmount2 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount2,
                                                            manualPriceAmount3:
                                                                changes[s?.id]
                                                                    ?.manualPriceAmount3 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount3,
                                                            bulkAmount1:
                                                                changes[s?.id]
                                                                    ?.bulkAmount1 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount1,
                                                            bulkAmount2:
                                                                changes[s?.id]
                                                                    ?.bulkAmount2 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount2,
                                                            bulkAmount3:
                                                                clampOrEmpty(
                                                                    evt.target.value.trim(),
                                                                    0,
                                                                    999,
                                                                ),
                                                            ignorePlausiCheck:
                                                                changes[s?.id]
                                                                    ?.ignorePlausiCheck ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.ignorePlausiCheck,
                                                        },
                                                    })
                                                }
                                            />
                                        ) : (
                                            changes[s?.id]?.bulkAmount3 ??
                                            s?.manualAdjustment?.bulkAmount3 ??
                                            s?.bulkAmount3
                                        )}
                                    </p>
                                    <p
                                        className={cn(
                                            tableStyle.cell,
                                            tableStyle.editCell,
                                        )}
                                        onClick={() =>
                                            setEditRow({
                                                id: s?.id,
                                                bulk: 3,
                                                isPrice: true,
                                            })
                                        }
                                    >
                                        {s?.id === editRow?.id &&
                                        editRow?.bulk === 3 &&
                                        editRow?.isPrice ? (
                                            <input
                                                type="number"
                                                autoFocus={true}
                                                value={
                                                    changes[s?.id]
                                                        ?.manualPriceAmount3 ??
                                                    s?.manualAdjustment
                                                        ?.priceAmount3 ??
                                                    s?.bigPlausibilityPriceAmount3 ??
                                                    s?.smallPlausibilityPriceAmount3 ??
                                                    s?.calculatedPriceAmount3 ??
                                                    ""
                                                }
                                                className={inputStyle.text}
                                                placeholder={
                                                    s?.manualAdjustment
                                                        ?.priceAmount3 ??
                                                    s?.bigPlausibilityPriceAmount3 ??
                                                    s?.smallPlausibilityPriceAmount3 ??
                                                    s?.calculatedPriceAmount3
                                                }
                                                onBlur={() => setEditRow(null)}
                                                onChange={(evt) =>
                                                    setChanges({
                                                        ...changes,
                                                        [s?.id]: {
                                                            manualPrice:
                                                                changes[s?.id]
                                                                    ?.manualPrice ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount1,
                                                            manualPriceAmount2:
                                                                changes[s?.id]
                                                                    ?.manualPriceAmount2 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.priceAmount2,
                                                            manualPriceAmount3:
                                                                clampOrEmpty(
                                                                    evt.target.value.trim(),
                                                                    0,
                                                                    99999.99,
                                                                ),
                                                            bulkAmount1:
                                                                changes[s?.id]
                                                                    ?.bulkAmount1 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount1,
                                                            bulkAmount2:
                                                                changes[s?.id]
                                                                    ?.bulkAmount2 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount2,
                                                            bulkAmount3:
                                                                changes[s?.id]
                                                                    ?.bulkAmount3 ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.bulkAmount3,
                                                            ignorePlausiCheck:
                                                                changes[s?.id]
                                                                    ?.ignorePlausiCheck ??
                                                                s
                                                                    ?.manualAdjustment
                                                                    ?.ignorePlausiCheck,
                                                        },
                                                    })
                                                }
                                            />
                                        ) : (
                                            currencyFormat.format(
                                                changes[s?.id]
                                                    ?.manualPriceAmount3 ??
                                                    s?.manualAdjustment
                                                        ?.priceAmount3 ??
                                                    s?.bigPlausibilityPriceAmount3 ??
                                                    s?.smallPlausibilityPriceAmount3 ??
                                                    s?.calculatedPriceAmount3,
                                            )
                                        )}
                                    </p>
                                    <EnumLabel
                                        className={cn(tableStyle.cell)}
                                        label={s?.usedStrategyParams}
                                    />
                                    <p className={tableStyle.fullWidthCell}>
                                        {currencyFormat.format(s?.agrosso1)}
                                    </p>
                                    <p className={tableStyle.fullWidthCell}>
                                        {currencyFormat.format(s?.agrosso2)}
                                    </p>
                                    <p className={tableStyle.fullWidthCell}>
                                        {currencyFormat.format(
                                            s?.exportAep ?? s?.AEP,
                                        )}
                                    </p>
                                    <p
                                        className={cn(
                                            tableStyle.fullWidthCell,
                                            tableStyle.lastCell,
                                        )}
                                    >
                                        {currencyFormat.format(s?.GEP)}
                                    </p>
                                    <PricingListResultExpander
                                        isShown={
                                            expanderProduct &&
                                            expanderProduct === s?.id
                                        }
                                        close={() => setExpanderProduct(null)}
                                        pricingListResult={s}
                                    />
                                </Fragment>
                            ),
                        )}
                    </div>
                    <Pagination
                        onPageChange={(page) => setPage(page)}
                        pagination={
                            data?.plausibilityCheck?.sortablePlausibilityResults
                        }
                        loading={
                            loading &&
                            !data?.plausibilityCheck
                                ?.sortablePlausibilityResults
                        }
                    />
                </div>
            </Impact>
        </div>
    )
}
