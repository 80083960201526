import { useSelector } from "react-redux"
import { createPortal } from "react-dom"
import { ProductViewer } from "./ProductViewer"

export const ModalManager = () => {
    const product = useSelector((s) => s.modal.product)

    if (product) {
        return createPortal(
            <ProductViewer product_id={product} />,
            document.getElementById("expanded"),
        )
    }

    return null
}
