import { Banner } from "../../util/Banner"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import style from "./CreatePricingCycle.module.css"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import { Loader } from "../../util/Loader"
import { Toggle } from "../../util/input/Toggle"
import { showError } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"
import inputStyle from "../../../style/input.module.css"
import { uniq, uniqueId } from "lodash"
import tableStyle from "../../../style/table.module.css"
import { useHistory } from "react-router-dom"

const STEP_1 = "STEP_1"
const STEP_2 = "STEP_2"
const STEP_3 = "STEP_3"

const GET_ASSORTMENTS = gql`
    query {
        assortments(
            limit: -1
            filterParams: {
                systemStatusEquals: "ACTIVE"
                excludeFromCycleEquals: false
            }
        ) {
            page {
                id
                name
            }
        }
    }
`

const GET_PRICING_LISTS = gql`
    query ($ids: [UUID]) {
        assortments(
            limit: -1
            ids: $ids
            filterParams: { systemStatusEquals: "ACTIVE" }
        ) {
            page {
                id
                name
                pricingLists(limit: -1) {
                    page {
                        id
                        name: displayName
                    }
                }
            }
        }
    }
`

const RESTART_CYCLE = gql`
    mutation restartPricingCycle(
        $assortmentIds: [UUID]
        $pricingListIds: [UUID]
        $monthlyPriceListEndDate: Date!
        $priceListStartDate: Date!
        $bimonthlyPriceListEndDate: Date!
        $copyStrategies: Boolean!
        $copyFixPrices: Boolean!
    ) {
        restartPricingCycle(
            assortmentIds: $assortmentIds
            pricingListIds: $pricingListIds
            priceListStartDate: $priceListStartDate
            monthlyPriceListEndDate: $monthlyPriceListEndDate
            bimonthlyPriceListEndDate: $bimonthlyPriceListEndDate
            copyStrategies: $copyStrategies
            copyFixPrices: $copyFixPrices
        )
    }
`

const SelectAssortments = ({ onAssortmentsSelected, selected }) => {
    const { data, loading } = useQuery(GET_ASSORTMENTS)

    const { t } = useTranslation(["table", "common"])

    const [assortments, setAssortments] = useState([])

    useEffect(() => {
        setAssortments(selected)
    }, [selected])

    useEffect(() => {
        if (!selected?.length && data?.assortments?.page.length) {
            setAssortments(data?.assortments?.page.map((a) => a.id))
        }
    }, [selected, data?.assortments])

    if (loading) {
        return <Loader />
    }

    const onToggle = (id) => {
        if (assortments.includes(id)) {
            setAssortments(assortments.filter((as) => id !== as))
        } else {
            setAssortments([...assortments, id])
        }
    }

    return (
        <>
            <h1>{t("table:select_assortments_to_transfer")}</h1>
            <div className={style.selectList}>
                {data?.assortments?.page.map((a) => (
                    <div
                        className={style.item}
                        key={a.id}
                        onClick={() => onToggle(a.id)}
                    >
                        <p>{a.name}</p>
                        <Toggle
                            isChecked={assortments.includes(a.id)}
                            setIsChecked={() => onToggle(a.id)}
                        />
                    </div>
                ))}
            </div>
            <div className={style.buttonBar}>
                <button
                    className={buttonStyle.button}
                    disabled={!assortments.length}
                    onClick={() => onAssortmentsSelected(assortments)}
                >
                    {c(t("common:next"))}
                </button>
            </div>
        </>
    )
}

const SelectPricingLists = ({
    assortments,
    onSelectPricingLists,
    back,
    selected,
}) => {
    const { data, loading } = useQuery(GET_PRICING_LISTS, {
        variables: { ids: assortments },
    })

    const { t } = useTranslation(["table", "common"])

    const [pricingLists, setPricingLists] = useState([])

    useEffect(() => {
        setPricingLists(selected)
    }, [selected])

    useEffect(() => {
        if (!selected?.length && data?.assortments?.page.length) {
            setPricingLists(
                data?.assortments?.page
                    .map((a) => a?.pricingLists?.page.map((p) => p.id))
                    .flat(),
            )
        }
    }, [selected, data?.assortments])

    const onToggle = (id) => {
        if (pricingLists.includes(id)) {
            setPricingLists(pricingLists.filter((pl) => id !== pl))
        } else {
            setPricingLists([...pricingLists, id])
        }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <h1>{t("table:select_pricing_lists_to_transfer")}</h1>

            <div>
                {data?.assortments?.page.map((a) => (
                    <div className={style.level2selectList} key={a.id}>
                        <div className={style.titleCheck}>
                            <h3>{a.name}</h3>
                            <Toggle
                                isChecked={a?.pricingLists?.page.every((p) =>
                                    pricingLists.includes(p.id),
                                )}
                                setIsChecked={(c) => {
                                    const ids = a?.pricingLists?.page.map(
                                        (p) => p.id,
                                    )
                                    if (c) {
                                        setPricingLists(
                                            uniq([...pricingLists, ...ids]),
                                        )
                                    } else {
                                        setPricingLists(
                                            pricingLists.filter(
                                                (pl) => !ids.includes(pl),
                                            ),
                                        )
                                    }
                                }}
                            />
                        </div>
                        <div className={style.selectList}>
                            {a?.pricingLists?.page.map((p) => (
                                <div
                                    className={style.item}
                                    key={p.id}
                                    onClick={() => onToggle(p.id)}
                                >
                                    <p>{p.name}</p>
                                    <Toggle
                                        isChecked={pricingLists.includes(p.id)}
                                        setIsChecked={() => onToggle(p.id)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <div className={style.buttonBar}>
                <button className={buttonStyle.button} onClick={() => back()}>
                    {c(t("common:back"))}
                </button>
                <button
                    className={buttonStyle.button}
                    onClick={() => onSelectPricingLists(pricingLists)}
                >
                    {c(t("common:next"))}
                </button>
            </div>
        </>
    )
}

const FinalSettings = ({ back, onDone }) => {
    const { t } = useTranslation(["table", "common"])

    const [monthlyPriceListEndDate, setMonthlyPriceListEndDate] = useState("")
    const [priceListStartDate, setPriceListStartDate] = useState("")
    const [bimonthlyPriceListEndDate, setBimonthlyPriceListEndDate] =
        useState("")

    const [copyStrategies, setCopyStrategies] = useState(false)
    const [copyFixPrices, setCopyFixPrices] = useState(false)

    const [monthly_price_start_id] = useState(() =>
        uniqueId("monthly_price_start_"),
    )
    const [monthly_price_end_id] = useState(() =>
        uniqueId("monthly_price_end_"),
    )
    const [bi_monthly_price_end_id] = useState(() =>
        uniqueId("bi_monthly_price_end_"),
    )

    const [copy_strategy_button_id] = useState(() =>
        uniqueId("copy_strategy_button_"),
    )
    const [revert_to_default_button_id] = useState(() =>
        uniqueId("revert_to_default_button_"),
    )

    useEffect(() => {
        const today = new Date()
        const nextMonth = new Date(
            Date.UTC(today.getFullYear(), today.getMonth() + 1, 1),
        )
        const startOfMonth = new Date(
            Date.UTC(nextMonth.getFullYear(), nextMonth.getMonth(), 1),
        )
            .toISOString()
            .substring(0, 10)
        const midOfMonth = new Date(
            Date.UTC(nextMonth.getFullYear(), nextMonth.getMonth(), 15),
        )
            .toISOString()
            .substring(0, 10)
        const endOfMonth = new Date(
            Date.UTC(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0),
        )
            .toISOString()
            .substring(0, 10)

        setBimonthlyPriceListEndDate(midOfMonth)
        setMonthlyPriceListEndDate(endOfMonth)
        setPriceListStartDate(startOfMonth)
    }, [])

    return (
        <div>
            <div className={style.settingBox}>
                <div className={tableStyle.filterInnerSection}>
                    <label
                        htmlFor={monthly_price_start_id}
                        className={tableStyle.label}
                    >
                        {t("table:start_date")}
                    </label>
                    <input
                        id={monthly_price_start_id}
                        className={inputStyle.text}
                        type="date"
                        value={priceListStartDate}
                        onChange={(evt) =>
                            setPriceListStartDate(evt.target.value)
                        }
                    />
                </div>
                <div className={tableStyle.filterInnerSection}>
                    <label
                        htmlFor={monthly_price_end_id}
                        className={tableStyle.label}
                    >
                        {t("table:monthly_end_date")}
                    </label>
                    <input
                        id={monthly_price_end_id}
                        className={inputStyle.text}
                        type="date"
                        value={monthlyPriceListEndDate}
                        onChange={(evt) =>
                            setMonthlyPriceListEndDate(evt.target.value)
                        }
                    />
                </div>
                <div className={tableStyle.filterInnerSection}>
                    <label
                        htmlFor={bi_monthly_price_end_id}
                        className={tableStyle.label}
                    >
                        {t("table:bi_monthly_end_date")}
                    </label>
                    <input
                        id={bi_monthly_price_end_id}
                        className={inputStyle.text}
                        type="date"
                        value={bimonthlyPriceListEndDate}
                        onChange={(evt) =>
                            setBimonthlyPriceListEndDate(evt.target.value)
                        }
                    />
                </div>
            </div>

            <div className={style.settingBox}>
                <label>{t("table:transfer_price_overrides")}</label>
                <Toggle
                    isChecked={copyFixPrices}
                    setIsChecked={(c) => setCopyFixPrices(c)}
                />
            </div>

            <div className={style.settingBox}>
                <input
                    type="checkbox"
                    id={copy_strategy_button_id}
                    className={inputStyle.hidden}
                    onChange={() => setCopyStrategies(true)}
                    checked={copyStrategies}
                />
                <label
                    htmlFor={copy_strategy_button_id}
                    className={buttonStyle.selectButton}
                >
                    {t("table:copy_strategies")}
                </label>
                <input
                    type="checkbox"
                    id={revert_to_default_button_id}
                    className={inputStyle.hidden}
                    onChange={() => setCopyStrategies(false)}
                    checked={!copyStrategies}
                />
                <label
                    htmlFor={revert_to_default_button_id}
                    className={buttonStyle.selectButton}
                >
                    {t("table:revert_strategies")}
                </label>
            </div>

            <div className={style.buttonBar}>
                <button className={buttonStyle.button} onClick={() => back()}>
                    {c(t("common:back"))}
                </button>
                <button
                    disabled={
                        !monthlyPriceListEndDate ||
                        !priceListStartDate ||
                        !bimonthlyPriceListEndDate
                    }
                    className={buttonStyle.button}
                    onClick={() =>
                        onDone(
                            monthlyPriceListEndDate,
                            priceListStartDate,
                            bimonthlyPriceListEndDate,
                            copyStrategies,
                            copyFixPrices,
                        )
                    }
                >
                    {c(t("common:finish"))}
                </button>
            </div>
        </div>
    )
}

export const CreatePricingCycle = () => {
    const { t } = useTranslation(["table", "page_names"])

    const [restartCycle, { loading }] = useMutation(RESTART_CYCLE)

    const [step, setStep] = useState(STEP_1)
    const [assortments, setAssortments] = useState([])
    const [priceLists, setPriceLists] = useState([])

    const history = useHistory()
    const dispatch = useDispatch()

    if (loading) {
        return <Loader />
    }

    return (
        <>
            <Banner text={t("page_names:new_pricing_cycle")} />
            <div className={style.wrapper}>
                {step === STEP_1 ? (
                    <SelectAssortments
                        selected={assortments}
                        onAssortmentsSelected={(a) => {
                            setAssortments(a)
                            setStep(STEP_2)
                        }}
                    />
                ) : null}
                {step === STEP_2 ? (
                    <SelectPricingLists
                        onSelectPricingLists={(p) => {
                            setPriceLists(p)
                            setStep(STEP_3)
                        }}
                        back={() => setStep(STEP_1)}
                        assortments={assortments}
                        selected={priceLists}
                    />
                ) : null}
                {step === STEP_3 ? (
                    <FinalSettings
                        back={() => setStep(STEP_2)}
                        onDone={(
                            monthlyPriceListEndDate,
                            priceListStartDate,
                            bimonthlyPriceListEndDate,
                            copyStrategies,
                            copyFixPrices,
                        ) =>
                            restartCycle({
                                variables: {
                                    assortmentIds: assortments,
                                    pricingListIds: priceLists,
                                    monthlyPriceListEndDate,
                                    priceListStartDate,
                                    bimonthlyPriceListEndDate,
                                    copyStrategies,
                                    copyFixPrices,
                                },
                            })
                                .then(() => history.push("/"))
                                .catch((e) => {
                                    dispatch(showError(e.message))
                                })
                        }
                    />
                ) : null}
            </div>
        </>
    )
}
