import {
    DISMISS_NOTIFICATION,
    ERROR,
    INFO,
    SHOW_NOTIFICATION,
    WARNING,
} from "../reducers/NotificationReducer"

/**
 * Builds the action that hides the top notification
 * @returns {{type: string}} the action hides the top modal
 */
export const dismissNotification = () => {
    return {
        type: DISMISS_NOTIFICATION,
    }
}

/**
 * Builds the action that shows an information notifications
 * @param message the message the info modal displays
 * @param list a list that can be displayed under the info modal message
 * @returns {{notification: {type: string, message, list}, type: string}} the action that shows the info modal
 */
export const showInfo = (message, list) => {
    return {
        type: SHOW_NOTIFICATION,
        notification: {
            type: INFO,
            message,
            list,
        },
    }
}

/**
 * Builds the action that shows an error notifications
 * @param message the message the error modal displays
 * @param list a list that can be displayed under the error modal message
 * @returns {{notification: {type: string, message, list}, type: string}} the action that shows the error modal
 */
export const showError = (message, list) => {
    return {
        type: SHOW_NOTIFICATION,
        notification: {
            type: ERROR,
            message,
            list,
        },
    }
}

/**
 * Builds the action that shows a warning notifications
 * @param message the message the warning modal displays
 * @param list a list that can be displayed under the warning modal message
 * @returns {{notification: {type: string, message, list}, type: string}} the action that shows the warning modal
 */
export const showWarning = (message, list) => {
    return {
        type: SHOW_NOTIFICATION,
        notification: {
            type: WARNING,
            message,
            list,
        },
    }
}
