import { combineReducers } from "redux"
import { AuthReducer } from "./AuthReducer"
import { NotificationReducer } from "./NotificationReducer"
import { ModalReducer } from "./ModalReducer"

/**
 * Root reducer that combines all the reducers
 */
export default combineReducers({
    isAuthShown: AuthReducer,
    notification: NotificationReducer,
    modal: ModalReducer,
})
