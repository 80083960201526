import { Banner } from "../../util/Banner"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Loader } from "../../util/Loader"
import style from "./PlausiCreator.module.css"
import buttonStyle from "../../../style/button.module.css"
import inputStyles from "../../../style/input.module.css"
import { useEffect, useState } from "react"
import cn from "classnames"
import inputStyle from "../../../style/input.module.css"
import styles from "../../util/modals/Notification.module.css"
import c from "capitalize"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { showError } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { uniqueId } from "lodash"

const PRICING_LIST_TYPES = gql`
    query {
        pricingListTypes(
            limit: -1
            filterParams: { hideInCreateEquals: false, disabledEquals: false }
        ) {
            page {
                id
                name
                displayName
                pricingListTypeNumber
                assortment {
                    name
                    id
                }
                dependsOn {
                    id
                }
                pricingLists(limit: -1) {
                    page {
                        name: displayName
                        id
                        assortment {
                            id
                            systemStatus
                        }
                        chosenCalculation {
                            id
                            canBeUsedForPlausi
                        }
                    }
                }
            }
        }
        me {
            name
            id
        }
    }
`

const TRIGGER_PLAUSI = gql`
    mutation (
        $ids: [UUID]
        $name: String!
        $updateIfa: Boolean
        $isEndOfMonth: Boolean
        $isCorrection: Boolean
        $runPlausi: Boolean
    ) {
        triggerPlausiRun(
            name: $name
            calculationRuns: $ids
            updateIfa: $updateIfa
            isEndOfMonth: $isEndOfMonth
            isCorrection: $isCorrection
            runPlausi: $runPlausi
        ) {
            id
        }
    }
`

export const PlausiCreatorFullscreen = ({
    updateIfa = false,
    runPlausi = true,
}) => {
    const { t } = useTranslation(["common", "table"])

    return (
        <div>
            <Banner
                text={t(
                    runPlausi
                        ? updateIfa
                            ? "common:ifa_changes"
                            : "table:create_plausibility_check"
                        : "table:new_correction_list",
                )}
            />
            <PlausiCreator updateIfa={updateIfa} runPlausi={runPlausi} />
        </div>
    )
}

export const PlausiCreator = ({ updateIfa = false, runPlausi = true }) => {
    const { data, loading } = useQuery(PRICING_LIST_TYPES)

    const { t, i18n } = useTranslation(["common", "table"])
    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    const [end_of_month_button_id] = useState(() =>
        uniqueId("end_of_month_button_"),
    )
    const [mid_of_month_button_id] = useState(() =>
        uniqueId("mid_of_month_button_id"),
    )

    const [isEndOfMonth, setIsEndOfMonth] = useState(true)

    const [calculations, setCalculations] = useState({})
    const [name, setName] = useState(
        `${c(
            t(updateIfa ? "common:ifa_changes" : "table:plausibility_check"),
        )} ${dateFormat.format(new Date())} [${data?.me?.name || ""}]`,
    )
    const [required, setRequired] = useState({})

    const [triggerPlausi, { loading: triggerPlausiLoading }] =
        useMutation(TRIGGER_PLAUSI)

    useEffect(() => {
        setName(
            `${c(
                t(
                    runPlausi
                        ? updateIfa
                            ? "common:ifa_changes"
                            : "table:plausibility_check"
                        : "table:new_correction_list",
                ),
            )} ${dateFormat.format(new Date())} [${data?.me?.name || ""}]`,
        )
    }, [updateIfa, data?.me?.name, runPlausi])

    const dispatch = useDispatch()
    const history = useHistory()

    if (loading || triggerPlausiLoading) {
        return <Loader />
    }

    return (
        <div className={style.wrapper}>
            <div className={style.inputBar}>
                {runPlausi ? (
                    <input
                        value={name}
                        onChange={(evt) => setName(evt.target.value)}
                        placeholder={`${c(
                            t(
                                updateIfa
                                    ? "common:ifa_changes"
                                    : "table:plausibility_check",
                            ),
                        )} ${dateFormat.format(new Date())}`}
                        className={cn(inputStyle.text, styles.input)}
                    />
                ) : null}

                <div className={style.buttonBar}>
                    {updateIfa ? (
                        <>
                            <input
                                type="radio"
                                id={end_of_month_button_id}
                                className={inputStyle.hidden}
                                onChange={() => setIsEndOfMonth(true)}
                                checked={isEndOfMonth}
                            />
                            <label
                                htmlFor={end_of_month_button_id}
                                className={buttonStyle.selectButton}
                            >
                                {t("table:use_end_of_month")}
                            </label>

                            <input
                                type="radio"
                                id={mid_of_month_button_id}
                                className={inputStyle.hidden}
                                onChange={() => setIsEndOfMonth(false)}
                                checked={!isEndOfMonth}
                            />
                            <label
                                htmlFor={mid_of_month_button_id}
                                className={buttonStyle.selectButton}
                            >
                                {t("table:use_mid_of_month")}
                            </label>
                        </>
                    ) : null}
                </div>
            </div>
            <div className={style.choiceGrid}>
                {data?.pricingListTypes?.page?.map((pt) => (
                    <PriceListSelector
                        key={pt.id}
                        pricingListType={pt}
                        calculation={calculations[pt.id]}
                        onSetCalculation={(c) => {
                            const localCalculations =
                                structuredClone(calculations)
                            localCalculations[pt.id] = c
                            setCalculations(localCalculations)
                        }}
                        highlight={required[pt.id] > 0}
                        onSetRequirements={(dependIds, add) => {
                            if (!dependIds) {
                                return
                            }
                            const localRequired = structuredClone(required)
                            for (const dependId of dependIds) {
                                if (add) {
                                    if (localRequired[dependId] > 0) {
                                        localRequired[dependId] += 1
                                    } else {
                                        localRequired[dependId] = 1
                                    }
                                } else {
                                    if (localRequired[dependId] > 0) {
                                        localRequired[dependId] -= 1
                                    } else {
                                        localRequired[dependId] = 0
                                    }
                                }
                            }
                            setRequired(localRequired)
                        }}
                    />
                ))}
            </div>
            <button
                className={cn(buttonStyle.button, buttonStyle.bigButton)}
                disabled={
                    !Object.values(calculations).filter(Boolean).length ||
                    !name ||
                    Object.entries(required)
                        .filter(([_, v]) => v > 0)
                        .some(([k]) => !Object.keys(calculations).includes(k))
                }
                onClick={() =>
                    triggerPlausi({
                        variables: {
                            name,
                            ids: Object.values(calculations),
                            updateIfa,
                            isEndOfMonth,
                            isCorrection: !runPlausi,
                            runPlausi,
                        },
                    })
                        .then(() => {
                            history.push("/plausibility_checks")
                        })
                        .catch((e) => dispatch(showError(e.message)))
                }
            >
                {c(t("common:start"))}
            </button>
        </div>
    )
}

const PriceListSelector = ({
    pricingListType,
    calculation,
    onSetCalculation,
    highlight,
    onSetRequirements,
}) => {
    const { t } = useTranslation(["common", "table"])

    if (!pricingListType) {
        return null
    }

    if (
        !pricingListType?.pricingLists?.page?.filter(
            (pl) =>
                pl.chosenCalculation?.id &&
                pl.chosenCalculation?.canBeUsedForPlausi,
        )?.length
    ) {
        if (highlight) {
            return (
                <div
                    style={
                        calculation
                            ? { border: "0.125em solid var(--green)" }
                            : highlight
                            ? { border: "0.125em solid var(--red)" }
                            : null
                    }
                >
                    <h3>
                        {pricingListType?.displayName} (
                        {pricingListType?.pricingListTypeNumber})
                    </h3>
                    <p>{t("table:required_but_not_available")}</p>
                </div>
            )
        }
        return null
    }

    return (
        <div
            style={
                calculation
                    ? { border: "0.125em solid var(--green)" }
                    : highlight
                    ? { border: "0.125em solid var(--red)" }
                    : null
            }
        >
            <h3>{pricingListType?.name}</h3>
            <select
                className={inputStyles.select}
                value={calculation || ""}
                onChange={(evt) => {
                    if (evt.target.value) {
                        onSetRequirements(
                            pricingListType?.dependsOn?.map((d) => d.id),
                            true,
                        )
                        onSetCalculation(evt.target.value)
                    } else {
                        onSetRequirements(
                            pricingListType?.dependsOn?.map((d) => d.id),
                            false,
                        )
                        onSetCalculation(null)
                    }
                }}
            >
                <option value="" />
                {pricingListType?.pricingLists?.page
                    .filter(
                        (d) =>
                            d.chosenCalculation?.id &&
                            d.chosenCalculation?.canBeUsedForPlausi,
                    )
                    .map((d, i) => (
                        <option key={i} value={d.chosenCalculation?.id}>
                            {d.name}
                        </option>
                    ))}
            </select>
        </div>
    )
}
