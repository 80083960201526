import { useTranslation } from "react-i18next"

export const AssortmentGroupNameDisplay = ({ row }) => {
    const { t } = useTranslation("table")

    if (row?.isSystemGroup && row?.priority === -1) {
        return t("prio_base_group")
    } else if (
        row?.isSystemGroup &&
        row?.priority === 0 &&
        row.action === "ENLIST"
    ) {
        return t("prio_zero_group_enlist")
    } else if (
        row?.isSystemGroup &&
        row?.priority === 2 &&
        row.action === "ENLIST"
    ) {
        return t("prio_two_group_enlist")
    } else if (
        row?.isSystemGroup &&
        row?.priority === 0 &&
        row.action === "DELIST"
    ) {
        return t("prio_zero_group_delist")
    } else if (
        row?.isSystemGroup &&
        row?.priority === 2 &&
        row.action === "DELIST"
    ) {
        return t("prio_two_group_delist")
    } else {
        return row?.name
    }
}
