import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import cn from "classnames"
import { SmartFilter } from "../../util/input/SmartFilter"
import {
    getCurrencyFormatter,
    getIntegerFormatter,
    getSafeFormatter,
} from "../../../i18n"
import { ProductDetailExpander } from "../../util/ProductDetailExpander"

const GET_PRICING_LIST_GROUP = gql`
    query (
        $pageNum: Int!
        $query: String
        $order: [ProductSearchOrder]
        $filter: ProductSearchFilter
    ) {
        productSearch(
            pageNum: $pageNum
            orderBy: $order
            filterParams: $filter
            query: $query
            limit: 20
        ) {
            query
            suggestedQuery
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                name
                pzn
                unit
                atc
                quantity
                prescriptionDrug
                pharmacyOnly
                supplierName
                AEP
                GEP
                agrosso1
                agrosso2
                agrosso1Midas
                agrosso2Midas
                agrosso1Mvda
                agrosso2Mvda
                agrosso1Generic
                agrosso2Generic
                revenueGrossoTotal
                revenueGrossoWholesale
                revenueGrossoPhoenix
                revenueGrossoDirect
            }
        }
    }
`

const FILTER_QUERY = gql`
    query getFilter {
        productSearch(limit: 0) {
            filterFields {
                name
                dataType
                displayName
                options
            }
        }
    }
`

export const ProductList = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [filter, setFilter] = useState(null)
    const [query, setQuery] = useState("")
    const [expanderProduct, setExpanderProduct] = useState(null)

    const { t, i18n } = useTranslation(["common", "table"])

    const currencyFormat = getSafeFormatter(getCurrencyFormatter(i18n.language))
    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    const { data, loading } = useQuery(GET_PRICING_LIST_GROUP, {
        variables: {
            pageNum,
            order,
            query,
            filter,
        },
    })

    return (
        <div>
            <Banner text={t("table:products")} />
            <div className={dropDownStyle.container}>
                <SmartFilter
                    filterFieldQuery={FILTER_QUERY}
                    filterPath={(q) => q?.productSearch?.filterFields}
                    setQuery={setQuery}
                    setFilter={setFilter}
                    suggestedQuery={data?.productSearch?.suggestedQuery}
                />
            </div>
            {!loading && !data?.productSearch ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}

            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "1fr 2fr 2fr repeat(15, 1fr)",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pzn")}
                    asc="PznAsc"
                    desc="PznDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:product_name")}
                    asc="NameAsc"
                    desc="NameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:supplier_name")}
                    asc="SupplierNameAsc"
                    desc="SupplierNameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:atc")}
                    asc="AtcAsc"
                    desc="AtcDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:unit")}
                    asc="UnitAsc"
                    desc="UnitDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:quantity")}
                    asc="QuantityAsc"
                    desc="QuantityDesc"
                    order={order}
                    setOrder={setOrder}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:aep")}
                    asc="IfaMetricAEPAsc"
                    desc="IfaMetricAEPDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:gep")}
                    asc="IfaMetricGEPAsc"
                    desc="IfaMetricGEPDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:agrosso") + " 1  Generic"}
                    asc="AgrossoMetricAgrosso1_Asc"
                    desc="AgrossoMetricAgrosso1_Desc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:agrosso") + " 2  Generic"}
                    asc="AgrossoMetricAgrosso2_Asc"
                    desc="AgrossoMetricAgrosso2_Desc"
                    order={order}
                    setOrder={setOrder}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:agrosso") + " 1  MVDA"}
                    asc="AgrossoMetricAgrosso1_Asc"
                    desc="AgrossoMetricAgrosso1_Desc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:agrosso") + " 2  MVDA"}
                    asc="AgrossoMetricAgrosso2_Asc"
                    desc="AgrossoMetricAgrosso2_Desc"
                    order={order}
                    setOrder={setOrder}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:agrosso") + " 1  MIDAS"}
                    asc="AgrossoMetricAgrosso1_Asc"
                    desc="AgrossoMetricAgrosso1_Desc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:agrosso") + " 2  MIDAS"}
                    asc="AgrossoMetricAgrosso2_Asc"
                    desc="AgrossoMetricAgrosso2_Desc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:revenue_grosso_total")}
                    asc="PerformanceMetricRevenueGrossoTotalAsc"
                    desc="PerformanceMetricRevenueGrossoTotalDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:revenue_grosso_wholesale")}
                    asc="PerformanceMetricRevenueGrossoWholesaleAsc"
                    desc="PerformanceMetricRevenueGrossoWholesaleDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:revenue_grosso_phoenix")}
                    asc="PerformanceMetricRevenueGrossoPhoenixAsc"
                    desc="PerformanceMetricRevenueGrossoPhoenixDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:revenue_grosso_direct")}
                    asc="PerformanceMetricRevenueGrossoDirectAsc"
                    desc="PerformanceMetricRevenueGrossoDirectDesc"
                    order={order}
                    setOrder={setOrder}
                />

                {loading && !data?.pricingListGroup && (
                    <TableContentLoader columns={18} />
                )}
                {data?.productSearch?.page.map((s, i) => (
                    <Fragment key={i}>
                        <p
                            className={tableStyle.cell}
                            onClick={() => setExpanderProduct(s?.id)}
                        >
                            {s?.pzn}
                        </p>
                        <p
                            className={tableStyle.cell}
                            onClick={() => setExpanderProduct(s?.id)}
                        >
                            {s?.name}
                        </p>
                        <p className={tableStyle.cell}>{s?.supplierName}</p>
                        <p className={tableStyle.cell}>{s?.atc}</p>
                        <p className={tableStyle.cell}>{s?.unit}</p>
                        <p className={tableStyle.cell}>
                            {integerFormatter.format(s?.quantity)}
                        </p>

                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.AEP)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.GEP)}
                        </p>

                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.agrosso1Generic)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.agrosso2Generic)}
                        </p>

                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.agrosso1Mvda)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.agrosso2Mvda)}
                        </p>

                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.agrosso1Midas)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.agrosso2Midas)}
                        </p>

                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.revenueGrossoTotal)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.revenueGrossoWholesale)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(s?.revenueGrossoPhoenix)}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {currencyFormat.format(s?.revenueGrossoDirect)}
                        </p>
                        <ProductDetailExpander
                            isShown={
                                expanderProduct && expanderProduct === s?.id
                            }
                            close={() => setExpanderProduct(null)}
                            product={s}
                        />
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.productSearch}
                loading={loading && !data?.productSearch}
            />
        </div>
    )
}
