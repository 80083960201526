import { Modal } from "./Modal"
import modalStyle from "./Modal.module.css"
import style from "./Sidebar.module.css"
import buttonStyle from "../../../style/button.module.css"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { Placeholder } from "../placeholder/Placeholder"
import ContentLoader from "react-content-loader"
import { Link } from "react-router-dom"
import { hideModals } from "../../../redux/actions/ModalActions"
import { useDispatch } from "react-redux"

/**
 * Query to get the product competitor prices
 *
 * @type {DocumentNode}
 */
const PRODUCT_QUERY = gql`
    query ($id: UUID!) {
        product(id: $id) {
            name
            id
            pzn
            competitorPrices(limit: -1) {
                page {
                    id
                    price
                    wholesaleName
                    competitorDisplayName
                    bulkPrice1
                    bulkAmount1
                    bulkPrice2
                    bulkAmount2
                    bulkPrice3
                    bulkAmount3
                }
            }
        }
    }
`

/**
 * This element is a placeholder for the product
 *
 * @returns {JSX.Element} the placeholder element
 * @constructor
 */
const ProductInfoPlaceholder = () => (
    <ContentLoader
        viewBox="0 0 100 500"
        color={"black"}
        className={style.priceViewerPlaceHolder}
    >
        {[...Array(50)].map((_, i) => (
            <rect key={i} x="5" y={i * 20 + 15} width="90" height="10" />
        ))}
    </ContentLoader>
)

/**
 * This element is a sidebar that displays the details of a product.
 *
 * @param product_id the product the detail should be shown for
 * @returns {JSX.Element|null} the Sidebar if the product is supplied, null otherwise
 * @constructor
 */
export const ProductViewer = ({ product_id }) => {
    const { data, loading } = useQuery(PRODUCT_QUERY, {
        variables: {
            id: product_id,
        },
        skip: !product_id,
    })

    const { t } = useTranslation(["common", "table"])

    const dispatch = useDispatch()

    if (!product_id) {
        return null
    }

    return (
        <Modal className={modalStyle.modal}>
            <Placeholder
                ready={!loading && data?.product}
                placeholder={<ProductInfoPlaceholder />}
            >
                <h1>{data?.product?.pzn}</h1>
                <h2>{data?.product?.name}</h2>

                <Link
                    to={`/product/${product_id}`}
                    onClick={() => dispatch(hideModals())}
                    className={buttonStyle.button}
                >
                    {t("table:show_details")}
                </Link>

                <h1 className={style.competitors}>{t("table:competitors")}</h1>

                {data?.product?.competitorPrices?.page?.map((p) => (
                    <div key={p?.id}>
                        <h3>
                            {p?.competitorDisplayName}: {p?.wholesaleName}
                        </h3>
                        <h4>
                            {t("table:price")}: {p.price}
                        </h4>
                        <div className={style.bulkTable}>
                            <p className={style.bulkTableHeader}>
                                {t("table:bulk")}
                            </p>
                            <p className={style.bulkTableHeader}>1</p>
                            <p className={style.bulkTableHeader}>2</p>
                            <p className={style.bulkTableHeader}>3</p>

                            <p className={style.bulkTableHeader}>
                                {t("table:amount")}
                            </p>
                            <p>{p?.bulkAmount1}</p>
                            <p>{p?.bulkAmount2}</p>
                            <p>{p?.bulkAmount3}</p>

                            <p className={style.bulkTableHeader}>
                                {t("table:price")}
                            </p>
                            <p>{p?.bulkPrice1}</p>
                            <p>{p?.bulkPrice2}</p>
                            <p>{p?.bulkPrice3}</p>
                        </div>
                    </div>
                ))}
                {data?.product?.competitorPrices?.page?.length ? null : (
                    <h3>{t("table:no_competitor_prices")}</h3>
                )}
            </Placeholder>
        </Modal>
    )
}
