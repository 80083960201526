import React, { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import cn from "classnames"
import { EnumLabel } from "../../util/table/EnumLabel"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { Link } from "react-router-dom"

const GET_PRICING_LIST_GROUP = gql`
    query ($pageNum: Int!, $order: [RunInfoAllOrder]) {
        runInfos(pageNum: $pageNum, orderBy: $order, limit: 20) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                createdAt
                name
                type
                pricingListName
                pricingListId
                runOrPlausiId
                message
                status
                startTime
                endTime
            }
        }
    }
`

const getLinkPart = (type) => {
    switch (type) {
        case "CALCULATION":
            return "pricing_list"
        case "MARKETING":
            return "marketing"
        case "ALLOCATION":
            return "allocation"
    }
}

export const RunInfoList = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState(["CreatedAtDesc"])

    const { t, i18n } = useTranslation(["common", "table"])

    const { data, loading } = useQuery(GET_PRICING_LIST_GROUP, {
        variables: {
            pageNum,
            order,
        }
    })

    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    return (
        <div>
            <Banner text={t("table:run_info")} />
            {!loading && !data?.runInfos ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}

            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns:
                        "2.5fr repeat(3, 1fr) 3fr repeat(3, 1fr)",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:name")}
                    asc="NameAsc"
                    desc="NameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:type")}
                    asc="TypeAsc"
                    desc="TypeDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:status")}
                    asc="StatusAsc"
                    desc="StatusDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pricing_list_name")}
                    asc="PricingListNameAsc"
                    desc="PricingListNameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:message")}
                    asc="MessageAsc"
                    desc="MessageDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                    asc="CreatedAtAsc"
                    desc="CreatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:start_datetime")}
                    asc="StartTimeAsc"
                    desc="StartTimeDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:end_datetime")}
                    asc="EndTimeAsc"
                    desc="EndTimeDesc"
                    order={order}
                    setOrder={setOrder}
                />

                {loading && !data?.runInfos && (
                    <TableContentLoader columns={8} />
                )}
                {data?.runInfos?.page.map((s, i) => (
                    <Fragment key={i}>
                        {s?.status === "DONE" && s?.type !== "UNKNOWN" ? (
                            <Link
                                to={
                                    [
                                        "ALLOCATION",
                                        "MARKETING",
                                        "CALCULATION",
                                    ].includes(s?.type)
                                        ? `/${getLinkPart(s?.type)}/${
                                              s?.pricingListId
                                          }/calculations/${s?.runOrPlausiId}`
                                        : `plausibility/${s?.runOrPlausiId}`
                                }
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.linkCell,
                                )}
                            >
                                {s?.name}
                            </Link>
                        ) : (
                            <p className={tableStyle.cell}>{s?.name}</p>
                        )}
                        <EnumLabel
                            className={tableStyle.cell}
                            label={s?.type}
                        />
                        <EnumLabel
                            className={tableStyle.cell}
                            label={s?.status}
                        />
                        {["ALLOCATION", "MARKETING", "CALCULATION"].includes(
                            s?.type,
                        ) ? (
                            <Link
                                to={`/${getLinkPart(s?.type)}/${
                                    s?.pricingListId
                                }`}
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.linkCell,
                                )}
                            >
                                {s?.pricingListName}
                            </Link>
                        ) : (
                            <p className={tableStyle.cell} />
                        )}
                        <p className={tableStyle.fullWidthCell}>{s?.message}</p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.createdAt))}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.startTime))}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {dateFormat.format(new Date(s?.endTime))}
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.runInfos}
                loading={loading && !data?.runInfos}
            />
        </div>
    )
}
