const initialState = false

export const SHOW_AUTH = "SHOW_AUTH"
export const HIDE_AUTH = "HIDE_AUTH"

/**
 * Reducer for the authentication model
 *
 * @param state the old auth state
 * @param type the type of the action that should be performed
 * @returns {boolean} true if the auth modal should be shown
 * @constructor
 */
export const AuthReducer = (state = initialState, { type }) => {
    switch (type) {
        case SHOW_AUTH:
            return true
        case HIDE_AUTH:
            return false
        default:
            return state
    }
}
