import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import {
    getDayMonthYearFormatter,
    getIntegerFormatter,
    getSafeFormatter,
} from "../../../i18n"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Link, useHistory, useParams } from "react-router-dom"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import inputStyle from "../../../style/input.module.css"
import { uniqueId } from "lodash"
import { EnumLabel } from "../../util/table/EnumLabel"
import buttonStyle from "../../../style/button.module.css"
import { showError } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"
import { AssortmentProductViewer } from "./AssortmentProductViewer"
import cn from "classnames"
import { AssortmentGroupNameDisplay } from "../assortmentGroup/AssortmentGroupNameDisplay"

const GET_ASSORTMENT = gql`
    query getAssortment(
        $id: UUID!
        $pageNum: Int
        $order: [AssortmentAssortmentGroupOrder]
    ) {
        assortment(id: $id) {
            id
            name
            finalAmount
            createdAt
            updatedAt
            isCompliment
            assortmentGroups(limit: 20, pageNum: $pageNum, orderBy: $order) {
                currentPage
                hasNext
                hasPrevious
                pages
                page {
                    id
                    name
                    action
                    isSystemGroup
                    status
                    priority
                    productAmount
                    validUntil
                    updatedAt
                }
            }
            baseAssortment {
                id
                name
                assortmentGroups(
                    limit: 20
                    pageNum: $pageNum
                    orderBy: $order
                ) {
                    currentPage
                    hasNext
                    hasPrevious
                    pages
                    page {
                        id
                        name
                        action
                        isSystemGroup
                        status
                        priority
                        productAmount
                        validUntil
                        updatedAt
                    }
                }
            }
            complimentAssortment {
                id
                name
                assortmentGroups(
                    limit: 20
                    pageNum: $pageNum
                    orderBy: $order
                ) {
                    currentPage
                    hasNext
                    hasPrevious
                    pages
                    page {
                        id
                        name
                        action
                        isSystemGroup
                        status
                        priority
                        productAmount
                        validUntil
                        updatedAt
                    }
                }
            }
        }
    }
`

const UPDATE_ASSORTMENT = gql`
    mutation ($id: UUID!, $name: String) {
        updateAssortment(id: $id, name: $name) {
            id
            name
        }
    }
`

export const AssortmentEditor = () => {
    const { id } = useParams()
    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])

    const [name, setName] = useState("")

    const { t, i18n } = useTranslation(["common", "table"])
    const dayMonthYearFormat = getSafeFormatter(
        getDayMonthYearFormatter(i18n.language),
    )

    const integerFormat = getSafeFormatter(getIntegerFormatter(i18n.language))

    const { data, loading } = useQuery(GET_ASSORTMENT, {
        variables: {
            pageNum,
            order,
            id,
        },
    })

    const history = useHistory()

    const [update, { loading: assortmentLoading }] = useMutation(
        UPDATE_ASSORTMENT,
        {
            refetchQueries: [GET_ASSORTMENT],
        },
    )

    const dispatch = useDispatch()

    useEffect(() => {
        setName(data?.assortment?.name || "")
    }, [data?.assortment])

    return (
        <div>
            <Banner
                text={`${t("table:assortment")}: ${
                    name || data?.assortment?.name || ""
                }`}
            />
            <AssortmentProductViewer assortmentId={id}>
                <div className={tableStyle.stretchFilterBar}>
                    <div className={tableStyle.filterBar}>
                        <div className={tableStyle.filterInnerSection}>
                            <p className={tableStyle.label}>
                                {t("table:assortment_name")}
                            </p>
                            <input
                                id={name_input_id}
                                className={inputStyle.text}
                                type="text"
                                value={name}
                                onChange={(evt) => setName(evt.target.value)}
                            />
                        </div>
                        <div className={tableStyle.filterInnerSection}>
                            <p className={tableStyle.label}>
                                {t("table:created_at")}
                            </p>
                            <p>
                                {dayMonthYearFormat.format(
                                    new Date(data?.assortment?.createdAt),
                                )}
                            </p>
                        </div>
                        <div className={tableStyle.filterInnerSection}>
                            <p className={tableStyle.label}>
                                {t("table:updated_at")}
                            </p>
                            <p>
                                {dayMonthYearFormat.format(
                                    new Date(data?.assortment?.updatedAt),
                                )}
                            </p>
                        </div>
                        <div className={tableStyle.filterInnerSection}>
                            <p className={tableStyle.label}>
                                {t("table:product_amount")}
                            </p>
                            <p>
                                {integerFormat.format(
                                    data?.assortment?.finalAmount,
                                )}
                            </p>
                        </div>
                    </div>
                    <div>
                        <button
                            className={buttonStyle.button}
                            onClick={() =>
                                update({
                                    variables: {
                                        id,
                                        name,
                                    },
                                })
                                    .then((r) =>
                                        history.push(
                                            `/assortment/${r?.data?.updateAssortment?.id}`,
                                        ),
                                    )
                                    .catch((e) =>
                                        dispatch(showError(e.message)),
                                    )
                            }
                        >
                            {t("table:save")}
                        </button>
                    </div>
                </div>
                {data?.assortment?.isCompliment ? (
                    <>
                        <h1 className={tableStyle.title}>
                            {t("table:base_assortment")}{" "}
                            <Link
                                to={`/assortment/${data?.assortment?.baseAssortment?.id}`}
                            >
                                [{data?.assortment?.baseAssortment?.name}]
                            </Link>
                        </h1>
                        <AssortmentTable
                            assortment={data?.assortment?.baseAssortment}
                            setPage={setPage}
                            setOrder={setOrder}
                            order={order}
                            loading={loading || assortmentLoading}
                        />
                        <h1 className={tableStyle.title}>
                            {t("table:compliment_assortment")}{" "}
                            <Link
                                to={`/assortment/${data?.assortment?.complimentAssortment?.id}`}
                            >
                                [{data?.assortment?.complimentAssortment?.name}]
                            </Link>
                        </h1>
                        <AssortmentTable
                            assortment={data?.assortment?.complimentAssortment}
                            setPage={setPage}
                            setOrder={setOrder}
                            order={order}
                            loading={loading || assortmentLoading}
                        />
                    </>
                ) : (
                    <AssortmentTable
                        assortment={data?.assortment}
                        setPage={setPage}
                        setOrder={setOrder}
                        order={order}
                        loading={loading || assortmentLoading}
                    />
                )}
            </AssortmentProductViewer>
        </div>
    )
}

const AssortmentTable = ({ setOrder, order, setPage, assortment, loading }) => {
    const { t, i18n } = useTranslation(["common", "table"])
    const dayMonthYearFormat = getSafeFormatter(
        getDayMonthYearFormatter(i18n.language),
    )

    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    return (
        <>
            {!loading && !assortment ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}

            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "repeat(5, 1fr)",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:assortment_group")}
                    asc="NameAsc"
                    desc="NameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:action")}
                    asc="ActionAsc"
                    desc="ActionDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:quantity")}
                    asc="ProductAmountAsc"
                    desc="ProductAmountDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:valid_until")}
                    asc="ValidUntilAsc"
                    desc="ValidUntilDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                    asc="UpdatedAtAsc"
                    desc="UpdatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                {loading && !assortment && <TableContentLoader columns={5} />}
                {assortment?.assortmentGroups?.page.map((s, i) => (
                    <Fragment key={i}>
                        <Link
                            to={`/assortment_group/${s?.id}`}
                            className={cn(tableStyle.cell, tableStyle.linkCell)}
                        >
                            <AssortmentGroupNameDisplay row={s} />
                        </Link>
                        <EnumLabel
                            className={tableStyle.cell}
                            label={s?.action}
                        />
                        <p className={tableStyle.cell}>
                            {integerFormatter.format(s?.productAmount)}
                        </p>
                        <p className={tableStyle.cell}>
                            {dayMonthYearFormat.format(new Date(s?.validUntil))}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {dayMonthYearFormat.format(new Date(s?.updatedAt))}
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={assortment?.assortmentGroups}
                loading={loading && assortment}
            />
        </>
    )
}
