import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { Banner } from "../../util/Banner"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import tableStyle from "../../../style/table.module.css"
import { Link } from "react-router-dom"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { EnumLabel } from "../../util/table/EnumLabel"
import cn from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { Pagination } from "../../util/table/Pagination"
import { ListCell } from "../../util/table/ListCell"
import { DeletionWithAffectedModal } from "../../util/modals/DeletionWithAffectedModal"
import { flatten, uniq } from "lodash"
import { BoolLabel } from "../../util/table/BoolLabel"
import { SmartFilter } from "../../util/input/SmartFilter"
import { AssortmentGroupNameDisplay } from "./AssortmentGroupNameDisplay"

const ASSORTMENT_GROUP_LIST_SEARCH_QUERY = gql`
    query assortmentGroupsList(
        $query: String
        $pageNum: Int
        $order: [AssortmentGroupSearchOrder]
        $filter: AssortmentGroupSearchFilter
    ) {
        assortmentGroupSearch(
            limit: 20
            pageNum: $pageNum
            orderBy: $order
            filterParams: $filter
            query: $query
        ) {
            currentPage
            hasNext
            hasPrevious
            suggestedQuery
            pages
            page {
                assortments(limit: -1) {
                    page {
                        id
                        name
                        pricingLists(limit: -1) {
                            page {
                                id
                                name: displayName
                            }
                        }
                    }
                }
                id
                isNew
                isSystemGroup
                name
                action
                status
                priority
                systemStatus
                productAmount
                createdAt
                updatedAt
            }
        }
    }
`
const DELETE_ASSORTMENT_GROUP = gql`
    mutation ($id: UUID!, $ignoreAffected: Boolean) {
        delete: deleteAssortmentGroup(
            id: $id
            ignoreAffected: $ignoreAffected
        ) {
            success
            affected {
                model
                name
                id
            }
            blockers {
                model
                name
                id
            }
        }
    }
`

const FILTER_QUERY = gql`
    query getFilter {
        assortmentGroupSearch(limit: 0) {
            filterFields {
                name
                dataType
                displayName
                options
            }
        }
    }
`

export const AssortmentGroupList = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [query, setQuery] = useState("")
    const [filter, setFilter] = useState(null)

    const { t, i18n } = useTranslation(["common", "table"])

    const { data, loading } = useQuery(ASSORTMENT_GROUP_LIST_SEARCH_QUERY, {
        variables: {
            pageNum,
            order,
            query,
            filter,
        },
    })

    const [currentElement, setCurrentElement] = useState(null)

    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    return (
        <div>
            <Banner text={t("table:assortment_groups")} />
            {currentElement && (
                <DeletionWithAffectedModal
                    id={currentElement?.id}
                    delete_mutation={DELETE_ASSORTMENT_GROUP}
                    onDismiss={() => setCurrentElement(null)}
                    refetchQueries={[ASSORTMENT_GROUP_LIST_SEARCH_QUERY]}
                    onCancel={() => setCurrentElement(null)}
                    name={currentElement?.name}
                />
            )}
            <div className={dropDownStyle.container}>
                <SmartFilter
                    filterFieldQuery={FILTER_QUERY}
                    filterPath={(q) => q?.assortmentGroupSearch?.filterFields}
                    setQuery={setQuery}
                    setFilter={setFilter}
                    suggestedQuery={data?.assortmentGroupSearch?.suggestedQuery}
                />
            </div>

            {!loading && !data?.assortmentGroupSearch.page?.length ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}
            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns:
                        "1fr 2fr 2fr repeat(2, 1fr) repeat(3,6em) 3em",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:assortment_group")}
                    asc="NameAsc"
                    desc="NameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:assortment")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pricing_lists")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                    asc="CreatedAtAsc"
                    desc="CreatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                    asc="UpdatedAtAsc"
                    desc="UpdatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:action")}
                    asc="ActionAsc"
                    desc="ActionDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:is_new")}
                    asc="IsNewAsc"
                    desc="IsNewDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:is_system_group")}
                    asc="IsSystemGroupAsc"
                    desc="IsSystemGroupDesc"
                    order={order}
                    setOrder={setOrder}
                />

                <p className={tableStyle.header} />
                {loading && !data?.assortmentGroupSearch && (
                    <TableContentLoader columns={10} />
                )}
                {data?.assortmentGroupSearch.page.map((s, i) => (
                    <Fragment key={i}>
                        <Link
                            className={cn(tableStyle.cell, tableStyle.linkCell)}
                            to={`/assortment_group/${s.id}`}
                        >
                            <AssortmentGroupNameDisplay row={s} />
                        </Link>
                        <ListCell
                            className={tableStyle.cell}
                            labels={s?.assortments?.page}
                            destination={"assortment"}
                        />
                        <ListCell
                            className={tableStyle.cell}
                            labels={uniq(
                                flatten(
                                    s.assortments?.page?.map(
                                        (a) => a?.pricingLists?.page || [],
                                    ),
                                ),
                            )}
                            destination={"pricing_list"}
                        />
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.createdAt))}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.updatedAt))}
                        </p>
                        <EnumLabel
                            className={cn(tableStyle.cell)}
                            label={s.action}
                        />
                        <BoolLabel
                            className={cn(tableStyle.cell)}
                            status={s.isNew}
                        />
                        <BoolLabel
                            className={cn(tableStyle.cell)}
                            status={s.isSystemGroup}
                        />
                        <p className={tableStyle.actionCell}>
                            {s.isSystemGroup ? null : (
                                <button
                                    className={cn(
                                        tableStyle.actionButton,
                                        tableStyle.negativeActionButton,
                                    )}
                                    onClick={() => {
                                        setCurrentElement({
                                            id: s.id,
                                            name: s.name,
                                        })
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            )}
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.assortmentGroupSearch}
                loading={loading && !data?.assortmentGroupSearch}
            />
        </div>
    )
}
