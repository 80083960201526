import ContentLoader from "react-content-loader"
import tableStyle from "../../../style/table.module.css"

/**
 * This element displays a ghost table to indicate loading
 *
 * @param columns the number of columns that should be displayed
 * @param rows the number of rows that should be displayed
 * @returns a list of content loaders that match the dimensions given
 * @constructor
 */
export const TableContentLoader = ({ columns, rows = 10 }) =>
    [...Array(columns * rows)].map((_, i) => (
        <ContentLoader
            key={i}
            viewBox="0 0 100 20"
            preserveAspectRatio="none"
            className={tableStyle.cellLoader}
        >
            <rect x="5" y="5" width="90" height="10" />
        </ContentLoader>
    ))
