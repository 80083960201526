import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import style from "./BoolLabel.module.css"

/**
 * This element is a simple label with a check or cross
 *
 * @param status true if it should display a check
 * @param className the classname of the label
 * @returns {JSX.Element} the boolean label block
 * @constructor
 */
export const BoolLabel = ({ status, className }) => {
    return (
        <p className={className}>
            {status ? (
                <span className={style.checkmark}>
                    <FontAwesomeIcon icon={faCheck} />
                </span>
            ) : (
                <span className={style.cross}>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
            )}
        </p>
    )
}
