import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { Flash, WARNING } from "../../util/Flash"
import "../../../style/index.css"
import tableStyle from "../../../style/table.module.css"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import { Banner } from "../../util/Banner"
import { ListCell } from "../../util/table/ListCell"
import { Link } from "react-router-dom"
import cn from "classnames"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { SmartFilter } from "../../util/input/SmartFilter"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { DeletionWithAffectedModal } from "../../util/modals/DeletionWithAffectedModal"

const GET_DISTRIBUTORS = gql`
    query (
        $query: String
        $pageNum: Int
        $order: [DistributorSearchOrder]
        $filter: DistributorSearchFilter
    ) {
        distributorSearch(
            limit: 20
            pageNum: $pageNum
            orderBy: $order
            filterParams: $filter
            query: $query
        ) {
            page {
                id
                name
                createdAt
                updatedAt
                pricingListTypes(limit: -1) {
                    page {
                        id
                        displayName
                        pricingListTypeNumber
                    }
                }
                emails(limit: -1) {
                    page {
                        email
                        id
                    }
                }
            }
        }
    }
`

const FILTER_QUERY = gql`
    query getFilter {
        distributorSearch(limit: 0) {
            filterFields {
                name
                dataType
                displayName
                options
            }
        }
    }
`

const DELETE_DISTRIBUTORS = gql`
    mutation ($id: UUID!, $ignoreAffected: Boolean) {
        delete: deleteDistributor(id: $id, ignoreAffected: $ignoreAffected) {
            success
            affected {
                model
                name
                id
            }
            blockers {
                model
                name
                id
            }
        }
    }
`

export const DistributorList = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [query, setQuery] = useState("")
    const [filter, setFilter] = useState(null)

    const [currentElement, setCurrentElement] = useState(null)

    const { t, i18n } = useTranslation(["common", "table"])
    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    const { data, loading } = useQuery(GET_DISTRIBUTORS, {
        variables: {
            pageNum,
            order,
            query,
            filter,
        },
    })

    return (
        <div>
            {currentElement && (
                <DeletionWithAffectedModal
                    id={currentElement?.id}
                    delete_mutation={DELETE_DISTRIBUTORS}
                    onDismiss={() => setCurrentElement(null)}
                    refetchQueries={[GET_DISTRIBUTORS]}
                    onCancel={() => setCurrentElement(null)}
                    name={currentElement?.name}
                />
            )}
            <Banner text={t("table:distributors")} />

            <div className={dropDownStyle.container}>
                <SmartFilter
                    filterFieldQuery={FILTER_QUERY}
                    filterPath={(q) => q?.distributorSearch?.filterFields}
                    setQuery={setQuery}
                    setFilter={setFilter}
                    suggestedQuery={data?.distributorSearch?.suggestedQuery}
                />
                <div className={tableStyle.buttonBar}>
                    <Link to={"/distributor"} className={buttonStyle.button}>
                        {c(t("table:create_distributor"))}
                    </Link>
                </div>
            </div>

            {!loading && !data?.distributorSearch.page?.length ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}
            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "1fr 3fr 4fr 1fr 1fr 3em",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:name")}
                    asc="NameAsc"
                    desc="NameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:emails")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pricing_list_types")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                />
                <p className={tableStyle.header} />
                {loading && !data?.distributorSearch && (
                    <TableContentLoader columns={4} />
                )}
                {data?.distributorSearch.page.map((s, i) => (
                    <Fragment key={i}>
                        <Link
                            className={cn(tableStyle.cell, tableStyle.linkCell)}
                            to={`/distributor/${s.id}`}
                        >
                            {s.name}
                        </Link>
                        <ListCell
                            className={tableStyle.cell}
                            labels={s?.emails?.page.map((e) => ({
                                name: e.email,
                                id: e.id,
                            }))}
                        />
                        <ListCell
                            className={tableStyle.cell}
                            labels={s?.pricingListTypes?.page.map((a) => ({
                                name: `${a.displayName} (${a.pricingListTypeNumber})`,
                            }))}
                        />
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.createdAt))}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.updatedAt))}
                        </p>
                        <p className={tableStyle.actionCell}>
                            <button
                                className={cn(
                                    tableStyle.actionButton,
                                    tableStyle.lastCell,
                                    tableStyle.negativeActionButton,
                                )}
                                onClick={() => {
                                    setCurrentElement({
                                        id: s.id,
                                        name: s.name,
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.distributorSearch}
                loading={loading && !data?.distributorSearch}
            />
        </div>
    )
}
