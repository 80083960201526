import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Flash, WARNING } from "../../util/Flash"
import "../../../style/index.css"
import tableStyle from "../../../style/table.module.css"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import { Banner } from "../../util/Banner"
import { ListCell } from "../../util/table/ListCell"
import { Link } from "react-router-dom"
import cn from "classnames"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { SmartFilter } from "../../util/input/SmartFilter"
import buttonStyle from "../../../style/button.module.css"
import { showError, showInfo } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"

const PRICING_LIST_SEARCH_QUERY = gql`
    query (
        $query: String
        $pageNum: Int
        $order: [AssortmentSearchOrder]
        $filter: AssortmentSearchFilter
    ) {
        assortmentSearch(
            limit: 20
            pageNum: $pageNum
            orderBy: $order
            filterParams: $filter
            query: $query
        ) {
            currentPage
            hasNext
            hasPrevious
            suggestedQuery
            pages
            page {
                id
                name
                createdAt
                updatedAt
                pricingLists(limit: -1) {
                    page {
                        id
                        name: displayName
                    }
                }
            }
        }
    }
`

const PROCESS_ENLISTING_DELISTING = gql`
    mutation {
        processEnlistingDelisting
    }
`

const FILTER_QUERY = gql`
    query getFilter {
        assortmentSearch(limit: 0) {
            filterFields {
                name
                dataType
                displayName
                options
            }
        }
    }
`

export const AssortmentList = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [query, setQuery] = useState("")
    const [filter, setFilter] = useState(null)

    const { t, i18n } = useTranslation(["common", "table"])
    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    const [processEnlistingDelisting] = useMutation(PROCESS_ENLISTING_DELISTING)

    const dispatch = useDispatch()
    const [disable, setDisable] = useState(false)

    const { data, loading } = useQuery(PRICING_LIST_SEARCH_QUERY, {
        variables: {
            pageNum,
            order,
            query,
            filter: { ...filter, systemStatusEquals: "ACTIVE" },
        },
    })

    return (
        <div>
            <Banner text={t("table:assortments")} />

            <div className={dropDownStyle.container}>
                <SmartFilter
                    filterFieldQuery={FILTER_QUERY}
                    filterPath={(q) => q?.assortmentSearch?.filterFields}
                    setQuery={setQuery}
                    setFilter={setFilter}
                    suggestedQuery={data?.assortmentSearch?.suggestedQuery}
                />

                <div className={tableStyle.buttonBar}>
                    <button
                        disabled={disable}
                        className={buttonStyle.orangeButton}
                        onClick={() => {
                            setDisable(true)
                            processEnlistingDelisting()
                                .then(() =>
                                    dispatch(
                                        showInfo("common:refresh_triggered"),
                                    ),
                                )
                                .catch((e) => {
                                    dispatch(showError(e.message))
                                    setDisable(false)
                                })
                        }}
                    >
                        {t("table:update_enlisting_delisting")}
                    </button>
                </div>
            </div>

            {!loading && !data?.assortmentSearch.page?.length ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}

            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "1fr 4fr 1fr 1fr",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:assortment")}
                    asc="NameAsc"
                    desc="NameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pricing_lists")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                />
                {loading && !data?.pricingLists && (
                    <TableContentLoader columns={4} />
                )}
                {data?.assortmentSearch.page.map((s, i) => (
                    <Fragment key={i}>
                        <Link
                            className={cn(tableStyle.cell, tableStyle.linkCell)}
                            to={`/assortment/${s.id}`}
                        >
                            {s.name}
                        </Link>
                        <ListCell
                            className={tableStyle.cell}
                            labels={s?.pricingLists?.page}
                            destination={"pricing_list"}
                        />
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.createdAt))}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {dateFormat.format(new Date(s?.updatedAt))}
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.assortmentSearch}
                loading={loading && !data?.assortmentSearch}
            />
        </div>
    )
}
