import { Banner } from "../../util/Banner"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import { useCallback, useEffect, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Redirect, useHistory, useParams } from "react-router-dom"
import { Loader } from "../../util/Loader"
import { uniqueId } from "lodash"
import inputStyle from "../../../style/input.module.css"
import style from "./GroupUpload.module.css"
import cn from "classnames"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import tableStyle from "../../../style/table.module.css"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import { showError } from "../../../redux/actions/NotificationActions"
import downloadCsv from "js-file-download"
import { useDispatch } from "react-redux"
import { Toggle } from "../../util/input/Toggle"

const UPLOAD_FILE = gql`
    mutation ($file: Upload!, $name: String!, $force: Boolean, $id: UUID) {
        createEmptyPricingListGroupFromCsv(
            file: $file
            name: $name
            force: $force
            id: $id
        ) {
            id
        }
    }
`

const GET_PRICING_LIST_GROUP = gql`
    query ($id: UUID!) {
        pricingListGroup(id: $id) {
            id
            name
            isSystemGroup
        }
    }
`

export const StrategyUpload = () => {
    const { t } = useTranslation(["enums", "table", "common"])
    const history = useHistory()

    const { id } = useParams()

    const [name, setName] = useState("")
    const [file, setFile] = useState("")
    const [force, setForce] = useState(false)

    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [ignore_missing_product_id] = useState(() =>
        uniqueId("ignore_missing_product_"),
    )

    const { data, loading: pricingListGroupLoading } = useQuery(
        GET_PRICING_LIST_GROUP,
        {
            variables: {
                id,
            },
            skip: !id,
        },
    )

    useEffect(() => {
        setName(data?.pricingListGroup?.name || "")
    }, [data])

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles[0]) {
            setFile(acceptedFiles[0])
            if (!name) {
                setName(acceptedFiles[0].name || "")
            }
        }
    }, [])

    const [upload, { loading }] = useMutation(UPLOAD_FILE)

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    const dispatch = useDispatch()

    if (loading) {
        return (
            <>
                <Banner text={t("table:upload_article_group")} />
                <Loader />
            </>
        )
    }

    if (data?.pricingListGroup?.isSystemGroup) {
        return <Redirect to={"/article_group/csv"} />
    }

    return (
        <>
            <Banner text={t("table:upload_article_group")} />
            <div className={tableStyle.stackedFilterBar}>
                {loading || (id && pricingListGroupLoading) ? (
                    <Loader />
                ) : (
                    <div className={tableStyle.filterBar}>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={name_input_id}
                                className={tableStyle.label}
                            >
                                {t("table:name")}
                            </label>
                            <input
                                id={name_input_id}
                                className={inputStyle.text}
                                type="text"
                                value={name}
                                onChange={(evt) => setName(evt.target.value)}
                            />
                        </div>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={ignore_missing_product_id}
                                className={tableStyle.label}
                            >
                                {t("table:ignore_missing_product")}
                            </label>
                            <Toggle
                                id={ignore_missing_product_id}
                                isChecked={force}
                                setIsChecked={setForce}
                            />
                        </div>
                    </div>
                )}
                <div className={tableStyle.buttonSection}>
                    <button
                        className={buttonStyle.button}
                        disabled={!name || !file}
                        onClick={() =>
                            upload({
                                variables: {
                                    file,
                                    name,
                                    force,
                                    id,
                                },
                            })
                                .then((r) => {
                                    history.push(
                                        `/pricing_list_group/${r.data?.createEmptyPricingListGroupFromCsv?.id}`,
                                    )
                                })
                                .catch((e) => dispatch(showError(e.message)))
                        }
                    >
                        {c(t("table:upload"))}
                    </button>
                    <button
                        onClick={() =>
                            downloadCsv(
                                "pzn",
                                "group_upload.csv",
                                "application/csv",
                            )
                        }
                        className={buttonStyle.button}
                    >
                        {t("table:download_template_csv")}
                    </button>
                </div>
            </div>
            {!file && (
                <div className={style.dropWrapper}>
                    <div
                        className={cn(
                            style.dropZone,
                            isDragActive && style.highlight,
                        )}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <h2>{t("table:drop_to_upload")}</h2>
                        ) : (
                            <h2>{t("table:drag_to_upload")}</h2>
                        )}
                    </div>
                </div>
            )}
            {file && (
                <div className={style.uploadedList}>
                    <div className={style.fileBox}>
                        <p>{file?.name || t("table:file")}</p>
                        <button
                            className={cn(
                                tableStyle.actionButton,
                                tableStyle.negativeActionButton,
                            )}
                            onClick={() => setFile(null)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
