import { useDispatch } from "react-redux"
import { Modal } from "./Modal"
import modalStyle from "./Modal.module.css"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import inputStyle from "../../../style/input.module.css"
import { useState } from "react"
import formStyle from "../../../style/form.module.css"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import { showError } from "../../../redux/actions/NotificationActions"
import { hideModals } from "../../../redux/actions/ModalActions"
import { uniqueId } from "lodash"
import ContentLoader from "react-content-loader"
import style from "./Sidebar.module.css"

const DATA_SOURCE_ASSORTMENT_QUERY = gql`
    query {
        assortments(limit: -1, filterParams: { systemStatusEquals: "ACTIVE" }) {
            page {
                id
                name
            }
        }
    }
`

const UPDATE = gql`
    mutation update(
        $id: UUID!
        $tableName: String!
        $date: Date!
        $assortments: [UUID]
    ) {
        updateCalculationSetting(
            id: $id
            tableName: $tableName
            date: $date
            assortments: $assortments
        ) {
            id
            tableName
            date
            assortments {
                id
                name
            }
        }
    }
`

const CREATE = gql`
    mutation createCS($tableName: String!, $date: Date!, $assortments: [UUID]) {
        createCalculationSetting(
            tableName: $tableName
            date: $date
            assortments: $assortments
        ) {
            id
            tableName
            date
            assortments {
                id
                name
            }
        }
    }
`

const CalculationPlaceholder = () => (
    <ContentLoader
        viewBox="0 0 100 500"
        color={"black"}
        className={style.priceViewerPlaceHolder}
    >
        {[...Array(10)].map((_, i) => (
            <rect key={i} x="5" y={i * 20 + 15} width="90" height="10" />
        ))}
    </ContentLoader>
)

export const CalculationSettingsEditorSidebar = ({
    calculationSettingVariables,
    dismiss,
    refetch,
}) => {
    const { t } = useTranslation(["common", "table"])

    const options = [
        { value: "AGROSSO", label: c(t("enums:agrosso")) },
        { value: "IFA", label: c(t("enums:ifa")) },
        { value: "HISTORIC_IFA", label: c(t("enums:historic_ifa")) },
        { value: "COMPETITOR", label: c(t("enums:competitor")) },
        { value: "PERFORMANCE", label: c(t("enums:performance")) },
        {
            value: "PRICE_LIST_PERFORMANCE",
            label: c(t("enums:price_list_performance")),
        },
    ]

    const [date, setDate] = useState(calculationSettingVariables?.date || "")

    const [assortments, setAssortments] = useState(
        calculationSettingVariables?.assortments
            ? calculationSettingVariables?.assortments.map((s) => s.id)
            : [],
    )
    const [tableName, setTableName] = useState(
        calculationSettingVariables?.tableName || "",
    )

    const { data, loading: optionsLoading } = useQuery(
        DATA_SOURCE_ASSORTMENT_QUERY,
    )

    const [update] = useMutation(UPDATE)
    const [create] = useMutation(CREATE)

    const dispatch = useDispatch()

    const [table_name_input_id] = useState(() => uniqueId("table_name_input_"))
    const [date_id] = useState(() => uniqueId("date_"))
    const [assortment_id] = useState(() => uniqueId("assortment_"))

    const saveCalculationSettings = (s) => {
        s.id
            ? update({ variables: s })
                  .then(() => dispatch(hideModals()))
                  .then(() => refetch())
                  .then(() => dismiss())
                  .catch((e) => dispatch(showError(e.message)))
            : create({ variables: s })
                  .then(() => dispatch(hideModals()))
                  .then(() => refetch())
                  .then(() => dismiss())
                  .catch((e) => dispatch(showError(e.message)))
    }

    if (optionsLoading) {
        return (
            <Modal className={modalStyle.calculationSettings} dismiss={dismiss}>
                <CalculationPlaceholder />
            </Modal>
        )
    }

    return (
        <Modal className={modalStyle.calculationSettings} dismiss={dismiss}>
            <h1>{t("table:add_new_calculation_setting")}</h1>
            <div className={formStyle.formGrid}>
                <label htmlFor={table_name_input_id}>
                    {t("table:data_source")}
                </label>
                <Select
                    placeholder={""}
                    id={table_name_input_id}
                    value={options.find((o) => o.value === tableName)}
                    components={makeAnimated()}
                    onChange={(tn) => setTableName(tn.value)}
                    options={options}
                />
                <label htmlFor={date_id}>{t("table:reference_date")}</label>
                <input
                    id={date_id}
                    className={inputStyle.text}
                    type="date"
                    value={date}
                    onChange={(evt) => {
                        setDate(evt.target.value)
                    }}
                />
                <label htmlFor={assortment_id}>{t("table:assortments")}</label>
                <Select
                    id={assortment_id}
                    isMulti={true}
                    loadingMessage={() => t("common:loading")}
                    placeholder={""}
                    isLoading={optionsLoading}
                    onChange={(t) => setAssortments(t.map((s) => s.value))}
                    defaultValue={calculationSettingVariables?.assortments.map(
                        (a) => ({
                            value: a.id,
                            label: a.name,
                        }),
                    )}
                    components={makeAnimated()}
                    options={data?.assortments?.page?.map((a) => ({
                        value: a.id,
                        label: a.name,
                    }))}
                />
                <div />
                <button
                    disabled={!(tableName && date && assortments.length > 0)}
                    className={buttonStyle.button}
                    onClick={() =>
                        saveCalculationSettings({
                            id: calculationSettingVariables
                                ? calculationSettingVariables.id
                                : null,
                            tableName: tableName,
                            date: date,
                            assortments,
                        })
                    }
                >
                    {c(t("common:save"))}
                </button>
            </div>
        </Modal>
    )
}
