import tableStyle from "../../../style/table.module.css"
import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import {
    getCurrencyFormatter,
    getIntegerFormatter,
    getMonthYearFormatter,
    getSafeFormatter,
} from "../../../i18n"
import { TableHeader } from "../../util/table/TableHeader"
import cn from "classnames"

export const CompetitorPriceTable = ({ competitorPrices }) => {
    const { t, i18n } = useTranslation(["table"])

    const currencyFormat = getSafeFormatter(getCurrencyFormatter(i18n.language))
    const monthYearFormatter = getSafeFormatter(
        getMonthYearFormatter(i18n.language),
    )
    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    if (!competitorPrices) {
        return null
    }
    return (
        <div className={tableStyle.wrapper}>
            <div
                className={tableStyle.table}
                style={{
                    margin: 0,
                    gridTemplateColumns: "2fr repeat(7, 1fr)",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:name")}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={t("table:month")}
                />

                <TableHeader
                    className={tableStyle.header}
                    label={`${t("table:bulk_amount")} 1`}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={`${t("table:price")} ${t("table:bulk")} 1`}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={`${t("table:bulk_amount")} 2`}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={`${t("table:price")} ${t("table:bulk")} 2`}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={`${t("table:bulk_amount")} 3`}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={`${t("table:price")} ${t("table:bulk")} 3`}
                />

                {competitorPrices.map((c) => (
                    <Fragment key={c.id}>
                        <p className={tableStyle.cell}>
                            {c?.competitorDisplayName}
                        </p>
                        <p className={tableStyle.cell}>
                            {monthYearFormatter.format(new Date(c?.validFrom))}
                        </p>

                        <p className={tableStyle.cell}>
                            {integerFormatter.format(c?.bulkAmount1)}
                        </p>
                        <p className={tableStyle.cell}>
                            {currencyFormat.format(c?.bulkPrice1)}
                        </p>

                        <p className={tableStyle.cell}>
                            {integerFormatter.format(c?.bulkAmount2)}
                        </p>

                        <p className={tableStyle.cell}>
                            {currencyFormat.format(c?.bulkPrice2)}
                        </p>

                        <p className={tableStyle.cell}>
                            {integerFormatter.format(c?.bulkAmount3)}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {currencyFormat.format(c?.bulkPrice3)}
                        </p>
                    </Fragment>
                ))}
            </div>
        </div>
    )
}
