import { gql, useQuery } from "@apollo/client"
import { Fragment, useState } from "react"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import tableStyle from "../../../style/table.module.css"
import { useTranslation } from "react-i18next"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import cn from "classnames"
import style from "./AssortmentProductViewer.module.css"
import { SmartFilter } from "../../util/input/SmartFilter"
import { ProductDetailExpander } from "../../util/ProductDetailExpander"
import { getIntegerFormatter, getSafeFormatter } from "../../../i18n"

const GET_GROUP_LIST_ASSORTMENT_PRODUCTS = gql`
    query (
        $id: UUID!
        $pageNum: Int!
        $isEnlist: Boolean
        $order: [ListGroupProductAllOrder]
        $filter: ListGroupProductAllFilter
    ) {
        assortment(id: $id) {
            id
            name
            listGroupsProducts(
                pageNum: $pageNum
                orderBy: $order
                filterParams: $filter
                isEnlist: $isEnlist
            ) {
                currentPage
                hasNext
                hasPrevious
                pages
                page {
                    id
                    enlistingDelistingRule
                    priority
                    isCompliment
                    isBaseToCompliment
                    product {
                        id
                        name
                        pzn
                        unit
                        quantity
                        atc
                        quantity
                        supplierName
                    }
                }
            }
        }
    }
`

const GROUP_LIST_FILTER_QUERY = gql`
    query getFilter($id: UUID!) {
        assortment(id: $id) {
            id
            listGroupsProducts(limit: 0) {
                filterFields {
                    name
                    dataType
                    displayName
                    options
                }
            }
        }
    }
`

const GET_FINAL_ASSORTMENT_PRODUCTS = gql`
    query (
        $id: UUID!
        $pageNum: Int!
        $order: [FinalProductAllOrder]
        $filter: FinalProductAllFilter
    ) {
        assortment(id: $id) {
            id
            name
            finalProducts(
                pageNum: $pageNum
                orderBy: $order
                filterParams: $filter
            ) {
                currentPage
                hasNext
                hasPrevious
                pages
                page {
                    id
                    enlistingDelistingRule
                    product {
                        id
                        name
                        quantity
                        pzn
                        unit
                        atc
                        quantity
                        supplierName
                    }
                }
            }
        }
    }
`

const FINAL_FILTER_QUERY = gql`
    query getFilter($id: UUID!) {
        assortment(id: $id) {
            id
            finalProducts(limit: 0) {
                filterFields {
                    name
                    dataType
                    displayName
                    options
                }
            }
        }
    }
`

export const OVERVIEW = "OVERVIEW"
export const FINAL = "FINAL"
export const ENLIST = "ENLIST"
export const DELIST = "DELIST"

export const AssortmentProductViewer = ({
    assortmentId,
    children,
    disabled,
    pricingListType,
}) => {
    const [view, setView] = useState(OVERVIEW)

    const { t } = useTranslation(["common", "table"])

    if (disabled) {
        return children
    }

    return (
        <div>
            <div className={style.buttonBar}>
                <button
                    onClick={() => setView(OVERVIEW)}
                    style={
                        view === OVERVIEW
                            ? { borderBottom: "0.125em solid var(--green)" }
                            : null
                    }
                >
                    {t("common:overview")}
                </button>
                <button
                    onClick={() => setView(FINAL)}
                    style={
                        view === FINAL
                            ? { borderBottom: "0.125em solid var(--green)" }
                            : null
                    }
                >
                    {t("common:final_assortment")}
                </button>
                <button
                    onClick={() => setView(ENLIST)}
                    style={
                        view === ENLIST
                            ? { borderBottom: "0.125em solid var(--green)" }
                            : null
                    }
                >
                    {t("common:enlist")}
                </button>
                <button
                    onClick={() => setView(DELIST)}
                    style={
                        view === DELIST
                            ? { borderBottom: "0.125em solid var(--green)" }
                            : null
                    }
                >
                    {t("common:delist")}
                </button>
            </div>
            {view === OVERVIEW ? (
                children
            ) : view === FINAL ? (
                <FinalAssortmentTable
                    assortmentId={assortmentId}
                    pricingListType={pricingListType}
                />
            ) : (
                <ListGroupAssortmentTable
                    assortmentId={assortmentId}
                    isEnlist={view === ENLIST}
                    pricingListType={pricingListType}
                />
            )}
        </div>
    )
}

const ListGroupAssortmentTable = ({
    assortmentId,
    isEnlist,
    pricingListType,
}) => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [filter, setFilter] = useState(null)
    const [query, setQuery] = useState("")
    const [expanderProduct, setExpanderProduct] = useState(null)

    const { t, i18n } = useTranslation(["common", "table"])

    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    const { data, loading } = useQuery(GET_GROUP_LIST_ASSORTMENT_PRODUCTS, {
        variables: {
            pageNum,
            order,
            query,
            filter,
            isEnlist,
            id: assortmentId,
        },
    })

    return (
        <>
            <div className={dropDownStyle.container}>
                <SmartFilter
                    filterFieldQuery={GROUP_LIST_FILTER_QUERY}
                    hasSearch={false}
                    filterQueryOptions={{
                        variables: { id: assortmentId },
                    }}
                    filterPath={(q) =>
                        q?.assortment?.listGroupsProducts?.filterFields
                    }
                    setQuery={setQuery}
                    setFilter={setFilter}
                    suggestedQuery={
                        data?.assortment?.listGroupsProducts?.suggestedQuery
                    }
                />
            </div>
            <div className={tableStyle.wrapper}>
                <div
                    className={tableStyle.table}
                    style={{
                        gridTemplateColumns: "1fr 2fr repeat(5, 1fr) 2fr",
                    }}
                >
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:pzn")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductPznAsc"}
                        desc={"ProductPznDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:product")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductNameAsc"}
                        desc={"ProductNameDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:supplier")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductSupplierNameAsc"}
                        desc={"ProductSupplierNameDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:unit")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductUnitAsc"}
                        desc={"ProductUnitDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:quantity")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductQuantityAsc"}
                        desc={"ProductQuantityDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:atc")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductAtcAsc"}
                        desc={"ProductAtcDesc"}
                    />

                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:priority")}
                        order={order}
                        setOrder={setOrder}
                        asc={"PriorityAsc"}
                        desc={"PriorityDesc"}
                    />

                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:enlisting_delisting_rule")}
                        order={order}
                        setOrder={setOrder}
                        asc={"EnlistingDelistingRuleAsc"}
                        desc={"EnlistingDelistingRuleDesc"}
                    />

                    {loading && !data?.pricingList && (
                        <TableContentLoader columns={18} />
                    )}
                    {data?.assortment?.listGroupsProducts?.page.map((s, i) => (
                        <Fragment key={i}>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.linkCell,
                                )}
                                onClick={() =>
                                    setExpanderProduct(s?.product?.id)
                                }
                            >
                                {s?.product?.pzn}
                            </p>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.linkCell,
                                )}
                                onClick={() =>
                                    setExpanderProduct(s?.product?.id)
                                }
                            >
                                {s?.product?.name}
                            </p>
                            <p className={tableStyle.cell}>
                                {s?.product?.supplierName}
                            </p>
                            <p className={tableStyle.cell}>
                                {s?.product?.unit}
                            </p>
                            <p className={tableStyle.cell}>
                                {integerFormatter.format(s?.product?.quantity)}
                            </p>
                            <p className={cn(tableStyle.cell)}>
                                {s?.product?.atc}
                            </p>
                            <p className={tableStyle.cell}>
                                {integerFormatter.format(s?.priority)}
                            </p>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.lastCell,
                                )}
                            >
                                {s?.enlistingDelistingRule}
                            </p>
                            <ProductDetailExpander
                                isShown={
                                    expanderProduct &&
                                    expanderProduct === s?.product?.id
                                }
                                pricingListType={pricingListType}
                                close={() => setExpanderProduct(null)}
                                product={s?.product}
                            />
                        </Fragment>
                    ))}
                </div>
                <Pagination
                    onPageChange={(page) => setPage(page)}
                    pagination={data?.assortment?.listGroupsProducts}
                    loading={loading && !data?.assortment}
                />
            </div>
        </>
    )
}

const FinalAssortmentTable = ({ assortmentId, pricingListType }) => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [filter, setFilter] = useState(null)
    const [query, setQuery] = useState("")
    const [expanderProduct, setExpanderProduct] = useState(null)

    const { t, i18n } = useTranslation(["common", "table"])

    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    const { data, loading } = useQuery(GET_FINAL_ASSORTMENT_PRODUCTS, {
        variables: {
            pageNum,
            order,
            query,
            filter,
            id: assortmentId,
        },
    })

    return (
        <>
            <div className={dropDownStyle.container}>
                <SmartFilter
                    hasSearch={false}
                    filterFieldQuery={FINAL_FILTER_QUERY}
                    filterQueryOptions={{
                        variables: { id: assortmentId },
                    }}
                    filterPath={(q) =>
                        q?.assortment?.finalProducts?.filterFields
                    }
                    setQuery={setQuery}
                    setFilter={setFilter}
                    suggestedQuery={
                        data?.assortment?.finalProducts?.suggestedQuery
                    }
                />
            </div>
            <div className={tableStyle.wrapper}>
                <div
                    className={tableStyle.table}
                    style={{
                        gridTemplateColumns: "1fr 2fr repeat(4, 1fr) 2fr",
                    }}
                >
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:pzn")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductPznAsc"}
                        desc={"ProductPznDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:product")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductNameAsc"}
                        desc={"ProductNameDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:supplier")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductSupplierNameAsc"}
                        desc={"ProductSupplierNameDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:unit")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductUnitAsc"}
                        desc={"ProductUnitDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:quantity")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductQuantityAsc"}
                        desc={"ProductQuantityDesc"}
                    />
                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:atc")}
                        order={order}
                        setOrder={setOrder}
                        asc={"ProductAtcAsc"}
                        desc={"ProductAtcDesc"}
                    />

                    <TableHeader
                        className={tableStyle.header}
                        label={t("table:enlisting_delisting_rule")}
                        order={order}
                        setOrder={setOrder}
                        asc={"EnlistingDelistingRuleAsc"}
                        desc={"EnlistingDelistingRuleDesc"}
                    />

                    {loading && !data?.pricingList && (
                        <TableContentLoader columns={18} />
                    )}
                    {data?.assortment?.finalProducts?.page.map((s, i) => (
                        <Fragment key={i}>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.linkCell,
                                )}
                                onClick={() =>
                                    setExpanderProduct(s?.product?.id)
                                }
                            >
                                {s?.product?.pzn}
                            </p>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.linkCell,
                                )}
                                onClick={() =>
                                    setExpanderProduct(s?.product?.id)
                                }
                            >
                                {s?.product?.name}
                            </p>
                            <p className={tableStyle.cell}>
                                {s?.product?.supplierName}
                            </p>
                            <p className={tableStyle.cell}>
                                {s?.product?.unit}
                            </p>
                            <p className={tableStyle.cell}>
                                {integerFormatter.format(s?.product?.quantity)}
                            </p>
                            <p className={cn(tableStyle.cell)}>
                                {s?.product?.atc}
                            </p>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.lastCell,
                                )}
                            >
                                {s?.enlistingDelistingRule}
                            </p>
                            <ProductDetailExpander
                                isShown={
                                    expanderProduct &&
                                    expanderProduct === s?.product?.id
                                }
                                close={() => setExpanderProduct(null)}
                                pricingListType={pricingListType}
                                product={s?.product}
                            />
                        </Fragment>
                    ))}
                </div>
                <Pagination
                    onPageChange={(page) => setPage(page)}
                    pagination={data?.assortment?.finalProducts}
                    loading={loading && !data?.assortment}
                />
            </div>
        </>
    )
}
