import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { Flash, WARNING } from "../../util/Flash"
import "../../../style/index.css"
import tableStyle from "../../../style/table.module.css"
import buttonStyle from "../../../style/button.module.css"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import cn from "classnames"
import { Banner } from "../../util/Banner"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { EnumLabel } from "../../util/table/EnumLabel"
import { DeletionWithAffectedModal } from "../../util/modals/DeletionWithAffectedModal"
import c from "capitalize"
import { SmartFilter } from "../../util/input/SmartFilter"

const PRICING_LIST_SEARCH_QUERY = gql`
    query pricingListsList(
        $query: String
        $pageNum: Int
        $order: [PricingListSearchOrder]
        $filter: PricingListSearchFilter
    ) {
        pricingLists: pricingListSearch(
            limit: 20
            pageNum: $pageNum
            orderBy: $order
            filterParams: $filter
            query: $query
        ) {
            currentPage
            hasNext
            hasPrevious
            suggestedQuery
            pages
            page {
                id
                name: displayName
                status
                createdAt
                updatedAt
                validFrom
                validUntil
                assortment {
                    id
                    name
                    systemStatus
                }
            }
        }
    }
`

const FILTER_QUERY = gql`
    query getFilter {
        pricingLists: pricingListSearch(limit: 0) {
            filterFields {
                name
                dataType
                displayName
                options
            }
        }
    }
`

const DELETE_PRICING_LIST = gql`
    mutation ($id: UUID!, $ignoreAffected: Boolean) {
        delete: deletePricingList(id: $id, ignoreAffected: $ignoreAffected) {
            success
            affected {
                model
                name
                id
            }
            blockers {
                model
                name
                id
            }
        }
    }
`

export const PricingListList = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [query, setQuery] = useState("")
    const [filter, setFilter] = useState(null)

    const { t, i18n } = useTranslation(["common", "table"])

    const { data, loading } = useQuery(PRICING_LIST_SEARCH_QUERY, {
        variables: {
            pageNum,
            order,
            query,
            filter,
        },
    })

    const [currentElement, setCurrentElement] = useState(null)

    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    return (
        <div>
            <Banner text={t("table:pricing_lists")} />

            {currentElement && (
                <DeletionWithAffectedModal
                    id={currentElement?.id}
                    delete_mutation={DELETE_PRICING_LIST}
                    onDismiss={() => setCurrentElement(null)}
                    refetchQueries={[PRICING_LIST_SEARCH_QUERY]}
                    onCancel={() => setCurrentElement(null)}
                    name={currentElement?.name}
                />
            )}

            <div className={dropDownStyle.container}>
                <SmartFilter
                    filterFieldQuery={FILTER_QUERY}
                    filterPath={(q) => q?.pricingLists?.filterFields}
                    setQuery={setQuery}
                    setFilter={setFilter}
                    suggestedQuery={data?.pricingLists?.suggestedQuery}
                />
                <div className={tableStyle.buttonBar}>
                    <Link to={"/pricing_list"} className={buttonStyle.button}>
                        {c(t("table:add_new_pricing_list"))}
                    </Link>
                </div>
            </div>

            {!loading && !data?.pricingLists.page?.length ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}
            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "repeat(5, 2fr) 0.5fr",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pricing_list")}
                    asc="NameAsc"
                    desc="NameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:assortment")}
                    asc="AssortmentNameAsc"
                    desc="AssortmentNameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:status")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                    asc="CreatedAtAsc"
                    desc="CreatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                    asc="UpdatedAtAsc"
                    desc="UpdatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <p className={tableStyle.header} />
                {loading && !data?.pricingLists && (
                    <TableContentLoader columns={7} />
                )}
                {data?.pricingLists.page.map((s, i) => (
                    <Fragment key={i}>
                        <Link
                            className={cn(tableStyle.linkCell, tableStyle.cell)}
                            to={`/pricing_list/${s.id}`}
                        >
                            {s.name}
                        </Link>
                        <Link
                            className={cn(tableStyle.linkCell, tableStyle.cell)}
                            to={`/assortment/${s?.assortment?.id}`}
                        >
                            {s?.assortment?.name}
                        </Link>
                        <EnumLabel
                            className={tableStyle.cell}
                            label={
                                s?.systemStatus === "HISTORIC"
                                    ? "HISTORIC"
                                    : s?.status
                            }
                        />
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s.createdAt))}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s.updatedAt))}
                        </p>
                        <p className={tableStyle.actionCell}>
                            <button
                                className={cn(
                                    tableStyle.actionButton,
                                    tableStyle.negativeActionButton,
                                )}
                                onClick={() => {
                                    setCurrentElement({
                                        id: s.id,
                                        name: s.name,
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.pricingLists}
                loading={loading && !data?.pricingLists}
            />
        </div>
    )
}
