import style from "./QuickFilter.module.css"
import inputStyle from "../../../style/input.module.css"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import { gql, useQuery } from "@apollo/client"
import makeAnimated from "react-select/animated"
import Select from "react-select"
import { useDebouncedCallback } from "use-debounce"
import { useEffect, useState } from "react"

const QUICK_FILTER_QUERY = gql`
    query ($calculationRunIds: [UUID]) {
        calculationQuickFilterOptions(calculationIds: $calculationRunIds) {
            bigPlausibilityMessages
            smallPlausibilityMessages
            pricingListNames
            pricingListTypeNames
        }
    }
`

export const QuickFilter = ({
    filter,
    setFilter,
    isBigPlausi,
    calculationRunIds,
    loading: parentLoading,
}) => {
    const { t } = useTranslation(["table"])

    const { data, loading } = useQuery(QUICK_FILTER_QUERY, {
        variables: {
            calculationRunIds: calculationRunIds || [],
        },
    })

    const [displayPzn, setDisplayPzn] = useState("")

    useEffect(() => {
        setDisplayPzn(filter?.productPznEquals)
    }, [filter?.productPznEquals])

    const debouncedSetPzn = useDebouncedCallback((pzn) => {
        setFilter({
            ...(filter || {}),
            productPznEquals: pzn || null,
        })
    }, 1000)

    return (
        <div className={style.container}>
            <StatusSelect
                currentStatus={
                    isBigPlausi
                        ? filter?.bigPlausibilityStatusEquals
                        : filter?.smallPlausibilityStatusEquals
                }
                onSetStatus={(s) =>
                    setFilter({
                        ...(filter || {}),
                        [isBigPlausi
                            ? "bigPlausibilityStatusEquals"
                            : "smallPlausibilityStatusEquals"]: s,
                    })
                }
            />

            <TypeSelect
                currentType={filter?.typeEquals}
                onSetType={(s) =>
                    setFilter({
                        ...(filter || {}),
                        typeEquals: s,
                    })
                }
            />

            <input
                className={inputStyle.text}
                placeholder={t("table:pzn")}
                value={displayPzn || ""}
                onChange={(evt) => {
                    setDisplayPzn(evt.target.value)
                    debouncedSetPzn(evt.target.value)
                }}
            />

            {/*<IgnorePlausiSelect*/}
            {/*    currentIgnoreState={filter?.ignorePlausiCheckEquals}*/}
            {/*    onIgnoreState={(s) =>*/}
            {/*        setFilter({*/}
            {/*            ...(filter || {}),*/}
            {/*            ignorePlausiCheckEquals: s,*/}
            {/*        })*/}
            {/*    }*/}
            {/*/>*/}

            {isBigPlausi ? (
                <>
                    <FilterSelectOne
                        loading={loading || parentLoading}
                        name={t("table:plausibility_message")}
                        field={"bigPlausibilityMessageEquals"}
                        filter={filter || {}}
                        onSetField={setFilter}
                        data={
                            data?.calculationQuickFilterOptions
                                ?.bigPlausibilityMessages || []
                        }
                    />

                    <FilterSelectOne
                        loading={loading || parentLoading}
                        name={t("table:name")}
                        filter={filter || {}}
                        onSetField={setFilter}
                        field={"pricingListCalculationPricingListNameEquals"}
                        data={
                            data?.calculationQuickFilterOptions
                                ?.pricingListNames || []
                        }
                    />
                    <FilterSelectOne
                        loading={loading || parentLoading}
                        name={t("table:type")}
                        filter={filter || {}}
                        onSetField={setFilter}
                        field={
                            "pricingListCalculationPricingListPricingListTypeNameEquals"
                        }
                        data={
                            data?.calculationQuickFilterOptions
                                ?.pricingListTypeNames || []
                        }
                    />
                </>
            ) : (
                <FilterSelectOne
                    loading={loading || parentLoading}
                    filter={filter || {}}
                    onSetField={setFilter}
                    field={"smallPlausibilityMessageEquals"}
                    name={t("table:plausibility_message")}
                    data={
                        data?.calculationQuickFilterOptions
                            ?.smallPlausibilityMessages || []
                    }
                />
            )}
        </div>
    )
}

const StatusSelect = ({ currentStatus, onSetStatus }) => {
    const { t } = useTranslation(["enums"])

    return (
        <div className={style.radioSelect}>
            <button
                className={cn(
                    currentStatus === "NO_ISSUES" ? style.checked : null,
                )}
                onClick={() => {
                    if (currentStatus === "NO_ISSUES") {
                        onSetStatus(null)
                    } else {
                        onSetStatus("NO_ISSUES")
                    }
                }}
            >
                {t("enums:no_issues")}
            </button>
            <button
                className={cn(
                    currentStatus === "SOLVED" ? style.checked : null,
                )}
                onClick={() => {
                    if (currentStatus === "SOLVED") {
                        onSetStatus(null)
                    } else {
                        onSetStatus("SOLVED")
                    }
                }}
            >
                {t("enums:solved")}
            </button>
            <button
                className={cn(
                    currentStatus === "ATTENTION" ? style.checked : null,
                )}
                onClick={() => {
                    if (currentStatus === "ATTENTION") {
                        onSetStatus(null)
                    } else {
                        onSetStatus("ATTENTION")
                    }
                }}
            >
                {t("enums:attention")}
            </button>
        </div>
    )
}

const TypeSelect = ({ currentType, onSetType }) => {
    const { t } = useTranslation(["enums"])

    return (
        <div className={style.radioSelect}>
            <button
                className={cn(currentType === "RULE" ? style.checked : null)}
                onClick={() => {
                    if (currentType === "RULE") {
                        onSetType(null)
                    } else {
                        onSetType("RULE")
                    }
                }}
            >
                {t("enums:rule")}
            </button>
            <button
                className={cn(currentType === "MODEL" ? style.checked : null)}
                onClick={() => {
                    if (currentType === "MODEL") {
                        onSetType(null)
                    } else {
                        onSetType("MODEL")
                    }
                }}
            >
                {t("enums:model")}
            </button>
        </div>
    )
}

// const IgnorePlausiSelect = ({ currentIgnoreState, onIgnoreState }) => {
//     const { t } = useTranslation(["table"])
//
//     return (
//         <div className={style.radioSelect}>
//             <button
//                 className={cn(
//                     currentIgnoreState === true ? style.checked : null,
//                 )}
//                 onClick={() => {
//                     if (currentIgnoreState === true) {
//                         onIgnoreState(null)
//                     } else {
//                         onIgnoreState(true)
//                     }
//                 }}
//             >
//                 {t("table:ignore_plausi_check")}
//             </button>
//             <button
//                 className={cn(
//                     currentIgnoreState === false ? style.checked : null,
//                 )}
//                 onClick={() => {
//                     if (currentIgnoreState === false) {
//                         onIgnoreState(null)
//                     } else {
//                         onIgnoreState(false)
//                     }
//                 }}
//             >
//                 {t("table:not_ignore_plausi_check")}
//             </button>
//         </div>
//     )
// }

const FilterSelectOne = ({
    loading,
    field,
    data,
    name,
    onSetField,
    filter,
}) => {
    const { t } = useTranslation(["common"])

    return (
        <div className={style.selectHolder}>
            <Select
                loadingMessage={() => t("common:loading")}
                placeholder={name}
                isClearable={true}
                isLoading={loading}
                onChange={(v) => {
                    if (v?.value) {
                        onSetField({ ...filter, [field]: v.value })
                    } else {
                        onSetField({ ...filter, [field]: null })
                    }
                }}
                defaultValue={
                    filter[field]
                        ? {
                              label: filter[field],
                              value: filter[field],
                          }
                        : null
                }
                components={makeAnimated()}
                options={data?.map((v) => ({ label: v, value: v }))}
            />
        </div>
    )
}
