import { Banner } from "../../util/Banner"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Fragment, useEffect, useState } from "react"
import makeAnimated from "react-select/animated"
import Select from "react-select"
import style from "./StrategyEditor.module.css"
import { cloneDeep, uniqueId } from "lodash"
import buttonStyle from "../../../style/button.module.css"
import inputStyle from "../../../style/input.module.css"
import { Toggle } from "../../util/input/Toggle"
import { EnumLabel } from "../../util/table/EnumLabel"
import { Redirect, useHistory, useParams } from "react-router-dom"
import { Loader } from "../../util/Loader"
import { useDispatch } from "react-redux"
import { showError } from "../../../redux/actions/NotificationActions"
import c from "capitalize"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const STRATEGY_CONFIG = gql`
    query ($isMl: Boolean) {
        readStrategyConfig(isMl: $isMl) {
            type
            strategies {
                name
                actions {
                    name
                    type
                    options
                    default
                    isReadOnly
                    rank
                    infoKey
                }
                requirements {
                    name
                    type
                    options
                    default
                    isReadOnly
                    infoKey
                }
            }
        }
    }
`

const GET_STRATEGY = gql`
    query ($id: UUID!) {
        strategy(id: $id) {
            id
            name
            isMl
            pricingListType
            model {
                id
                name
            }
            strategyParameters {
                id
                rank
                pricingStrategy
                parameterType
                parameterName
                parameterValue
            }
        }
    }
`

const UPDATE = gql`
    mutation (
        $id: UUID!
        $name: String
        $pricingListType: String
        $isMl: Boolean
        $strategyParams: [StrategyParameterInput]
    ) {
        updateStrategyWithParams(
            id: $id
            name: $name
            pricingListType: $pricingListType
            isMl: $isMl
            strategyParams: $strategyParams
        ) {
            id
            name
            isMl
            pricingListType
            model {
                id
                name
            }
            strategyParameters {
                id
                rank
                pricingStrategy
                parameterType
                parameterName
                parameterValue
            }
        }
    }
`

const CREATE = gql`
    mutation (
        $name: String
        $pricingListType: String
        $strategyParams: [StrategyParameterInput]
    ) {
        createStrategyWithParams(
            name: $name
            pricingListType: $pricingListType
            isMl: true
            strategyParams: $strategyParams
        ) {
            id
            name
            isMl
            pricingListType
            model {
                id
                name
            }
            strategyParameters {
                id
                pricingStrategy
                parameterType
                parameterName
                parameterValue
            }
        }
    }
`

const ValueInputField = ({ value, onChange, disabled, setting, readOnly }) => {
    const { t } = useTranslation(["common", "table"])

    if (!setting || disabled) {
        return (
            <input
                readOnly={true}
                disabled={true}
                className={inputStyle.text}
                value={value}
            />
        )
    }

    if (setting.type === "BOOLEAN") {
        return (
            <Toggle
                isChecked={value}
                setIsChecked={(v) => onChange(v)}
                disabled={readOnly}
            />
        )
    }

    if (setting.type === "NUMBER") {
        return (
            <input
                type={"number"}
                value={value || 0}
                readOnly={readOnly}
                disabled={readOnly}
                onChange={(evt) => onChange(evt.target.value)}
                className={inputStyle.text}
            />
        )
    }

    if (setting.type === "LIST") {
        return (
            <Select
                placeholder={setting.default || ""}
                value={
                    value?.map
                        ? value?.map((v) => ({
                              value: v || "",
                              label: v || "",
                          })) || []
                        : []
                }
                isMulti={true}
                isDisabled={readOnly}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
                menuPosition={"fixed"}
                onChange={(s) => onChange(s.map((v) => v.value))}
                loadingMessage={() => t("common:loading")}
                components={makeAnimated()}
                options={setting.options?.map((o) => ({
                    value: o || "",
                    label: o || "",
                }))}
            />
        )
    }

    if (setting.options?.length) {
        return (
            <Select
                placeholder={setting.default || ""}
                value={{
                    value: value || "",
                    label: value || "",
                }}
                isDisabled={readOnly}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
                menuPosition={"fixed"}
                onChange={(s) => onChange(s.value)}
                loadingMessage={() => t("common:loading")}
                components={makeAnimated()}
                options={setting.options?.map((o) => ({
                    value: o || "",
                    label: o || "",
                }))}
            />
        )
    }

    return (
        <input
            type={"text"}
            value={value || ""}
            onChange={(evt) => onChange(evt.target.value)}
            className={inputStyle.text}
            readOnly={readOnly}
            disabled={readOnly}
        />
    )
}

const ValueSliderField = ({ value, onChange, disabled, setting, readOnly }) => {
    const { t } = useTranslation(["common", "table", "strategy"])

    if (!setting || disabled) {
        return null
    }

    const [key1, key2] = Object.keys(value)
    const [value1, value2] = Object.values(value)

    return (
        <div className={style.sliderRow}>
            <p>
                {t(`strategy:${key1}`) + " " + `${Math.floor(+value1 * 100)}%`}
            </p>
            <input
                className={style.slider}
                type={"range"}
                min={0}
                max={1}
                step={0.01}
                readOnly={readOnly}
                disabled={readOnly}
                value={+value2 || 0}
                onChange={(evt) =>
                    onChange({
                        [key1]: 1 - +evt.target.value,
                        [key2]: +evt.target.value,
                    })
                }
            />
            <p>
                {t(`strategy:${key2}`) + " " + `${Math.floor(+value2 * 100)}%`}
            </p>
        </div>
    )
}

const SimpleStrategyRow = ({
    strategyFields,
    strategyRow,
    setStrategyRow,
    isFirst,
    isLast,
    hideSliders,
}) => {
    const [level3Option, setLevel3Option] = useState(null)
    const [isReadOnly, setIsReadOnly] = useState(false)

    const { t } = useTranslation(["common", "table", "strategy"])

    useEffect(() => {
        const strategy = strategyFields?.find(
            (s) => s.name === strategyRow.pricingStrategy,
        )

        if (strategy) {
            const option = strategy[
                strategyRow.parameterType === "ACTION"
                    ? "actions"
                    : "requirements"
            ]?.find((o) => o.name === strategyRow.parameterName)

            if (option?.isReadOnly) {
                setIsReadOnly(true)
            }

            setLevel3Option(option)
            if (option) {
                setStrategyRow({
                    pricingStrategy: strategyRow.pricingStrategy,
                    parameterType: strategyRow.parameterType,
                    parameterName: strategyRow.parameterName,
                    parameterValue: JSON.parse(option.default),
                    rank: strategyRow.rank,
                })
            }
        }
    }, [strategyFields, strategyRow.pricingStrategy])

    if (hideSliders === (level3Option?.type === "SLIDER")) {
        return null
    }

    if (level3Option?.type === "SLIDER") {
        return (
            <>
                {/*<p>{t(`strategy:${strategyRow.pricingStrategy}`)}</p>*/}
                {/*<EnumLabel label={strategyRow.parameterType}/>*/}
                <p className={style.bold}>
                    {t(`strategy:${strategyRow.parameterName}`)}
                </p>
                <p />
                <p />
                {t(`strategy:${level3Option?.infoKey}`) ? (
                    <p
                        className={style.info}
                        title={t(`strategy:${level3Option?.infoKey}`)}
                        onClick={() =>
                            alert(t(`strategy:${level3Option?.infoKey}`))
                        }
                    >
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </p>
                ) : (
                    <p />
                )}
                <ValueSliderField
                    value={strategyRow.parameterValue}
                    onChange={(s) =>
                        setStrategyRow({
                            pricingStrategy: strategyRow.pricingStrategy,
                            parameterType: strategyRow.parameterType,
                            parameterName: strategyRow.parameterName,
                            parameterValue: s,
                            rank: strategyRow.rank,
                        })
                    }
                    readOnly={isReadOnly}
                    setting={level3Option}
                    disabled={
                        !strategyRow.pricingStrategy ||
                        !strategyRow.parameterType ||
                        !level3Option
                    }
                />
            </>
        )
    }

    return (
        <>
            {isFirst ? (
                <p className={style.bold}>
                    {t(`strategy:${strategyRow.pricingStrategy}`)}
                </p>
            ) : (
                <p />
            )}
            <p>{t(`strategy:${strategyRow.parameterName}`)}</p>
            <EnumLabel label={strategyRow.parameterType} />
            <ValueInputField
                value={strategyRow.parameterValue}
                onChange={(s) =>
                    setStrategyRow({
                        pricingStrategy: strategyRow.pricingStrategy,
                        parameterType: strategyRow.parameterType,
                        parameterName: strategyRow.parameterName,
                        parameterValue: s,
                        rank: strategyRow.rank,
                    })
                }
                readOnly={isReadOnly}
                setting={level3Option}
                disabled={
                    !strategyRow.pricingStrategy ||
                    !strategyRow.parameterType ||
                    !level3Option
                }
            />
            {t(`strategy:${level3Option?.infoKey}`) ? (
                <p
                    className={style.info}
                    title={t(`strategy:${level3Option?.infoKey}`)}
                    onClick={() =>
                        alert(t(`strategy:${level3Option?.infoKey}`))
                    }
                >
                    <FontAwesomeIcon icon={faInfoCircle} />
                </p>
            ) : (
                <p />
            )}
            {isLast ? <span className={style.seperator} /> : null}
        </>
    )
}

export const StrategyEditor = ({ isMl }) => {
    const { t } = useTranslation(["common", "table", "strategy"])
    const { id } = useParams()

    const { data: configData, loading: configLoading } = useQuery(
        STRATEGY_CONFIG,
        { variables: { isMl } },
    )

    const { data: strategyData, loading: loadingStrategy } = useQuery(
        GET_STRATEGY,
        { variables: { id }, skip: !id },
    )

    const [strategyRows, setStrategyRows] = useState([])
    const [name, setName] = useState("")
    const [strategies, setStrategies] = useState([])
    const [pricingListType, setPricingListType] = useState(isMl ? "ML" : "")

    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [pricing_list_type_input_id] = useState(() =>
        uniqueId("pricing_list_type_input_"),
    )

    const [update, { loading: loadingUpdate }] = useMutation(UPDATE)
    const [create, { loading: loadingCreate }] = useMutation(CREATE)

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        setName(strategyData?.strategy?.name || "")

        if (strategyData?.strategy?.strategyParameters?.length) {
            setStrategyRows(
                strategyData?.strategy?.strategyParameters.map((s) => ({
                    parameterName: s.parameterName,
                    rank: s.rank,
                    parameterType: s.parameterType,
                    parameterValue: JSON.parse(s.parameterValue),
                    pricingStrategy: s.pricingStrategy,
                })),
            )
        }

        if (
            strategyData?.strategy?.pricingListType &&
            configData?.readStrategyConfig
        ) {
            let strat = configData?.readStrategyConfig
                ?.find(
                    (str) =>
                        str.type.toLowerCase() ===
                        strategyData?.strategy?.pricingListType.toLowerCase(),
                )
                ?.strategies.slice(0)
            setPricingListType(strategyData?.strategy?.pricingListType)
            setStrategies(strat)
        }
    }, [strategyData?.strategy, configData?.readStrategyConfig])

    useEffect(() => {
        if (isMl && !id && configData?.readStrategyConfig) {
            let strat = configData?.readStrategyConfig
                ?.find((str) => str.type.toLowerCase() === "ml")
                ?.strategies.slice(0)
            resetDefaults(strat)
            setStrategies(strat)
        }
    }, [id, isMl, configData?.readStrategyConfig])

    const resetDefaults = (new_strategies) => {
        let local_strategies = strategies

        if (new_strategies) {
            local_strategies = new_strategies
        }

        const rows = []
        if (local_strategies?.length) {
            for (const strategy of local_strategies) {
                if (strategy["requirements"]) {
                    for (const req of strategy["requirements"]) {
                        rows.push({
                            pricingStrategy: strategy.name,
                            parameterType: "REQUIREMENT",
                            parameterName: req.name,
                            parameterValue: JSON.parse(req.default),
                            rank: req.rank,
                        })
                    }
                }
                if (strategy["actions"]) {
                    for (const req of strategy["actions"]) {
                        rows.push({
                            pricingStrategy: strategy.name,
                            parameterType: "ACTION",
                            parameterName: req.name,
                            parameterValue: JSON.parse(req.default),
                            rank: req.rank,
                        })
                    }
                }
            }
        }

        setStrategyRows(rows)
    }

    if (strategyData?.strategy?.isMl && !isMl) {
        return <Redirect to={`/ml_strategy/${id}`} />
    }

    if (strategyData?.strategy && !strategyData?.strategy?.isMl && isMl) {
        return <Redirect to={`/rule_strategy/${id}`} />
    }

    if (
        (id && loadingStrategy) ||
        configLoading ||
        loadingCreate ||
        loadingUpdate
    ) {
        return (
            <>
                <Banner text={t("table:strategy")} />
                <div className={style.wrapper}>
                    <Loader />
                </div>
            </>
        )
    }

    return (
        <>
            <Banner text={t("table:strategy")} />
            <div className={style.wrapper}>
                <div className={style.strategySettingBox}>
                    <label htmlFor={name_input_id}>{t("table:name")}</label>
                    <input
                        id={name_input_id}
                        className={inputStyle.text}
                        type="text"
                        value={name || ""}
                        onChange={(evt) => setName(evt.target.value)}
                    />

                    {isMl ? null : (
                        <>
                            <label htmlFor={pricing_list_type_input_id}>
                                {t("table:pricing_list_type")}
                            </label>
                            <Select
                                id={pricing_list_type_input_id}
                                placeholder={""}
                                value={{
                                    value: pricingListType,
                                    label: t(
                                        `strategy:${
                                            pricingListType?.toLowerCase() || ""
                                        }`,
                                    ),
                                }}
                                styles={{
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 99999,
                                    }),
                                }}
                                menuPosition={"fixed"}
                                onChange={(s) => {
                                    const strat =
                                        configData?.readStrategyConfig?.find(
                                            (str) =>
                                                str.type.toLowerCase() ===
                                                s.value.toLowerCase(),
                                        )?.strategies
                                    resetDefaults(strat)
                                    setPricingListType(s.value)
                                    setStrategies(strat)
                                }}
                                loadingMessage={() => t("common:loading")}
                                isLoading={configLoading}
                                components={makeAnimated()}
                                options={
                                    configData?.readStrategyConfig?.map(
                                        (s) => ({
                                            value: s.type?.toUpperCase(),
                                            label: t(
                                                `strategy:${
                                                    s?.type?.toLowerCase() || ""
                                                }`,
                                            ),
                                        }),
                                    ) || []
                                }
                            />
                        </>
                    )}
                </div>

                <div className={style.buttonBox}>
                    <button
                        disabled={!strategies?.length}
                        className={buttonStyle.button}
                        onClick={() => resetDefaults()}
                    >
                        {t("table:reset_defaults")}
                    </button>

                    <button
                        disabled={!pricingListType || !name}
                        className={buttonStyle.button}
                        onClick={() =>
                            id
                                ? update({
                                      variables: {
                                          id,
                                          name,
                                          pricingListType,
                                          isMl,
                                          strategyParams: strategyRows?.map(
                                              (s) => ({
                                                  parameterName:
                                                      s.parameterName,
                                                  parameterType:
                                                      s.parameterType,
                                                  parameterValue:
                                                      JSON.stringify(
                                                          s.parameterValue,
                                                      ),
                                                  pricingStrategy:
                                                      s.pricingStrategy,
                                                  rank: s.rank || 0,
                                              }),
                                          ),
                                      },
                                  })
                                      .then(() => history.push("/strategies"))
                                      .catch((e) =>
                                          dispatch(showError(e.message)),
                                      )
                                : create({
                                      variables: {
                                          id,
                                          name,
                                          pricingListType,
                                          isMl,
                                          strategyParams: strategyRows?.map(
                                              (s) => ({
                                                  parameterName:
                                                      s.parameterName,
                                                  parameterType:
                                                      s.parameterType,
                                                  parameterValue:
                                                      JSON.stringify(
                                                          s.parameterValue,
                                                      ),
                                                  pricingStrategy:
                                                      s.pricingStrategy,
                                                  rank: s.rank || 0,
                                              }),
                                          ),
                                      },
                                  })
                                      .then(() => history.push("/strategies"))
                                      .catch((e) =>
                                          dispatch(showError(e.message)),
                                      )
                        }
                    >
                        {c(t("table:save"))}
                    </button>
                </div>

                {strategyRows.find(
                    (s) =>
                        typeof s.parameterValue === "object" &&
                        !Array.isArray(s.parameterValue),
                ) ? (
                    <div className={style.paramBox}>
                        {strategyRows.map((s, i, a) => (
                            <Fragment key={i}>
                                <SimpleStrategyRow
                                    hideSliders={false}
                                    strategyRow={s}
                                    strategyFields={strategies}
                                    setStrategyRow={(f) => {
                                        const copy = cloneDeep(strategyRows)
                                        copy[i] = f
                                        setStrategyRows(copy)
                                    }}
                                    isFirst={
                                        i === 0 ||
                                        a[i - 1]?.pricingStrategy !==
                                            s?.pricingStrategy
                                    }
                                    isLast={
                                        !(
                                            a[i + 1]?.pricingStrategy ===
                                                s?.pricingStrategy ||
                                            a[i + 1] === undefined
                                        )
                                    }
                                />
                            </Fragment>
                        ))}
                    </div>
                ) : null}
                {strategyRows.find(
                    (s) =>
                        !(
                            typeof s.parameterValue === "object" &&
                            !Array.isArray(s.parameterValue)
                        ),
                ) ? (
                    <div className={style.paramBox}>
                        {strategyRows.map((s, i, a) => (
                            <Fragment key={i}>
                                <SimpleStrategyRow
                                    strategyRow={s}
                                    hideSliders={true}
                                    strategyFields={strategies}
                                    setStrategyRow={(f) => {
                                        const copy = cloneDeep(strategyRows)
                                        copy[i] = f
                                        setStrategyRows(copy)
                                    }}
                                    isFirst={
                                        i === 0 ||
                                        a[i - 1]?.pricingStrategy !==
                                            s?.pricingStrategy
                                    }
                                    isLast={
                                        !(
                                            a[i + 1]?.pricingStrategy ===
                                                s?.pricingStrategy ||
                                            a[i + 1] === undefined
                                        )
                                    }
                                />
                            </Fragment>
                        ))}
                    </div>
                ) : null}
            </div>
        </>
    )
}
