import { useHistory, useParams } from "react-router-dom"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { showError } from "../../../redux/actions/NotificationActions"
import formStyle from "../../../style/form.module.css"
import buttonStyle from "../../../style/button.module.css"
import inputStyle from "../../../style/input.module.css"
import cn from "classnames"
import { FormContentLoader } from "../../util/form/FormContentLoader"
import c from "capitalize"
import { uniqueId } from "lodash"
import { Banner } from "../../util/Banner"
import { Toggle } from "../../util/input/Toggle"
import queryString from "query-string"
import makeAnimated from "react-select/animated"
import Select from "react-select"

const GET_MODEL_CONFIGURATION = gql`
    query get_model_configuration($id: UUID!) {
        modelConfiguration(id: $id) {
            id
            name
            strategy {
                id
                name
            }
            pricingListGroup {
                id
                name
            }
            pricingList {
                id
                name: displayName
            }
        }
    }
`

const GET_SELECTION_OPTIONS = gql`
    {
        strategyGroups: pricingListGroups(
            limit: -1
            filterParams: { actionEquals: "STRATEGY" }
        ) {
            page {
                id
                name
                action
            }
        }
        fixPriceGroups: pricingListGroups(
            limit: -1
            filterParams: { actionEquals: "PRICE" }
        ) {
            page {
                id
                name
                action
            }
        }
        pricingLists(limit: -1) {
            page {
                id
                name: displayName
            }
        }
        strategies(limit: -1) {
            page {
                id
                name
            }
        }
    }
`

const UPDATE = gql`
    mutation (
        $id: UUID!
        $name: String
        $pricingListGroup: UUID
        $strategy: UUID
        $pricingList: UUID
    ) {
        updateModelConfiguration(
            id: $id
            name: $name
            pricingListGroup: $pricingListGroup
            strategy: $strategy
            pricingList: $pricingList
        ) {
            id
            pricingList {
                id
            }
        }
    }
`

const CREATE = gql`
    mutation createMC(
        $name: String!
        $pricingListGroup: UUID!
        $strategy: UUID
        $pricingList: UUID!
    ) {
        createModelConfiguration(
            name: $name
            pricingListGroup: $pricingListGroup
            strategy: $strategy
            pricingList: $pricingList
        ) {
            id
            pricingList {
                id
            }
        }
    }
`

const BULK_CREATE = gql`
    mutation bulkCreate(
        $name: String!
        $pricingListGroup: UUID!
        $strategy: UUID
        $pricingLists: [UUID]
    ) {
        bulkCreateModelConfigurations(
            name: $name
            pricingListGroupId: $pricingListGroup
            strategyId: $strategy
            pricingListIds: $pricingLists
        )
    }
`

const ModelConfigurationForm = ({
    strategies,
    strategyGroups,
    fixPriceGroups,
    modelConfiguration,
    pricingLists,
    onChange,
    loading,
    mass,
}) => {
    const { t } = useTranslation(["common", "table", "enums"])

    const [bulkCreate, { loading: loadingBulkCreate }] =
        useMutation(BULK_CREATE)

    const dispatch = useDispatch()
    const history = useHistory()

    const [name, setName] = useState(modelConfiguration?.name || "")
    const [strategy, setStrategy] = useState(
        modelConfiguration?.strategy?.id || "",
    )
    const [pricingListGroup, setPricingListGroup] = useState(
        modelConfiguration?.pricingListGroup?.id,
    )
    const [pricingList, setPricingList] = useState(
        modelConfiguration?.pricingList?.id,
    )

    const [chosenPricingLists, setChosenPricingLists] = useState([])

    const { product_group_id, strategy_id, action, pricing_list } =
        queryString.parse(window.location.search)

    const [isStrategyConfig, setIsStrategyConfigBase] = useState(true)

    const setIsStrategyConfig = (isStrategy) => {
        setStrategy("")
        setPricingListGroup("")
        setIsStrategyConfigBase(isStrategy)
    }

    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [pricing_list_group_input_id] = useState(() =>
        uniqueId("pricing_list_group_input"),
    )
    const [strategy_input_id] = useState(() => uniqueId("strategy_input_"))
    useEffect(() => {
        setName(modelConfiguration?.name || "")
        setStrategy(modelConfiguration?.strategy?.id || strategy_id || "")
        setPricingListGroup(
            modelConfiguration?.pricingListGroup?.id || product_group_id || "",
        )
        setPricingList(
            modelConfiguration?.pricingList?.id || pricing_list || "",
        )
        if (action !== undefined) {
            setIsStrategyConfigBase(action === "STRATEGY")
        } else {
            setIsStrategyConfigBase(!!modelConfiguration?.strategy?.id)
        }
    }, [modelConfiguration])

    if (loading || loadingBulkCreate) {
        return (
            <div className={formStyle.wrapper}>
                <div className={formStyle.loading}>
                    <FormContentLoader rows={3} />
                </div>
            </div>
        )
    }
    return (
        <>
            <div className={formStyle.wrapper}>
                <div className={formStyle.modelConfigurationFormGrid}>
                    <label htmlFor={name_input_id}>{t("table:name")}</label>
                    <input
                        id={name_input_id}
                        className={cn(
                            inputStyle.text,
                            formStyle.inputBoxSpanDouble,
                        )}
                        type="text"
                        value={name}
                        onChange={(evt) => setName(evt.target.value)}
                    />

                    <label
                        onClick={() => setIsStrategyConfig(!isStrategyConfig)}
                    >
                        {t("table:is_strategy_config")}
                    </label>

                    <label htmlFor={strategy_input_id}>
                        {t("table:article_group")}
                    </label>

                    <label htmlFor={strategy_input_id}>
                        {t("table:strategy")}
                    </label>

                    <label htmlFor={strategy_input_id}>
                        {t("table:pricing_list")}
                    </label>

                    <Toggle
                        setIsChecked={() =>
                            setIsStrategyConfig(!isStrategyConfig)
                        }
                        isChecked={isStrategyConfig}
                    />

                    <select
                        id={pricing_list_group_input_id}
                        value={pricingListGroup || ""}
                        onChange={(evt) =>
                            setPricingListGroup(evt.target.value)
                        }
                        className={inputStyle.select}
                    >
                        <option value="" />
                        {isStrategyConfig
                            ? strategyGroups?.map((m) => (
                                  <option key={m.id} value={m.id}>
                                      {m.name}
                                  </option>
                              ))
                            : fixPriceGroups?.map((m) => (
                                  <option key={m.id} value={m.id}>
                                      {m.name}
                                  </option>
                              ))}
                    </select>
                    <select
                        disabled={!isStrategyConfig}
                        id={strategy_input_id}
                        value={strategy || ""}
                        onChange={(evt) => setStrategy(evt.target.value)}
                        className={inputStyle.select}
                    >
                        <option value="" />
                        {strategies?.map((m) => (
                            <option key={m.id} value={m.id}>
                                {m.name}
                            </option>
                        ))}
                    </select>
                    {mass ? (
                        <Select
                            id={strategy_input_id}
                            components={makeAnimated()}
                            isMulti={true}
                            value={chosenPricingLists?.map((a) => ({
                                value: a,
                                label: pricingLists.find((as) => as.id === a)
                                    ?.name,
                            }))}
                            onChange={(as) =>
                                setChosenPricingLists(as.map((a) => a.value))
                            }
                            loadingMessage={() => t("common:loading")}
                            isLoading={loading}
                            options={pricingLists?.map((a) => ({
                                value: a.id,
                                label: a.name,
                            }))}
                        />
                    ) : (
                        <select
                            id={strategy_input_id}
                            value={pricingList || ""}
                            onChange={(evt) => setPricingList(evt.target.value)}
                            className={inputStyle.select}
                        >
                            <option value="" />
                            {pricingLists?.map((m) => (
                                <option key={m.id} value={m.id}>
                                    {m.name}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
                {mass ? (
                    <button
                        className={cn(buttonStyle.button)}
                        onClick={() =>
                            bulkCreate({
                                variables: {
                                    name,
                                    strategy: strategy || null,
                                    pricingListGroup,
                                    pricingLists: chosenPricingLists,
                                },
                            })
                                .then(() => history.push(`/`))
                                .catch((e) => dispatch(showError(e.message)))
                        }
                        disabled={
                            !name ||
                            !pricingListGroup ||
                            (!strategy && isStrategyConfig) ||
                            !pricingLists?.length > 0
                        }
                    >
                        {c(t("common:save"))}
                    </button>
                ) : (
                    <button
                        className={cn(buttonStyle.button)}
                        onClick={() =>
                            onChange &&
                            onChange({
                                id: modelConfiguration?.id,
                                name,
                                strategy: strategy || null,
                                pricingListGroup,
                                pricingList,
                            })
                        }
                        disabled={
                            !name ||
                            !pricingListGroup ||
                            (!strategy && isStrategyConfig) ||
                            !pricingList
                        }
                    >
                        {c(t("common:save"))}
                    </button>
                )}
            </div>
        </>
    )
}

export const ModelConfigurationEditor = ({ mass = false }) => {
    const { id } = useParams()

    const { data: modelConfigurationData, loading: loadingModelConfiguration } =
        useQuery(GET_MODEL_CONFIGURATION, {
            variables: { id },
            skip: !id,
            fetchPolicy: "network-only",
        })

    const { data, loading } = useQuery(GET_SELECTION_OPTIONS)

    const [update, { loading: loadingUpdate }] = useMutation(UPDATE)
    const [create, { loading: loadingCreate }] = useMutation(CREATE)

    const dispatch = useDispatch()
    const history = useHistory()

    const { t } = useTranslation(["common", "table"])
    return (
        <>
            {id ? (
                <Banner text={t("table:edit_model_configuration")} />
            ) : (
                <Banner text={t("table:create_new_model_configuration")} />
            )}
            <ModelConfigurationForm
                loading={
                    loading ||
                    loadingModelConfiguration ||
                    loadingUpdate ||
                    loadingCreate
                }
                modelConfiguration={modelConfigurationData?.modelConfiguration}
                strategies={data?.strategies?.page}
                strategyGroups={data?.strategyGroups?.page}
                fixPriceGroups={data?.fixPriceGroups?.page}
                pricingLists={data?.pricingLists?.page}
                mass={mass}
                onChange={(s) =>
                    s.id
                        ? update({ variables: s })
                              .then((r) =>
                                  history.push(
                                      `/pricing_list/${r?.data?.updateModelConfiguration?.pricingList?.id}`,
                                  ),
                              )
                              .catch((e) => dispatch(showError(e.message)))
                        : create({ variables: s })
                              .then((r) =>
                                  history.push(
                                      `/pricing_list/${r?.data?.createModelConfiguration?.pricingList?.id}`,
                                  ),
                              )
                              .catch((e) => dispatch(showError(e.message)))
                }
            />
        </>
    )
}
