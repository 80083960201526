import { Banner } from "../../util/Banner"
import { gql, useQuery } from "@apollo/client"
import tableStyle from "../../../style/table.module.css"
import buttonStyle from "../../../style/button.module.css"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Fragment } from "react"
import { Link } from "react-router-dom"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import {
    getDayMonthYearFormatter,
    getIntegerFormatter,
    getSafeFormatter,
    getTimeFormatter,
} from "../../../i18n"
import style from "./Home.module.css"
import { EnumLabel } from "../../util/table/EnumLabel"

const GET_HOME_INFO = gql`
    query {
        newestIfaUpdate {
            newestDate
            latestUpdate
        }
        newestProductPerformanceUpdate {
            newestDate
            latestUpdate
        }
        newestProductAgrossoMetricUpdate {
            newestDate
            latestUpdate
        }
        newestProductPriceListPerformanceUpdate {
            newestDate
            latestUpdate
        }
        pricingLists(
            filterParams: {
                systemStatusEquals: "ACTIVE"
                assortmentSystemStatusEquals: "ACTIVE"
            }
            orderBy: [ValidUntilDesc, PricingListTypeNameAsc]
            limit: 20
        ) {
            page {
                id
                name: displayName
                status
                assortment {
                    id
                    name
                }
                submittedAt
                createdAt
                updatedAt
                validFrom
                validUntil
            }
        }
        assortmentGroups(
            filterParams: { isSystemGroupEquals: false }
            limit: 10
        ) {
            page {
                id
                name
                systemStatus
                status
                action
                productAmount
                createdAt
                updatedAt
                validFrom
                validUntil
            }
        }
        pricingListGroups(
            filterParams: { isSystemGroupEquals: false }
            limit: 10
        ) {
            page {
                id
                name
                action
                systemStatus
                status
                productAmount
                createdAt
                updatedAt
                validFrom
                validUntil
            }
        }
    }
`

export const Home = () => {
    const today = new Date()

    const { data, loading } = useQuery(GET_HOME_INFO, {
        variables: {
            today: new Date(
                Date.UTC(today.getFullYear(), today.getMonth(), today.getDay()),
            ).toISOString(),
        },
    })

    const { t, i18n } = useTranslation("table")
    const dayMonthYearFormat = getSafeFormatter(
        getDayMonthYearFormatter(i18n.language),
    )
    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    return (
        <>
            <Banner />
            <h2 className={style.sectionHeading}>
                {t("table:latest_pricing_lists")}
            </h2>
            <div
                className={style.table}
                style={{
                    gridTemplateColumns: "repeat(3, 1fr) repeat(3, 10em)",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:name")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:assortment")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:status")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:valid_from")}
                />
                {loading && !data?.pricingLists && (
                    <TableContentLoader columns={7} />
                )}
                {data?.pricingLists?.page.map((s, i) => (
                    <Fragment key={i}>
                        <Link
                            className={cn(tableStyle.cell, tableStyle.linkCell)}
                            to={`/pricing_list/${s?.id}`}
                        >
                            {s?.name}
                        </Link>
                        <Link
                            className={cn(tableStyle.cell, tableStyle.linkCell)}
                            to={`/assortment/${s?.assortment?.id}`}
                        >
                            {s?.assortment?.name}
                        </Link>
                        <EnumLabel
                            label={s?.status}
                            className={cn(tableStyle.cell)}
                        />
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.createdAt))}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.updatedAt))}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {dayMonthYearFormat.format(new Date(s?.validFrom))}
                        </p>
                    </Fragment>
                ))}
            </div>

            <Link to={`/pricing_lists/`} className={style.linkButton}>
                <button className={buttonStyle.orangeButton}>
                    {t("table:all_price_lists")}
                </button>
            </Link>

            <h2 className={style.sectionHeading}>
                {t("table:latest_assortment_groups")}
            </h2>
            <div
                className={style.table}
                style={{
                    gridTemplateColumns: "repeat(4, 1fr) repeat(3,10em)",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:name")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:action")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:products")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:status")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:valid_from")}
                />
                {loading && !data?.pricingLists && (
                    <TableContentLoader columns={7} />
                )}
                {data?.assortmentGroups?.page.map((ag, i) => (
                    <Fragment key={i}>
                        <Link
                            className={cn(tableStyle.cell, tableStyle.linkCell)}
                            to={`/assortment_group/${ag?.id}`}
                        >
                            {ag?.name}
                        </Link>
                        <EnumLabel
                            label={ag?.action}
                            className={cn(tableStyle.cell)}
                        />
                        <p className={tableStyle.cell}>
                            {integerFormatter.format(ag?.productAmount)}
                        </p>
                        <EnumLabel
                            label={ag?.status}
                            className={cn(tableStyle.cell)}
                        />
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(ag.createdAt))}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(ag.updatedAt))}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {dayMonthYearFormat.format(new Date(ag.validFrom))}
                        </p>
                    </Fragment>
                ))}
            </div>

            <Link to={`/assortment_groups/`} className={style.linkButton}>
                <button className={buttonStyle.orangeButton}>
                    {t("table:all_assortment_groups")}
                </button>
            </Link>

            <h2 className={style.sectionHeading}>
                {t("table:latest_pricing_list_groups")}
            </h2>
            <div
                className={style.table}
                style={{
                    gridTemplateColumns: "repeat(5, 1fr) 10em 10em 10em",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pricing_list_group")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:action")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:products")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:system_status")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:status")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:valid_from")}
                />
                {loading && !data?.productGroups && (
                    <TableContentLoader columns={9} />
                )}
                {data?.pricingListGroups?.page.map((pg, i) => (
                    <Fragment key={i}>
                        <Link
                            className={cn(tableStyle.cell, tableStyle.linkCell)}
                            to={`/pricing_list_group/${pg?.id}`}
                        >
                            {pg?.name}
                        </Link>
                        <EnumLabel
                            label={pg?.action}
                            className={cn(tableStyle.cell)}
                        />
                        <p className={tableStyle.cell}>
                            {integerFormatter.format(pg?.productAmount)}
                        </p>
                        <EnumLabel
                            label={pg?.status}
                            className={cn(tableStyle.cell)}
                        />
                        <EnumLabel
                            label={pg?.systemStatus}
                            className={cn(tableStyle.cell)}
                        />
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(pg?.createdAt))}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(pg?.updatedAt))}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {dayMonthYearFormat.format(new Date(pg?.validFrom))}
                        </p>
                    </Fragment>
                ))}
            </div>
            <Link to={`/pricing_list_groups/`} className={style.linkButton}>
                <button className={buttonStyle.orangeButton}>
                    {t("table:all_pricing_list_groups")}
                </button>
            </Link>

            <h2 className={style.sectionHeading}>{t("table:data_updates")}</h2>
            <div
                className={style.table}
                style={{
                    gridTemplateColumns: "2fr 1fr 1fr",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:newest_date")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                />
                {loading ? (
                    <TableContentLoader columns={3} />
                ) : (
                    <>
                        <p className={tableStyle.cell}>{t("table:ifa_date")}</p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(
                                new Date(data?.newestIfaUpdate?.newestDate),
                            )}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {dateFormat.format(
                                new Date(data?.newestIfaUpdate?.latestUpdate),
                            )}
                        </p>

                        <p className={tableStyle.cell}>
                            {t("table:agrosso_date")}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(
                                new Date(
                                    data?.newestProductAgrossoMetricUpdate?.newestDate,
                                ),
                            )}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {dateFormat.format(
                                new Date(
                                    data?.newestProductAgrossoMetricUpdate?.latestUpdate,
                                ),
                            )}
                        </p>

                        <p className={tableStyle.cell}>
                            {t("table:product_performance_date")}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(
                                new Date(
                                    data?.newestProductPerformanceUpdate?.newestDate,
                                ),
                            )}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {dateFormat.format(
                                new Date(
                                    data?.newestProductPerformanceUpdate?.latestUpdate,
                                ),
                            )}
                        </p>

                        <p className={tableStyle.cell}>
                            {t("table:pricing_list_performance_date")}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(
                                new Date(
                                    data?.newestProductPriceListPerformanceUpdate?.newestDate,
                                ),
                            )}
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.lastCell)}>
                            {dateFormat.format(
                                new Date(
                                    data?.newestProductPriceListPerformanceUpdate?.latestUpdate,
                                ),
                            )}
                        </p>
                    </>
                )}
            </div>
        </>
    )
}
