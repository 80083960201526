import modalStyle from "../../util/modals/Modal.module.css"
import { Modal } from "../../util/modals/Modal"
import { createPortal } from "react-dom"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Loader } from "../../util/Loader"
import { useState } from "react"
import { uniqueId } from "lodash"
import { useTranslation } from "react-i18next"
import { Toggle } from "../../util/input/Toggle"
import inputStyle from "../../../style/input.module.css"
import style from "./ModelConfigurationPopup.module.css"
import cn from "classnames"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import { showError } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"

const GET_SELECTION_OPTIONS = gql`
    {
        strategyGroups: pricingListGroups(
            limit: -1
            filterParams: { actionEquals: "STRATEGY" }
        ) {
            page {
                id
                name
                action
            }
        }
        priceGroups: pricingListGroups(
            limit: -1
            filterParams: { actionEquals: "PRICE" }
        ) {
            page {
                id
                name
                action
            }
        }
        strategies(limit: -1) {
            page {
                id
                name
            }
        }
    }
`

const CREATE = gql`
    mutation createMC(
        $name: String!
        $pricingListGroup: UUID!
        $strategy: UUID
        $pricingList: UUID!
    ) {
        createModelConfiguration(
            name: $name
            pricingListGroup: $pricingListGroup
            strategy: $strategy
            pricingList: $pricingList
        ) {
            id
            pricingList {
                id
            }
        }
    }
`

export const ModelConfigurationPopup = ({
    pricingListId,
    dismiss,
    refetchQueries,
}) => {
    const { t } = useTranslation(["common", "table"])

    const dispatch = useDispatch()

    const { data, loading } = useQuery(GET_SELECTION_OPTIONS)

    const [create, { loading: loadingCreate }] = useMutation(CREATE, {
        refetchQueries,
    })

    const [isStrategyConfig, setIsStrategyConfigBase] = useState(true)
    const [name, setName] = useState("")
    const [strategy, setStrategy] = useState("")
    const [pricingListGroup, setPricingListGroup] = useState("")

    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [pricing_list_group_input_id] = useState(() =>
        uniqueId("pricing_list_group_input"),
    )
    const [strategy_input_id] = useState(() => uniqueId("strategy_input_"))

    const setIsStrategyConfig = (isStrategy) => {
        setStrategy("")
        setPricingListGroup("")
        setIsStrategyConfigBase(isStrategy)
    }

    return createPortal(
        <Modal className={modalStyle.modal} dismiss={dismiss}>
            <h1>{t("table:create_new_model_configuration")}</h1>
            {loading || loadingCreate ? (
                <Loader />
            ) : (
                <div className={style.form}>
                    <label
                        onClick={() => setIsStrategyConfig(!isStrategyConfig)}
                    >
                        {t("table:is_strategy_config")}
                    </label>

                    <Toggle
                        setIsChecked={() =>
                            setIsStrategyConfig(!isStrategyConfig)
                        }
                        isChecked={isStrategyConfig}
                    />

                    <label htmlFor={name_input_id}>{t("table:name")}</label>

                    <input
                        id={name_input_id}
                        type={"text"}
                        className={inputStyle.text}
                        value={name}
                        onChange={(evt) => setName(evt.target.value)}
                    />

                    <label htmlFor={pricing_list_group_input_id}>
                        {t("table:pricing_list_group")}
                    </label>

                    <select
                        id={pricing_list_group_input_id}
                        value={pricingListGroup || ""}
                        onChange={(evt) =>
                            setPricingListGroup(evt.target.value)
                        }
                        className={inputStyle.select}
                    >
                        <option value="" />
                        {isStrategyConfig
                            ? data?.strategyGroups?.page?.map((m) => (
                                  <option key={m.id} value={m.id}>
                                      {m.name}
                                  </option>
                              ))
                            : data?.priceGroups?.page?.map((m) => (
                                  <option key={m.id} value={m.id}>
                                      {m.name}
                                  </option>
                              ))}
                    </select>

                    <label htmlFor={strategy_input_id}>
                        {t("table:strategy")}
                    </label>

                    <select
                        disabled={!isStrategyConfig}
                        id={strategy_input_id}
                        value={strategy || ""}
                        onChange={(evt) => setStrategy(evt.target.value)}
                        className={inputStyle.select}
                    >
                        <option value="" />
                        {data?.strategies?.page?.map((m) => (
                            <option key={m.id} value={m.id}>
                                {m.name}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            <button
                className={cn(buttonStyle.button)}
                onClick={() =>
                    create({
                        variables: {
                            name,
                            strategy: strategy || null,
                            pricingListGroup,
                            pricingList: pricingListId,
                        },
                    })
                        .then(() => dismiss())
                        .catch((e) => dispatch(showError(e.message)))
                }
                disabled={
                    !name ||
                    !pricingListGroup ||
                    (!strategy && isStrategyConfig)
                }
            >
                {c(t("common:save"))}
            </button>
        </Modal>,
        document.getElementById("modal"),
    )
}
