import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Link, Redirect, useHistory, useParams } from "react-router-dom"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import inputStyle from "../../../style/input.module.css"
import { uniqueId } from "lodash"
import { showProduct } from "../../../redux/actions/ModalActions"
import { useDispatch } from "react-redux"
import buttonStyle from "../../../style/button.module.css"
import { showError, showInfo } from "../../../redux/actions/NotificationActions"
import { EnumLabel } from "../../util/table/EnumLabel"
import { Loader } from "../../util/Loader"
import cn from "classnames"
import {
    getCurrencyFormatter,
    getIntegerFormatter,
    getSafeFormatter,
} from "../../../i18n"

const GET_PRICING_LIST_GROUP = gql`
    query pricingListGroup(
        $id: UUID!
        $pageNum: Int!
        $order: [PricingListGroupProductGroupOrder]
    ) {
        pricingListGroup(id: $id) {
            id
            name
            action
            isSystemGroup
            isUploading
            pricingListProducts(pageNum: $pageNum, orderBy: $order) {
                currentPage
                hasNext
                hasPrevious
                pages
                page {
                    id
                    type
                    minPrice
                    maxPrice
                    fixPriceBulk1
                    fixPriceBulk2
                    fixPriceBulk3
                    bulkAmount1
                    bulkAmount2
                    bulkAmount3
                    product {
                        id
                        name
                        pzn
                        unit
                        atc
                        quantity
                        prescriptionDrug
                        pharmacyOnly
                        supplierName
                        AEP
                        GEP
                        agrosso1
                        agrosso2
                        agrosso1Midas
                        agrosso2Midas
                        agrosso1Mvda
                        agrosso2Mvda
                        agrosso1Generic
                        agrosso2Generic
                        revenueGrossoTotal
                        revenueGrossoWholesale
                        revenueGrossoPhoenix
                        revenueGrossoDirect
                    }
                }
            }
        }
    }
`

const UPDATE_PRICING_LIST_GROUP = gql`
    mutation ($id: UUID!, $name: String) {
        updatePricingListGroup(id: $id, name: $name) {
            id
        }
    }
`

const EXPORT_CSV = gql`
    mutation send($id: UUID!) {
        sendPricingListGroupAsMail(pricingListGroupId: $id)
    }
`

export const PricingListGroupEditor = () => {
    const { id } = useParams()
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [query] = useState("")

    const [name, setName] = useState("")

    const [name_input_id] = useState(() => uniqueId("name_input_"))

    const { t, i18n } = useTranslation(["common", "table"])

    const currencyFormat = getSafeFormatter(getCurrencyFormatter(i18n.language))
    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    const history = useHistory()

    const { data, loading } = useQuery(GET_PRICING_LIST_GROUP, {
        variables: {
            pageNum,
            order,
            query,
            id,
        },
    })

    const [exportGroup] = useMutation(EXPORT_CSV)

    const [updatePricingListGroup, { loading: pricingListGroupLoading }] =
        useMutation(UPDATE_PRICING_LIST_GROUP, {
            refetchQueries: [GET_PRICING_LIST_GROUP],
        })

    useEffect(() => {
        let clear = -1

        if (data?.pricingListGroup?.isUploading) {
            clear = setTimeout(() => window.location.reload(), 1000 * 30)
        }

        setName(data?.pricingListGroup?.name || "")

        return () => window.clearTimeout(clear)
    }, [data?.pricingListGroup])

    const dispatch = useDispatch()

    if (!id || (!loading && !data?.pricingListGroup)) {
        return <Redirect to={"/"} />
    }

    return (
        <div>
            <Banner
                text={`${t("table:pricing_list_group")}: ${
                    data?.pricingListGroup?.name || ""
                }`}
            />
            <div className={tableStyle.stackedFilterBar}>
                {loading || pricingListGroupLoading ? (
                    <Loader />
                ) : (
                    <div className={tableStyle.filterBar}>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={name_input_id}
                                className={tableStyle.label}
                            >
                                {t("table:name")}
                            </label>
                            <input
                                disabled={data?.pricingListGroup?.isSystemGroup}
                                id={name_input_id}
                                className={inputStyle.text}
                                type="text"
                                value={name}
                                onChange={(evt) => setName(evt.target.value)}
                            />
                        </div>
                        <div className={tableStyle.filterInnerSection}>
                            <span className={tableStyle.label}>
                                {t("table:action")}
                            </span>
                            <EnumLabel
                                label={data?.pricingListGroup?.action}
                                className={tableStyle.filterInnerSectionStatic}
                            />
                        </div>
                    </div>
                )}
                <div className={tableStyle.buttonSection}>
                    {!loading &&
                        !data?.pricingListGroup?.isSystemGroup &&
                        !data?.pricingListGroup?.isUploading && (
                            <Link
                                to={
                                    data?.pricingListGroup?.action ===
                                    "STRATEGY"
                                        ? `/article_group/csv/${data?.pricingListGroup?.id}`
                                        : `/price/csv/${data?.pricingListGroup?.id}`
                                }
                                className={buttonStyle.button}
                            >
                                {t("table:reupload_csv")}
                            </Link>
                        )}
                    <button
                        className={buttonStyle.button}
                        onClick={() =>
                            exportGroup({
                                variables: {
                                    id,
                                },
                            })
                                .then(() =>
                                    dispatch(
                                        showInfo("common:export_triggered"),
                                    ),
                                )
                                .catch((e) => dispatch(showError(e.message)))
                        }
                    >
                        {t("table:download_csv")}
                    </button>
                    {!loading && (
                        <Link
                            to={`/model_configuration/?product_group_id=${id}&action=${data?.pricingListGroup?.action}`}
                            className={buttonStyle.orangeButton}
                        >
                            {t("table:create_configuration")}
                        </Link>
                    )}
                    <button
                        className={buttonStyle.button}
                        disabled={
                            loading ||
                            data?.pricingListGroup?.isSystemGroup ||
                            !name
                        }
                        onClick={() =>
                            updatePricingListGroup({
                                variables: {
                                    id,
                                    name,
                                },
                            })
                                .then((r) =>
                                    history.push(
                                        `/pricing_list_group/${r?.data?.updatePricingListGroup?.id}`,
                                    ),
                                )
                                .catch((e) => dispatch(showError(e.message)))
                        }
                    >
                        {t("table:save")}
                    </button>
                </div>
            </div>
            {!loading && !data?.pricingListGroup ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}

            {data?.pricingListGroup?.isUploading ? (
                <Loader />
            ) : (
                <>
                    <div
                        className={tableStyle.table}
                        style={{
                            gridTemplateColumns:
                                "repeat(6, 1fr) repeat(16, 0.5fr)",
                        }}
                    >
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:pzn")}
                            asc="ProductPznAsc"
                            desc="ProductPznDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:product_name")}
                            asc="ProductNameAsc"
                            desc="ProductNameDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:supplier_name")}
                            asc="ProductSupplierNameAsc"
                            desc="ProductSupplierNameDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:atc")}
                            asc="ProductAtcAsc"
                            desc="ProductAtcDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:unit")}
                            asc="ProductUnitAsc"
                            desc="ProductUnitDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:type")}
                            asc="TypeAsc"
                            desc="TypeDesc"
                            order={order}
                            setOrder={setOrder}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={`${t("table:fix_price")} ${t(
                                "table:bulk",
                            )} 1`}
                            asc="FixPriceBulk1_Asc"
                            desc="FixPriceBulk1_Desc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={`${t("table:bulk_amount")} 1`}
                            asc="BulkAmount1_Asc"
                            desc="BulkAmount1_Desc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={`${t("table:fix_price")} ${t(
                                "table:bulk",
                            )} 2`}
                            asc="FixPriceBulk2_Asc"
                            desc="FixPriceBulk2_Desc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={`${t("table:bulk_amount")} 2`}
                            asc="BulkAmount3_Asc"
                            desc="BulkAmount3_Desc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={`${t("table:fix_price")} ${t(
                                "table:bulk",
                            )} 3`}
                            asc="FixPriceBulk3_Asc"
                            desc="FixPriceBulk3_Desc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={`${t("table:bulk_amount")} 3`}
                            asc="BulkAmount3_Asc"
                            desc="BulkAmount3_Desc"
                            order={order}
                            setOrder={setOrder}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:min_price")}
                            asc="FixPriceAsc"
                            desc="FixPriceDesc"
                            order={order}
                            setOrder={setOrder}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:max_price")}
                            asc="FixPriceAsc"
                            desc="FixPriceDesc"
                            order={order}
                            setOrder={setOrder}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:aep")}
                            asc="ProductIfaMetricAEPAsc"
                            desc="ProductIfaMetricAEPDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:gep")}
                            asc="ProductIfaMetricGEPAsc"
                            desc="ProductIfaMetricGEPDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:agrosso") + " 1"}
                            asc="ProductAgrossoMetricAgrosso1_Asc"
                            desc="ProductAgrossoMetricAgrosso1_Desc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:agrosso") + " 2"}
                            asc="ProductAgrossoMetricAgrosso2_Asc"
                            desc="ProductAgrossoMetricAgrosso2_Desc"
                            order={order}
                            setOrder={setOrder}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:revenue_grosso_total")}
                            asc="ProductPerformanceMetricRevenueGrossoTotalAsc"
                            desc="ProductPerformanceMetricRevenueGrossoTotalDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:revenue_grosso_wholesale")}
                            asc="ProductPerformanceMetricRevenueGrossoWholesaleAsc"
                            desc="ProductPerformanceMetricRevenueGrossoWholesaleDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:revenue_grosso_phoenix")}
                            asc="ProductPerformanceMetricRevenueGrossoPhoenixAsc"
                            desc="ProductPerformanceMetricRevenueGrossoPhoenixDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:revenue_grosso_direct")}
                            asc="ProductPerformanceMetricRevenueGrossoDirectAsc"
                            desc="ProductPerformanceMetricRevenueGrossoDirectDesc"
                            order={order}
                            setOrder={setOrder}
                        />

                        {loading && !data?.pricingListGroup && (
                            <TableContentLoader columns={17} />
                        )}
                        {data?.pricingListGroup?.pricingListProducts?.page.map(
                            (s, i) => (
                                <Fragment key={i}>
                                    <p
                                        className={tableStyle.cell}
                                        onClick={() =>
                                            dispatch(
                                                showProduct(s?.product?.id),
                                            )
                                        }
                                    >
                                        {s?.product?.pzn}
                                    </p>
                                    <p
                                        className={tableStyle.cell}
                                        onClick={() =>
                                            dispatch(
                                                showProduct(s?.product?.id),
                                            )
                                        }
                                    >
                                        {s?.product?.name}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {s?.product?.supplierName}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {s?.product?.atc}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {s?.product?.unit}
                                    </p>
                                    <EnumLabel
                                        className={cn(tableStyle.cell)}
                                        label={s?.type}
                                    />
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.fixPriceBulk1,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {integerFormatter.format(
                                            s?.bulkAmount1,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.fixPriceBulk2,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {integerFormatter.format(
                                            s?.bulkAmount2,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.fixPriceBulk3,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {integerFormatter.format(
                                            s?.bulkAmount3,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(s?.minPrice)}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(s?.maxPrice)}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(s?.product?.AEP)}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(s?.product?.GEP)}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.agrosso1,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.agrosso2,
                                        )}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.revenueGrossoTotal,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.revenueGrossoWholesale,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.revenueGrossoPhoenix,
                                        )}
                                    </p>
                                    <p
                                        className={cn(
                                            tableStyle.cell,
                                            tableStyle.lastCell,
                                        )}
                                    >
                                        {currencyFormat.format(
                                            s?.product?.revenueGrossoDirect,
                                        )}
                                    </p>
                                </Fragment>
                            ),
                        )}
                    </div>
                    <Pagination
                        onPageChange={(page) => setPage(page)}
                        pagination={data?.pricingListGroup?.pricingListProducts}
                        loading={
                            (pricingListGroupLoading || loading) &&
                            !data?.pricingListGroup
                        }
                    />
                </>
            )}
        </div>
    )
}
