import ContentLoader from "react-content-loader"

/**
 * This element is the loading indicator for a form.
 *
 * @param rows the number of ghost rows the loader should show
 * @returns {JSX.Element} the content loader element
 * @constructor
 */
export const FormContentLoader = ({ rows = 3 }) => (
    <>
        {[...Array(rows)].map((_, i) => (
            <ContentLoader key={i} viewBox="0 0 100 20">
                <rect x="5" y="5" width="30" height="10" />
                <rect x="40" y="5" width="60" height="10" />
            </ContentLoader>
        ))}
        <ContentLoader viewBox="0 0 100 20">
            <rect x="40" y="5" width="60" height="10" />
        </ContentLoader>
    </>
)
