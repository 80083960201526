import style from "./Flash.module.css"
import cn from "classnames"

export const ERROR = "ERROR"
export const INFO = "INFO"
export const WARNING = "WARNING"

/**
 * This element is a colored banner with a message
 *
 * @param text the message the banner displays
 * @param type the type of message displayed, which determines the color
 * @returns {JSX.Element} the flash element
 * @constructor
 */
export const Flash = ({ text, type }) => {
    return (
        <p
            className={cn(
                style.flash,
                type === INFO && style.info,
                type === WARNING && style.warning,
                type === ERROR && style.error,
            )}
        >
            {text}
        </p>
    )
}
