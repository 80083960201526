import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import {
    getCurrencyFormatter,
    getDayMonthYearFormatter,
    getNumberFormatter,
    getPercentSafeFormatter,
    getSafeFormatter,
    getTimeFormatter,
} from "../../../i18n"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Link, useHistory, useParams } from "react-router-dom"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import inputStyle from "../../../style/input.module.css"
import { uniqueId } from "lodash"
import style from "../home/Home.module.css"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import { useDispatch } from "react-redux"
import { showError } from "../../../redux/actions/NotificationActions"
import { Loader } from "../../util/Loader"
import { EnterNameModal } from "../../util/modals/EnterNameModal"
import cn from "classnames"
import { ProductDetailExpander } from "../../util/ProductDetailExpander"
import { Pagination } from "../../util/table/Pagination"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import inputStyles from "../../../style/input.module.css"

const GET_MARKETING_LIST = gql`
    query (
        $id: UUID!
        $pageNum: Int
        $order: [MarketingGroupProductGroupOrder]
    ) {
        me {
            name
            id
        }
        list: marketingList(id: $id) {
            id
            name
            isUploading
            chosenCalculation {
                id
            }
            calculation: marketingListCalculations(limit: -1) {
                page {
                    id
                    name
                    calculationStatus
                }
            }
            pricingLists {
                id
                name
            }
            products: marketingProducts(
                limit: 20
                pageNum: $pageNum
                orderBy: $order
            ) {
                currentPage
                hasNext
                hasPrevious
                pages
                page {
                    id
                    marketingType
                    validFrom
                    validUntil
                    product {
                        id
                        pzn
                        name
                    }
                }
            }
        }
    }
`

const UPDATE_MARKETING_LIST = gql`
    mutation (
        $id: UUID!
        $name: String
        $pricingListIds: [UUID]
        $chosenCalculation: UUID
    ) {
        update: updateMarketingList(
            id: $id
            name: $name
            pricingListIds: $pricingListIds
            chosenCalculation: $chosenCalculation
        ) {
            id
            name
        }
    }
`

const TRIGGER_MARKETING_ML = gql`
    mutation ($id: UUID!, $name: String!) {
        trigger: triggerMarketingRun(name: $name, marketingListId: $id) {
            id
        }
    }
`

const GET_ALLOCATION_LIST = gql`
    query (
        $id: UUID!
        $pageNum: Int
        $order: [AllocationGroupProductGroupOrder]
    ) {
        me {
            name
            id
        }
        list: allocationList(id: $id) {
            id
            name
            isUploading
            chosenCalculation {
                id
            }
            calculation: allocationListCalculations(limit: -1) {
                page {
                    id
                    name
                    calculationStatus
                }
            }
            products: allocationProducts(
                limit: 20
                pageNum: $pageNum
                orderBy: $order
            ) {
                currentPage
                hasNext
                hasPrevious
                pages
                page {
                    id
                    bundle
                    extraCondition
                    validFrom
                    validUntil
                    product {
                        id
                        pzn
                        name
                    }
                }
            }
        }
    }
`

const UPDATE_ALLOCATION_LIST = gql`
    mutation ($id: UUID!, $name: String, $chosenCalculation: UUID) {
        update: updateAllocationList(
            id: $id
            name: $name
            chosenCalculation: $chosenCalculation
        ) {
            id
            name
        }
    }
`

const TRIGGER_ALLOCATION_ML = gql`
    mutation ($id: UUID!, $name: String!, $dependentId: UUID!) {
        trigger: triggerAllocationRun(
            name: $name
            allocationListId: $id
            dependentId: $dependentId
        ) {
            id
        }
    }
`

const GET_PRICING_LISTS = gql`
    query ($threeMonthsAgo: DateTime) {
        pricingLists(
            limit: -1
            filterParams: { updatedAtEqualOrLarger: $threeMonthsAgo }
        ) {
            page {
                id
                pricingListType {
                    id
                    pricingListTypeNumber
                }
                name: displayName
                chosenCalculation {
                    id
                }
            }
        }
    }
`

export const MarketingListEditor = ({ isAllocation }) => {
    const today = new Date()

    const { id } = useParams()

    const [name, setName] = useState("")
    const [pricingLists, setPricingLists] = useState([])
    const [chosenCalculation, setChosenCalculation] = useState("")

    const [unsavedChange, setUnsavedChange] = useState(false)

    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [type_pricing_lists_id] = useState(() =>
        uniqueId("type_pricing_lists_"),
    )
    const [chosenCalculation_id] = useState(() =>
        uniqueId("chosenCalculation_"),
    )
    const [dependent_chooser_id] = useState(() =>
        uniqueId("dependent_chooser_"),
    )

    const { t, i18n } = useTranslation(["common", "table"])
    const dayMonthYearFormat = getSafeFormatter(
        getDayMonthYearFormatter(i18n.language),
    )
    const timeFormat = getSafeFormatter(getTimeFormatter(i18n.language))
    const currencyFormat = getSafeFormatter(getCurrencyFormatter(i18n.language))
    const percentFormat = getPercentSafeFormatter(
        getNumberFormatter(i18n.language),
    )

    const [namePickerShown, setNamePickerShown] = useState(false)
    const [dependentId, setDependentId] = useState("")

    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])

    const [expanderProduct, setExpanderProduct] = useState(null)

    const { data, loading } = useQuery(
        isAllocation ? GET_ALLOCATION_LIST : GET_MARKETING_LIST,
        {
            variables: {
                id,
                order,
                pageNum,
            },
            skip: !id,
        },
    )

    const { data: pricingListData, loading: pricingListLoading } = useQuery(
        GET_PRICING_LISTS,
        {
            variables: {
                threeMonthsAgo: new Date(
                    Date.UTC(today.getFullYear(), today.getMonth() - 3, 0),
                ).toISOString(),
            },
        },
    )

    const [updateMarketingList, { loading: marketingListLoading }] =
        useMutation(
            isAllocation ? UPDATE_ALLOCATION_LIST : UPDATE_MARKETING_LIST,
            {
                refetchQueries: [GET_MARKETING_LIST, GET_ALLOCATION_LIST],
            },
        )

    const [triggerML, { loading: triggerMlLoading }] = useMutation(
        isAllocation ? TRIGGER_ALLOCATION_ML : TRIGGER_MARKETING_ML,
    )

    useEffect(() => {
        setName(data?.list?.name || "")
        setPricingLists(data?.list?.pricingLists?.map((p) => p.id) || [])
        setChosenCalculation(data?.list?.chosenCalculation?.id || "")
    }, [data?.list])

    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <div>
            {namePickerShown ? (
                <EnterNameModal
                    text={t(
                        isAllocation
                            ? "table:name_allocation_list_calculation"
                            : "table:name_marketing_list_calculation",
                    )}
                    defaultText={`${c(
                        t(
                            isAllocation
                                ? "table:allocation_calculation"
                                : "table:marketing_calculation",
                        ),
                    )} ${timeFormat.format(new Date())} [${
                        data?.me?.name || ""
                    }]`}
                    onCancel={() => setNamePickerShown(false)}
                    loading={triggerMlLoading}
                    disabled={isAllocation && !dependentId}
                    onNameSet={(name) => {
                        triggerML({
                            variables: {
                                id,
                                name,
                                dependentId,
                            },
                        })
                            .then(() =>
                                history.push(
                                    isAllocation
                                        ? `/allocation/${id}/calculations`
                                        : `/marketing/${id}/calculations`,
                                ),
                            )
                            .catch((e) => {
                                setNamePickerShown(false)
                                dispatch(showError(e.message))
                            })
                    }}
                >
                    {isAllocation ? (
                        <div className={style.dependentBox}>
                            <label htmlFor={dependent_chooser_id}>
                                {t("table:is_dependent_on")}
                            </label>
                            <select
                                className={inputStyles.select}
                                value={dependentId}
                                onChange={(evt) =>
                                    setDependentId(evt.target.value)
                                }
                            >
                                <option value="" />
                                {pricingListData?.pricingLists?.page
                                    .filter(
                                        (d) =>
                                            d?.chosenCalculation?.id &&
                                            d?.pricingListType
                                                ?.pricingListTypeNumber ===
                                                "204",
                                    )
                                    .map((d, i) => (
                                        <option key={i} value={d?.id}>
                                            {d.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    ) : null}
                </EnterNameModal>
            ) : null}
            <Banner
                text={`${t(
                    isAllocation ? "allocation_list" : "table:marketing_list",
                )}: ${data?.list?.name || ""}`}
            />
            <div className={tableStyle.stackedFilterBar}>
                {loading || marketingListLoading ? (
                    <Loader />
                ) : (
                    <div className={tableStyle.filterBar}>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={name_input_id}
                                className={tableStyle.label}
                            >
                                {t("table:name")}
                            </label>
                            <input
                                id={name_input_id}
                                className={inputStyle.text}
                                type="text"
                                value={name}
                                onChange={(evt) => setName(evt.target.value)}
                            />
                        </div>
                        {isAllocation ? null : (
                            <div className={tableStyle.filterInnerSection}>
                                <label
                                    htmlFor={type_pricing_lists_id}
                                    className={tableStyle.label}
                                >
                                    {t("table:pricing_lists")}
                                </label>
                                <Select
                                    isMulti={true}
                                    className={style.selectMany}
                                    placeholder={""}
                                    value={pricingLists?.map((a) => ({
                                        value: a,
                                        label: pricingListData?.pricingLists?.page.find(
                                            (as) => as.id === a,
                                        )?.name,
                                    }))}
                                    onChange={(as) => {
                                        setPricingLists(as.map((a) => a.value))
                                        setUnsavedChange(true)
                                    }}
                                    loadingMessage={() => t("common:loading")}
                                    isLoading={pricingListLoading}
                                    components={makeAnimated()}
                                    options={pricingListData?.pricingLists?.page?.map(
                                        (a) => ({
                                            value: a.id,
                                            label: a.name,
                                        }),
                                    )}
                                    id={type_pricing_lists_id}
                                />
                            </div>
                        )}
                        {data?.list?.id ? (
                            <div className={tableStyle.filterInnerSection}>
                                <label
                                    htmlFor={chosenCalculation_id}
                                    className={tableStyle.label}
                                >
                                    {t("table:chosen_calculation")}
                                </label>
                                <select
                                    id={chosenCalculation_id}
                                    className={inputStyle.select}
                                    disabled={data?.list?.status === "CLOSED"}
                                    onChange={(evt) =>
                                        setChosenCalculation(evt.target.value)
                                    }
                                    value={chosenCalculation}
                                >
                                    <option value="" />
                                    {data?.list?.calculation?.page
                                        ?.filter(
                                            (calc) =>
                                                calc.calculationStatus ===
                                                "DONE",
                                        )
                                        .map((calc, i) => (
                                            <option key={i} value={calc.id}>
                                                {calc.name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        ) : null}
                    </div>
                )}
                <div className={tableStyle.buttonSection}>
                    {data?.list?.id ? (
                        <button
                            className={buttonStyle.orangeButton}
                            title={
                                unsavedChange
                                    ? t("common:save_before_running")
                                    : null
                            }
                            disabled={
                                loading ||
                                unsavedChange ||
                                (!isAllocation && !pricingLists.length)
                            }
                            onClick={() => setNamePickerShown(true)}
                        >
                            {t("table:run_calculation")}
                        </button>
                    ) : null}

                    {data?.list?.calculation?.page?.length ? (
                        <Link
                            className={buttonStyle.lightBlueButton}
                            to={
                                isAllocation
                                    ? `/allocation/${data?.list?.id}/calculations`
                                    : `/marketing/${data?.list?.id}/calculations`
                            }
                        >
                            {c(t("table:see_calculations"))}
                        </Link>
                    ) : null}

                    <button
                        className={buttonStyle.button}
                        disabled={!name}
                        onClick={() =>
                            updateMarketingList({
                                variables: {
                                    id,
                                    name,
                                    pricingListIds: pricingLists,
                                    chosenCalculation:
                                        chosenCalculation || null,
                                },
                            })
                                .then((r) => {
                                    history.push(
                                        isAllocation
                                            ? `/allocation/${r?.data?.update?.id}`
                                            : `/marketing/${r?.data?.update?.id}`,
                                    )
                                    setUnsavedChange(false)
                                })
                                .catch((e) => dispatch(showError(e.message)))
                        }
                    >
                        {t("table:save")}
                    </button>
                    <Link
                        to={
                            isAllocation
                                ? `/allocation/csv/${id}`
                                : `/marketing/csv/${id}`
                        }
                        className={buttonStyle.button}
                    >
                        {c(t("table:reupload_csv"))}
                    </Link>
                </div>
            </div>
            {!data?.list?.isUploading ? (
                <>
                    <h2 className={style.sectionHeading}>
                        {t("table:products")}
                    </h2>
                    <div
                        className={tableStyle.table}
                        style={{
                            gridTemplateColumns: isAllocation
                                ? "repeat(6, 1fr)"
                                : "repeat(5, 1fr)",
                        }}
                    >
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:pzn")}
                            asc="ProductPznAsc"
                            desc="ProductPznDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:name")}
                            asc="ProductNameAsc"
                            desc="ProductNameDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        {isAllocation ? null : (
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:marketing_type")}
                                asc="MarketingTypeAsc"
                                desc="MarketingTypeDesc"
                                order={order}
                                setOrder={setOrder}
                            />
                        )}
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:valid_from")}
                            asc="ValidFromAsc"
                            desc="ValidFromDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:valid_until")}
                            asc="ValidUntilAsc"
                            desc="ValidUntilDesc"
                            order={order}
                            setOrder={setOrder}
                        />
                        {isAllocation ? (
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:bundle")}
                                asc="MarketingTypeAsc"
                                desc="MarketingTypeDesc"
                                order={order}
                                setOrder={setOrder}
                            />
                        ) : null}
                        {isAllocation ? (
                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:extra_condition")}
                                asc="MarketingTypeAsc"
                                desc="MarketingTypeDesc"
                                order={order}
                                setOrder={setOrder}
                            />
                        ) : null}
                        {(marketingListLoading || loading) && !data?.list && (
                            <TableContentLoader columns={8} />
                        )}
                        {data?.list?.products?.page.map((s, i) => (
                            <Fragment key={i}>
                                <p
                                    className={tableStyle.cell}
                                    onClick={() =>
                                        setExpanderProduct({
                                            ...s?.product,
                                            row: i,
                                        })
                                    }
                                >
                                    {s?.product?.pzn}
                                </p>
                                <p
                                    className={tableStyle.cell}
                                    onClick={() =>
                                        setExpanderProduct({
                                            ...s?.product,
                                            row: i,
                                        })
                                    }
                                >
                                    {s?.product?.name}
                                </p>
                                {isAllocation ? null : (
                                    <p className={tableStyle.cell}>
                                        {s?.marketingType}
                                    </p>
                                )}
                                <p className={tableStyle.cell}>
                                    {dayMonthYearFormat.format(
                                        new Date(s?.validFrom),
                                    )}
                                </p>
                                <p
                                    className={cn(
                                        tableStyle.cell,
                                        isAllocation
                                            ? null
                                            : tableStyle.lastCell,
                                    )}
                                >
                                    {dayMonthYearFormat.format(
                                        new Date(s?.validUntil),
                                    )}
                                </p>
                                {isAllocation ? (
                                    <>
                                        <p className={cn(tableStyle.cell)}>
                                            {currencyFormat.format(s?.bundle)}
                                        </p>
                                        <p
                                            className={cn(
                                                tableStyle.cell,
                                                tableStyle.lastCell,
                                            )}
                                        >
                                            {percentFormat.format(
                                                isNaN(s?.extraCondition)
                                                    ? null
                                                    : s?.extraCondition / 100,
                                            )}
                                        </p>
                                    </>
                                ) : null}
                                <ProductDetailExpander
                                    isShown={
                                        expanderProduct &&
                                        expanderProduct?.id ===
                                            s?.product?.id &&
                                        expanderProduct?.row === i
                                    }
                                    close={() => setExpanderProduct(null)}
                                    product={s?.product}
                                />
                            </Fragment>
                        ))}
                    </div>
                    <Pagination
                        onPageChange={(page) => setPage(page)}
                        pagination={data?.list?.products}
                        loading={loading && !data?.list?.products}
                    />
                </>
            ) : (
                <Loader />
            )}
        </div>
    )
}
