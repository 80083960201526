import { Banner } from "../../util/Banner"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import { useCallback, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useHistory } from "react-router-dom"
import { Loader } from "../../util/Loader"
import { uniqueId } from "lodash"
import inputStyle from "../../../style/input.module.css"
import style from "../groups/GroupUpload.module.css"
import cn from "classnames"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import tableStyle from "../../../style/table.module.css"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { showError } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"
import downloadCsv from "js-file-download"
import { Toggle } from "../../util/input/Toggle"

const ASSORTMENT_QUERY = gql`
    query {
        assortments(limit: -1, filterParams: { systemStatusEquals: "ACTIVE" }) {
            page {
                id
                name
            }
        }
    }
`

const UPLOAD_FILE = gql`
    mutation (
        $file: Upload!
        $assortmentIds: [UUID]!
        $date: DateTime!
        $force: Boolean
        $metaTypes: [MetaType]!
    ) {
        changeMetaDates(
            file: $file
            assortmentIds: $assortmentIds
            date: $date
            metaTypes: $metaTypes
            force: $force
        )
    }
`

export const CalculationSettingUpload = () => {
    const { t } = useTranslation(["enums", "table", "common"])
    const history = useHistory()

    const { data, loading: assortmentLoading } = useQuery(ASSORTMENT_QUERY)

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles[0]) {
            setFile(acceptedFiles[0])
        }
    }, [])

    const [upload, { loading }] = useMutation(UPLOAD_FILE)

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    const today = new Date()

    const [file, setFile] = useState("")
    const [metaTypes, setMetaTypes] = useState([])
    const [force, setForce] = useState(false)
    const [assortments, setAssortments] = useState([])

    const [date, setDate] = useState(today.toISOString().substring(0, 10))

    const [meta_type_input_id] = useState(() => uniqueId("meta_type_input_"))
    const [type_assortment_id] = useState(() => uniqueId("date_id_"))
    const [date_id] = useState(() => uniqueId("date_"))

    const [ignore_missing_product_id] = useState(() =>
        uniqueId("ignore_missing_product_"),
    )

    const dispatch = useDispatch()

    if (loading) {
        return (
            <>
                <Banner text={t("table:upload_calculation_setting_dates")} />
                <Loader />
            </>
        )
    }

    const options = [
        { value: "AGROSSO", label: c(t("enums:agrosso")) },
        { value: "IFA", label: c(t("enums:ifa")) },
        { value: "HISTORIC_IFA", label: c(t("enums:historic_ifa")) },
        { value: "COMPETITOR", label: c(t("enums:competitor")) },
        { value: "PERFORMANCE", label: c(t("enums:performance")) },
        {
            value: "PRICE_LIST_PERFORMANCE",
            label: c(t("enums:price_list_performance")),
        },
    ]

    return (
        <>
            <Banner text={t("table:upload_calculation_setting_dates")} />
            <div className={tableStyle.stackedFilterBar}>
                <div className={tableStyle.filterBar}>
                    <div className={tableStyle.filterInnerSection}>
                        <label htmlFor={meta_type_input_id}>
                            {t("table:type")}
                        </label>
                        <Select
                            isMulti={true}
                            className={style.selectMany}
                            placeholder={""}
                            id={meta_type_input_id}
                            defaultValue={metaTypes?.map((t) =>
                                options.find((opt) => opt.value === t),
                            )}
                            components={makeAnimated()}
                            onChange={(ts) =>
                                setMetaTypes(ts.map((t) => t.value))
                            }
                            options={options}
                        />
                    </div>
                    <div className={tableStyle.filterInnerSection}>
                        <label
                            htmlFor={type_assortment_id}
                            className={tableStyle.label}
                        >
                            {t("table:assortments")}
                        </label>
                        <Select
                            isMulti={true}
                            className={style.selectMany}
                            placeholder={""}
                            defaultValue={assortments?.map((a) => ({
                                value: a,
                                label: data?.assortments?.page.find(
                                    (as) => as.id === a,
                                )?.name,
                            }))}
                            onChange={(as) =>
                                setAssortments(as.map((a) => a.value))
                            }
                            loadingMessage={() => t("common:loading")}
                            isLoading={assortmentLoading}
                            components={makeAnimated()}
                            options={data?.assortments?.page?.map((a) => ({
                                value: a.id,
                                label: a.name,
                            }))}
                            id={type_assortment_id}
                        />
                    </div>
                    <div className={tableStyle.filterInnerSection}>
                        <label htmlFor={date_id} className={tableStyle.label}>
                            {t("table:reference_date")}
                        </label>
                        <input
                            type="date"
                            id={date_id}
                            value={date}
                            onChange={(evt) => setDate(evt.target.value)}
                            className={inputStyle.text}
                        />
                    </div>
                    <div className={tableStyle.filterInnerSection}>
                        <label
                            htmlFor={ignore_missing_product_id}
                            className={tableStyle.label}
                        >
                            {t("table:ignore_missing_product")}
                        </label>
                        <Toggle
                            id={ignore_missing_product_id}
                            isChecked={force}
                            setIsChecked={setForce}
                        />
                    </div>
                </div>
                <div className={tableStyle.buttonSection}>
                    <button
                        className={buttonStyle.button}
                        disabled={
                            !file ||
                            !metaTypes?.length ||
                            !assortments?.length ||
                            !date
                        }
                        onClick={() =>
                            upload({
                                variables: {
                                    file,
                                    metaTypes,
                                    date: new Date(date),
                                    force,
                                    assortmentIds: assortments,
                                },
                            })
                                .then((r) =>
                                    r
                                        ? history.push(`/targeted_data_updates`)
                                        : dispatch(showError("no_changes")),
                                )
                                .catch((e) => dispatch(showError(e.message)))
                        }
                    >
                        {c(t("table:upload"))}
                    </button>
                    <button
                        onClick={() =>
                            downloadCsv(
                                "pzn",
                                "setting_upload.csv",
                                "application/csv",
                            )
                        }
                        className={buttonStyle.button}
                    >
                        {t("table:download_template_csv")}
                    </button>
                </div>
            </div>
            {!file && (
                <div className={style.dropWrapper}>
                    <div
                        className={cn(
                            style.dropZone,
                            isDragActive && style.highlight,
                        )}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <h2>{t("table:drop_to_upload")}</h2>
                        ) : (
                            <h2>{t("table:drag_to_upload")}</h2>
                        )}
                    </div>
                </div>
            )}
            {file && (
                <div className={style.uploadedList}>
                    <div className={style.fileBox}>
                        <p>{file?.name || t("table:file")}</p>
                        <button
                            className={cn(
                                tableStyle.actionButton,
                                tableStyle.negativeActionButton,
                            )}
                            onClick={() => setFile(null)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
