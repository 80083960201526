import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import "../../../style/index.css"
import style from "./Impact.module.css"
import { Fragment, useState } from "react"
import { Flash, WARNING } from "../../util/Flash"
import tableStyle from "../../../style/table.module.css"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Link } from "react-router-dom"
import cn from "classnames"

import {
    getCurrencyFormatter,
    getIntegerFormatter,
    getNumberFormatter,
    getPercentSafeFormatter,
    getSafeFormatter,
} from "../../../i18n"

const GET_PLAUSI_IMPACT = gql`
    query ($plausibilityRunId: UUID!) {
        impactPerPricingList(plausibilityRunId: $plausibilityRunId) {
            calculatedDb
            previousDb
            deltaDb
            deltaDbRx
            deltaDbNonRx
            calculatedGrossmargin
            previousGrossmargin
            deltaGrossmargin
            deltaGrossmarginRx
            deltaGrossmarginNonRx
            calculatedEs
            calculatedEsRx
            calculatedEsNonRx
            previousEs
            deltaEs
            deltaEsRx
            deltaEsNonRx
            calculatedMargin
            calculatedMarginRx
            calculatedMarginNonRx
            previousMargin
            deltaMargin
            deltaMarginRx
            deltaMarginNonRx
            deltaMarketShare
            deltaMarketShareRx
            deltaMarketShareNonRx
            count
            countRx
            countNonRx
            revenue
            revenueRx
            revenueNonRx
            id
            name
            pricingListType {
                id
                displayName
                pricingListTypeNumber
            }
        }
        impactPerKviClass: largeImpactPerKviClass(
            plausibilityRunId: $plausibilityRunId
        ) {
            calculatedDb
            previousDb
            deltaDb
            deltaDbRx
            deltaDbNonRx
            calculatedGrossmargin
            previousGrossmargin
            deltaGrossmargin
            deltaGrossmarginRx
            deltaGrossmarginNonRx
            calculatedEs
            calculatedEsRx
            calculatedEsNonRx
            previousEs
            deltaEs
            deltaEsRx
            deltaEsNonRx
            calculatedMargin
            calculatedMarginRx
            calculatedMarginNonRx
            previousMargin
            deltaMargin
            deltaMarginRx
            deltaMarginNonRx
            deltaMarketShare
            deltaMarketShareRx
            deltaMarketShareNonRx
            count
            countRx
            countNonRx
            revenue
            revenueRx
            revenueNonRx
            kviClass
            isIncrease
            isDecrease
            isSame
            noPrevPrice
        }
        totalImpact: totalLargeImpact(plausibilityRunId: $plausibilityRunId) {
            calculatedDb
            previousDb
            deltaDb
            deltaDbRx
            deltaDbNonRx
            calculatedGrossmargin
            previousGrossmargin
            deltaGrossmargin
            deltaGrossmarginRx
            deltaGrossmarginNonRx
            calculatedEs
            calculatedEsRx
            calculatedEsNonRx
            previousEs
            deltaEs
            deltaEsRx
            deltaEsNonRx
            calculatedMargin
            calculatedMarginRx
            calculatedMarginNonRx
            previousMargin
            deltaMargin
            deltaMarginRx
            deltaMarginNonRx
            deltaMarketShare
            deltaMarketShareRx
            deltaMarketShareNonRx
            count
            countRx
            countNonRx
            revenue
            revenueRx
            revenueNonRx
        }
    }
`

const GET_CALCULATION_IMPACT = gql`
    query ($calculationId: UUID!) {
        impactPerKviClass: calculationImpactPerKviClass(
            calculationId: $calculationId
        ) {
            calculatedDb
            previousDb
            deltaDb
            deltaDbRx
            deltaDbNonRx
            calculatedGrossmargin
            previousGrossmargin
            deltaGrossmargin
            deltaGrossmarginRx
            deltaGrossmarginNonRx
            calculatedEs
            calculatedEsRx
            calculatedEsNonRx
            previousEs
            deltaEs
            deltaEsRx
            deltaEsNonRx
            calculatedMargin
            calculatedMarginRx
            calculatedMarginNonRx
            previousMargin
            deltaMargin
            deltaMarginRx
            deltaMarginNonRx
            deltaMarketShare
            deltaMarketShareRx
            deltaMarketShareNonRx
            count
            countRx
            countNonRx
            revenue
            revenueRx
            revenueNonRx
            kviClass
            isIncrease
            isDecrease
            isSame
            noPrevPrice
        }
        totalImpact(calculationId: $calculationId) {
            calculatedDb
            previousDb
            deltaDb
            deltaDbRx
            deltaDbNonRx
            calculatedGrossmargin
            previousGrossmargin
            deltaGrossmargin
            deltaGrossmarginRx
            deltaGrossmarginNonRx
            calculatedEs
            calculatedEsRx
            calculatedEsNonRx
            previousEs
            deltaEs
            deltaEsRx
            deltaEsNonRx
            calculatedMargin
            calculatedMarginRx
            calculatedMarginNonRx
            previousMargin
            deltaMargin
            deltaMarginRx
            deltaMarginNonRx
            deltaMarketShare
            deltaMarketShareRx
            deltaMarketShareNonRx
            count
            countRx
            countNonRx
            revenue
            revenueRx
            revenueNonRx
        }
    }
`

export const Impact = ({ plausibilityRunId, calculationId, children }) => {
    const { t } = useTranslation(["common", "table"])

    const [showImpact, setShowImpact] = useState(false)

    const { data: plausiData, loading: plausiLoading } = useQuery(
        GET_PLAUSI_IMPACT,
        {
            variables: {
                plausibilityRunId,
            },
            skip: !plausibilityRunId || !showImpact,
        },
    )

    const { data: calculationData, loading: calculationLoading } = useQuery(
        GET_CALCULATION_IMPACT,
        {
            variables: {
                calculationId,
            },
            skip: !calculationId || !showImpact,
        },
    )

    const loading = calculationLoading || plausiLoading
    const data = plausiData || calculationData

    return (
        <div>
            <div>
                <div className={style.buttonBar}>
                    <button
                        onClick={() => setShowImpact(false)}
                        style={
                            !showImpact
                                ? { borderBottom: "0.125em solid var(--green)" }
                                : null
                        }
                    >
                        {t("common:overview")}
                    </button>
                    <button
                        onClick={() => setShowImpact(true)}
                        style={
                            showImpact
                                ? { borderBottom: "0.125em solid var(--green)" }
                                : null
                        }
                    >
                        {t("common:impact")}
                    </button>
                </div>
                {showImpact ? (
                    <div>
                        {!loading && !data?.impactPerKviClass?.length ? (
                            <Flash
                                text={t("table:no_results")}
                                type={WARNING}
                            />
                        ) : null}
                        <div
                            className={cn(tableStyle.table, style.table)}
                            style={{
                                gridTemplateColumns:
                                    "20em 20em 1em repeat(9, 1fr) 1em repeat(9, 1fr) 1em repeat(9, 1fr)",
                            }}
                        >
                            <p style={{ gridColumn: "span 3" }} />
                            <p
                                style={{ gridColumn: "span 9" }}
                                className={style.header}
                            >
                                {t("table:all")}
                            </p>
                            <p />
                            <p
                                style={{ gridColumn: "span 9" }}
                                className={style.header}
                            >
                                {t("table:all_non_rx")}
                            </p>
                            <p />
                            <p
                                style={{ gridColumn: "span 9" }}
                                className={style.header}
                            >
                                {t("table:all_rx")}
                            </p>

                            <p />
                            <p />
                            <p />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:count")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:revenue_aep")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_db")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_grossmargin")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_ma")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:calculated_es")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_es")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:calculated_margin")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_margin")}
                            />

                            <p />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:count")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:revenue")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_db")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_grossmargin")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_ma")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:calculated_es")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_es")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:calculated_margin")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_margin")}
                            />

                            <p />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:count")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:revenue")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_db")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_grossmargin")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_ma")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:calculated_es")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_es")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:calculated_margin")}
                            />

                            <TableHeader
                                className={tableStyle.header}
                                label={t("table:delta_margin")}
                            />

                            <h1 className={style.impactHeader}>
                                {t("table:general_impact")}
                            </h1>

                            <Filler isShown={!loading} />

                            {loading && !data?.impactPerPricingList && (
                                <TableContentLoader columns={22} rows={16} />
                            )}

                            {data?.totalImpact && (
                                <>
                                    <p
                                        className={cn(
                                            tableStyle.fullWidthCell,
                                            tableStyle.topCell,
                                            style.spanTwoCell,
                                            tableStyle.lastCell,
                                            tableStyle.boldCell,
                                        )}
                                    >
                                        {t("table:total")}
                                    </p>
                                </>
                            )}

                            {data?.totalImpact && (
                                <ImpactRow
                                    row={data?.totalImpact}
                                    isHeader={true}
                                />
                            )}

                            {data?.impactPerPricingList?.map((s, i) => (
                                <Fragment key={i}>
                                    <Link
                                        className={cn(
                                            tableStyle.fullWidthCell,
                                            tableStyle.linkCell,
                                            s?.name === "total"
                                                ? tableStyle.boldCell
                                                : null,
                                        )}
                                        to={`/pricing_list/${s?.id}`}
                                    >
                                        {s?.name}
                                    </Link>

                                    <p
                                        className={cn(
                                            tableStyle.fullWidthCell,
                                            tableStyle.lastCell,
                                        )}
                                    >
                                        {s?.pricingListType?.displayName} (
                                        {
                                            s?.pricingListType
                                                ?.pricingListTypeNumber
                                        }
                                        )
                                    </p>

                                    <ImpactRow row={s} />
                                </Fragment>
                            ))}

                            <h1 className={style.impactHeader}>
                                {t("table:general_impact_increases")}
                            </h1>

                            <Filler
                                isShown={!loading && data?.impactPerKviClass}
                            />

                            {loading && !data?.impactPerKviClass && (
                                <TableContentLoader columns={22} rows={7} />
                            )}

                            {data?.impactPerKviClass
                                ?.filter(
                                    (s) =>
                                        s.isIncrease &&
                                        !s.isSame &&
                                        !s.noPrevPrice,
                                )
                                ?.map((s, i) => (
                                    <Fragment key={i}>
                                        <p
                                            className={cn(
                                                tableStyle.fullWidthCell,
                                                tableStyle.linkCell,
                                                style.spanTwoCell,
                                                tableStyle.lastCell,
                                                i === 0
                                                    ? tableStyle.topCell
                                                    : null,
                                                s?.kviClass === "total"
                                                    ? tableStyle.boldCell
                                                    : null,
                                            )}
                                        >
                                            {t(`table:${s?.kviClass}`)}
                                        </p>

                                        <ImpactRow row={s} isHeader={i === 0} />
                                    </Fragment>
                                ))}

                            <h1 className={style.impactHeader}>
                                {t("table:general_impact_decreases")}
                            </h1>

                            <Filler
                                isShown={!loading && data?.impactPerKviClass}
                            />

                            {loading && !data?.impactPerKviClass && (
                                <TableContentLoader columns={22} rows={7} />
                            )}

                            {data?.impactPerKviClass
                                ?.filter(
                                    (s) =>
                                        s.isDecrease &&
                                        !s.isSame &&
                                        !s.noPrevPrice,
                                )
                                ?.map((s, i) => (
                                    <Fragment key={i}>
                                        <p
                                            className={cn(
                                                tableStyle.fullWidthCell,
                                                tableStyle.linkCell,
                                                style.spanTwoCell,
                                                tableStyle.lastCell,
                                                i === 0
                                                    ? tableStyle.topCell
                                                    : null,
                                                s?.kviClass === "total"
                                                    ? tableStyle.boldCell
                                                    : null,
                                            )}
                                        >
                                            {t(`table:${s?.kviClass}`)}
                                        </p>

                                        <ImpactRow row={s} isHeader={i === 0} />
                                    </Fragment>
                                ))}

                            <h1 className={style.impactHeader}>
                                {t("table:general_impact_same")}
                            </h1>

                            <Filler
                                isShown={!loading && data?.impactPerKviClass}
                            />

                            {loading && !data?.impactPerKviClass && (
                                <TableContentLoader columns={22} rows={7} />
                            )}

                            {data?.impactPerKviClass
                                ?.filter((s) => s.isSame && !s.noPrevPrice)
                                ?.map((s, i) => (
                                    <Fragment key={i}>
                                        <p
                                            className={cn(
                                                tableStyle.fullWidthCell,
                                                tableStyle.linkCell,
                                                style.spanTwoCell,
                                                tableStyle.lastCell,
                                                i === 0
                                                    ? tableStyle.topCell
                                                    : null,
                                                s?.kviClass === "total"
                                                    ? tableStyle.boldCell
                                                    : null,
                                            )}
                                        >
                                            {t(`table:${s?.kviClass}`)}
                                        </p>

                                        <ImpactRow row={s} isHeader={i === 0} />
                                    </Fragment>
                                ))}

                            <h1 className={style.impactHeader}>
                                {t("table:general_impact_no_prev_price")}
                            </h1>

                            <Filler
                                isShown={!loading && data?.impactPerKviClass}
                            />

                            {loading && !data?.impactPerKviClass && (
                                <TableContentLoader columns={22} rows={7} />
                            )}

                            {data?.impactPerKviClass
                                ?.filter((s) => s.noPrevPrice)
                                ?.map((s, i) => (
                                    <Fragment key={i}>
                                        <p
                                            className={cn(
                                                tableStyle.fullWidthCell,
                                                tableStyle.linkCell,
                                                style.spanTwoCell,
                                                tableStyle.lastCell,
                                                i === 0
                                                    ? tableStyle.topCell
                                                    : null,
                                                s?.kviClass === "total"
                                                    ? tableStyle.boldCell
                                                    : null,
                                            )}
                                        >
                                            {t(`table:${s?.kviClass}`)}
                                        </p>

                                        <ImpactRow row={s} isHeader={i === 0} />
                                    </Fragment>
                                ))}
                        </div>
                    </div>
                ) : (
                    children
                )}
            </div>
        </div>
    )
}

const Filler = ({ isShown }) => {
    if (!isShown) {
        return null
    }

    return (
        <>
            <p />
            {[...Array(8)].map((_, i) => (
                <p key={i} className={tableStyle.cell} />
            ))}
            <p className={cn(tableStyle.cell, tableStyle.lastCell)} />
            <p />
            {[...Array(8)].map((_, i) => (
                <p key={i} className={tableStyle.cell} />
            ))}
            <p className={cn(tableStyle.cell, tableStyle.lastCell)} />
            <p />
            {[...Array(8)].map((_, i) => (
                <p key={i} className={tableStyle.cell} />
            ))}
            <p className={cn(tableStyle.cell, tableStyle.lastCell)} />
        </>
    )
}

const ImpactRow = ({ row, isHeader }) => {
    const { i18n } = useTranslation()

    const currencyFormat = getSafeFormatter(
        getCurrencyFormatter(i18n.language, true),
    )
    const integerFormat = getSafeFormatter(getIntegerFormatter(i18n.language))
    const percentFormatter = getPercentSafeFormatter(
        getNumberFormatter(i18n.language),
    )

    return (
        <Fragment>
            <p />
            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {integerFormat.format(row?.count)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {currencyFormat.format(row?.revenue)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {currencyFormat.format(row?.deltaDb)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {currencyFormat.format(row?.deltaGrossmargin)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.deltaMarketShare)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.calculatedEs)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.deltaEs)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.calculatedMargin)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    tableStyle.lastCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.deltaMargin)}
            </p>

            <p />

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {integerFormat.format(row?.countNonRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {currencyFormat.format(row?.revenueNonRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {currencyFormat.format(row?.deltaDbNonRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {currencyFormat.format(row?.deltaGrossmarginNonRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.deltaMarketShareNonRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.calculatedEsNonRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.deltaEsNonRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.calculatedMarginNonRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    tableStyle.lastCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.deltaMarginNonRx)}
            </p>

            <p />

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {integerFormat.format(row?.countRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {currencyFormat.format(row?.revenueRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {currencyFormat.format(row?.deltaDbRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {currencyFormat.format(row?.deltaGrossmarginRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.deltaMarketShareRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.calculatedEsRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.deltaEsRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.calculatedMarginRx)}
            </p>

            <p
                className={cn(
                    tableStyle.fullWidthCell,
                    tableStyle.lastCell,
                    isHeader ? tableStyle.boldCell : null,
                )}
            >
                {percentFormatter.format(row?.deltaMarginRx)}
            </p>
        </Fragment>
    )
}
