import { createPortal } from "react-dom"
import styles from "./Notification.module.css"
import buttonStyle from "../../../style/button.module.css"
import inputStyle from "../../../style/input.module.css"
import cn from "classnames"
import { animated, useSpring } from "react-spring"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import c from "capitalize"
import { Loader } from "../Loader"

/**
 * This element is a modal with a text edit field and a confirm/cancel button
 *
 * @param message the message the user is shown
 * @param onNameSet the function that gets called with the entered text
 * @param onCancel the function that gets called when the user cancels the dialog
 * @param defaultText the default text of the textfield
 * @param loading if true, spinner is shown and the modal cant be interacted with
 * @param children additional content in the modal
 * @param disabled if the confirm button is disabled
 * @returns {JSX.Element} the content of the dialog
 * @constructor
 */
const EnterNameContent = ({
    message,
    onNameSet,
    onCancel,
    defaultText,
    loading,
    children,
    disabled = false,
}) => {
    const { t } = useTranslation(["common"])

    const warningProps = useSpring({
        transform: "scale(1)",
        from: { transform: "scale(0)" },
    })

    const wrapperProps = useSpring({
        opacity: 1,
        from: { opacity: 0 },
    })

    const [text, setText] = useState("")

    useEffect(() => setText(defaultText), [defaultText])

    return (
        <animated.div className={styles.wrapper} style={wrapperProps}>
            <animated.div
                className={styles.notificationBox}
                style={warningProps}
            >
                <p className={cn(styles.topBar, styles.info)}>{message}</p>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <input
                            value={text}
                            onChange={(evt) => setText(evt.target.value)}
                            placeholder={defaultText}
                            className={cn(inputStyle.text, styles.input)}
                        />
                        {children}
                    </>
                )}
                <div className={styles.buttonBar}>
                    <button
                        className={cn(
                            buttonStyle.button,
                            buttonStyle.bigButton,
                        )}
                        disabled={!text || loading || disabled}
                        onClick={() => onNameSet(text)}
                    >
                        {t("ok")}
                    </button>
                    <button
                        disabled={loading}
                        className={cn(
                            buttonStyle.button,
                            buttonStyle.bigButton,
                        )}
                        onClick={onCancel}
                    >
                        {c(t("cancel"))}
                    </button>
                </div>
            </animated.div>
        </animated.div>
    )
}

/**
 *
 * @param onNameSet the function that gets called with the entered text
 * @param onCancel the function that gets called when the user cancels the dialog
 * @param text the message the user is shown
 * @param defaultText the default text of the textfield
 * @param loading if true, spinner is shown and the modal cant be interacted with
 * @param children additional content in the modal
 * @param disabled if the confirm button is disabled
 * @returns {EnterNameModal} the input modal as a portal
 * @constructor
 */
export const EnterNameModal = ({
    onNameSet,
    onCancel,
    text,
    defaultText,
    loading,
    children,
    disabled,
}) => {
    return createPortal(
        <EnterNameContent
            onNameSet={onNameSet}
            onCancel={onCancel}
            message={text}
            loading={loading}
            defaultText={defaultText}
            disabled={disabled}
        >
            {children}
        </EnterNameContent>,
        document.getElementById("confirm"),
    )
}
