import {
    HIDE_ALL_MODALS,
    SHOW_PRODUCT,
    SHOW_SIDEBAR_MODAL,
} from "../reducers/ModalReducer"

/**
 * Builds the action that shows the sidebar, unused currently
 * @returns {{type: string}} the action that shows the sidebar
 */
export const showSidebarModal = () => {
    return {
        type: SHOW_SIDEBAR_MODAL,
    }
}

/**
 * Builds the action that shows the sidebar with product details
 * @param product the product details should be shown for
 * @returns {{product, type: string}} the action that shows the product sidebar
 */
export const showProduct = (product) => {
    return {
        type: SHOW_PRODUCT,
        product,
    }
}

/**
 * Vuilds the action that hides all sidebar modals
 * @returns {{type: string}} the action that hides all sidebar modals
 */
export const hideModals = () => {
    return {
        type: HIDE_ALL_MODALS,
    }
}
