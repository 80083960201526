import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Link } from "react-router-dom"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { EnumLabel } from "../../util/table/EnumLabel"
import cn from "classnames"
import { Pagination } from "../../util/table/Pagination"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { DeletionWithAffectedModal } from "../../util/modals/DeletionWithAffectedModal"
import { flatten, uniq } from "lodash"
import { ListCell } from "../../util/table/ListCell"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import { SmartFilter } from "../../util/input/SmartFilter"
import { BoolLabel } from "../../util/table/BoolLabel"

const PRICING_LIST_GROUP_LIST = gql`
    query pricingListGroupsList(
        $query: String
        $pageNum: Int
        $order: [PricingListGroupSearchOrder]
        $filter: PricingListGroupSearchFilter
    ) {
        pricingListGroupSearch(
            limit: 20
            pageNum: $pageNum
            query: $query
            orderBy: $order
            filterParams: $filter
        ) {
            currentPage
            hasNext
            hasPrevious
            suggestedQuery
            pages
            page {
                id
                name
                action
                systemStatus
                status
                isSystemGroup
                productAmount
                createdAt
                updatedAt
                modelConfigurations(limit: -1) {
                    page {
                        id
                        pricingList {
                            id
                            name: displayName
                        }
                    }
                }
            }
        }
    }
`

const FILTER_QUERY = gql`
    query getFilter {
        pricingListGroupSearch(limit: 0) {
            filterFields {
                name
                dataType
                displayName
                options
            }
        }
    }
`

const DELETE_PRICING_LIST_GROUP = gql`
    mutation ($id: UUID!, $ignoreAffected: Boolean) {
        delete: deletePricingListGroup(
            id: $id
            ignoreAffected: $ignoreAffected
        ) {
            success
            affected {
                model
                name
                id
            }
            blockers {
                model
                name
                id
            }
        }
    }
`

export const PricingListGroupList = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [query, setQuery] = useState("")
    const [filter, setFilter] = useState(null)
    const [currentElement, setCurrentElement] = useState(null)

    const { t, i18n } = useTranslation(["common", "table"])

    const { data, loading } = useQuery(PRICING_LIST_GROUP_LIST, {
        variables: {
            pageNum,
            order,
            query,
            filter,
        },
    })

    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    return (
        <div>
            <Banner text={t("table:pricing_list_groups")} />

            <div className={dropDownStyle.container}>
                <SmartFilter
                    filterFieldQuery={FILTER_QUERY}
                    filterPath={(q) => q?.pricingListGroupSearch?.filterFields}
                    setQuery={setQuery}
                    setFilter={setFilter}
                    suggestedQuery={
                        data?.pricingListGroupSearch?.suggestedQuery
                    }
                />
            </div>

            {currentElement && (
                <DeletionWithAffectedModal
                    id={currentElement?.id}
                    name={currentElement?.name}
                    delete_mutation={DELETE_PRICING_LIST_GROUP}
                    onDismiss={() => setCurrentElement(null)}
                    refetchQueries={[PRICING_LIST_GROUP_LIST]}
                    onCancel={() => setCurrentElement(null)}
                />
            )}

            {!loading && !data?.pricingListGroupSearch.page?.length ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}
            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "1fr 1fr 2fr repeat(3, 1fr) 6em 3em",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pricing_list_group")}
                    asc="NameAsc"
                    desc="NameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:action")}
                    asc="ActionAsc"
                    desc="ActionDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pricing_lists")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                    asc="CreatedAtAsc"
                    desc="CreatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                    asc="UpdatedAtAsc"
                    desc="UpdatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:status")}
                    asc="StatusAsc"
                    desc="StatusDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:is_system_group")}
                    asc="IsSystemGroupAsc"
                    desc="IsSystemGroupDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <p className={tableStyle.header} />

                {loading && !data?.pricingListGroupSearch && (
                    <TableContentLoader columns={8} />
                )}
                {data?.pricingListGroupSearch?.page.map((s, i) => (
                    <Fragment key={i}>
                        <Link
                            className={cn(tableStyle.cell, tableStyle.linkCell)}
                            to={`/pricing_list_group/${s?.id}`}
                        >
                            {s?.name}
                        </Link>
                        <EnumLabel
                            className={tableStyle.cell}
                            label={s?.action}
                        />
                        <ListCell
                            className={tableStyle.cell}
                            labels={uniq(
                                flatten(
                                    s.modelConfigurations?.page?.map(
                                        (m) => m?.pricingList,
                                    ),
                                ),
                            ).filter(Boolean)}
                            destination={"pricing_list"}
                        />
                        <p className={tableStyle.cell}>
                            {s?.createdAt
                                ? dateFormat.format(new Date(s?.createdAt))
                                : null}
                        </p>
                        <p className={tableStyle.cell}>
                            {s?.updatedAt
                                ? dateFormat.format(new Date(s?.updatedAt))
                                : null}
                        </p>
                        <EnumLabel
                            className={cn(tableStyle.cell)}
                            label={s?.status}
                        />
                        <BoolLabel
                            className={cn(tableStyle.cell)}
                            status={s.isSystemGroup}
                        />
                        <p className={tableStyle.actionCell}>
                            {s.isSystemGroup ? null : (
                                <button
                                    className={cn(
                                        tableStyle.actionButton,
                                        tableStyle.negativeActionButton,
                                    )}
                                    onClick={() => {
                                        setCurrentElement({
                                            id: s.id,
                                            name: s.name,
                                        })
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            )}
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.pricingListGroupSearch}
                loading={loading && !data?.pricingListGroupSearch}
            />
        </div>
    )
}
