import { Banner } from "../../util/Banner"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import { useCallback, useEffect, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useHistory, useParams } from "react-router-dom"
import { Loader } from "../../util/Loader"
import { uniqueId } from "lodash"
import inputStyle from "../../../style/input.module.css"
import style from "./GroupUpload.module.css"
import cn from "classnames"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import tableStyle from "../../../style/table.module.css"
import buttonStyle from "../../../style/button.module.css"
import c from "capitalize"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { showError } from "../../../redux/actions/NotificationActions"
import { useDispatch } from "react-redux"
import downloadCsv from "js-file-download"
import { Toggle } from "../../util/input/Toggle"
import { Redirect } from "react-router-dom"

const ASSORTMENT_QUERY = gql`
    query {
        assortments(
            limit: -1
            filterParams: {
                isComplimentEquals: false
                systemStatusEquals: "ACTIVE"
            }
        ) {
            page {
                id
                name: displayName
            }
        }
    }
`

const UPLOAD_FILE = gql`
    mutation (
        $file: Upload!
        $name: String!
        $assortmentIds: [UUID]!
        $force: Boolean
        $type: String!
        $id: UUID
    ) {
        createAssortmentGroupFromCsv(
            file: $file
            name: $name
            assortmentIds: $assortmentIds
            action: $type
            force: $force
            id: $id
        ) {
            id
        }
    }
`

const GET_ASSORTMENT_GROUP = gql`
    query ($id: UUID!) {
        assortmentGroup(id: $id) {
            id
            name
            action
            isSystemGroup
            assortments(limit: -1) {
                page {
                    id
                    name
                }
            }
        }
    }
`

export const EnDelistUpload = () => {
    const { t } = useTranslation(["enums", "table", "common"])
    const history = useHistory()

    const { data: assortmentData, loading: assortmentLoading } =
        useQuery(ASSORTMENT_QUERY)

    const { id } = useParams()

    const [name, setName] = useState("")
    const [file, setFile] = useState("")
    const [type, setType] = useState("ENLIST")
    const [force, setForce] = useState(false)
    const [assortments, setAssortments] = useState([])

    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [type_input_id] = useState(() => uniqueId("type_input_"))
    const [type_assortment_id] = useState(() => uniqueId("type_assortment_"))
    const [ignore_missing_product_id] = useState(() =>
        uniqueId("ignore_missing_product_"),
    )

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles[0]) {
            setFile(acceptedFiles[0])
            if (!name) {
                setName(acceptedFiles[0].name || "")
            }
        }
    }, [])

    const [upload, { loading }] = useMutation(UPLOAD_FILE)

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    const { data, loading: assortmentGroupLoading } = useQuery(
        GET_ASSORTMENT_GROUP,
        {
            variables: {
                id,
            },
            skip: !id,
        },
    )

    useEffect(() => {
        setName(data?.assortmentGroup?.name || "")
        setType(data?.assortmentGroup?.action || "ENLIST")
        setAssortments(
            data?.assortmentGroup?.assortments?.page?.map((a) => a.id),
        )
    }, [data])

    const dispatch = useDispatch()

    if (loading) {
        return (
            <>
                <Banner text={t("table:upload_enlist_delist_group")} />
                <Loader />
            </>
        )
    }

    if (data?.assortmentGroup?.isSystemGroup) {
        return <Redirect to={"/enlist_and_delist/csv"} />
    }

    return (
        <>
            <Banner text={t("table:upload_enlist_delist_group")} />

            <div className={tableStyle.stackedFilterBar}>
                {loading || (id && assortmentGroupLoading) ? (
                    <Loader />
                ) : (
                    <div className={tableStyle.filterBar}>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={name_input_id}
                                className={tableStyle.label}
                            >
                                {t("table:name")}
                            </label>
                            <input
                                id={name_input_id}
                                className={inputStyle.text}
                                type="text"
                                value={name}
                                onChange={(evt) => setName(evt.target.value)}
                            />
                        </div>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={type_input_id}
                                className={tableStyle.label}
                            >
                                {t("table:type")}
                            </label>
                            <Select
                                id={type_input_id}
                                components={makeAnimated()}
                                onChange={(t) => setType(t.value)}
                                value={
                                    type === "ENLIST"
                                        ? {
                                              value: "ENLIST",
                                              label: c(t("enums:enlist")),
                                          }
                                        : {
                                              value: "DELIST",
                                              label: c(t("enums:delist")),
                                          }
                                }
                                options={[
                                    {
                                        value: "ENLIST",
                                        label: c(t("enums:enlist")),
                                    },
                                    {
                                        value: "DELIST",
                                        label: c(t("enums:delist")),
                                    },
                                ]}
                            />
                        </div>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={type_assortment_id}
                                className={tableStyle.label}
                            >
                                {t("table:assortments")}
                            </label>
                            <Select
                                isMulti={true}
                                className={style.selectMany}
                                placeholder={""}
                                value={assortments?.map((a) => ({
                                    value: a,
                                    label: assortmentData?.assortments?.page.find(
                                        (as) => as.id === a,
                                    )?.name,
                                }))}
                                onChange={(as) =>
                                    setAssortments(as.map((a) => a.value))
                                }
                                loadingMessage={() => t("common:loading")}
                                isLoading={assortmentLoading}
                                components={makeAnimated()}
                                options={assortmentData?.assortments?.page?.map(
                                    (a) => ({
                                        value: a.id,
                                        label: a.name,
                                    }),
                                )}
                                id={type_assortment_id}
                            />
                        </div>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={ignore_missing_product_id}
                                className={tableStyle.label}
                            >
                                {t("table:ignore_missing_product")}
                            </label>
                            <Toggle
                                id={ignore_missing_product_id}
                                isChecked={force}
                                setIsChecked={setForce}
                            />
                        </div>
                    </div>
                )}
                <div className={tableStyle.buttonSection}>
                    <button
                        className={buttonStyle.button}
                        disabled={
                            !name || !file || !type || !assortments?.length
                        }
                        onClick={() => {
                            upload({
                                variables: {
                                    file,
                                    name,
                                    type,
                                    force,
                                    id: id,
                                    assortmentIds: assortments,
                                },
                            })
                                .then((r) => {
                                    history.push(
                                        `/assortment_group/${r?.data?.createAssortmentGroupFromCsv?.id}`,
                                    )
                                })
                                .catch((e) => dispatch(showError(e.message)))
                        }}
                    >
                        {c(t("table:upload"))}
                    </button>
                    <button
                        onClick={() =>
                            downloadCsv(
                                "pzn",
                                "listing_upload.csv",
                                "application/csv",
                            )
                        }
                        className={buttonStyle.button}
                    >
                        {t("table:download_template_csv")}
                    </button>
                </div>
            </div>

            {!file && (
                <div className={style.dropWrapper}>
                    <div
                        className={cn(
                            style.dropZone,
                            isDragActive && style.highlight,
                        )}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <h2>{t("table:drop_to_upload")}</h2>
                        ) : (
                            <h2>{t("table:drag_to_upload")}</h2>
                        )}
                    </div>
                </div>
            )}
            {file && (
                <div className={style.uploadedList}>
                    <div className={style.fileBox}>
                        <p>{file?.name || t("table:file")}</p>
                        <button
                            className={cn(
                                tableStyle.actionButton,
                                tableStyle.negativeActionButton,
                            )}
                            onClick={() => setFile(null)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
