import cn from "classnames"
import { useTranslation } from "react-i18next"
import style from "./EnumLabel.module.css"
import { Link } from "react-router-dom"

/**
 * This element is a simple label which changes color depending on the content
 *
 * @param label the text that should be displayed, will be translated
 * @param className classname of  the label
 * @param link the link destination of the label
 * @param linkEnabled boolean if the link should be enabled or not
 * @returns {JSX.Element}  the enum label element
 * @constructor
 */
export const EnumLabel = ({ label, className, link, linkEnabled }) => {
    const { t } = useTranslation("enums")

    let color

    switch (label) {
        case "ACTIVE":
        case "ACCEPTED":
        case "FIX_PRICE":
        case "PRICE":
        case "STRATEGY":
        case "DONE":
        case "MIN_PRICE":
        case "ENLIST":
        case "NO_ISSUES":
        case "LOW":
        case "CLOSED":
            color = "var(--green)"
            break

        case "DELETED":
        case "LOCKED":
        case "DELIST":
        case "REJECTED":
        case "OUTDATED":
        case "ABORTED":
        case "ATTENTION":
        case "CRITICAL":
            color = "var(--red)"
            break

        case "WIP":
        case "PENDING":
        case "CREATED":
        case "CALCULATING":
        case "SOLVED":
        case "PRIO":
            color = "var(--orange)"
            break

        default:
            color = "var(--black)"
    }

    if (linkEnabled) {
        return (
            <Link to={link} className={cn(className, style.label)}>
                <span style={{ color }} className={style.badge}>
                    {t(`${label?.toLowerCase() || ""}`)}
                </span>
            </Link>
        )
    }

    return (
        <p className={cn(className, style.label)}>
            <span style={{ color }} className={style.badge}>
                {t(`${label?.toLowerCase() || ""}`)}
            </span>
        </p>
    )
}
