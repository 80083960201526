import { Banner } from "../../util/Banner"
import { gql, useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Loader } from "../../util/Loader"
import style from "./ProductDetail.module.css"
import { BoolLabel } from "../../util/table/BoolLabel"
import { Fragment } from "react"
import { CompetitorPriceTable } from "./CompetitorPriceTable"
import {
    getCurrencyFormatter,
    getIntegerFormatter,
    getSafeFormatter,
} from "../../../i18n"

const PRODUCT_QUERY = gql`
    query ($id: UUID!) {
        product(id: $id) {
            id
            pzn
            name
            replacedBy
            previousProduct
            quantity
            atc
            unit
            type
            nonBindingPrice
            administrationForm
            supplierNumber
            supplierName
            articleNumber
            modelCategorization
            ims
            wgr
            wgrDkart
            swg1
            swg2
            swg3
            tax
            taxable
            prescriptionDrug
            pharmacyOnly
            foodSupplement
            privateAssortmentBlocked
            privateAssortmentYear
            notAboveWholesale
            genericDrug
            reimport
            narcotic
            vetenaryDrug
            refrigeratedArticle
            cytostaticDrug
            tfg
            pharmaceutical
            medicalDrug
            vaccine
            minSpan
            noDiscount
            articleBlocked
            purchasingBlocked
            privateAssortmentBiosiegel
            privateAssortmentAlcohol
            sapNumber
            AEP
            GEP
            agrosso1
            agrosso2
            agrosso1Midas
            agrosso2Midas
            agrosso1Mvda
            agrosso2Mvda
            agrosso1Generic
            agrosso2Generic
            revenueGrossoTotal
            revenueGrossoWholesale
            revenueGrossoPhoenix
            revenueGrossoDirect
            pricingListPerformanceMetrics {
                salesQuantity
                revenue
                id
                pricingListType {
                    name
                    id
                }
            }
            productCategory {
                id
                categoryLevel1
                categoryLevel2
                categoryLevel3
                categoryLevel4
            }
            newestCompetitorPrices {
                id
                price
                validFrom
                competitorDisplayName
                bulkPrice1
                bulkAmount1
                bulkPrice2
                bulkAmount2
                bulkPrice3
                bulkAmount3
            }
        }
    }
`

export const ProductDetail = () => {
    const { id } = useParams()

    const { data, loading } = useQuery(PRODUCT_QUERY, {
        variables: {
            id,
        },
    })

    const { t, i18n } = useTranslation(["table"])
    const currencyFormat = getSafeFormatter(getCurrencyFormatter(i18n.language))
    const integerFormatter = getSafeFormatter(
        getIntegerFormatter(i18n.language),
    )

    if (loading || !data?.product) {
        return (
            <>
                <Banner text={t("product")} />
                <Loader />
            </>
        )
    }

    return (
        <>
            <Banner
                text={`${t("product")}: ${data?.product?.name} [${
                    data?.product?.pzn
                }]`}
            />
            <div className={style.wrapper}>
                <div>
                    <h1>{t("table:details")}</h1>
                    <div className={style.detailTable}>
                        <p>{t("table:pzn")}</p>
                        <p>{data.product?.pzn}</p>

                        <p>{t("table:name")}</p>
                        <p>{data.product?.name}</p>

                        <p>{t("table:category_level") + " 1"}</p>
                        <p>{data.product?.productCategory?.categoryLevel1}</p>

                        <p>{t("table:category_level") + " 2"}</p>
                        <p>{data.product?.productCategory?.categoryLevel2}</p>

                        <p>{t("table:category_level") + " 3"}</p>
                        <p>{data.product?.productCategory?.categoryLevel3}</p>

                        <p>{t("table:category_level") + " 4"}</p>
                        <p>{data.product?.productCategory?.categoryLevel4}</p>

                        <p>{t("table:replaced_by")}</p>
                        <p>{data.product?.replacedBy}</p>

                        <p>{t("table:previous_product")}</p>
                        <p>{data.product?.previousProduct}</p>

                        <p>{t("table:quantity")}</p>
                        <p>{integerFormatter.format(data.product?.quantity)}</p>

                        <p>{t("table:atc")}</p>
                        <p>{data.product?.atc}</p>

                        <p>{t("table:unit")}</p>
                        <p>{data.product?.unit}</p>

                        <p>{t("table:type")}</p>
                        <p>{data.product?.type}</p>

                        <p>{t("table:administration_form")}</p>
                        <p>{data.product?.administrationForm}</p>

                        <p>{t("table:non_binding_price")}</p>
                        <p>{data.product?.nonBindingPrice}</p>

                        <p>{t("table:supplier_number")}</p>
                        <p>{data.product?.supplierNumber}</p>

                        <p>{t("table:supplier_name")}</p>
                        <p>{data.product?.supplierName}</p>

                        <p>{t("table:article_number")}</p>
                        <p>{data.product?.articleNumber}</p>

                        <p>{t("table:model_categorization")}</p>
                        <p>{data.product?.modelCategorization}</p>

                        <p>{t("table:ims")}</p>
                        <p>{data.product?.ims}</p>

                        <p>{t("table:wgr")}</p>
                        <p>{data.product?.wgr}</p>

                        <p>{t("table:wgr_dkart")}</p>
                        <p>{data.product?.wgrDkart}</p>

                        <p>{t("table:swg") + " 1"}</p>
                        <p>{data.product?.swg1}</p>

                        <p>{t("table:swg") + " 2"}</p>
                        <p>{data.product?.swg2}</p>

                        <p>{t("table:swg") + " 3"}</p>
                        <p>{data.product?.swg3}</p>

                        <p>{t("table:tax")}</p>
                        <BoolLabel status={data.product?.tax} />

                        <p>{t("table:taxable")}</p>
                        <BoolLabel status={data.product?.taxable} />

                        <p>{t("table:prescription_drug")}</p>
                        <BoolLabel status={data.product?.prescriptionDrug} />

                        <p>{t("table:pharmacy_only")}</p>
                        <BoolLabel status={data.product?.pharmacyOnly} />

                        <p>{t("table:food_supplement")}</p>
                        <BoolLabel status={data.product?.foodSupplement} />

                        <p>{t("table:private_assortment_blocked")}</p>
                        <BoolLabel
                            status={data.product?.privateAssortmentBlocked}
                        />

                        <p>{t("table:private_assortment_year")}</p>
                        <p>{data.product?.privateAssortmentYear}</p>

                        <p>{t("table:not_above_wholesale")}</p>
                        <BoolLabel status={data.product?.notAboveWholesale} />

                        <p>{t("table:generic_drug")}</p>
                        <BoolLabel status={data.product?.genericDrug} />

                        <p>{t("table:reimport")}</p>
                        <BoolLabel status={data.product?.reimport} />

                        <p>{t("table:narcotic")}</p>
                        <BoolLabel status={data.product?.narcotic} />

                        <p>{t("table:vetenary_drug")}</p>
                        <BoolLabel status={data.product?.vetenaryDrug} />

                        <p>{t("table:refrigerated_article")}</p>
                        <BoolLabel status={data.product?.refrigeratedArticle} />

                        <p>{t("table:cytostatic_drug")}</p>
                        <BoolLabel status={data.product?.cytostaticDrug} />

                        <p>{t("table:tfg")}</p>
                        <BoolLabel status={data.product?.tfg} />

                        <p>{t("table:pharmaceutical")}</p>
                        <BoolLabel status={data.product?.pharmaceutical} />

                        <p>{t("table:medical_drug")}</p>
                        <BoolLabel status={data.product?.medicalDrug} />

                        <p>{t("table:vaccine")}</p>
                        <BoolLabel status={data.product?.vaccine} />

                        <p>{t("table:min_span")}</p>
                        <BoolLabel status={data.product?.minSpan} />

                        <p>{t("table:no_discount")}</p>
                        <BoolLabel status={data.product?.noDiscount} />

                        <p>{t("table:article_blocked")}</p>
                        <BoolLabel status={data.product?.articleBlocked} />

                        <p>{t("table:purchasing_blocked")}</p>
                        <BoolLabel status={data.product?.purchasingBlocked} />

                        <p>{t("table:private_assortment_biosiegel")}</p>
                        <p>{data.product?.privateAssortmentBiosiegel}</p>

                        <p>{t("table:private_assortment_alcohol")}</p>
                        <p>{data.product?.privateAssortmentAlcohol}</p>

                        <p>{t("table:sap_number")}</p>
                        <p>{data.product?.sapNumber}</p>
                    </div>
                </div>
                <div>
                    <h1>{t("table:metrics")}</h1>
                    <div className={style.detailTable}>
                        <p>{t("table:aep")}</p>
                        <p>{currencyFormat.format(data.product?.AEP)}</p>
                        <p>{t("table:gep")}</p>
                        <p>{currencyFormat.format(data.product?.GEP)}</p>
                        <p>{t("table:agrosso") + " 1  Generic"}</p>
                        <p>
                            {currencyFormat.format(
                                data.product?.agrosso1Generic,
                            )}
                        </p>
                        <p>{t("table:agrosso") + " 2  Generic"}</p>
                        <p>
                            {currencyFormat.format(
                                data.product?.agrosso2Generic,
                            )}
                        </p>

                        <p>{t("table:agrosso") + " 1  MVDA"}</p>
                        <p>
                            {currencyFormat.format(data.product?.agrosso1Mvda)}
                        </p>
                        <p>{t("table:agrosso") + " 2  MVDA"}</p>
                        <p>
                            {currencyFormat.format(data.product?.agrosso2Mvda)}
                        </p>

                        <p>{t("table:agrosso") + " 1  MIDAS"}</p>
                        <p>
                            {currencyFormat.format(data.product?.agrosso1Midas)}
                        </p>
                        <p>{t("table:agrosso") + " 2  MIDAS"}</p>
                        <p>
                            {currencyFormat.format(data.product?.agrosso2Midas)}
                        </p>

                        <p>{t("table:revenue_grosso_total")}</p>
                        <p>
                            {currencyFormat.format(
                                data.product?.revenueGrossoTotal,
                            )}
                        </p>
                        <p>{t("table:revenue_grosso_wholesale")}</p>
                        <p>
                            {currencyFormat.format(
                                data.product?.revenueGrossoWholesale,
                            )}
                        </p>
                        <p>{t("table:revenue_grosso_phoenix")}</p>
                        <p>
                            {currencyFormat.format(
                                data.product?.revenueGrossoPhoenix,
                            )}
                        </p>
                        <p>{t("table:revenue_grosso_direct")}</p>
                        <p>
                            {currencyFormat.format(
                                data.product?.revenueGrossoDirect,
                            )}
                        </p>
                        {data.product?.pricingListPerformanceMetrics?.map(
                            (plp) => (
                                <Fragment key={plp.id}>
                                    <p>{plp?.pricingListType?.name} </p>
                                    <div className={style.detailTable}>
                                        <p> {t("table:sales_quantity")}</p>
                                        <p>
                                            {integerFormatter.format(
                                                plp?.salesQuantity,
                                            )}
                                        </p>
                                        <p>{t("table:revenue")}</p>
                                        <p>
                                            {currencyFormat.format(
                                                plp?.revenue,
                                            )}
                                        </p>
                                    </div>
                                </Fragment>
                            ),
                        )}
                    </div>
                    {data?.product?.newestCompetitorPrices ? (
                        <h1>{t("table:competitors")}</h1>
                    ) : null}
                    <CompetitorPriceTable
                        competitorPrices={data?.product?.newestCompetitorPrices}
                    />
                </div>
            </div>
        </>
    )
}
