import { useSelector } from "react-redux"
import { animated, useSpring } from "react-spring"
import { createPortal } from "react-dom"
import style from "./LoginScreen.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMicrosoft } from "@fortawesome/free-brands-svg-icons"
import { useTranslation } from "react-i18next"
import { ShapeSVG } from "../ShapeSVG"

/**
 * This element displays the login overlay if the redux state says so
 *
 * @returns {LoginScreen|null} the auth portal
 * @constructor
 */
export const LoginScreen = () => {
    const isAuthShown = useSelector((s) => s.isAuthShown)

    const dropdownProps = useSpring({
        transform: "translate(0,0)",
        from: { transform: "translate(0,-100%)" },
    })

    const { t } = useTranslation("common")
    const urlParams = new URLSearchParams(window.location.search)

    if (isAuthShown) {
        return createPortal(
            <animated.div style={dropdownProps} className={style.wrapper}>
                <ShapeSVG className={style.shapeSVG} />
                <div className={style.loginBoxWrapper}>
                    <div className={style.loginBox}>
                        <h1>{t("login_with_ms")}</h1>
                        {urlParams.get("error") && (
                            <h2>{t(urlParams.get("error"))}</h2>
                        )}
                        <a className={style.loginButton} href="/auth/login">
                            <FontAwesomeIcon icon={faMicrosoft} />
                            {t("login")}
                        </a>
                    </div>
                </div>
            </animated.div>,
            document.getElementById("login"),
        )
    } else {
        return null
    }
}
