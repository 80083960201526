import { createPortal } from "react-dom"
import styles from "./Notification.module.css"
import buttonStyle from "../../../style/button.module.css"
import inputStyle from "../../../style/input.module.css"
import cn from "classnames"
import { animated, useSpring } from "react-spring"
import { useTranslation } from "react-i18next"
import { useMutation } from "@apollo/client"
import { Loader } from "../Loader"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { showError } from "../../../redux/actions/NotificationActions"

/**
 * This element is the content of the modal shown when deleting an object
 *
 * @param delete_mutation the GraphQL mutation that theles the object
 * @param id the ID of the object
 * @param onCancel the function that is called when the user cancels the operation
 * @param onDismiss the function that gets called if the dialog is dismissed
 * @param refetchQueries the queries that need to be refetched after the deletion
 * @param name the name of the model to be deleted
 * @returns {JSX.Element} the content of the deletion modal
 * @constructor
 */
const NotificationContent = ({
    delete_mutation,
    id,
    onCancel,
    onDismiss,
    refetchQueries,
    name,
}) => {
    const { t } = useTranslation(["common", "page_names"])

    const warningProps = useSpring({
        transform: "scale(1)",
        from: { transform: "scale(0)" },
    })

    const wrapperProps = useSpring({
        opacity: 1,
        from: { opacity: 0 },
    })

    const [safeDelete, { loading }] = useMutation(delete_mutation, {
        fetchPolicy: "network-only",
        refetchQueries,
    })

    const [affected, setAffected] = useState([])
    const [blockers, setBlockers] = useState([])
    const [validationCode, setValidationCode] = useState(
        (Math.random() + 1).toString(36).substring(3, 7),
    )
    const [validationName, setValidationNane] = useState("")

    const dispatch = useDispatch()

    useEffect(() => {
        setValidationCode((Math.random() + 1).toString(36).substring(3, 7))
    }, [id])

    return (
        <animated.div className={styles.wrapper} style={wrapperProps}>
            <animated.div
                className={styles.notificationBox}
                style={warningProps}
            >
                <p className={cn(styles.topBar, styles.warning)}>
                    {t("common:attention")}
                </p>
                {loading ? <Loader /> : null}
                {!loading ? (
                    <>
                        <p className={styles.message}>
                            {t("common:are_you_sure")}
                        </p>
                        {name ? (
                            <div className={styles.confirmationTextBox}>
                                <p>
                                    {t("common:enter_deletion_code")}:{" "}
                                    <span className={styles.bold}>
                                        {validationCode}
                                    </span>
                                </p>
                                <input
                                    onPaste={(evt) => evt.preventDefault()}
                                    value={validationName}
                                    onChange={(evt) =>
                                        setValidationNane(evt.target.value)
                                    }
                                    className={inputStyle.text}
                                />
                            </div>
                        ) : null}
                    </>
                ) : null}
                <ul className={styles.dependentDisplay}>
                    {affected.length ? (
                        <>
                            <li>{t("common:affected_models")}</li>
                            <ul>
                                {affected.map((a) => (
                                    <li key={a.id}>
                                        {t(`page_names:${a.model}`)}: {a.name}
                                    </li>
                                ))}
                            </ul>
                        </>
                    ) : null}
                    {blockers.length ? (
                        <>
                            <li>{t("common:blocker_models")}</li>
                            <ul>
                                {blockers.map((b) => (
                                    <li key={b.id}>
                                        {t(`page_names:${b.model}`)}: {b.name}
                                    </li>
                                ))}
                            </ul>
                        </>
                    ) : null}
                </ul>
                <div className={styles.buttonBar}>
                    <button
                        disabled={
                            blockers.length || validationCode !== validationName
                        }
                        className={cn(
                            buttonStyle.button,
                            buttonStyle.bigButton,
                        )}
                        onClick={() =>
                            safeDelete({
                                variables: {
                                    id,
                                    ignoreAffected: affected.length,
                                },
                            })
                                .then((r) => {
                                    if (r.data.delete.success) {
                                        onDismiss()
                                    } else {
                                        setBlockers(r.data.delete.blockers)
                                        setAffected(r.data.delete.affected)
                                    }
                                })
                                .catch((e) => dispatch(showError(e.message)))
                        }
                    >
                        {t("yes")}
                    </button>
                    <button
                        className={cn(
                            buttonStyle.button,
                            buttonStyle.bigButton,
                        )}
                        onClick={onCancel}
                    >
                        {t("no")}
                    </button>
                </div>
            </animated.div>
        </animated.div>
    )
}

/**
 * This element is the modal shown when deleting an object
 *
 * @param delete_mutation the GraphQL mutation that theles the object
 * @param id the ID of the object
 * @param onCancel the function that is called when the user cancels the operation
 * @param onDismiss the function that gets called if the dialog is dismissed
 * @param refetchQueries the queries that need to be refetched after the deletion
 * @param name the name of the model to be deleted
 * @returns {JSX.Element} the deletion modal
 * @constructor
 */
export const DeletionWithAffectedModal = ({
    delete_mutation,
    onCancel,
    onDismiss,
    id,
    refetchQueries,
    name,
}) => {
    return createPortal(
        <NotificationContent
            id={id}
            delete_mutation={delete_mutation}
            onCancel={onCancel}
            onDismiss={onDismiss}
            refetchQueries={refetchQueries}
            name={name}
        />,
        document.getElementById("confirm"),
    )
}
