import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { Flash, WARNING } from "../../util/Flash"
import "../../../style/index.css"
import tableStyle from "../../../style/table.module.css"
import buttonStyle from "../../../style/button.module.css"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import cn from "classnames"
import { Banner } from "../../util/Banner"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { DeletionWithAffectedModal } from "../../util/modals/DeletionWithAffectedModal"
import { flatten, uniq } from "lodash"
import { ListCell } from "../../util/table/ListCell"
import { SmartFilter } from "../../util/input/SmartFilter"

const STRATEGY_SEARCH_QUERY = gql`
    query (
        $query: String
        $pageNum: Int
        $order: [StrategySearchOrder]
        $filter: StrategySearchFilter
    ) {
        strategies: strategySearch(
            limit: 20
            pageNum: $pageNum
            orderBy: $order
            filterParams: $filter
            query: $query
        ) {
            currentPage
            hasNext
            hasPrevious
            suggestedQuery
            pages
            page {
                id
                name
                createdAt
                updatedAt
                systemStatus
                isSystemStrategy
                modelConfigurations(limit: -1) {
                    page {
                        id
                        pricingList {
                            id
                            name
                        }
                    }
                }
                model {
                    name
                }
            }
        }
    }
`

const FILTER_QUERY = gql`
    query getFilter {
        strategySearch(limit: 0) {
            filterFields {
                name
                dataType
                displayName
                options
            }
        }
    }
`

const DELETE_STRATEGY = gql`
    mutation ($id: UUID!, $ignoreAffected: Boolean) {
        delete: deleteStrategy(id: $id, ignoreAffected: $ignoreAffected) {
            success
            affected {
                model
                name
                id
            }
            blockers {
                model
                name
                id
            }
        }
    }
`

export const StrategyList = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [query, setQuery] = useState("")
    const [filter, setFilter] = useState(null)

    const { t, i18n } = useTranslation(["common", "table"])

    const { data, loading } = useQuery(STRATEGY_SEARCH_QUERY, {
        variables: {
            pageNum,
            order,
            query,
            filter,
        },
    })

    const [currentElement, setCurrentElement] = useState(null)

    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    return (
        <div>
            <Banner text={t("table:strategies")} />
            {currentElement && (
                <DeletionWithAffectedModal
                    id={currentElement?.id}
                    name={currentElement?.name}
                    delete_mutation={DELETE_STRATEGY}
                    onDismiss={() => setCurrentElement(null)}
                    refetchQueries={[STRATEGY_SEARCH_QUERY]}
                    onCancel={() => setCurrentElement(null)}
                />
            )}

            <div className={dropDownStyle.container}>
                <SmartFilter
                    filterFieldQuery={FILTER_QUERY}
                    filterPath={(q) => q?.strategySearch?.filterFields}
                    setQuery={setQuery}
                    setFilter={setFilter}
                    suggestedQuery={data?.strategySearch?.suggestedQuery}
                />
                <div className={tableStyle.buttonBar}>
                    <Link to={"/rule_strategy"} className={buttonStyle.button}>
                        {t("table:add_new_rule_strategy")}
                    </Link>
                    <Link to={"/ml_strategy"} className={buttonStyle.button}>
                        {t("table:add_new_ml_strategy")}
                    </Link>
                </div>
            </div>

            {!loading && !data?.strategies.page?.length ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}
            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "1fr 1fr 4fr 1fr 1fr 3em",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:name")}
                    asc="NameAsc"
                    desc="NameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:model")}
                    asc="ModelNameAsc"
                    desc="ModelNameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pricing_lists")}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                    asc="ModelNameAsc"
                    desc="ModelNameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                    asc="ModelNameAsc"
                    desc="ModelNameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <p className={tableStyle.header} />
                {loading && !data?.strategies && (
                    <TableContentLoader columns={6} />
                )}
                {data?.strategies.page.map((s, i) => (
                    <Fragment key={i}>
                        <p className={cn(tableStyle.cell, tableStyle.linkCell)}>
                            <Link
                                to={`/${s?.isMl ? "ml" : "rule"}_strategy/${
                                    s?.id
                                }`}
                            >
                                {s.name}
                            </Link>
                        </p>
                        <p className={cn(tableStyle.cell, tableStyle.linkCell)}>
                            {s.model?.name}
                        </p>
                        <ListCell
                            className={tableStyle.cell}
                            labels={uniq(
                                flatten(
                                    s.modelConfigurations?.page?.map(
                                        (m) => m?.pricingList,
                                    ),
                                ),
                            ).filter(Boolean)}
                            destination={"pricing_list"}
                        />
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.createdAt))}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.updatedAt))}
                        </p>
                        <p className={tableStyle.actionCell}>
                            {s?.isSystemStrategy ? null : (
                                <button
                                    className={cn(
                                        tableStyle.actionButton,
                                        tableStyle.negativeActionButton,
                                    )}
                                    onClick={() => {
                                        setCurrentElement({
                                            id: s.id,
                                            name: s.name,
                                        })
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            )}
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.strategies}
                loading={loading && !data?.strategies}
            />
        </div>
    )
}
