import style from "./Toogle.module.css"

/**
 * This element is a toggle which works like a checkbox
 *
 * @param isChecked value the toggle should display
 * @param setIsChecked function that is called when the toggle is clicked
 * @param disabled set to true to disable the toggle
 * @returns {JSX.Element} the toggle element
 * @constructor
 */
export const Toggle = ({ isChecked, setIsChecked, disabled }) => {
    if (!setIsChecked) {
        setIsChecked = () => {
            console.warn("Toggle not connected")
        }
        console.warn("Toggle not connected")
    }

    return (
        <div
            className={style.toggle}
            style={{
                justifyContent: isChecked ? "flex-end" : "flex-start",
                backgroundColor: isChecked
                    ? "var(--green)"
                    : "var(--lightGray)",
            }}
            onClick={() => {
                if (!disabled) {
                    setIsChecked(!isChecked)
                }
            }}
        >
            <span
                className={style.handle}
                style={{
                    backgroundColor: disabled
                        ? "var(--darkGray)"
                        : "var(--white)",
                }}
            />
        </div>
    )
}
