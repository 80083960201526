import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { Flash, WARNING } from "../../util/Flash"
import "../../../style/index.css"
import tableStyle from "../../../style/table.module.css"
import buttonStyle from "../../../style/button.module.css"
import dropDownStyle from "../../util/input/DropDownFilter.module.css"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { EnumLabel } from "../../util/table/EnumLabel"
import { Pagination } from "../../util/table/Pagination"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import cn from "classnames"
import { Banner } from "../../util/Banner"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { DeletionWithAffectedModal } from "../../util/modals/DeletionWithAffectedModal"
import { SmartFilter } from "../../util/input/SmartFilter"

const MODEL_CONFIGURATION_SEARCH_QUERY = gql`
    query modelConfigurationsList(
        $query: String
        $pageNum: Int
        $order: [ModelConfigurationSearchOrder]
        $filter: ModelConfigurationSearchFilter
    ) {
        modelConfigurations: modelConfigurationSearch(
            limit: 20
            pageNum: $pageNum
            orderBy: $order
            filterParams: $filter
            query: $query
        ) {
            currentPage
            hasNext
            hasPrevious
            suggestedQuery
            pages
            page {
                id
                name
                systemStatus
                pricingListGroup {
                    id
                    name
                }
                strategy {
                    id
                    name
                }
                pricingList {
                    id
                    name: displayName
                }
                createdAt
                updatedAt
            }
        }
    }
`

const FILTER_QUERY = gql`
    query getFilter {
        modelConfigurations: modelConfigurationSearch(limit: 0) {
            filterFields {
                name
                dataType
                displayName
                options
            }
        }
    }
`

const DELETE_MODEL_CONFIGURATION = gql`
    mutation ($id: UUID!, $ignoreAffected: Boolean) {
        delete: deleteModelConfiguration(
            id: $id
            ignoreAffected: $ignoreAffected
        ) {
            success
            affected {
                model
                name
                id
            }
            blockers {
                model
                name
                id
            }
        }
    }
`

export const ModelConfigurationList = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState([])
    const [query, setQuery] = useState("")
    const [filter, setFilter] = useState(null)

    const { t, i18n } = useTranslation(["common", "table"])

    const { data, loading } = useQuery(MODEL_CONFIGURATION_SEARCH_QUERY, {
        variables: {
            pageNum,
            order,
            query,
            filter,
        },
    })

    const [currentElement, setCurrentElement] = useState(null)

    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    return (
        <div>
            <Banner text={t("table:model_configurations")} />
            {currentElement && (
                <DeletionWithAffectedModal
                    id={currentElement?.id}
                    delete_mutation={DELETE_MODEL_CONFIGURATION}
                    onDismiss={() => setCurrentElement(null)}
                    refetchQueries={[MODEL_CONFIGURATION_SEARCH_QUERY]}
                    onCancel={() => setCurrentElement(null)}
                    name={currentElement?.name}
                />
            )}

            <div className={dropDownStyle.container}>
                <SmartFilter
                    filterFieldQuery={FILTER_QUERY}
                    filterPath={(q) => q?.modelConfigurations?.filterFields}
                    setQuery={setQuery}
                    setFilter={setFilter}
                    suggestedQuery={data?.modelConfigurations?.suggestedQuery}
                />
                <div className={tableStyle.buttonBar}>
                    <Link
                        to={"/model_configuration"}
                        className={buttonStyle.button}
                    >
                        {t("table:add_new_model_configuration")}
                    </Link>
                </div>
            </div>

            {!loading && !data?.modelConfigurations?.page?.length ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}
            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 3em",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:model_configuration")}
                    asc="NameAsc"
                    desc="NameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pricing_list")}
                    asc="PricingListNameAsc"
                    desc="PricingListNameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:pricing_list_group")}
                    asc="PricingListGroupNameAsc"
                    desc="PricingListGroupNameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:strategy")}
                    asc="StrategyNameAsc"
                    desc="StrategyNameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:created_at")}
                    asc="CreatedAtAsc"
                    desc="CreatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:updated_at")}
                    asc="UpdatedAtAsc"
                    desc="UpdatedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:status")}
                />
                <p className={tableStyle.header} />
                {loading && !data?.modelConfigurations && (
                    <TableContentLoader columns={9} />
                )}
                {data?.modelConfigurations?.page.map((s, i) => (
                    <Fragment key={i}>
                        <Link
                            className={tableStyle.cell}
                            to={`/model_configuration/${s?.id}`}
                        >
                            {s?.name}
                        </Link>
                        <Link
                            className={tableStyle.cell}
                            to={`/pricing_list/${s?.pricingList?.id}`}
                        >
                            {s?.pricingList?.name}
                        </Link>
                        <Link
                            className={tableStyle.cell}
                            to={`/pricing_list_group/${s?.pricingListGroup?.id}`}
                        >
                            {s?.pricingListGroup?.name}
                        </Link>
                        <p className={tableStyle.cell}>
                            <Link to={`/strategy/${s?.strategy?.id}`}>
                                {s?.strategy?.name}
                            </Link>
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.createdAt))}
                        </p>
                        <p className={tableStyle.cell}>
                            {dateFormat.format(new Date(s?.updatedAt))}
                        </p>
                        <EnumLabel
                            className={tableStyle.cell}
                            label={s?.systemStatus}
                        />

                        <p className={tableStyle.actionCell}>
                            <button
                                className={cn(
                                    tableStyle.actionButton,
                                    tableStyle.negativeActionButton,
                                )}
                                onClick={() => {
                                    setCurrentElement({
                                        id: s?.id,
                                        name: s?.name,
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </p>
                    </Fragment>
                ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.modelConfigurations}
                loading={loading && !data?.modelConfigurations}
            />
        </div>
    )
}
