import { cloneDeep } from "lodash"

const initialState = {
    isShown: false,
    notifications: [],
}

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION"
export const DISMISS_NOTIFICATION = "DISMISS_NOTIFICATION"

export const INFO = "INFO"
export const ERROR = "ERROR"
export const WARNING = "WARNING"
export const DANGER = "DANGER"

/**
 * Reducer for the notification.
 *
 * @param state the previous state
 * @param type the type of the action that should be performed
 * @param notification the notification params that should be shown
 * @returns {{isShown: boolean, notifications: *[]}|{isShown: boolean, notifications: []}} state if the notification
 * system, if its shown and a queue of notifications
 * @constructor
 */
export const NotificationReducer = (
    state = initialState,
    { type, notification },
) => {
    const notifications = cloneDeep(state.notifications)

    switch (type) {
        case DISMISS_NOTIFICATION:
            notifications.pop()
            return {
                isShown: notifications.length > 0,
                notifications,
            }
        case SHOW_NOTIFICATION:
            notifications.push(notification)
            return {
                isShown: true,
                notifications,
            }
        default:
            return state
    }
}
