import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import cn from "classnames"
import { EnumLabel } from "../../util/table/EnumLabel"
import { showError } from "../../../redux/actions/NotificationActions"
import { getSafeFormatter, getTimeFormatter } from "../../../i18n"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"

const GET_TASKS = gql`
    query ($pageNum: Int!, $order: [TaskAllOrder]) {
        tasks(pageNum: $pageNum, orderBy: $order, limit: 50) {
            currentPage
            hasNext
            hasPrevious
            pages
            page {
                id
                taskName
                priority
                attempts
                queue
                lockedAt
                runAt
            }
        }
    }
`

const DELETE_TASK = gql`
    mutation ($id: Int!) {
        deleteTask(id: $id)
    }
`

export const TaskList = () => {
    const [pageNum, setPage] = useState(1)
    const [order, setOrder] = useState(["PriorityDesc"])

    const { t, i18n } = useTranslation(["common", "table"])

    const { data, loading } = useQuery(GET_TASKS, {
        variables: {
            pageNum,
            order,
        }
    })
    const dispatch = useDispatch()

    const [deleteTask] = useMutation(DELETE_TASK, {
        fetchPolicy: "network-only",
        refetchQueries: [GET_TASKS],
    })

    const dateFormat = getSafeFormatter(getTimeFormatter(i18n.language))

    return (
        <div>
            <Banner text={t("table:tasks")} />
            {!loading && !data?.tasks ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}

            <div
                className={tableStyle.table}
                style={{
                    gridTemplateColumns: "2fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 3em",
                }}
            >
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:name")}
                    asc="TaskNameAsc"
                    desc="TaskNameDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:queue")}
                    asc="QueueAsc"
                    desc="QueueDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:scheduled")}
                    asc="RunAtAsc"
                    desc="RunAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:start_datetime")}
                    asc="LockedAtAsc"
                    desc="LockedAtDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:priority")}
                    asc="PriorityAsc"
                    desc="PriorityDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader
                    className={tableStyle.header}
                    label={t("table:attempts")}
                    asc="AttemptsAsc"
                    desc="AttemptsDesc"
                    order={order}
                    setOrder={setOrder}
                />
                <TableHeader />

                {loading && !data?.pricingListGroup && (
                    <TableContentLoader columns={6} />
                )}
                {data?.tasks?.page
                    .filter((s) => s?.queue !== "LOW")
                    .map((s, i) => (
                        <Fragment key={i}>
                            <p className={tableStyle.cell}>
                                {s?.taskName
                                    ? s.taskName
                                          .split(".")
                                          .pop()
                                          ?.replaceAll("_", " ")
                                    : null}
                            </p>
                            <EnumLabel
                                className={tableStyle.cell}
                                label={s?.queue}
                            />
                            <p className={tableStyle.cell}>
                                {dateFormat.format(new Date(s?.runAt))}
                            </p>
                            <p className={tableStyle.cell}>
                                {dateFormat.format(new Date(s?.lockedAt))}
                            </p>
                            <p className={tableStyle.cell}>{s?.priority}</p>
                            <p
                                className={cn(
                                    tableStyle.cell,
                                    tableStyle.lastCell,
                                )}
                            >
                                {s?.attempts}
                            </p>
                            <p className={tableStyle.actionCell}>
                                <button
                                    disabled={s?.lockedAt}
                                    className={cn(
                                        tableStyle.actionButton,
                                        tableStyle.lastCell,
                                        tableStyle.negativeActionButton,
                                    )}
                                    onClick={() =>
                                        deleteTask({
                                            variables: { id: s?.id },
                                        }).catch((e) =>
                                            dispatch(showError(e.message)),
                                        )
                                    }
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </p>
                        </Fragment>
                    ))}
            </div>
            <Pagination
                onPageChange={(page) => setPage(page)}
                pagination={data?.tasks}
                loading={loading && !data?.tasks}
            />
        </div>
    )
}
