import { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Banner } from "../../util/Banner"
import tableStyle from "../../../style/table.module.css"
import { Link, Redirect, useHistory, useParams } from "react-router-dom"
import { Flash, WARNING } from "../../util/Flash"
import { TableHeader } from "../../util/table/TableHeader"
import { TableContentLoader } from "../../util/table/TableContentLoader"
import { Pagination } from "../../util/table/Pagination"
import inputStyle from "../../../style/input.module.css"
import { uniqueId } from "lodash"
import { showProduct } from "../../../redux/actions/ModalActions"
import { useDispatch } from "react-redux"
import style from "../groups/GroupUpload.module.css"
import makeAnimated from "react-select/animated"
import Select from "react-select"
import buttonStyle from "../../../style/button.module.css"
import { showError, showInfo } from "../../../redux/actions/NotificationActions"
import c from "capitalize"
import {
    getCurrencyFormatter,
    getDayMonthYearFormatter,
    getSafeFormatter,
} from "../../../i18n"
import { Loader } from "../../util/Loader"
import cn from "classnames"

/**
 * Query to get one assortment group
 *
 * @type {DocumentNode}
 */
const GET_ASSORTMENT_GROUP = gql`
    query assortmentGroupProducts($id: UUID!, $pageNum: Int) {
        assortments(limit: -1, filterParams: { isComplimentEquals: false }) {
            page {
                id
                name
            }
        }
        assortmentGroup(id: $id) {
            assortments(limit: -1) {
                page {
                    id
                    name
                }
            }
            assortmentProducts(limit: 20, pageNum: $pageNum) {
                currentPage
                hasNext
                hasPrevious
                pages
                page {
                    id
                    ifaDate
                    historicIfaDate
                    agrossoDate
                    competitorDate
                    performanceDate
                    pricingListPerformanceDate
                    enlistingDelistingRule
                    product {
                        id
                        pzn
                        name
                        atc
                        unit
                        quantity
                        supplierName
                        AEP
                        GEP
                        agrosso1
                        agrosso2
                        agrosso1Midas
                        agrosso2Midas
                        agrosso1Mvda
                        agrosso2Mvda
                        agrosso1Generic
                        agrosso2Generic
                        revenueGrossoTotal
                        revenueGrossoWholesale
                        revenueGrossoPhoenix
                        revenueGrossoDirect
                    }
                }
            }
            id
            name
            action
            productAmount
            isSystemGroup
            isUploading
        }
    }
`

/**
 * Query to update one assortment group
 *
 * @type {DocumentNode}
 */
const UPDATE_ASSORTMENT_GROUP = gql`
    mutation (
        $id: UUID!
        $name: String
        $assortments: [UUID]
        $action: String
    ) {
        updateAssortmentGroup(
            id: $id
            name: $name
            assortments: $assortments
            listingAction: $action
        ) {
            id
        }
    }
`

/**
 * Query to trigger the assortment group export
 *
 * @type {DocumentNode}
 */
const EXPORT_CSV = gql`
    mutation send($id: UUID!) {
        sendAssortmentGroupIdAsMail(assortmentGroupId: $id)
    }
`

/**
 * This element is the assortment group editor which allows for
 * changing the name of an assortment group, its type and assigning it to
 * different assortmennts
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const AssortmentGroupEditor = () => {
    const { id } = useParams()

    const [name, setName] = useState("")
    const [action, setAction] = useState("")
    const [assortments, setAssortments] = useState([])

    const [name_input_id] = useState(() => uniqueId("name_input_"))
    const [action_id] = useState(() => uniqueId("action_"))
    const [assortments_id] = useState(() => uniqueId("assortments_"))

    const [pageNum, setPage] = useState(1)
    const [query] = useState("")

    const { t, i18n } = useTranslation(["common", "table"])

    const { data, loading } = useQuery(GET_ASSORTMENT_GROUP, {
        variables: {
            pageNum,
            query,
            id,
        },
    })

    const [exportGroup] = useMutation(EXPORT_CSV)

    const [updateAssortment, { loading: assortmentLoading }] = useMutation(
        UPDATE_ASSORTMENT_GROUP,
        {
            refetchQueries: [GET_ASSORTMENT_GROUP],
        },
    )

    const history = useHistory()

    const dateFormat = getSafeFormatter(getDayMonthYearFormatter(i18n.language))
    const currencyFormat = getSafeFormatter(getCurrencyFormatter(i18n.language))

    useEffect(() => {
        let clear = -1

        if (data?.assortmentGroup?.isUploading) {
            clear = setTimeout(() => window.location.reload(), 1000 * 30)
        }

        setName(data?.assortmentGroup?.name || "")
        setAction(data?.assortmentGroup?.action || "")
        setAssortments(
            data?.assortmentGroup?.assortments?.page?.map((a) => a?.id) || [],
        )

        return () => window.clearTimeout(clear)
    }, [data?.assortmentGroup])

    const dispatch = useDispatch()

    if (!id || (!loading && !data?.assortmentGroup)) {
        return <Redirect to={"/"} />
    }

    return (
        <div>
            <Banner
                text={`${t("table:assortment_group")}: ${
                    data?.assortmentGroup?.name || ""
                }`}
            />
            <div className={tableStyle.stackedFilterBar}>
                {loading ? (
                    <Loader />
                ) : (
                    <div className={tableStyle.filterBar}>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={name_input_id}
                                className={tableStyle.label}
                            >
                                {t("table:assortment_group_name")}
                            </label>
                            <input
                                disabled={data?.assortmentGroup?.isSystemGroup}
                                id={name_input_id}
                                className={inputStyle.text}
                                type="text"
                                value={name}
                                onChange={(evt) => setName(evt.target.value)}
                            />
                        </div>
                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={action_id}
                                className={tableStyle.label}
                            >
                                {t("table:action")}
                            </label>
                            <Select
                                isDisabled={
                                    data?.assortmentGroup?.isSystemGroup
                                }
                                id={action_id}
                                onChange={(evt) => setAction(evt.value)}
                                className={style.selectField}
                                value={
                                    action === "ENLIST"
                                        ? {
                                              value: "ENLIST",
                                              label: c(t("enums:enlist")),
                                          }
                                        : {
                                              value: "DELIST",
                                              label: c(t("enums:delist")),
                                          }
                                }
                                placeholder={""}
                                options={[
                                    {
                                        value: "ENLIST",
                                        label: c(t("enums:enlist")),
                                    },
                                    {
                                        value: "DELIST",
                                        label: c(t("enums:delist")),
                                    },
                                ]}
                            />
                        </div>

                        <div className={tableStyle.filterInnerSection}>
                            <label
                                htmlFor={assortments_id}
                                className={tableStyle.label}
                            >
                                {t("table:assortments")}
                            </label>
                            <Select
                                isDisabled={
                                    data?.assortmentGroup?.isSystemGroup
                                }
                                isMulti={true}
                                className={style.selectMany}
                                value={assortments?.map((a) => ({
                                    value: a,
                                    label: data?.assortments?.page.find(
                                        (as) => as.id === a,
                                    )?.name,
                                }))}
                                onChange={(as) =>
                                    setAssortments(as.map((a) => a.value))
                                }
                                loadingMessage={() => t("common:loading")}
                                isLoading={loading}
                                components={makeAnimated()}
                                options={data?.assortments?.page?.map((a) => ({
                                    value: a.id,
                                    label: a.name,
                                }))}
                                id={assortments_id}
                            />
                        </div>
                    </div>
                )}
                <div className={tableStyle.buttonSection}>
                    {!loading &&
                        !data?.assortmentGroup?.isSystemGroup &&
                        !data?.assortmentGroup?.isUploading && (
                            <Link
                                to={`/enlist_and_delist/csv/${data?.assortmentGroup?.id}`}
                                className={buttonStyle.button}
                            >
                                {t("table:reupload_csv")}
                            </Link>
                        )}
                    <button
                        className={buttonStyle.button}
                        onClick={() =>
                            exportGroup({
                                variables: {
                                    id,
                                },
                            })
                                .then(() =>
                                    dispatch(
                                        showInfo("common:export_triggered"),
                                    ),
                                )
                                .catch((e) => dispatch(showError(e.message)))
                        }
                    >
                        {t("table:download_csv")}
                    </button>
                    <button
                        className={buttonStyle.button}
                        disabled={
                            loading || data?.assortmentGroup?.isSystemGroup
                        }
                        onClick={() => {
                            updateAssortment({
                                variables: {
                                    id,
                                    name,
                                    assortments,
                                    action,
                                },
                            })
                                .then((r) =>
                                    history.push(
                                        `/assortment_group/${r?.data?.updateAssortmentGroup?.id}`,
                                    ),
                                )
                                .catch((e) => dispatch(showError(e.message)))
                        }}
                    >
                        {t("table:save")}
                    </button>
                </div>
            </div>
            {!assortmentLoading && !loading && !data?.assortmentGroup ? (
                <Flash text={t("table:no_results")} type={WARNING} />
            ) : null}

            {data?.assortmentGroup?.isUploading ? (
                <Loader />
            ) : (
                <>
                    <div
                        className={tableStyle.table}
                        style={{
                            gridTemplateColumns:
                                "0.5fr repeat(2, 1fr) repeat(20, 0.5fr) 1fr",
                        }}
                    >
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:pzn")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:product_name")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:supplier_name")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:atc")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:unit")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:aep")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:gep")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:agrosso") + " 1  Generic"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:agrosso") + " 2  Generic"}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:agrosso") + " 1  MVDA"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:agrosso") + " 2  MVDA"}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:agrosso") + " 1  MIDAS"}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:agrosso") + " 2  MIDAS"}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:revenue_grosso_total")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:revenue_grosso_wholesale")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:revenue_grosso_phoenix")}
                        />
                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:revenue_grosso_direct")}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:agrosso_date")}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:ifa_date")}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:historic_ifa_date")}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:competitor_date")}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:performance_date")}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:price_list_performance_date")}
                        />

                        <TableHeader
                            className={tableStyle.header}
                            label={t("table:enlisting_delisting_rule")}
                        />

                        {(assortmentLoading || loading) &&
                            !data?.assortmentGroup && (
                                <TableContentLoader columns={26} />
                            )}
                        {data?.assortmentGroup.assortmentProducts.page.map(
                            (s, i) => (
                                <Fragment key={i}>
                                    <p
                                        className={tableStyle.cell}
                                        onClick={() =>
                                            dispatch(
                                                showProduct(s?.product?.id),
                                            )
                                        }
                                    >
                                        {s?.product?.pzn}
                                    </p>
                                    <p
                                        className={tableStyle.cell}
                                        onClick={() =>
                                            dispatch(
                                                showProduct(s?.product?.id),
                                            )
                                        }
                                    >
                                        {s?.product?.name}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {s?.product?.supplierName}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {s?.product?.atc}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {s?.product?.unit}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(s?.product?.AEP)}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(s?.product?.GEP)}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.agrosso1Generic,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.agrosso2Generic,
                                        )}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.agrosso1Mvda,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.agrosso2Mvda,
                                        )}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.agrosso1Midas,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.agrosso2Midas,
                                        )}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.revenueGrossoTotal,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.revenueGrossoWholesale,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.revenueGrossoPhoenix,
                                        )}
                                    </p>
                                    <p className={tableStyle.cell}>
                                        {currencyFormat.format(
                                            s?.product?.revenueGrossoDirect,
                                        )}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {dateFormat.format(
                                            new Date(s?.agrossoDate),
                                        )}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {dateFormat.format(
                                            new Date(s?.ifaDate),
                                        )}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {dateFormat.format(
                                            new Date(s?.historicIfaDate),
                                        )}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {dateFormat.format(
                                            new Date(s?.competitorDate),
                                        )}
                                    </p>

                                    <p className={tableStyle.cell}>
                                        {dateFormat.format(
                                            new Date(s?.performanceDate),
                                        )}
                                    </p>

                                    <p className={cn(tableStyle.cell)}>
                                        {dateFormat.format(
                                            new Date(
                                                s?.pricingListPerformanceDate,
                                            ),
                                        )}
                                    </p>
                                    <p
                                        className={cn(
                                            tableStyle.cell,
                                            tableStyle.lastCell,
                                        )}
                                    >
                                        {s?.enlistingDelistingRule}
                                    </p>
                                </Fragment>
                            ),
                        )}
                    </div>
                    <Pagination
                        onPageChange={(page) => setPage(page)}
                        pagination={data?.assortmentGroup.assortmentProducts}
                        loading={
                            (assortmentLoading || loading) &&
                            !data?.assortmentGroup
                        }
                    />
                </>
            )}
        </div>
    )
}
