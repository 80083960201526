import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import en_common from "./locales/en/common.json"
import en_filter from "./locales/en/filter.json"
import en_enums from "./locales/en/enums.json"
import en_table from "./locales/en/table.json"
import en_page_names from "./locales/en/page_names.json"
import en_messages from "./locales/en/messages.json"
import en_strategy from "./locales/en/strategy.json"

import de_common from "./locales/de/common.json"
import de_filter from "./locales/de/filter.json"
import de_enums from "./locales/de/enums.json"
import de_table from "./locales/de/table.json"
import de_page_names from "./locales/de/page_names.json"
import de_messages from "./locales/de/messages.json"
import de_strategy from "./locales/de/strategy.json"

import Cookies from "universal-cookie"
import { isDate } from "lodash"

const cookies = new Cookies()
const LANGUAGES = ["de", "en"]

/**
 * Mapping for the translation
 **/
const resources = {
    en: {
        common: en_common,
        filter: en_filter,
        enums: en_enums,
        table: en_table,
        page_names: en_page_names,
        messages: en_messages,
        strategy: en_strategy,
    },
    de: {
        common: de_common,
        filter: de_filter,
        enums: de_enums,
        table: de_table,
        page_names: de_page_names,
        messages: de_messages,
        strategy: de_strategy,
    },
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "de",
        debug: process.env.NODE_ENV === "development",
        interpolation: {
            escapeValue: false,
        },
        resources,
    })

cookies.set("user_lang", i18n.language, {
    path: "/",
    secure: true,
    sameSite: "strict",
    domain: window.location.hostname,
})

/**
 * generates time formatter that displays the full time in a short manner
 * @param lang the language the date should be formatted in
 * @returns {Intl.DateTimeFormat} the formatter that can be used to format dates in that way
 */
export const getTimeFormatter = (lang) =>
    Intl.DateTimeFormat(lang, {
        dateStyle: "short",
        timeStyle: "short",
    })

/**
 * generates time formatter that displays just the month and year
 * @param lang the language the date should be formatted in
 * @returns {Intl.DateTimeFormat} the formatter that can be used to format dates in that way
 */
export const getMonthYearFormatter = (lang) =>
    Intl.DateTimeFormat(lang, {
        year: "numeric",
        month: "long",
    })

/**
 * generates time formatter that displays the date in a short manner
 * @param lang the language the date should be formatted in
 * @returns {Intl.DateTimeFormat} the formatter that can be used to format dates in that way
 */
export const getDayMonthYearFormatter = (lang) =>
    Intl.DateTimeFormat(lang, {
        dateStyle: "short",
    })

/**
 * utility function to cycle the language between all available
 */
export const cycleLanguage = () => {
    let current = LANGUAGES.indexOf(i18n.language)
    if (current < 0) {
        current = 0
    }
    const locale = LANGUAGES[(current + 1) % LANGUAGES.length]
    cookies.set("user_lang", locale, {
        path: "/",
        secure: true,
        sameSite: "strict",
        domain: window.location.hostname,
    })
    i18n.changeLanguage(locale)
}

/**
 * generates number formatter that displays a number with two decimals
 * @param lang the language that is used to format the language
 * @returns {Intl.NumberFormat} the formatter that can be used to format numbers in that way
 */
export const getNumberFormatter = (lang) =>
    Intl.NumberFormat(lang, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })

/**
 * generates number formatter that displays a number without decimals
 * @param lang the language that is used to format the language
 * @returns {Intl.NumberFormat} the formatter that can be used to format numbers in that way
 */
export const getIntegerFormatter = (lang) =>
    Intl.NumberFormat(lang, {
        maximumFractionDigits: 0,
    })

/**
 * generates number formatter that displays a number as euro.
 * The number is either formatted with two decimals or as a whole number
 * @param lang the language that is used to format the language
 * @param round if the number should be a hole number or not
 * @returns {Intl.NumberFormat} the formatter that can be used to format numbers in that way
 */
export const getCurrencyFormatter = (lang, round) =>
    Intl.NumberFormat(lang, {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: round ? 0 : 2,
        minimumFractionDigits: round ? 0 : 2,
    })

/**
 * Formatter wrapper that insures formatters don't crash if invalid input is given
 * @param formatter the formatter that should be used
 * @returns {{format: ((function(*): (string|*))|*)}} an object with a format function which
 * returns and empty string on invalid input
 */
export const getSafeFormatter = (formatter) => ({
    format: (number) => {
        if (isNaN(number) || number === "" || number === null) {
            return ""
        } else if (isDate(number)) {
            if (
                number &&
                number?.getFullYear &&
                number.getFullYear() !== 1970
            ) {
                return formatter.format(number)
            } else {
                return ""
            }
        } else {
            return formatter.format(number)
        }
    },
})

/**
 * Formatter wrapper that multiplies a number by 100 first and appends a %.
 * If the formatter receives an invalid number it returns and emtpy stringf.
 * @param formatter the formatter that should be used
 * @returns {{format: ((function(*): (string|string))|*)}}an object with a format function which
 * returns and empty string on invalid input
 */
export const getPercentSafeFormatter = (formatter) => ({
    format: (number) => {
        if (isNaN(number) || number === "" || number === null) {
            return ""
        } else {
            return formatter.format(number * 100) + "%"
        }
    },
})
