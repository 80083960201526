import { useDispatch, useSelector } from "react-redux"
import { dismissNotification } from "../../../redux/actions/NotificationActions"
import ReactDOM, { createPortal } from "react-dom"
import styles from "./Notification.module.css"
import buttonStyle from "../../../style/button.module.css"
import cn from "classnames"
import { animated, useSpring } from "react-spring"
import { useTranslation } from "react-i18next"
import {
    DANGER,
    ERROR,
    INFO,
    WARNING,
} from "../../../redux/reducers/NotificationReducer"

/**
 * This element is a popup notification.
 * It displays errors translated if possible with the traceback id formatted.
 * If a confirm and cancel function are provided it transforms into a confirm dialog.
 *
 * @param level the level of the notification
 * @param message the message the notification displays
 * @param onConfirm the function that triggers when confirm is pressed
 * @param onCancel the function that triggers when cancel is pressed
 * @param list a list of strings that is displayed
 * @returns {JSX.Element} the notification content
 * @constructor
 */
const NotificationContent = ({ level, message, onConfirm, onCancel, list }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation(["common"])

    const warningProps = useSpring({
        transform: "scale(1)",
        from: { transform: "scale(0)" },
    })

    const wrapperProps = useSpring({
        opacity: 1,
        from: { opacity: 0 },
    })

    const clean_message = message?.split("$$traceback$$")

    return (
        <animated.div className={styles.wrapper} style={wrapperProps}>
            <animated.div
                className={styles.notificationBox}
                style={warningProps}
            >
                <p
                    className={cn(
                        styles.topBar,
                        level === ERROR && styles.error,
                        level === DANGER && styles.error,
                        level === WARNING && styles.warning,
                        level === INFO && styles.info,
                    )}
                >
                    {level === ERROR && t("common:error")}
                    {level === DANGER && t("common:attention")}
                    {level === WARNING && t("common:warning")}
                    {level === INFO && t("common:info")}
                </p>
                <p className={styles.message}>{t(clean_message[0])}</p>
                {clean_message[1] ? <p>{clean_message[1]}</p> : null}
                {Array.isArray(list) ? (
                    <ul className={styles.list}>
                        {list.map((e, i) => (
                            <li key={i}>{e}</li>
                        ))}
                    </ul>
                ) : null}
                <div className={styles.buttonBar}>
                    {onCancel && onConfirm ? (
                        <>
                            <button
                                className={cn(
                                    buttonStyle.button,
                                    buttonStyle.bigButton,
                                )}
                                onClick={onConfirm}
                            >
                                {t("yes")}
                            </button>
                            <button
                                className={cn(
                                    buttonStyle.button,
                                    buttonStyle.bigButton,
                                )}
                                onClick={onCancel}
                            >
                                {t("no")}
                            </button>
                        </>
                    ) : (
                        <button
                            className={cn(
                                buttonStyle.button,
                                buttonStyle.bigButton,
                            )}
                            onClick={() => dispatch(dismissNotification())}
                        >
                            {t("common:ok")}
                        </button>
                    )}
                </div>
            </animated.div>
        </animated.div>
    )
}

/**
 * Notification wrapper which supplies the portal
 *
 * @returns {NotificationContent|null} the notification portal
 * @constructor
 */
export const Notification = () => {
    const isShown = useSelector((state) => state.notification.isShown)
    const notifications = useSelector(
        (state) => state.notification.notifications,
    )

    if (isShown) {
        const notification = notifications[notifications.length - 1]
        if (notification) {
            return ReactDOM.createPortal(
                <NotificationContent
                    message={notification.message}
                    level={notification.type}
                    list={notification.list}
                />,
                document.getElementById("notification"),
            )
        }
    } else {
        return null
    }
}

/**
 * Notification for confirm dialog wrapper which supplies the portal
 *
 * @param onConfirm the function that triggers when confirm is pressed
 * @param onCancel the function that triggers when cancel is pressed
 * @param text the text that is displayed on the dialog
 * @param level the level of the notification
 * @returns {NotificationContent} the notification portal
 * @constructor
 */
export const ConfirmNotification = ({ onConfirm, onCancel, text, level }) => {
    return createPortal(
        <NotificationContent
            onConfirm={onConfirm}
            onCancel={onCancel}
            message={text}
            level={level}
        />,
        document.getElementById("confirm"),
    )
}
